import * as React from 'react';
import PropTypes from 'prop-types';

import Button, { BUTTON_TYPE } from '../../../../../components/Button';
import { handleCSVConversion } from '../../../../../components/ExportView/Helpers';
import theme from '../../../../../theme';

const generateRows = (data, corporation) => {
  const rows = [];

  data.map((employee) => {
    const row = [];
    const favorite = corporation.favorite.find((f) => f.waeID === employee.id) ? 'Favorite' : '-';
    const attendance = employee.attendance ? employee.attendance : 'Missing attendance data';
    const rating = employee.rating ? employee.rating : 'Not rated';
    const dnr = corporation.DNR.find((d) => d.waeID === employee.id) ? 'DNR' : '-';
    const note = employee.employerNote || '-';

    row.push(employee.name);
    row.push(attendance);
    row.push(rating);
    row.push(favorite);
    row.push(dnr);
    row.push(note);

    rows.push(row);
    return employee;
  });

  return rows;
};

const ExportRosterToCSV = ({ roster, corporation }) => {
  const handleClick = () => {
    const generatedRows = generateRows(roster, corporation);
    const headerRow = ['Name', 'Attendance', 'Rating', 'Favorite', 'DNR', 'Employer note'];

    const rows = [headerRow, ...generatedRows];
    const fileName = 'Job Roster';
    handleCSVConversion({ rows, fileName });
  };

  return (
    <Button
      text="Export Roster"
      onClick={handleClick}
      type={BUTTON_TYPE.OUTLINED}
      sx={{ padding: theme.spacing(0.67, 2) }}
    />
  );
};

ExportRosterToCSV.propTypes = {
  corporation: PropTypes.shape({}),
  roster: PropTypes.shape(Array),
};

export default ExportRosterToCSV;
