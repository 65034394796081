import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import Button from '../Button';

const ButtonActions = ({ buttonData, formValues, setButtonOnBottomAndFixed }) => {
  const ButtonGridItems = buttonData.map((button) => {
    const {
      color,
      disabled,
      endIcon,
      fullWidth,
      onClick,
      showIndeterminateProgress,
      startIcon,
      sx,
      text,
      type,
      variant,
      xs,
    } = button;

    const ButtonAction = (
      <Button
        color={color}
        disabled={disabled}
        fullWidth={fullWidth}
        onClick={() => onClick && onClick(formValues)}
        showIndeterminateProgress={showIndeterminateProgress}
        startIcon={startIcon}
        sx={sx}
        text={text}
        type={type}
        variant={variant}
        endIcon={endIcon}
      />
    );

    return (
      <Grid item key={`btn-${text}`} xs={xs}>
        {ButtonAction}
      </Grid>
    );
  });

  return (
    <Grid
      container
      item
      spacing={1}
      justifyContent="flex-end"
      sx={{ marginTop: setButtonOnBottomAndFixed ? 0 : 5, marginBottom: 1 }}
    >
      {ButtonGridItems}
    </Grid>
  );
};

ButtonActions.propTypes = {
  buttonData: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      disabled: PropTypes.bool,
      fullWidth: PropTypes.bool,
      onClick: PropTypes.func,
      showIndeterminateProgress: PropTypes.bool,
      text: PropTypes.string,
      variant: PropTypes.string,
      endIcon: PropTypes.shape({}),
    })
  ),
  formValues: PropTypes.shape({}),
  setButtonOnBottomAndFixed: PropTypes.bool,
};

export default ButtonActions;
