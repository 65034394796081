import React from 'react';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  selectHasUnreadNotifications,
  selectIsLoadingNotifications,
  selectNotifications,
} from '../../store/selectors/notificationSelectors';
import theme from '../../theme';
import IconButton from '../IconButton';

import NotificationCard from './SubComponents/NotificationCard';
import {
  setNotificationFrontendSoftDelete,
  setNotificationsToRead,
  setNotificationUndoSoftDelete,
} from './reducer';

const NotificationCenter = ({ openNotifications, onClose }) => {
  const phoneScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const isLoadingNotifications = useSelector(selectIsLoadingNotifications);
  const hasUnreadNotifications = useSelector(selectHasUnreadNotifications);

  const handleMarkAllAsRead = () => {
    const allUnreadNotificationIds = flow(
      filter((n) => n.unread),
      map((n) => n._id)
    )(notifications);

    if (allUnreadNotificationIds && allUnreadNotificationIds.length > 0) {
      dispatch(
        setNotificationsToRead({
          fields: {
            notifications: allUnreadNotificationIds,
            read: true,
          },
        })
      );
    }
  };

  const handleSetNotificationRead = (notificationId) => {
    if (find((n) => n._id === notificationId && n.unread, notifications)) {
      dispatch(
        setNotificationsToRead({
          fields: {
            notifications: [notificationId],
            read: true,
          },
        })
      );
    }
  };

  const handleOnFrontendSoftDelete = (notificationId) => {
    dispatch(setNotificationFrontendSoftDelete(notificationId));
  };

  const handleOnFrontendUndoDelete = (notificationId) => {
    dispatch(setNotificationUndoSoftDelete(notificationId));
  };

  return (
    <Drawer
      anchor="right"
      open={openNotifications}
      onClose={onClose}
      sx={{ zIndex: '2000' }}
      PaperProps={{
        sx: {
          width: (!phoneScreen && '576px') || '100%',
          paddingTop: theme.spacing(2),
        },
      }}
    >
      <Box>
        <IconButton
          iconElement={<CloseIcon />}
          onClick={() => onClose()}
          size="small"
          sx={{ marginLeft: 0.5, marginTop: 0.5 }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '35px',
        }}
      >
        <Box sx={{ marginLeft: '32px' }}>
          <Typography
            sx={{
              fontFamily: theme.components.notification.header.fontFamily,
              fontSize: theme.components.notification.header.fontSize,
            }}
          >
            Notifications
          </Typography>
        </Box>
        <Box sx={{ marginTop: 0.5, marginRight: '32px' }}>
          <Typography
            variant="string"
            onClick={handleMarkAllAsRead}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              color: hasUnreadNotifications
                ? theme.components.notification.palette.unRead
                : theme.components.notification.palette.markRead,
              fontFamily: theme.components.notification.markReadText.fontFamily,
              fontSize: theme.components.notification.markReadText.fontSize,
            }}
          >
            Mark all as read
          </Typography>
        </Box>
      </Box>
      <Box>
        {isLoadingNotifications && (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            {' '}
            <CircularProgress sx={{ marginTop: '20%' }} />{' '}
          </Box>
        )}

        {!isLoadingNotifications &&
          notifications &&
          notifications.length > 0 &&
          notifications.map((notification) => (
            <NotificationCard
              key={notification._id}
              notification={notification}
              unread={notification.unread}
              handleOnDelete={handleOnFrontendSoftDelete}
              handleOnUndoDelete={handleOnFrontendUndoDelete}
              handleSetNotificationRead={handleSetNotificationRead}
              closeNotificationCenter={onClose}
            />
          ))}
      </Box>
    </Drawer>
  );
};

NotificationCenter.propTypes = {
  openNotifications: PropTypes.bool,
  onClose: PropTypes.func,
};

export default NotificationCenter;
