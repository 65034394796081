import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';

import InvitationCard from './InvitationCard';

const Invitation = ({ selectedJobInvitations, corporation }) => (
  <Box>
    {selectedJobInvitations &&
      selectedJobInvitations.length > 0 &&
      selectedJobInvitations.map((jobInvite) => {
        const favorite =
          corporation.favorite.find((f) => f.waeID === jobInvite.candidateId) || false;
        return (
          <ListItem>
            <InvitationCard inviteData={jobInvite} fav={!!favorite} />
          </ListItem>
        );
      })}
  </Box>
);

Invitation.propTypes = {
  selectedJobInvitations: PropTypes.arrayOf(PropTypes.shape()),
  corporation: PropTypes.shape({ favorite: PropTypes.shape({ find: PropTypes.func }) }),
};

export default Invitation;
