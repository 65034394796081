import React, { useEffect, useState } from 'react';
import { get, map } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import List from '@mui/material/List';

import { apiPost } from '../../../../../datahub/axios';
import LazyList from '../../../../components/LazyList/LazyList';
import selectUser from '../../../../store/selectors/appSelector';
import {
  selectedCompletedCount,
  selectedUpcomingCount,
} from '../../../../store/selectors/userSelectors';
import theme from '../../../../theme';
import { epochToShortDateString, epochToTimeInReadableFormat } from '../../../../utils';
import filters from '../filters';

import JobActivityCard from './JobActivityCard';
import JobActivityNoDataCard from './JobActivityNoDataCard';

const JobActivity = ({ initialValues }) => {
  const user = useSelector(selectUser);
  const token = get('token', user);

  const apiPath = 'job-orders/read';

  const initialUpcomingCount = useSelector(selectedUpcomingCount);
  const initialCompletedCount = useSelector(selectedCompletedCount);

  const [userUpcomingJobs, setUpcomingJobs] = useState([]);
  const [userCompletedJobs, setCompletedJobs] = useState([]);

  const [upcomingPage, setUpcomingPage] = useState(0);
  const [completedPage, setCompletedPage] = useState(0);

  const [upcomingCount, setUpcomingCount] = useState(initialUpcomingCount);
  const [completedCount, setCompletedCount] = useState(initialCompletedCount);

  const upcomingHeight = userCompletedJobs && userCompletedJobs.length ? '35vh' : '65vh';
  const completedHeight = userUpcomingJobs && userUpcomingJobs.length ? '35vh' : '65vh';

  const getNextUpcoming = async () => {
    const upcomingData = {
      page: upcomingPage,
      limit: 4,
      filters: filters.USER_UPCOMING_JOBS,
      filterByUserID: initialValues.id,
      sortBy: [{ field: 'start', ascending: true }],
    };

    const newUpcomingData = await apiPost(apiPath, upcomingData, token);

    const { count, documents } = newUpcomingData.data;
    const selectedUpcomingJobs = map((job) => {
      const { _id, id, name, corporation, start, end } = job;
      const jobDate = `${epochToShortDateString(start)}-${epochToShortDateString(end)}`;
      const jobShift = `${epochToTimeInReadableFormat(start)}-${epochToTimeInReadableFormat(end)}`;
      return {
        id: id || _id,
        name,
        corporation: get(['name'], corporation),
        jobDate,
        jobShift,
      };
    }, documents);
    if (userUpcomingJobs.length) {
      setUpcomingJobs([...userUpcomingJobs, ...selectedUpcomingJobs]);
    } else {
      setUpcomingJobs([...selectedUpcomingJobs]);
    }
    setUpcomingPage(upcomingPage + 1);
    if (count !== upcomingCount) {
      setUpcomingCount(count);
    }
  };

  const getNextCompleted = async () => {
    const completedData = {
      page: completedPage,
      limit: 4,
      filters: filters.USER_COMPLETED_JOBS,
      filterByUserID: initialValues.id,
      sortBy: [{ field: 'start', descending: true }],
    };

    const newCompletedData = await apiPost(apiPath, completedData, token);

    const { count, documents } = newCompletedData.data;
    const selectedCompletedJobs = map((job) => {
      const { _id, id, name, corporation, start, end } = job;
      const jobDate = `${epochToShortDateString(start)}-${epochToShortDateString(end)}`;
      const jobShift = `${epochToTimeInReadableFormat(start)}-${epochToTimeInReadableFormat(end)}`;
      return {
        id: id || _id,
        name,
        corporation: get(['name'], corporation),
        jobDate,
        jobShift,
      };
    }, documents);
    if (userCompletedJobs.length) {
      setCompletedJobs([...userCompletedJobs, ...selectedCompletedJobs]);
    } else {
      setCompletedJobs([...selectedCompletedJobs]);
    }
    setCompletedPage(upcomingPage + 1);
    if (count !== upcomingCount) {
      setCompletedCount(count);
    }
  };

  useEffect(() => {
    getNextUpcoming();
    getNextCompleted();
    // FIXME: Find a way to do this w/out disable linter - HB-2032
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          color: theme.components.detailView.typography.sectionHeader.fontColor,
          fontFamily: theme.components.detailView.typography.sectionHeader.fontFamily,
          fontSize: '18px',
        }}
      >
        {' '}
        Upcoming Jobs
      </Box>
      <List
        component="nav"
        aria-label="secondary mailbox folder"
        sx={{
          maxHeight: upcomingHeight,
          overflowY: 'scroll',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <LazyList
          Card={JobActivityCard}
          getNextData={getNextUpcoming}
          items={userUpcomingJobs}
          itemCount={upcomingCount || 1}
          skeletonVariant="Text"
          skeletonBackgroundColor={theme.components.detailView.palette.sectionBackgroundColor}
          backgroundColor={theme.components.detailView.palette.backgroundColor}
          NoDataCard={JobActivityNoDataCard}
        />
      </List>

      <Box
        sx={{
          color: theme.components.detailView.typography.sectionHeader.fontColor,
          fontFamily: theme.components.detailView.typography.sectionHeader.fontFamily,
          fontSize: '18px',
          marginTop: '2vh',
        }}
      >
        {' '}
        Completed Jobs
      </Box>
      <List
        component="nav"
        aria-label="secondary mailbox folder"
        sx={{
          maxHeight: completedHeight,
          overflowY: 'scroll',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <LazyList
          Card={JobActivityCard}
          getNextData={getNextCompleted}
          items={userCompletedJobs}
          itemCount={completedCount || 1}
          skeletonVariant="Text"
          skeletonBackgroundColor={theme.components.detailView.palette.sectionBackgroundColor}
          backgroundColor={theme.components.detailView.palette.backgroundColor}
          NoDataCard={JobActivityNoDataCard}
        />
      </List>
    </Box>
  );
};

JobActivity.propTypes = {
  container: PropTypes.shape({}),
  initialValues: PropTypes.shape({
    id: PropTypes.string,
  }),

  jobActivities: PropTypes.shape({
    userUpcomingJobs: PropTypes.arrayOf(PropTypes.shape({})),
    userCompletedJobs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default JobActivity;
