import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Form from '../../components/Form';
import { doFieldsMatch } from '../../components/Form/formLevelValidations';
import selectVerifyingIsProcessing from '../../store/selectors/accountVerificationSelector';

import formFieldData from './formData/formFieldData';
import { passwordReset, verifyAccount } from './reducer';

const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
}));

const FormGrid = styled(Grid)(() => ({
  justifyContent: 'center',
}));

const PasswordVerificationOrResetPage = memo(({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isProcessing = useSelector(selectVerifyingIsProcessing);

  const [searchParams] = useSearchParams();
  const emailQuery = searchParams.get('email');
  const verificationCodeQuery = searchParams.get('verificationCode');
  const recoveryCodeQuery = searchParams.get('recoveryCode');

  const handleOnSubmit = useCallback(
    (values) => {
      const verifyAccBody = {
        email: emailQuery,
        verificationCode: verificationCodeQuery,
        newPassword: values.newPassword,
      };

      const resetPassBody = {
        email: emailQuery,
        recoveryCode: recoveryCodeQuery,
        password: values.newPassword,
      };
      if (type === 'passwordReset') {
        dispatch(passwordReset(resetPassBody));
        return;
      }
      if (type === 'accountVerification') {
        dispatch(verifyAccount(verifyAccBody));
      }
    },
    [dispatch, emailQuery, verificationCodeQuery, recoveryCodeQuery, type]
  );

  const buttonData = [
    {
      disabled: !!isProcessing,
      fullWidth: true,
      showIndeterminateProgress: !!isProcessing,
      text: 'Set Password',
      type: 'submit',
      variant: 'contained',
      xs: 12,
    },
    {
      fullWidth: true,
      onClick: () => navigate('/'),
      text: 'Cancel',
      variant: 'text',
      xs: 12,
    },
  ];

  return (
    <RootGrid container>
      <FormGrid item xs={10} sm={5} md={4} lg={3}>
        <Typography variant="h3" align="center" sx={{ paddingBottom: 5 }}>
          {type === 'passwordReset' ? 'Reset Password' : 'Set New Password'}
        </Typography>
        <Form
          buttonData={buttonData}
          formFieldData={formFieldData}
          onSubmit={handleOnSubmit}
          validateForm={(values) =>
            doFieldsMatch('newPassword', 'confirmPassword', {
              newPassword: 'Passwords do not match',
              confirmPassword: 'Passwords do not match',
            })(values)
          }
        />
      </FormGrid>
    </RootGrid>
  );
});

PasswordVerificationOrResetPage.propTypes = {
  type: PropTypes.string,
};
export default PasswordVerificationOrResetPage;
