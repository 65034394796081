import React from 'react';
import getOr from 'lodash/fp/getOr';

import Box from '@mui/material/Box';

import Label from '../../../components/Label';
import {
  WAE_SUPPORTED_FILTER_FIELD_TYPES,
  WAE_SUPPORTED_STRING_FILTER_OPERATORS,
} from '../../../constants/filterQueries';
import UserRole from '../../../constants/user-role';
import theme from '../../../theme';
import currencyFormatter from '../../../utils/currencyFormatter';

import { handleLabelProps } from './helpers';

const status = {
  align: 'center',
  field: 'approved',
  filterOperators: WAE_SUPPORTED_STRING_FILTER_OPERATORS,
  headerAlign: 'center',
  headerClassName: 'theme-header',
  headerName: 'Status',
  isExportable: true,
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.STRING,
  minWidth: 140,
  renderCell: (params) => <Label {...handleLabelProps(params.value)} />,
};

const billRateReg = {
  field: 'billRateReg',
  headerClassName: 'theme-header',
  headerName: 'Bill Rate',
  valueFormatter: ({ value }) => currencyFormatter.format(value),
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.NUMBER,
  headerAlign: 'left',
  align: 'left',
  flex: 1,
};

const corporation = {
  align: 'left',
  field: 'corporation',
  filterOperators: WAE_SUPPORTED_STRING_FILTER_OPERATORS,
  headerAlign: 'left',
  headerClassName: 'theme-header',
  headerName: 'Corporation',
  valueFormatter: ({ value }) => getOr('', 'name', value),
  flex: 1,
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.STRING,
};

const position = {
  field: 'positionInfo',
  cellClassName: 'info-cell',
  headerClassName: 'theme-header',
  headerName: 'Position',
  minWidth: 300,
  renderCell: (params) => (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          color: `${theme.dataGrid.positionInfo.positionColor}`,
          fontFamily: `${theme.dataGrid.positionInfo.fontFamily}`,
        }}
      >
        {params.value.name}
      </Box>
      <Box
        sx={{
          color: `${theme.dataGrid.positionInfo.descriptionColor}`,
        }}
      >
        {params.value.description}
      </Box>
    </Box>
  ),
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.STRING,
  filterOperators: WAE_SUPPORTED_STRING_FILTER_OPERATORS,
  headerAlign: 'left',
  align: 'left',
};

const payRateReg = {
  field: 'payRateReg',
  headerClassName: 'theme-header',
  headerName: 'Pay Rate',
  valueFormatter: ({ value }) => currencyFormatter.format(value),
  flex: 1,
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.NUMBER,
  align: 'center',
  headerAlign: 'center',
};

const saleRep = {
  field: 'saleRep',
  headerClassName: 'theme-header',
  headerName: 'Sales Rep',
  flex: 1,
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.STRING,
  filterOperators: WAE_SUPPORTED_STRING_FILTER_OPERATORS,
};

const adminColumnData = [status, position, corporation, billRateReg, payRateReg, saleRep];

const employerColumnData = [status, position, corporation, billRateReg];

const recruiterColumnData = [status, position, corporation, billRateReg, payRateReg, saleRep];

const getPositionTemplateListColumnData = (userRole) => {
  switch (userRole) {
    case UserRole.ADMIN:
      return adminColumnData;
    case UserRole.EMPLOYER:
      return employerColumnData;
    case UserRole.RECRUITER:
      return recruiterColumnData;
    default:
      throw Error(`Invalid user role '${userRole}'`);
  }
};

export default getPositionTemplateListColumnData;
