import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Box, Grid } from '@mui/material';

import Chip from '../../../components/Chip';
import theme from '../../../theme';
import { TIMEKEEPING_STATUSES } from '../constants';

const TimeKeepingDateSelector = ({ data, dates, initialSelectedDate, onClick, setCurrentDate }) => {
  const initialValues = {};

  // eslint-disable-next-line no-return-assign
  dates.forEach((dat) => (initialValues[get(['value'], dat)] = false));
  const [datesValues, setDatesValues] = useState({
    ...initialValues,
    [initialSelectedDate]: true,
  });

  const componentsTimeCardDateSelectorPalette = ['components', 'timecardDateSelector', 'palette'];
  const componentsTimeCardDateSelectorTypography = [
    'components',
    'timecardDateSelector',
    'typography',
  ];

  const dayLabelSx = {
    fontSize: get([...componentsTimeCardDateSelectorTypography, 'defaultFontSize'], theme),
    fontFamily: get([...componentsTimeCardDateSelectorTypography, 'dayFontFamily'], theme),
    color: get([...componentsTimeCardDateSelectorPalette, 'defaultFontColor'], theme),
  };

  const dateLabelSx = {
    fontSize: get([...componentsTimeCardDateSelectorTypography, 'defaultFontSize'], theme),
    fontFamily: get([...componentsTimeCardDateSelectorTypography, 'dateFontFamily'], theme),
    color: get([...componentsTimeCardDateSelectorPalette, 'defaultFontColor'], theme),
  };
  const handleOnClick = (value) => {
    setDatesValues({ ...initialValues, [value]: true });
    setCurrentDate(value);
    onClick(value);
  };
  const generateChipSx = (value, status) => {
    const hasConflict =
      status &&
      status.filter((stat) => get(['timecard', 'status'], stat) === TIMEKEEPING_STATUSES.DISPUTED);

    if (hasConflict && hasConflict.length > 0 && !value) {
      return {
        backgroundColor: get(
          [...componentsTimeCardDateSelectorPalette, 'conflictChipBgColor'],
          theme
        ),
        color: get([...componentsTimeCardDateSelectorPalette, 'conflictFontColor'], theme),
        width: '60.57px',
        height: '30px',
        borderRadius: '8px',
      };
    }
    if (status.length === 0 && !value) {
      return {
        backgroundColor: get([...componentsTimeCardDateSelectorPalette, 'emptyChipBgColor'], theme),
        color: get([...componentsTimeCardDateSelectorPalette, 'emptyFontColor'], theme),
        width: '60.57px',
        height: '30px',
        borderRadius: '8px',
        borderWidth: '3px',
        borderColor: get([...componentsTimeCardDateSelectorPalette, 'emptyBorderColor'], theme),
      };
    }

    return value
      ? {
          backgroundColor: get(
            [...componentsTimeCardDateSelectorPalette, 'selectedChipBgColor'],
            theme
          ),
          color: get([...componentsTimeCardDateSelectorPalette, 'defaultFontColor'], theme),
          width: '60.57px',
          height: '30px',
          borderRadius: '8px',
        }
      : {
          backgroundColor: get(
            [...componentsTimeCardDateSelectorPalette, 'defaultChipBgColor'],
            theme
          ),
          color: get([...componentsTimeCardDateSelectorPalette, 'defaultFontColor'], theme),
          width: '60.57px',
          height: '30px',
          borderRadius: '8px',
        };
  };
  // eslint-disable-next-line react/prop-types
  const DateSelectors = ({ date, day, totalHours, value }) => (
    <Grid container item direction="column" xs={1.4} sx={{ width: '100%' }}>
      <Grid container item justifyContent="center">
        <Box sx={dayLabelSx}>{day}</Box>
      </Grid>
      <Grid container item justifyContent="center">
        <Box sx={dateLabelSx}>{date}</Box>
      </Grid>
      <Grid container item justifyContent="center" sx={{ margin: theme.spacing(1, 0, 0, 0) }}>
        <Chip
          label={totalHours}
          background="dark"
          sx={generateChipSx(get([`${value}`], datesValues), get(['days', `${value}`], data))}
          onClick={() => handleOnClick(value)}
          variant={
            !get([`${value}`], datesValues) && get(['days', `${value}`], data).length === 0
              ? 'outlined'
              : 'filled'
          }
        />
      </Grid>
    </Grid>
  );
  return (
    <Grid container item justifyContent="space-around" sx={{ marginTop: '32px' }}>
      {dates &&
        dates.map((dat) => (
          <DateSelectors
            date={get(['date'], dat)}
            day={get(['day'], dat)}
            totalHours={get(['totalHours'], dat)}
            value={get(['value'], dat)}
            key={get(['date'], dat)}
          />
        ))}
    </Grid>
  );
};

TimeKeepingDateSelector.propTypes = {
  data: PropTypes.shape({}),
  dates: PropTypes.arrayOf(PropTypes.shape({})),
  initialSelectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onClick: PropTypes.func,
  setCurrentDate: PropTypes.func,
};

export default TimeKeepingDateSelector;
