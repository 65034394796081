import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import WhiteTrashIcon from '../../../../assets/icons/WhiteTrashIcon';
import Button from '../../../../components/Button';
import theme from '../../../../theme';

const RecruiterOverview = ({ initialValues, onDelete }) => {
  const componentsDetailViewSectionHeaders = [
    'components',
    'detailView',
    'typography',
    'sectionHeader',
  ];

  const componentsDetailViewFieldHeaders = [
    'components',
    'detailView',
    'typography',
    'fieldHeaders',
  ];

  const componentsDetailViewSemiBoldText = [
    'components',
    'detailView',
    'typography',
    'semiBoldText',
  ];

  const viewHeaderStyling = {
    fontSize: get([...componentsDetailViewSectionHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSectionHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewSectionHeaders, 'fontColor'], theme),
  };

  const fieldHeaderStyling = {
    fontSize: get([...componentsDetailViewFieldHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewFieldHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewFieldHeaders, 'fontColor'], theme),
    margin: theme.spacing(4, 0, 1, 1),
  };

  const readOnlyFieldValueStyling = {
    fontSize: get([...componentsDetailViewSemiBoldText, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSemiBoldText, 'fontFamily'], theme),
    color: get([...componentsDetailViewSemiBoldText, 'fontColor'], theme),
    margin: theme.spacing(0, 0, 0, 1),
  };

  const isApproved = get('approved', initialValues) ? 'Approved' : 'Not Approved';

  const possibleLocationData = get('locations', initialValues)
    ?.map((loc) => get('name', loc))
    .toString();

  const possibleDepartmentData = get('departments', initialValues)
    ?.map((dep) => get('name', dep))
    .toString();

  return (
    <Box>
      <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(0, 0, 1, 0) }}>
        Position Overview
      </Typography>
      <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
        <Typography sx={fieldHeaderStyling}>Position Title</Typography>
        <Typography sx={readOnlyFieldValueStyling}>{get(['name'], initialValues)}</Typography>
        <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(2, 0, 1, 1) }}>
          Position Corporation
        </Typography>
        <Typography sx={readOnlyFieldValueStyling}>
          {get(['corporation', 'name'], initialValues)}
        </Typography>
        <Typography sx={fieldHeaderStyling}>Position Description</Typography>
        <Typography sx={readOnlyFieldValueStyling}>
          {get(['description'], initialValues)}
        </Typography>
        <Typography sx={fieldHeaderStyling}> Is this job approved?</Typography>
        <Typography sx={readOnlyFieldValueStyling}>{isApproved}</Typography>
        <Typography sx={fieldHeaderStyling}> Position Location</Typography>
        <Typography sx={readOnlyFieldValueStyling}>{possibleLocationData}</Typography>
        <Typography sx={fieldHeaderStyling}> Position Department</Typography>
        <Typography sx={readOnlyFieldValueStyling}>{possibleDepartmentData}</Typography>
        <Button
          onClick={() => onDelete(true)}
          text="Delete"
          type="delete"
          endIcon={<WhiteTrashIcon />}
          sx={{
            width: '146px',
            height: '32px',
            margin: theme.spacing(4, 0, 2, 1),
          }}
        />
      </Grid>
    </Box>
  );
};

RecruiterOverview.propTypes = {
  container: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  onDelete: PropTypes.func,
};

export default RecruiterOverview;
