/* eslint-disable  react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import AlertBanner from '../../../components/Candidate/AlertBanner';
import DetailView from '../../../components/Candidate/DetailView/DetailView';
import ShiftGroupCard from '../../../components/Candidate/ShiftGroupCard';
import FreeSearchFilter from '../../../components/Filters/FreeSearchFilter';
import SelectDropdownFilter from '../../../components/Filters/SelectDropdownFilter';
import LazyList from '../../../components/LazyList/LazyList';
import searchParamOptions from '../../../constants/searchParams';
import { MILES_TO_METERS } from '../../../constants/unitMeasurement';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectAllJobsPage,
  selectIsLoadingAllJobsList,
  selectIsLoadingFilteredJobsList,
  selectIsLoadingPathSelectedJob,
  selectJobGroupsCount,
  selectJobGroupsList,
  selectSelectedGroup,
} from '../../../store/selectors/candidateJobsListSelectors';
import theme, { navigationBarHeight } from '../../../theme';
import { isSearchParamValid } from '../../../utils';
import { useDims } from '../../../utils/customHooks';
import JobInvitation from '../jobInvitation/JobInvitation';

import AllJobsDetail from './Details/AllJobsDetail';
import { SelectMultipleDropdownForFilter } from './subComponent/SelectMultipleDropdownForFilter';
import SwitchFilter from './subComponent/SwitchFilter';
import CandidateEmptyCard from './CandidateEmptyCard';
import CandidateJobTypeTab from './CandidateJobTypeTab';
import { CANDIDATE_JOB_LIST_REQ_PARAMS, DISTANCE_OPTIONS, INDUSTRY_OPTIONS } from './constants';
import {
  getCandidateJobsList,
  getCandidatePathSelectedJob,
  getFilteredJobsList,
  resetCandidateJobsList,
  setSelectedGroup,
} from './reducer';
import ResponsiveFilter from './ResponsiveFilter';

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => {
    setIntersecting(entry.isIntersecting);
  });

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

const AllJobsList = () => {
  const muiTheme = useTheme();
  const alertBannerTheme = get(['candidate', 'components', 'alertBanner'], theme);
  const mediumScreen = useMediaQuery(muiTheme.breakpoints.up('md'));
  const phoneScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useDispatch();
  const selectedGroup = useSelector(selectSelectedGroup);
  const isLoadingAllJobsList = useSelector(selectIsLoadingAllJobsList);
  const isLoadingFilteredJobsList = useSelector(selectIsLoadingFilteredJobsList);
  const user = useSelector(selectUser);
  const jobGroupsList = useSelector(selectJobGroupsList);
  const jobGroupsCount = useSelector(selectJobGroupsCount);
  const allJobPage = useSelector(selectAllJobsPage);
  const isLoadingPathSelectedJob = useSelector(selectIsLoadingPathSelectedJob);

  const headerRef = useRef();
  const isHeaderVisible = useOnScreen(headerRef || <div />);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const candidateCertifications = get(['candidate', 'certifications'], user) || [];

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedJobId = searchParams.get(searchParamOptions.JOB_ORDER);
  const selectedLocationId = searchParams.get(searchParamOptions.LOCATION);
  const selectedPositionTemplateId = searchParams.get(searchParamOptions.POSITION);
  const selectedShiftGroupId = searchParams.get(searchParamOptions.SHIFT_GROUP);

  const pageSize = 10;

  const closeDetailView = () => {
    setSearchParams({});
    dispatch(setSelectedGroup({}));
  };

  const workCardClickHandler = (jobGroup) => {
    if (jobGroup._id !== selectedGroup._id) {
      dispatch(setSelectedGroup(jobGroup));
    }
  };

  const JobGroupCard = ({ item }) => (
    <ShiftGroupCard
      data={item}
      key={item._id}
      itemClickHandler={workCardClickHandler}
      selected={item._id === selectedGroup._id}
    />
  );

  const getNextData = () => {
    if (!isLoadingAllJobsList) {
      dispatch(
        getCandidateJobsList({
          page: allJobPage,
          filters:
            selectedFilters && selectedFilters.items && selectedFilters.items.length > 0
              ? selectedFilters.items
              : null,
          limit: pageSize,
          sortBy: [{ field: 'start', descending: true }],
        })
      );
    }
  };

  const certsFilterData = {
    field: 'certifications',
    operation: 'idIncludedIn',
    fixedValue: candidateCertifications,
    type: SwitchFilter,
    allowEmptyArray: true,
    initialValue: {
      value: candidateCertifications,
    },
  };

  useEffect(() => {
    if (!isLoadingAllJobsList) {
      // only run when first load
      dispatch(
        getCandidateJobsList({
          ...CANDIDATE_JOB_LIST_REQ_PARAMS,
          filters: [
            {
              value: certsFilterData.initialValue.value,
              field: certsFilterData.field,
              operation: certsFilterData.operation,
              allowEmptyArray: certsFilterData.allowEmptyArray,
            },
          ],
        })
      );
    }
    return () => {
      // only run when user quit this component clean up
      dispatch(resetCandidateJobsList());
    };
  }, []);

  const customFilter = [
    certsFilterData,
    {
      field: 'industry',
      operation: 'includedIn',
      reformatValue: (value) => value.map((v) => v.value),
      multiple: true,
      getOptionLabel: (option) => get(['name'], option),
      options: INDUSTRY_OPTIONS,
      placeholder: 'Industry',
      type: SelectMultipleDropdownForFilter,
    },
    {
      field: 'distance',
      operation: 'near',
      getOptionLabel: (option) => get(['name'], option),
      options: DISTANCE_OPTIONS,
      placeholder: 'Distance',
      reformatValue: (value) => ({
        latitude: 43.149703, // need to be updated to user geoLocation
        longitude: -77.58833,
        distance: MILES_TO_METERS(value),
      }),
      type: SelectDropdownFilter,
    },
    {
      field: 'name',
      operation: 'icontains',
      placeholder: 'Group',
      type: FreeSearchFilter,
    },
  ];

  const onFilterChange = (filterVal) => {
    const validFilter = filterVal && filterVal.items && filterVal.items.length > 0;
    setSelectedFilters(validFilter ? filterVal : null);
    dispatch(resetCandidateJobsList());
    dispatch(
      getFilteredJobsList({
        limit: pageSize,
        filters: validFilter ? filterVal.items : null,
        page: 0,
        sortBy: [{ field: 'start', descending: true }],
      })
    );
  };

  const containerRef = useRef();

  const containerDims = useDims(containerRef);

  useEffect(() => {
    if (
      isLoadingAllJobsList ||
      isLoadingFilteredJobsList ||
      isLoadingPathSelectedJob ||
      !isSearchParamValid(selectedJobId) ||
      jobGroupsList.length + 1 !== pageSize + 1 ||
      !!selectedGroup._id
    ) {
      return;
    }

    dispatch(
      getCandidatePathSelectedJob({
        filters: [{ field: '_id', value: selectedJobId, operation: 'equalsID' }],
      })
    );
  }, [
    dispatch,
    isLoadingFilteredJobsList,
    isLoadingPathSelectedJob,
    jobGroupsList,
    workCardClickHandler,
    pageSize,
    selectedGroup,
    selectedJobId,
  ]);

  useEffect(() => {
    if (
      selectedGroup &&
      selectedGroup.location &&
      selectedGroup.positionTemplate &&
      !searchParams.get(searchParamOptions.INVITED)
    ) {
      setSearchParams({
        [searchParamOptions.LOCATION]: get(['location', '_id'], selectedGroup),
        [searchParamOptions.POSITION]: get('positionTemplate', selectedGroup),
        [searchParamOptions.SHIFT_GROUP]: get('multipleShiftCommitment', selectedGroup),
      });
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (
      !isSearchParamValid(selectedLocationId) ||
      !isSearchParamValid(selectedPositionTemplateId) ||
      !(jobGroupsList.length > 0) ||
      !!selectedGroup._id
    ) {
      return;
    }

    const match = jobGroupsList.filter(
      (row) =>
        get(['location', '_id'], row) === selectedLocationId &&
        get('positionTemplate', row) === selectedPositionTemplateId &&
        get('multipleShiftCommitment', row) === selectedShiftGroupId
    );
    if (match.length > 0) {
      dispatch(setSelectedGroup(match[0]));
      return;
    }

    dispatch(
      getCandidatePathSelectedJob({
        filters: [
          { field: 'location', value: selectedLocationId, operation: 'equalsID' },
          { field: 'positionTemplate', value: selectedPositionTemplateId, operation: 'equalsID' },
          {
            field: 'shiftGroup',
            operation: isSearchParamValid(selectedShiftGroupId) ? 'equalsID' : 'equals',
            value: isSearchParamValid(selectedShiftGroupId) ? `${selectedShiftGroupId}` : null,
          },
        ],
      })
    );
  }, [selectedLocationId, selectedPositionTemplateId, selectedShiftGroupId, jobGroupsList]);

  return (
    <Grid
      sx={{
        position: 'relative',
        height: phoneScreen
          ? `calc(100vh - ${navigationBarHeight})`
          : `calc(90vh - ${navigationBarHeight})`,
        top: navigationBarHeight,
        padding: theme.spacing(0, 0),
      }}
      container
    >
      <Slide
        direction="right"
        unmountOnExit
        in={!(!mediumScreen && !!selectedGroup.positionTemplate && !!selectedGroup.location)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
      >
        <Grid
          item
          container
          md
          xs
          sm
          sx={{
            height: phoneScreen ? 'calc(100vh - 30px)' : '90vh',
            marginRight: !!selectedGroup.positionTemplate && !!selectedGroup.location && 2,
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Box>
            <div ref={headerRef}>
              <CandidateJobTypeTab initVal="allJobs" id="typeTab" />
            </div>
            <Box sx={{ width: '100%', margin: theme.spacing(2, 0, 2, 0) }}>
              <AlertBanner
                LeftIcon={WarningAmberIcon}
                CloseIcon={CloseIcon}
                message="Earnings displayed are pre-tax and do not include any withhold amounts. The final amount
          received may be different after taxes and other deductions have been taken out."
                backgroundColor={get(['backgroundColor'], alertBannerTheme)}
                messageSx={{
                  color: get(['fontColor'], alertBannerTheme),
                  fontFamily: get(['font'], alertBannerTheme),
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                marginBottom: '15px',
              }}
              ref={containerRef}
            >
              <ResponsiveFilter
                items={customFilter}
                onFilterChange={onFilterChange}
                forceCollapse={!!selectedGroup.positionTemplate && !!selectedGroup.location}
                containerDims={containerDims}
              />
            </Box>
            {(isLoadingAllJobsList || isLoadingFilteredJobsList) && allJobPage === 0 ? (
              <Grid
                sx={{
                  width: '100%',
                  height: '95%',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '15%',
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid sx={{ width: '100%' }}>
                <LazyList
                  Card={JobGroupCard}
                  getNextData={getNextData}
                  items={jobGroupsList}
                  itemCount={jobGroupsCount}
                  skeletonVariant="Text"
                  skeletonBackgroundColor={theme.components.detailView.palette.lightLazyLoadColor}
                  NoDataCard={CandidateEmptyCard}
                />

                <Box
                  sx={{
                    visibility: isHeaderVisible ? 'hidden' : 'visible',
                    position: 'absolute',
                    bottom: phoneScreen ? theme.spacing(3) : '10vh',
                    color: theme.components.nav.textColor.default,
                    backgroundColor: theme.components.nav.backgroundColor.default,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    padding: theme.spacing(1),
                    cursor: 'pointer',
                  }}
                  onClick={() => headerRef.current.scrollIntoView()}
                >
                  <ArrowUpwardIcon />
                </Box>
              </Grid>
            )}
          </Box>
        </Grid>
      </Slide>

      {selectedGroup.positionTemplate && selectedGroup.location && (
        <DetailView Content={AllJobsDetail} close={closeDetailView} />
      )}

      {searchParams.get(searchParamOptions.INVITED) && <JobInvitation />}
    </Grid>
  );
};

AllJobsList.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
  }),
};

export default AllJobsList;
