import React, { useCallback } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Drawer from '@mui/material/Drawer';

import WorkFlow from '../../../components/WorkFlow';
import {
  selectTimeCardIsUpdating,
  selectTimecardUpdateError,
} from '../../../store/selectors/timeSheetSelector';
import { epochToTimeInReadableFormat } from '../../../utils';
import { clearTimeCardError, updateTimeCard } from '../reducer';

import { formFieldData, readOnlyValues } from './FormFieldData/FormFieldData';

const TimekeepingEditDrawer = ({ isOpen, onClose, data, description }) => {
  const dispatch = useDispatch();
  const timeCardIsUpdating = useSelector(selectTimeCardIsUpdating);

  const timecardUpdateError = useSelector(selectTimecardUpdateError);
  const handleOnClose = useCallback(() => {
    onClose();
    dispatch(clearTimeCardError());
  }, [dispatch, onClose]);

  const handleAPICall = (values) => {
    // Add JSON schema validation here. If there is a validation error, return false.
    const submitObject = {
      placement: get(['placement'], data),
      punches: get(['punches'], values).map((punch) => ({
        in: get(['in', 'stamp'], punch),
        out: get(['out', 'stamp'], punch),
      })),
    };
    dispatch(updateTimeCard(submitObject));
    return true;
  };

  const punches = get(['timecard', 'punches'], data) || [];

  const initialData = {
    ...description,
    ...readOnlyValues,
    shiftTime: `${epochToTimeInReadableFormat(
      get('start', description)
    )} - ${epochToTimeInReadableFormat(get('end', description))}`,
    punches: punches.length
      ? punches
      : [{ in: { stamp: get('start', data) }, out: { stamp: get('end', data) } }],
  };
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleOnClose}
      PaperProps={{
        sx: {
          width: '576px',
        },
      }}
    >
      <WorkFlow
        confirmationPageButtonText="Back to Timekeeping"
        formSpec={formFieldData}
        attemptToHandleAPICall={handleAPICall}
        onClose={handleOnClose}
        readOnlyValues={initialData}
        isApiCallProcessing={timeCardIsUpdating}
        processingMessage="message.generic.updating"
        successMessage="success.timecard.edit"
        errorMessage={timecardUpdateError}
      />
    </Drawer>
  );
};

TimekeepingEditDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({}),
  description: PropTypes.shape({}),
};

export default TimekeepingEditDrawer;
