import { get } from 'lodash/fp';

import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from './axios';
import { logOutOn401 } from './helpers';

const getAxiosQuery = (http) => {
  switch (http.toUpperCase()) {
    case 'GET':
      return apiGet;
    case 'PUT':
      return apiPut;
    case 'POST':
      return apiPost;

    case 'PATCH':
      return apiPatch;

    case 'DELETE':
      return apiDelete;

    default:
      throw Error(`API Request Type '${http}' is invalid or not implemented yet.`);
  }
};

const asyncService = ({ httpMethod, route, data, token, onSuccess, onError, dispatch }) => {
  const axiosQuery = getAxiosQuery(httpMethod);
  return axiosQuery(route, data, token)
    .then((webResponse) => onSuccess(webResponse))
    .catch((err) => {
      const code = get(['response', 'status'], err);
      if (code === 401 && dispatch) {
        logOutOn401(dispatch);
        return;
      }
      onError(err);
    });
};

export default asyncService;
