const searchParamOptions = {
  PLACEMENT: 'placement',
  START: 'start',
  JOB_ORDER: 'jobOrder',
  CANDIDATE: 'candidate',
  DATE: 'date',
  LOCATION: 'location',
  SHIFT_GROUP: 'shiftGroup',
  POSITION: 'positionTemplate',
  INVITED: 'isInvite',
};
export default searchParamOptions;
