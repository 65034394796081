import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import EmployeeNavigation from '../../components/Candidate/Navigation/EmployeeNavigation';
import GeolocationDemo from '../../components/geolocation/GeolocationDemo';
import Snackbar from '../../components/Snackbar';
import searchParamOptions from '../../constants/searchParams';
import UserRole from '../../constants/user-role';
import selectUser from '../../store/selectors/appSelector';
import { getItemFromLocalStorage, LOCAL_STORAGE_KEYS } from '../../store/utils';
import { setLanguage } from '../../utils/internationalization';
import launchDarklyToggles from '../../utils/launchDarklyToggles';
import { history } from '../../utils/navigator';
import Account from '../account/Account';
import Dashboard from '../Candidate/dashboard';
import AllJobsList from '../Candidate/jobs/AllJobsList';
import MyJobs from '../Candidate/jobs/MyJobs';
// import EmployeeNavigation from '../Candidate/EmployeeNavigation';
import Payment from '../Candidate/payment/Payment';
import TimeTracking from '../Candidate/timeTracking';
import CorporationList from '../corporations/corporationList/CorporationList';
import CreateCorporation from '../corporations/createCorporation/CreateCorporation';
import ForgotMyPassword from '../forgotMyPassword';
import JobsList from '../jobs/jobList/JobsList';
import LongTermJobsList from '../jobs/jobList/LongTermJobsList';
import Login from '../login';
import MaintenancePage from '../maintenance';
import Notification from '../notification/Notification';
import PasswordUpdate from '../passwordUpdate';
import PasswordVerificationOrResetPage from '../PasswordVerificationOrReset/PasswordVerificationOrResetPage';
import PositionTemplateList from '../positionTemplate/positionTemplateList/PositionTemplateList';
import Register from '../register/Register';
import Reporting from '../reporting';
import Settings from '../settings';
import SkillsAndCerts from '../skillsAndCerts';
import TimekeepingGridView from '../timekeeping/timekeepingGridView/TimekeepingGridView';
import UserList from '../users/userList/UserList';

import Navigation from './Navigation';
import { updateUserData } from './reducer';

const AppRouter = ({ flags }) => {
  // eslint-disable-next-line no-unused-vars
  const [dictData, setDictData] = useState({});
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      const userData = getItemFromLocalStorage(LOCAL_STORAGE_KEYS.userDataKey);

      if (userData) {
        dispatch(updateUserData(userData));
        // TODO: (TICKET PENDING) Longer term, we'll want just the token and we'll do a lookup to get the
        // rest of the user data if it is a valid token. That way, either the token is invalid and
        // user is forced to reauth, or the token is valid and we get fresh data for the user.
      }
    }
  }, [dispatch, user]);

  const isAdminMetricsPageEnabled = launchDarklyToggles(flags, 'isAdminMetricsPageEnabled');
  const maintenance = launchDarklyToggles(flags, 'isMaintenanceModeEnabled');
  const timesheetToggle = launchDarklyToggles(flags, 'isTimesheetEnabled');
  const token = get('token', user);
  const role = token && get('role', user);
  const { ADMIN, EMPLOYER, RECRUITER, CANDIDATE } = UserRole;
  const isAdmin = role === ADMIN;
  const isEmployer = role === EMPLOYER;
  const isRecruiter = role === RECRUITER;
  const isEmployee = role === CANDIDATE;

  // Set the language in the internationalization file
  useEffect(() => {
    setLanguage(setDictData);
  }, []);

  if (Object.keys(dictData).length !== 0) {
    return (
      <IntlProvider messages={dictData} locale="en" defaultLocale="en">
        <HistoryRouter history={history}>
          <Snackbar />

          {maintenance ? (
            <MaintenancePage />
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route
                path="accountverification/:queryinformation"
                element={<PasswordVerificationOrResetPage type="accountVerification" />}
              />
              <Route
                path="passwordReset/:queryinformation"
                element={<PasswordVerificationOrResetPage type="passwordReset" />}
              />
              <Route path="forgotMyPassword" element={<ForgotMyPassword />} />

              {/* Admin, Employer, Recruiter View */}
              {(isAdmin || isEmployer || isRecruiter) && (
                <Route element={<Navigation role={role} />}>
                  <Route path="dashboard" element={<JobsList />} />
                  <Route path="geolocation" element={<GeolocationDemo />} />

                  {!isEmployer && (
                    <Route path="corporations/corporationList" element={<CorporationList />} />
                  )}
                  {isAdmin && (
                    <Route path="corporations/createCorporation" element={<CreateCorporation />} />
                  )}
                  {isAdmin && <Route path="skillsAndCerts" element={<SkillsAndCerts />} />}
                  {isAdmin && isAdminMetricsPageEnabled && (
                    <Route path="reporting" element={<Reporting />} />
                  )}
                  {!isEmployer && <Route path="users/usersList" element={<UserList />} />}

                  <Route path="settings" element={<Settings />} />
                  <Route
                    path={`jobs/jobsList/:${searchParamOptions.JOB_ORDER}?`}
                    element={<JobsList />}
                  />
                  <Route path="jobs/longTermJobsList" element={<LongTermJobsList />} />

                  <Route path="jobs/positionTemplateList" element={<PositionTemplateList />} />
                  {timesheetToggle && (
                    <Route
                      path={`timekeeping/:${searchParamOptions.START}?/:${searchParamOptions.CANDIDATE}?/:${searchParamOptions.PLACEMENT}?/:${searchParamOptions.DATE}?`}
                      element={<TimekeepingGridView />}
                    />
                  )}
                </Route>
              )}

              {/* Employee View */}
              {isEmployee && (
                <Route element={<EmployeeNavigation />}>
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route
                    path={`jobs/jobsList/:${searchParamOptions.JOB_ORDER}?/:${searchParamOptions.PLACEMENT}?/:${searchParamOptions.INVITED}?/:${searchParamOptions.LOCATION}?/:${searchParamOptions.POSITION}?/:${searchParamOptions.SHIFT_GROUP}?`}
                    element={<AllJobsList />}
                  />
                  <Route
                    path={`jobs/myJobs/:${searchParamOptions.PLACEMENT}?`}
                    element={<MyJobs />}
                  />
                  <Route path="settings" element={<Settings />} />
                  {/* FIXME: Update headers to use Portal in the following views */}
                  <Route path="payment" element={<Payment />} />
                  <Route
                    path={`timekeeping/:${searchParamOptions.START}?/:${searchParamOptions.PLACEMENT}?`}
                    element={<TimeTracking />}
                  />
                  <Route path="notification" element={<Notification />} />
                  <Route path="account" element={<Account />} />
                  <Route path="account/passwordUpdate" element={<PasswordUpdate />} />
                </Route>
              )}

              <Route path="*" element={<Login />} />
            </Routes>
          )}
        </HistoryRouter>
      </IntlProvider>
    );
  }
  return null;
};

AppRouter.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(AppRouter);
