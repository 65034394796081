const Filters = {
  USER_UPCOMING_JOBS: [
    {
      operation: 'equals',
      field: 'cancelled',
      value: false,
    },
    {
      operation: 'after',
      field: 'end',
      value: +new Date(),
    },
  ],
  USER_COMPLETED_JOBS: [
    {
      operation: 'equals',
      field: 'cancelled',
      value: false,
    },
    {
      operation: 'before',
      field: 'end',
      value: +new Date(),
    },
  ],
};
export default Filters;
