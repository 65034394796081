import React, { useEffect, useState } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';

import UserRole from '../../constants/user-role';
import selectUser from '../../store/selectors/appSelector';
import theme from '../../theme';
import { epochToDateInReadableFormat } from '../../utils';
import Button from '../Button';

import filters from './filters';
import getJobListColumnData from './getJobListColumnData';
import { getSetData, handleCSVConversion } from './Helpers';

const ExportView = ({ close, open }) => {
  const user = useSelector(selectUser);
  const apiPath = 'job-orders/read';
  const [allStatusSelected, setAllStatusSelected] = useState(true);
  const [allColumnsSelected, setAllColumnsSelected] = useState(true);

  const [rosterReady, setRosterReady] = useState(false);
  const [upcoming, setUpcoming] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [orderStatus, setOrderStatus] = useState(false);
  const [corporation, setCorporation] = useState(false);
  const [position, setPosition] = useState(false);
  const [date, setDate] = useState(false);
  const [address, setAddress] = useState(false);
  const [time, setTime] = useState(false);
  const [fill, setFill] = useState(false);

  const allowCorp = !!(user.role === UserRole.ADMIN || user.role === UserRole.RECRUITER);

  const [columns, setColumns] = useState([]);

  const [exporting, setExporting] = useState(false);
  const [completedProgress, setCompletedProgress] = useState(0);
  const [upcomingProgress, setUpcomingProgress] = useState(0);

  useEffect(() => {
    setColumns(
      getJobListColumnData({
        address,
        allColumnsSelected,
        orderStatus,
        corporation,
        position,
        date,
        time,
        fill,
        allowCorp,
      })
    );
  }, [
    allColumnsSelected,
    orderStatus,
    corporation,
    position,
    date,
    time,
    fill,
    address,
    allowCorp,
  ]);

  const token = get('token', user);

  useEffect(() => {
    setRosterReady(false);
    setUpcoming(false);
    setCompleted(false);
  }, [allStatusSelected]);

  useEffect(() => {
    setOrderStatus(false);
    setCorporation(false);
    setPosition(false);
    setDate(false);
    setTime(false);
    setFill(false);
    setAddress(false);
  }, [allColumnsSelected]);

  const [upcomingData, setUpcomingData] = useState(null);
  const [completedData, setCompletedData] = useState(null);

  const handleClick = () => {
    setExporting(true);

    if (completed || allStatusSelected) {
      setCompletedProgress(0);
      getSetData({
        columns,
        apiPath,
        filters: filters.COMPLETED,
        pageSize: 8,
        token,
        setProgress: setCompletedProgress,
      }).then((data) => {
        setCompletedData(data);
      });
    }

    if (upcoming || allStatusSelected) {
      setUpcomingProgress(0);
      getSetData({
        columns,
        apiPath,
        filters: filters.UPCOMING,
        pageSize: 8,
        token,
        setProgress: setUpcomingProgress,
      }).then((data) => {
        setUpcomingData(data);
      });
    }
  };

  useEffect(() => {
    const completedCheck = completed || allStatusSelected;
    const upcomingCheck = upcoming || allStatusSelected;
    const hasData = !!completedData || !!upcomingData;

    if (!!completedData === completedCheck && !!upcomingData === upcomingCheck && hasData) {
      let rows = [[]];
      columns.map((colData) => rows[0].push(colData.headerName));
      rows = completedData ? [...rows, ...completedData] : rows;
      rows = upcomingData ? [...rows, ...upcomingData] : rows;
      handleCSVConversion({
        rows,
        fileName: `WAE Job Orders ${epochToDateInReadableFormat(+new Date())}`,
      });

      setCompletedData(null);
      setUpcomingData(null);
      setExporting(false);
      setCompletedProgress(0);
      setUpcomingProgress(0);
    }
  }, [allStatusSelected, columns, completed, completedData, upcoming, upcomingData]);

  const styleForCheckboxes = {
    '& .MuiSvgIcon-root': {
      color: theme.export.checkbox.default,
    },
  };

  const styleForSectionHeaders = {
    fontFamily: theme.export.typography.boldFont,
    marginBottom: theme.spacing(0.5),
  };

  const styleForHeader = {
    fontFamily: theme.export.typography.boldFont,
    marginBottom: theme.spacing(1),
    fontSize: 32,
  };

  const styleForPaper = {
    padding: theme.spacing(3),
  };

  const styleForSlide = {
    boxShadow: '0 0 30px black',
    marginLeft: theme.spacing(4),
  };

  const styleForWrapper = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    minHeight: '100%',
  };

  const styleForButtonPlacement = {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: theme.spacing(4),
  };

  const styleForWorkingWrapper = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  };

  const styleForWorkingTextBox = {
    display: 'flex',
    width: '80%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
  };

  const styleForWorkingText = {
    fontFamily: theme.export.typography.boldFont,
  };

  return (
    open && (
      <Slide direction="left" in unmountOnExit timeout={200} sx={styleForSlide}>
        <Grid item md={4} xs sx={{ height: '100%' }}>
          {exporting ? (
            <Box sx={styleForWorkingWrapper}>
              <Typography sx={styleForHeader}>Exporting...</Typography>
              {upcoming || allStatusSelected ? (
                <Box sx={styleForWorkingTextBox}>
                  <Typography sx={styleForWorkingText}>Upcoming Orders Progress:</Typography>
                  <Typography sx={styleForWorkingText}>
                    {upcomingProgress < 1 ? `${(upcomingProgress * 100).toFixed(2)}%` : '100%'}
                  </Typography>
                </Box>
              ) : null}
              {completed || allStatusSelected ? (
                <Box sx={styleForWorkingTextBox}>
                  <Typography sx={styleForWorkingText}>Completed Orders Progress:</Typography>
                  <Typography sx={styleForWorkingText}>
                    {completedProgress < 1 ? `${(completedProgress * 100).toFixed(2)}%` : '100%'}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          ) : (
            <Box sx={styleForWrapper}>
              <Box>
                <IconButton color="inherit" aria-label="close" onClick={close}>
                  <CloseRoundedIcon />
                </IconButton>
                <Box sx={styleForPaper}>
                  <Typography sx={styleForHeader}>Export CSV</Typography>
                  <Typography sx={{ fontFamily: theme.export.typography.font }}>
                    Please select any column(s) you would like to include in a CSV export.
                  </Typography>

                  <Box
                    sx={{
                      margin: theme.spacing(3, 0, 3),
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography sx={styleForSectionHeaders}>Job Status</Typography>
                    <FormControlLabel
                      value={allStatusSelected}
                      control={<Checkbox sx={styleForCheckboxes} />}
                      label="All Jobs"
                      labelPlacement="end"
                      checked={allStatusSelected}
                      onChange={() => setAllStatusSelected(!allStatusSelected)}
                    />
                    {/* <FormControlLabel
                  value={rosterReady}
                  control={<Checkbox sx={styleForCheckboxes} />}
                  label="Roster Ready"
                  labelPlacement="end"
                  checked={rosterReady}
                  onChange={() => setRosterReady(!rosterReady)}
                  disabled={allStatusSelected}
                /> */}
                    <FormControlLabel
                      value={rosterReady}
                      control={<Checkbox sx={styleForCheckboxes} />}
                      label="Upcoming Job"
                      labelPlacement="end"
                      checked={upcoming}
                      onChange={() => setUpcoming(!upcoming)}
                      disabled={allStatusSelected}
                    />
                    <FormControlLabel
                      value={rosterReady}
                      control={<Checkbox sx={styleForCheckboxes} />}
                      label="Completed Job"
                      labelPlacement="end"
                      checked={completed}
                      onChange={() => setCompleted(!completed)}
                      disabled={allStatusSelected}
                    />
                  </Box>

                  <Box
                    sx={{
                      margin: theme.spacing(3, 0, 3),
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography sx={styleForSectionHeaders}>Columns to Export</Typography>
                    <FormControlLabel
                      value={allStatusSelected}
                      control={<Checkbox sx={styleForCheckboxes} />}
                      label="All Columns"
                      labelPlacement="end"
                      checked={allColumnsSelected}
                      onChange={() => setAllColumnsSelected(!allColumnsSelected)}
                    />
                    <FormControlLabel
                      value={rosterReady}
                      control={<Checkbox sx={styleForCheckboxes} />}
                      label="Order Status"
                      labelPlacement="end"
                      checked={orderStatus}
                      onChange={() => setOrderStatus(!orderStatus)}
                      disabled={allColumnsSelected}
                    />
                    {allowCorp && (
                      <FormControlLabel
                        value={rosterReady}
                        control={<Checkbox sx={styleForCheckboxes} />}
                        label="Corporation Name"
                        labelPlacement="end"
                        checked={corporation}
                        onChange={() => setCorporation(!corporation)}
                        disabled={allColumnsSelected}
                      />
                    )}
                    <FormControlLabel
                      value={rosterReady}
                      control={<Checkbox sx={styleForCheckboxes} />}
                      label="Position Title"
                      labelPlacement="end"
                      checked={position}
                      onChange={() => setPosition(!position)}
                      disabled={allColumnsSelected}
                    />
                    <FormControlLabel
                      value={rosterReady}
                      control={<Checkbox sx={styleForCheckboxes} />}
                      label="Address"
                      labelPlacement="end"
                      checked={address}
                      onChange={() => setAddress(!address)}
                      disabled={allColumnsSelected}
                    />
                    <FormControlLabel
                      value={rosterReady}
                      control={<Checkbox sx={styleForCheckboxes} />}
                      label="Start & End Date"
                      labelPlacement="end"
                      checked={date}
                      onChange={() => setDate(!date)}
                      disabled={allColumnsSelected}
                    />
                    <FormControlLabel
                      value={rosterReady}
                      control={<Checkbox sx={styleForCheckboxes} />}
                      label="Start & End Time"
                      labelPlacement="end"
                      checked={time}
                      onChange={() => setTime(!time)}
                      disabled={allColumnsSelected}
                    />
                    <FormControlLabel
                      value={rosterReady}
                      control={<Checkbox sx={styleForCheckboxes} />}
                      label="Fill Rate"
                      labelPlacement="end"
                      checked={fill}
                      onChange={() => setFill(!fill)}
                      disabled={allColumnsSelected}
                    />
                  </Box>
                </Box>
              </Box>
              <Grid container spacing={1} sx={styleForButtonPlacement}>
                <Grid item>
                  <Button
                    onClick={close}
                    type="outlined"
                    startIcon={<ArrowBackIcon sx={{ color: theme.button.palette.secondary }} />}
                  >
                    Go Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleClick} type="submit" startIcon={<FileDownloadIcon />}>
                    Export CSV
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Slide>
    )
  );
};
ExportView.propTypes = {
  close: PropTypes.func,
  open: PropTypes.bool,
};
export default ExportView;
