import { createTheme } from '@mui/material/styles';

import candidateTheme from '../views/Candidate/theme/candidateTheme';

import { ACTION_BLUE, BLACK, FEED_BACK, PRIMARY_COLOR, PRIMARY_PURPLE } from './colorConstants';
import { PRIMARY_FONT } from './fontConstants';
import fonts from './fonts';

const theme = createTheme({
  candidate: { ...candidateTheme },
  components: {
    MuiCssBaseline: {
      styleOverrides: fonts,
    },
    scrollBar: {
      trackColor: PRIMARY_COLOR[30],
      thumbColor: PRIMARY_COLOR[50],
      thumbHover: PRIMARY_COLOR[80],
    },
    alert: {
      palette: {
        backgroundColor: {
          errorBgColor: `${FEED_BACK.RED_50}`,
          warningBgColor: `${FEED_BACK.YELLOW_50}`,
          successBgColor: `${FEED_BACK.GREEN_50}`,
        },
        iconColor: `${BLACK[100]}`,
      },
      typography: {
        fontFamily: 'Barlow',
        fontSize: '16px',
        fontColor: `${BLACK[100]}`,
      },
    },
    barChart: {
      palette: {},
      typography: {
        header: {
          fontFamily: 'Barlow',
          fontSize: '16px',
          fontWeight: 500,
          fontColor: `${BLACK[100]}`,
        },
        legend: {
          fontFamily: 'Barlow',
          fontSize: '16px',
          fontWeight: 500,
          fontColor: `${BLACK[100]}`,
        },
      },
    },
    pieChart: {
      palette: {},
      typography: {
        header: {
          fontFamily: 'Barlow',
          fontSize: '16px',
          fontWeight: 500,
          fontColor: `${BLACK[100]}`,
        },
        pieChartDetailHeader: {
          fontFamily: 'Barlow',
          fontSize: '30px',
          fontWeight: 800,
          fontColor: `${BLACK[100]}`,
        },
        pieChartDetailText: {
          fontFamily: 'Barlow',
          fontSize: '14px',
          fontWeight: 500,
          fontColor: `${BLACK[70]}`,
        },
        legend: {
          fontFamily: 'Barlow',
          fontSize: '16px',
          fontWeight: 400,
          fontColor: `${BLACK[100]}`,
        },
      },
    },
    checkbox: {
      typography: {
        dark: {
          fontColor: `${BLACK[0]}`,
          fontSize: '14px',
          fontFamily: 'Barlow',
        },
      },
    },
    RadioChips: {
      typography: {
        light: {
          errorFontFamily: 'Roboto,Helvetica,Arial,sans-serif',
          errorFontSize: '12px',
        },
        dark: {},
      },
      palette: {
        light: {
          errorFontColor: '#d32f2f',
        },
        dark: {
          errorFontColor: `${BLACK[100]}`,
        },
      },
    },
    selectDropdown: {
      typography: {
        fontFamily: 'Barlow-600',
        fontSize: '16px',
      },
      borderRadius: '40px',
      palette: {
        dark: {
          backgroundColor: `${BLACK[80]}`,
          fontColor: `${BLACK[0]}`,
        },
        light: {
          backgroundColor: `${PRIMARY_PURPLE[20]}`,
          fontColor: `${BLACK[100]}`,
        },
        grey: {
          backgroundColor: `${BLACK[20]}`,
          fontColor: `${BLACK[100]}`,
        },
      },
    },
    PillChips: {
      palette: {
        unselected: {
          background: {
            light: `${PRIMARY_PURPLE[30]}`,
          },
          font: {
            light: `${PRIMARY_PURPLE[80]}`,
          },
        },
        selected: {
          background: {
            light: `${PRIMARY_PURPLE[70]}`,
          },
          font: {
            light: `${BLACK[0]}`,
          },
        },
      },
      typography: {
        fontFamily: 'Barlow-600',
        fontSize: '14px',
      },
    },
    modal: {
      typography: {
        title: {
          fontColor: BLACK[80],
          fontFamily: 'Barlow-800',
          fontSize: '30px',
        },
        bodyText: {
          fontColor: BLACK[70],
          fontFamily: 'Barlow-500',
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },
    settings: {
      fieldBlock: {
        margin: '64px 0',
      },
      palette: {
        mainHeader: {
          fontColor: `${BLACK[100]}`,
        },
        sectionHeader: {
          fontColor: `${BLACK[100]}`,
        },
        sectionSubText: {
          fontColor: `${BLACK[80]}`,
        },
        subSectionHeader: {
          fontColor: `${BLACK[100]}`,
        },
        subSectionSubText: {
          fontColor: `${BLACK[80]}`,
        },
        fieldHeader: {
          fontColor: `${BLACK[100]}`,
        },
        fieldText: {
          fontColor: `${BLACK[100]}`,
        },
      },
      typography: {
        mainHeader: {
          fontSize: '38px',
          fontWeight: '800',
          fontFamily: `Barlow-800`,
        },
        sectionHeader: {
          fontSize: '24px',
          fontWeight: '800',
          fontFamily: `Barlow-800`,
        },
        sectionSubText: {
          fontSize: '16px',
          fontWeight: '400',
          fontFamily: `Barlow`,
          margin: '0 0 8px 0',
        },
        subSectionHeader: {
          fontSize: '18px',
          fontWeight: '600',
          fontFamily: `Barlow-800`,
        },
        subSectionSubText: {
          fontSize: '16px',
          fontWeight: '400',
          fontFamily: `Barlow`,
          margin: '0 0 8px 0',
        },
        fieldHeader: {
          fontSize: '14px',
          fontWeight: '400',
          fontFamily: `Barlow`,
          lineHeight: 0,
        },
        fieldText: {
          fontSize: '16px',
          fontWeight: '600',
          fontFamily: `Barlow`,
          lineHeight: 0,
        },
      },
    },
    detailView: {
      palette: {
        defaultTextColor: `${BLACK[100]}`,
        backgroundColor: `${BLACK[90]}`,
        closeIconColor: `${BLACK[50]}`,
        closeIconWhiteColor: `${BLACK[0]}`,
        sectionBackgroundColor: `${BLACK[100]}`,
        lightLazyLoadColor: `${BLACK[0]}`,
        sectionTextColor: `${PRIMARY_PURPLE[40]}`,
        corpTextColor: `${BLACK[20]}`,
        employeesNameColor: `${PRIMARY_PURPLE[70]}`,
        lightHeartColor: `${BLACK[60]}`,
        searchBarColor: `${BLACK[20]}`,
        trashCanColor: `${FEED_BACK.RED_50}`,
        dnrTextFieldColor: `${BLACK[0]}`,
        recordButtonColor: `${BLACK[0]}`,
        deletePlacementBgColor: `${BLACK[30]}`,
        cancelledShiftCardBgColor: `${PRIMARY_PURPLE[10]}`,
        cancelledShiftTitleColor: `${PRIMARY_PURPLE[70]}`,
        cancelledShiftSubtitleColor: `${BLACK[80]}`,
        cancelledShiftTimeColor: `${BLACK[100]}`,
        fixedBottomBoxColor: `${BLACK[0]}`,
        dnrTextColor: `${BLACK[80]}`,
      },
      typography: {
        general: {
          fontSize: '16px',
          fontColor: `${BLACK[20]}`,
          fontFamily: 'Barlow',
        },
        fieldHeaders: {
          fontSize: '14px',
          fontFamily: `Barlow-500`,
          fontColor: `${BLACK[40]}`,
        },
        header: {
          fontSize: '30px',
          fontColor: `${BLACK[0]}`,
          fontFamily: 'Barlow-800',
        },
        sectionHeader: {
          fontSize: '18px',
          fontColor: `${BLACK[0]}`,
          fontFamily: 'Barlow-700',
        },
        subheader: {
          fontSize: '18px',
          fontColor: ` ${BLACK[50]}`,
          fontFamily: 'Barlow',
        },
        subSectionHeader: {
          fontSize: '14px',
          fontColor: ` ${BLACK[40]}`,
          fontFamily: 'Barlow-500',
        },
        semiBoldHeader: {
          fontSize: '18px',
          fontColor: ` ${BLACK[50]}`,
          fontFamily: 'Barlow-600',
        },
        semiBoldText: {
          fontSize: '16px',
          fontColor: `${BLACK[0]}`,
          fontFamily: 'Barlow-600',
        },
        detailText: {
          fontSize: '12px',
          fontColor: `${BLACK[40]}`,
          fontFamily: 'Barlow',
        },
        certText: {
          fontSize: '14px',
          fontColor: `${BLACK[30]}`,
          fontFamily: 'Barlow',
        },
        locationText: {
          fontSize: '16px',
          fontColor: `${PRIMARY_PURPLE[40]}`,
          fontFamily: 'Barlow-600',
        },
        employeeText: {
          fontSize: '12px',
          fontColor: `${BLACK[70]}`,
          fontFamily: 'Barlow',
        },

        requireCertText: {
          fontFamily: 'Barlow-500',
          fontSize: '16px',
          fontColor: `${BLACK[0]}`,
        },

        rating: {
          color: `${PRIMARY_PURPLE[40]}`,
        },
        recordHeader: {
          fontFamily: 'Barlow-700',
          fontSize: '20px',
          fontColor: `${PRIMARY_PURPLE[40]}`,
        },
        recordText: {
          fontFamily: 'Barlow-500',
          fontSize: '14px',
          fontColor: `${BLACK[50]}`,
        },
        reasonTextWhite: {
          fontFamily: 'Barlow-500',
          fontSize: '14px',
          fontColor: `${BLACK[0]}`,
        },
        reasonTextBlack: {
          fontFamily: 'Barlow-500',
          fontSize: '14px',
          fontColor: `${BLACK[100]}`,
        },
        corpHeader: {
          fontFamily: 'Barlow-600',
          fontSize: '14px',
          fontColor: `${BLACK[50]}`,
        },
        shiftName: {
          fontFamily: 'Barlow-500',
          fontSize: '14px',
          fontColor: `${BLACK[20]}`,
        },
        ptShiftName: {
          fontFamily: 'Barlow',
          fontSize: '16px',
          fontColor: `${BLACK[20]}`,
        },
        dnrTextField: {
          fontFamily: 'Barlow-500',
          fontSize: '16px',
        },
        recordButton: {
          fontFamily: 'Barlow-700',
          fontSize: '16px',
        },
        cancelPlacement: {
          fontFamily: 'Barlow-700',
          fontSize: '16px',
        },
        cancelledShiftTitle: {
          fontFamily: PRIMARY_FONT[800],
          fontSize: '30px',
        },
        cancelledShiftSubtitle: {
          fontFamily: PRIMARY_FONT,
          fontSize: '16px',
        },
        cancelledShiftTime: {
          fontFamily: PRIMARY_FONT[700],
          fontSize: '22x',
        },
        departmentName: {
          fontSize: '16px',
          fontColor: `${PRIMARY_PURPLE[40]}`,
          fontFamily: PRIMARY_FONT[600],
        },
        departmentDesc: {
          fontSize: '14px',
          fontColor: `${BLACK[50]}`,
          fontFamily: PRIMARY_FONT[600],
        },
        manageUserName: {
          fontFamily: PRIMARY_FONT[700],
          fontSize: '22x',
        },
      },
    },
    form: {
      palette: {
        dark: { backgroundColor: `${BLACK[100]}` },
      },
      typography: {},
    },
    readOnlyListField: {
      palette: {},
      typography: {
        fontFamilyMedium: 'Barlow-500',
        fontFamily: 'Barlow-600',
        fontWeightOfKey: 400,
        fontWeightOfValue: 600,
        fontSize: '16px',
        fontColor: `${BLACK[10]}`,
        darkFontColor: `${BLACK[100]}`,
        lineHeight: '24px',
      },
    },
    shiftSelector: {
      palette: {
        unselectedBackgroundColor: BLACK[10],
        selectedBackgroundColor: BLACK[30],
      },
    },
    table: {
      palette: {
        body: {
          light: {},
          dark: {
            odd: {
              backgroundColor: `${BLACK[100]}`,
            },
            backgroundColor: `${BLACK[90]}`,
            fontColor: `${BLACK[0]}`,
          },
        },
      },
      typography: {
        body: {
          fontFamily: 'Barlow-600',
          fontSize: '16px',
        },
        header: {
          fontFamily: 'Barlow-600',
          fontSize: '14px',
        },
      },
    },
    tabPages: {
      palette: {
        tab: {
          light: {},
          dark: {
            unselected: {
              fontColor: `${PRIMARY_PURPLE[40]}`,
              backgroundColor: `${BLACK[100]}`,
            },
            selected: {
              fontColor: `${BLACK[0]}`,
              backgroundColor: `${PRIMARY_PURPLE[70]}`,
            },
          },
        },
      },
      typography: {
        tab: {
          selected: {
            fontFamily: 'Barlow-600',
            fontSize: '14px',
          },
          unselected: { fontFamily: 'Barlow', fontSize: '14px' },
        },
      },
    },

    nav: {
      activeBackgroundColor: {
        default: `${PRIMARY_PURPLE[70]}`,
      },
      arrowColor: {
        default: `${PRIMARY_PURPLE[40]}`,
      },
      backgroundColor: {
        default: `${PRIMARY_PURPLE[80]}`,
      },
      subSectionBackgroundColor: {
        default: `${PRIMARY_PURPLE[90]}`,
      },
      subSectionTypeText: {
        default: `${BLACK[30]}`,
      },
      textColor: {
        default: '#FFF',
      },
      borderBottom: {
        color: `${BLACK[40]}`,
      },
    },
    timecardDateSelector: {
      palette: {
        defaultChipBgColor: BLACK[100],
        conflictChipBgColor: FEED_BACK.YELLOW_50,
        emptyChipBgColor: BLACK[90],
        selectedChipBgColor: PRIMARY_PURPLE[70],
        emptyFontColor: BLACK[50],
        emptyBorderColor: BLACK[70],
        conflictFontColor: FEED_BACK.YELLOW_100,
        defaultFontColor: BLACK[0],
      },
      typography: {
        dayFontFamily: 'Barlow-500',
        dateFontFamily: 'Barlow-600',
        defaultFontSize: '12px',
      },
    },

    timecardDetailPunchCard: {
      palette: {
        dayandDateFontColor: BLACK[50],
      },
      typography: {
        dayAndDateFontFamily: `Barlow-600`,
        dayAndDateFontSize: `24px`,
      },
    },

    workflow: {
      palette: {
        background: {
          default: {
            confirmationPageBackgroundColor: `${PRIMARY_PURPLE[70]}`,
            transitionPageBackgroundColor: `${BLACK[0]}`,
          },
          dark: {
            confirmationPageBackgroundColor: `${PRIMARY_PURPLE[70]}`,
            transitionPageBackgroundColor: `${BLACK[0]}`,
          },
        },
        font: {
          default: {
            defaultLastPageFontColor: `${BLACK[0]}`,
            subHeaderFontColor: BLACK[80],
            transitionPageFontColor: `${BLACK[100]}`,
          },
          dark: {
            defaultLastPageFontColor: `${BLACK[0]}`,
            transitionPageFontColor: `${BLACK[100]}`,
          },
        },
      },
      typography: {
        defaultFont: 'Barlow',
        boldFont: 'Barlow-800',
        defaultTitleFontSize: '30px',
        defaultFontSize: '18px',
        defaultFontWeight: 800,
        titleLineHeight: '34px',
      },
      timecardEdit: {
        palette: {
          dayAndDatefontColor: BLACK[100],
          descriptionNameFontColor: BLACK[70],
          shiftTimeFontColor: PRIMARY_PURPLE[70],
        },
        typography: {
          dayAndDatefontFamily: 'Barlow-700',
          dayAndDateFontSize: '16px',
          descriptionFontFamily: 'Barlow-500',
          descriptionFontSize: '14px',
          shiftTimeFontFamily: 'Barlow-700',
          shiftTImeFontSize: '20px',
        },
      },
    },
    notification: {
      palette: {
        light: `${PRIMARY_PURPLE[20]}`,
        middle: `${PRIMARY_PURPLE[30]}`,
        markRead: `${BLACK[50]}`,
        unRead: `${PRIMARY_PURPLE[80]}`,
      },
      header: {
        fontFamily: PRIMARY_FONT[800],
        fontSize: '30px',
      },
      mainText: {
        fontFamily: PRIMARY_FONT[400],
        fontSize: '14px',
      },
      boldText: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '12px',
      },
      deleteText: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '14px',
      },
      markReadText: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '16px',
      },
    },
    profileMenu: {
      palette: {
        border: `${BLACK[20]}`,
        hover: `${PRIMARY_PURPLE[20]}`,
        white: `${BLACK[0]}`,
      },
      font: {
        selection: {
          fontFamily: PRIMARY_FONT[500],
          fontSize: '16px',
        },
        email: {
          fontFamily: PRIMARY_FONT[600],
          fontSize: '14px',
        },
        role: {
          fontFamily: PRIMARY_FONT[500],
          fontSize: '14px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: `${PRIMARY_PURPLE[80]}`,
      light: `${PRIMARY_PURPLE[40]}`,
      dark: `${PRIMARY_PURPLE[100]}`,
    },
    secondary: {
      main: `${BLACK[80]}`,
      light: `${BLACK[70]}`,
      dark: `${BLACK[90]}`,
    },
    subtext: {
      main: `${BLACK[50]}`,
      light: `${BLACK[40]}`,
      dark: `${BLACK[60]}`,
    },
    background: {
      default: `${BLACK[0]}`,
      dark: `${BLACK[90]}`,
      paper: `${BLACK[0]}`,
      black: `${BLACK[100]}`,
    },
    text: {
      primary: `${BLACK[100]}`,
      secondary: `${BLACK[40]}`,
      notificationTime: `${BLACK[70]}`,
    },
    neutral: {
      main: `${BLACK[60]}`,
      contrastText: `${BLACK[10]}`,
      contrastBgColor: `${BLACK[30]}`,
    },
    border: {
      grey: `${BLACK[40]}`,
    },
  },
  views: {
    reporting: {
      palette: {
        paper: {
          backgroundColor: {
            default: `${BLACK[10]}`,
            dark: {},
          },
        },
        primary: `${PRIMARY_PURPLE[70]}`,
        role: {
          default: {
            primary: `${BLACK[10]}`,
          },
          admin: {
            primary: `${PRIMARY_PURPLE[70]}`,
            secondary: `${PRIMARY_PURPLE[10]}`,
          },
          recruiter: {
            primary: `${FEED_BACK.ORANGE}`,
            secondary: `${FEED_BACK.ORANGE_90}`,
          },
          customer: {
            primary: `${FEED_BACK.GREEN_50}`,
            secondary: `${FEED_BACK.GREEN_90}`,
          },
        },
      },
      linearProgress: {
        filled: `${PRIMARY_PURPLE[90]}`,
        notFilled: `${PRIMARY_PURPLE[70]}`,
      },
      lineChart: {
        line: `${PRIMARY_PURPLE[70]}`,
        dot: `${PRIMARY_PURPLE[100]}`,
      },
      attendance: {
        attendanceColor: `${PRIMARY_PURPLE[70]}`,
        noCallNoShowColor: `${PRIMARY_PURPLE[100]}`,
        insubordinationColor: `${PRIMARY_PURPLE[100]}`,
        misconductColor: `${PRIMARY_PURPLE[50]}`,
        breachOfCompanyPolicyColor: `${FEED_BACK.ORANGE}`,
        walkedOffColor: `${FEED_BACK.YELLOW_50}`,
        unauthorizedPossessionColor: `${FEED_BACK.YELLOW_80}`,
        failureToFollowSupervisorColor: `${FEED_BACK.GREEN_50}`,
        failureToFollowProceduresColor: `${FEED_BACK.GREEN_100}`,
      },

      typography: {
        header: {
          fontFamily: 'Barlow',
          fontSize: '38px',
          fontWeight: 800,
          fontColor: `${BLACK[100]}`,
        },
        chartLegendText: {
          fontFamily: 'Barlow',
          fontSize: '14px',
          fontWeight: 500,
          fontColor: `${BLACK[70]}`,
        },
        metricsTitle: {
          fontFamily: 'Barlow',
          fontSize: '16px',
          fontWeight: 500,
          fontColor: `${BLACK[100]}`,
        },
        metricsHeader: {
          fontFamily: 'Barlow',
          fontSize: '22px',
          fontWeight: 700,
          fontColor: `${BLACK[80]}`,
        },
        card: {
          content: {
            fontFamily: 'Barlow',
            fontSize: '30px',
            fontWeight: 800,
            fontColor: `${BLACK[100]}`,
          },
          label: {
            fontFamily: 'Barlow',
            fontSize: '14px',
            fontWeight: 500,
            fontColor: `${BLACK[70]}`,
          },
        },
      },
    },
  },
  select: {
    main: BLACK[80],
  },
  button: {
    palette: {
      primary: `${ACTION_BLUE.ACTION_BLUE}`,
      secondary: `${ACTION_BLUE.ACTION_BLUE_REST}`,
      contrast: `${ACTION_BLUE.ACTION_CONTRAST_REST}`,
      delete: `${FEED_BACK.RED_50}`,
      deleteHover: `${FEED_BACK.RED_100}`,
      cancel: `${PRIMARY_PURPLE[90]}`,
      focus: `${PRIMARY_PURPLE[60]}`,
      fill: `${BLACK[40]}`,
      text: `${BLACK[0]}`,
      whiteIcon: `${BLACK[0]}`,
    },
    defaultBorderRadius: '40px',
    fontFamily: 'Barlow-700',
    fontSize: '14px',
  },

  chip: {
    default: {
      fontSize: '14px',
      iconSize: '10px',
      largeFontSize: '18px',
      largeIconSize: '14px',
      fontFamily: 'Barlow-600',
    },
    bgColor: {
      darkBgColor: `${PRIMARY_PURPLE[90]}`,
      lightBgColor: `${PRIMARY_PURPLE[30]}`,
      defaultBgColor: `${PRIMARY_PURPLE[70]}`,
    },
    hover: {
      defaultHoverColor: `${PRIMARY_PURPLE[60]}`,
      darkHoverColor: `${PRIMARY_PURPLE[80]}`,
      lightHoverColor: `${PRIMARY_PURPLE[20]}`,
    },
    selected: {
      background: {
        light: `${PRIMARY_PURPLE[70]}`,
      },
      font: {
        light: `${BLACK[0]}`,
      },
    },
    unSelected: {
      background: {
        light: `${PRIMARY_PURPLE[30]}`,
      },
      font: {
        light: `${PRIMARY_PURPLE[80]}`,
      },
    },
  },
  label: {
    font: 'Barlow-700',
    positionTemplate: {
      bgColor: {
        approved: FEED_BACK.GREEN_50,
        not_approved: PRIMARY_PURPLE[30],
      },
      color: {
        approved: FEED_BACK.GREEN_100,
        not_approved: PRIMARY_PURPLE[80],
      },
      font: 'Barlow-700',
    },
    users: {
      bgColor: {
        active: FEED_BACK.GREEN_10,
        inactive: BLACK[40],
        unverified: BLACK[40],
        dna: FEED_BACK.RED_10,
        dnr: FEED_BACK.RED_10,
        locked: FEED_BACK.RED_10,
      },
      color: {
        active: FEED_BACK.GREEN_100,
        inactive: BLACK[70],
        unverified: BLACK[70],
        dna: FEED_BACK.RED_100,
        dnr: FEED_BACK.RED_100,
        locked: FEED_BACK.RED_100,
      },
      font: 'Barlow-700',
    },
    job_orders: {
      bgColor: {
        active: FEED_BACK.GREEN_10,
        complete: FEED_BACK.GREEN_50,
        open: FEED_BACK.GREEN_10,
        cancelled: FEED_BACK.RED_10,
      },
      color: {
        active: FEED_BACK.GREEN_100,
        complete: FEED_BACK.GREEN_100,
        open: FEED_BACK.GREEN_100,
        cancelled: FEED_BACK.RED_100,
      },
      font: 'Barlow-700',
    },
    timekeeping: {
      bgColor: {
        approved: FEED_BACK.GREEN_50,
        conflict: FEED_BACK.YELLOW_50,
        pending: PRIMARY_PURPLE[30],
        disputed: FEED_BACK.YELLOW_50,
        resolved: FEED_BACK.GREEN_50,
        finalized: FEED_BACK.GREEN_50,
        overtime: FEED_BACK.YELLOW_50,
      },
      color: {
        approved: FEED_BACK.GREEN_100,
        conflict: FEED_BACK.YELLOW_100,
        pending: PRIMARY_PURPLE[80],
        disputed: FEED_BACK.YELLOW_100,
        resolved: FEED_BACK.GREEN_100,
        finalized: FEED_BACK.GREEN_100,
        overtime: FEED_BACK.YELLOW_100,
      },
      font: 'Barlow-700',
    },
  },
  datePicker: {
    font: {
      defaultFont: 'Barlow',
      boldFont: 'Barlow-800',

      headerFontSize: '20px',
      date: {
        fontSize: '16px',
        fontColor: `${BLACK[100]}`,
      },
      day: {
        fontSize: '14px',
        fontColor: `${BLACK[80]}`,
      },
    },
    iconButton: {
      light: {
        color: `${ACTION_BLUE.ACTION_BLUE}`,
      },
    },
    bgColor: {
      light: `${PRIMARY_PURPLE[10]}`,
    },
    dateBgColor: {
      light: `${PRIMARY_PURPLE[10]}`,
    },
    selected: {
      backgroundColor: {
        light: `${PRIMARY_PURPLE[30]}`,
      },
    },
  },
  jobRangePicker: {
    borderRadius: '16px',
    font: {
      defaultFont: 'Barlow',
      boldFont: 'Barlow-800',

      headerFontSize: '20px',
      date: {
        fontSize: '16px',
        fontColor: `${BLACK[100]}`,
      },
      day: {
        fontSize: '14px',
        fontColor: `${BLACK[80]}`,
      },
    },
    iconButton: {
      light: {
        color: `${ACTION_BLUE.ACTION_BLUE}`,
      },
    },
    bgColor: {
      light: `${PRIMARY_PURPLE[10]}`,
    },
    dateBgColor: {
      light: `${PRIMARY_PURPLE[10]}`,
    },
    selected: {
      backgroundColor: {
        dark: `${PRIMARY_PURPLE[90]}`,
      },
    },
    warning: {
      backgroundColor: `${FEED_BACK.ORANGE}`,
    },
    extraDay: {
      typography: {
        font: 'Barlow',
        boldFont: 'Barlow-800',
        fontSize: '16px',
        fontColor: BLACK[100],
      },
    },
  },

  export: {
    checkbox: {
      default: `${ACTION_BLUE.ACTION_BLUE}`,
    },
    typography: {
      font: 'Barlow',
      boldFont: 'Barlow-800',
    },
  },

  textField: {
    borderColor: {
      focused: `${PRIMARY_PURPLE[70]}`,
      error: `${FEED_BACK.RED_50}`,
    },
    inputLabel: {
      primary: {
        light: `${BLACK[20]}`,
        white: `${BLACK[0]}`,
      },
    },
    background: {
      light: `${BLACK[10]}`,
      white: `${BLACK[0]}`,
      grey: `${BLACK[20]}`,
    },
    darkBkColor: `${BLACK[80]}`,
  },
  selectDropdown: {
    bgColor: {
      hover: `${PRIMARY_PURPLE[10]}`,
      complete: `${PRIMARY_PURPLE[20]}`,
      focus: `${BLACK[0]}`,
    },
    borderColor: {
      focus: `${PRIMARY_PURPLE[70]}`,
    },
    listItemCheckbox: {
      color: `${ACTION_BLUE.ACTION_BLUE}`,
    },
    icon: {
      color: `${ACTION_BLUE.ACTION_BLUE}`,
    },
  },
  searchBox: {
    bgColor: {
      darkBkColor: `${BLACK[100]}`,
      lightBkColor: `${BLACK[0]}`,
      defaultBgColor: `${BLACK[0]}`,
      greyBkColor: `${BLACK[10]}`,
    },
    textBox: {
      darkBkColor: `${BLACK[90]}`,
    },
    font: {
      fontFamily: 'Barlow',
      fontSizeDefault: '14px',
      defaultHeaderSize: '17px',
      darkFontColor: `${BLACK[30]}`,
      defaultFontColor: `${BLACK[100]}`,
      lightFontColor: `${BLACK[100]}`,
      preDefinedTextFontFamily: 'Barlow-700',
      preDefinedTextFontColor: `${BLACK[100]}`,
      preDefinedTextFontSize: '18px',
      sectionHeaderFontFamily: 'Barlow-700',
      sectionHeaderFontSize: '18px',
      sectionHeaderFontColor: BLACK[100],
      subSectionFontColor: BLACK[80],
      subSectionFontFamily: 'Barlow-600',
    },
  },
  searchBoxForFilter: {
    bgColor: {
      hover: `${PRIMARY_PURPLE[10]}`,
      complete: `${PRIMARY_PURPLE[20]}`,
      focus: `${BLACK[0]}`,
    },
    borderColor: {
      focus: `${PRIMARY_PURPLE[70]}`,
    },
    chipBgColor: `${PRIMARY_PURPLE[40]}`,
  },

  searchEmployeeBar: {
    bgColor: {
      hover: `${PRIMARY_PURPLE[10]}`,
      complete: `${PRIMARY_PURPLE[20]}`,
      focus: `${BLACK[0]}`,
    },
    borderColor: {
      focus: `${PRIMARY_PURPLE[70]}`,
    },
    chipBgColor: `${PRIMARY_PURPLE[40]}`,
  },
  dateRangePickerFilter: {
    bgColor: {
      hover: `${PRIMARY_PURPLE[10]}`,
      complete: `${PRIMARY_PURPLE[20]}`,
      focus: `${BLACK[0]}`,
    },
    borderColor: {
      focus: `${PRIMARY_PURPLE[70]}`,
    },

    chipBgColor: `${PRIMARY_PURPLE[40]}`,
    dateDisplayBox: {
      bgColor: `${BLACK[20]}`,
      typography: {
        fontSize: '16px',
        fontWeight: 600,
        color: `${BLACK[100]}`,
      },
    },
  },
  timeRangePickerFilter: {
    bgColor: {
      hover: `${PRIMARY_PURPLE[10]}`,
      complete: `${PRIMARY_PURPLE[20]}`,
      focus: `${BLACK[0]}`,
    },
    borderColor: {
      focus: `${PRIMARY_PURPLE[70]}`,
    },

    chipBgColor: `${PRIMARY_PURPLE[40]}`,
    dateDisplayBox: {
      bgColor: `${BLACK[20]}`,
      typography: {
        fontSize: '16px',
        fontWeight: 600,
        color: `${BLACK[100]}`,
      },
    },
  },
  popoverFilter: {
    bgColor: `${BLACK[10]}`,
  },

  tab: {
    default: {
      bgColor: `${PRIMARY_PURPLE[20]}`,
      bgText: `${PRIMARY_PURPLE[70]}`,
      selected: `${PRIMARY_PURPLE[70]}`,
      selectedText: `${BLACK[10]}`,
      fontFamily: 'Barlow',
      boldFontFamily: 'Barlow-800',
      boldFontSize: '24px',
    },
    dark: {
      bgColor: `${BLACK[100]}`,
      bgText: `${PRIMARY_PURPLE[40]}`,
      selected: `${PRIMARY_PURPLE[70]}`,
      selectedText: `${BLACK[10]}`,
    },
  },

  dataGrid: {
    default: {
      fontSize: '14px',
      iconSize: '10px',
      largeFontSize: '18px',
      largeIconSize: '14px',
      fontFamily: 'Barlow',
      boldFont: 'Barlow-800',
      headerFont: 'Barlow-600',
      textColor: `${BLACK[90]}`,
      textFont: 'Barlow-500',
      headerColor: `${BLACK[100]}`,
      selectedTextColor: `${BLACK[0]}`,
    },
    bgColor: {
      columnHeaderBgColor: `${BLACK[30]}`,
      oddRowBgColor: `${BLACK[10]}`,
      selectedBgColor: `${BLACK[90]}`,
      tabIndicatorColor: `${PRIMARY_PURPLE[70]}`,
      selectedRowBgColor: `${BLACK[0]}`,
    },

    hover: {
      defaultHoverColor: `${PRIMARY_PURPLE[20]}`,
      selectedHoverColor: `${BLACK[80]}`,
    },

    jobInfo: {
      corpColor: `${BLACK[70]}`,
      jobColor: `${BLACK[100]}`,
      light: `${BLACK[20]}`,
      fontFamily: 'Barlow-600',
      longTermFontFamily: 'Barlow-700',
      fontSize: '20px',
      smallFontSize: '12px',
    },
    positionInfo: {
      descriptionColor: `${BLACK[70]}`,
      positionColor: `${BLACK[100]}`,
      fontFamily: 'Barlow-600',
    },
    userInfo: {
      roleColor: `${BLACK[70]}`,
      nameColor: `${BLACK[100]}`,
      fontFamily: 'Barlow-600',
    },
  },
  skillsAndCerts: {
    palette: {
      modalSubText: BLACK[70],
      modalHeader: BLACK[80],
      modalBgColor: BLACK[0],
      modalBorder: BLACK[20],
      newTextBgColor: BLACK[20],
    },
    typography: {
      modalSubText: {
        fontFamily: PRIMARY_FONT[500],
        fontSize: '16px',
      },
      modalHeader: {
        fontSize: '30px',
        fontFamily: PRIMARY_FONT[800],
      },
      placeholder: {
        fontFamily: PRIMARY_FONT[400],
        fontSize: '16px',
      },
    },
  },
  shiftSelector: {
    typography: {
      additionalShiftDescriptionFontFamily: 'Barlow',
      additionalShiftDescriptionFontSize: '14px',
      additionalShiftDescriptionFontColor: BLACK[80],
      additionalShiftDescriptionLineHeight: '18px',
    },
  },

  shiftPicker: {
    typography: {
      defaultFont: 'Barlow',
      boldFont: 'Barlow-700',
      headerFontColor: `${BLACK[100]}`,
      headerFontSize: '18px',
      defaultFontSize: '16px',
      assignedShifts: 'Barlow-500',
      shiftNameHeader: '14px',
      shiftTimeFont: 'Barlow-600',
    },
    palette: {
      assignedShifts: {
        backgroundColor: `${PRIMARY_PURPLE[10]}`,
        textColor: `${PRIMARY_PURPLE[70]}`,
        emptyTextColor: `${BLACK[70]}`,
        shiftNameHeaderColor: `${BLACK[80]}`,
      },
    },
  },
  emptyCard: {
    bgColor: `${BLACK[10]}`,
    headerColor: `${BLACK[80]}`,
    textColor: `${BLACK[70]}`,
  },
  icon: {
    checkOutlinedIcon: {
      color: `${BLACK[0]}`,
      fontSize: '30px',
    },
  },
});

export default theme;

export const drawerWidth = '240px';
export const drawerWidthHidden = `calc(${theme.spacing(9)} + 1px)`;
export const drawerWidthInternal = drawerWidth - 1;
export const groupListCompanyImageSize = { height: '70px', width: '70px' };
export const dashboardCompanyImageSize = { height: '50px', width: '50px' };
export const listHeaderHeight = '40px';
export const paperElevation = 1;
export const dataGridFiltersHeight = '40px';
export const tabHeight = '35px';
export const navigationBarHeight = '55px';
