import React, { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import asyncService from '../../../../../datahub/asyncService';
import Button from '../../../../components/Button';
import { buildFormField } from '../../../../components/Form/formFieldFactory';
import selectUser from '../../../../store/selectors/appSelector';
import theme from '../../../../theme';

import FormFieldDataCorporation from './FormData/FormFieldDataCorporation';

const componentsDetailViewSectionHeaders = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];

const viewHeaderStyling = {
  fontSize: get([...componentsDetailViewSectionHeaders, 'fontSize'], theme),
  fontFamily: get([...componentsDetailViewSectionHeaders, 'fontFamily'], theme),
  color: get([...componentsDetailViewSectionHeaders, 'fontColor'], theme),
};

const componentsDetailViewSemiBoldText = ['components', 'detailView', 'typography', 'semiBoldText'];

const RecruiterCorporationTabForAdmins = ({ initialValues, onUpdate }) => {
  const formFieldData = FormFieldDataCorporation(get(['recruiter', 'corporations'], initialValues));
  const corpIDs = get(['recruiter', 'corporations'], initialValues);
  const user = useSelector(selectUser);
  const token = get('token', user);
  const dispatch = useDispatch();
  const [corporationList, setCorporationList] = useState([]);

  const StyledFixedBottomBox = styled(Box)(() => ({
    position: 'absolute',
    backgroundImage: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
    fontFamily: `${theme.button.fontFamily}`,
    color: '#FFF',
    width: '100%',
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '4.40vh',
    cursor: 'pointer',
    borderBottomLeftRadius: '13px',
    borderBottomRightRadius: '13px',
  }));

  useEffect(() => {
    const submitAPICallData = {
      httpMethod: 'POST',
      route: 'corporations/read',
    };

    corpIDs &&
      corpIDs.forEach((corp) => {
        const data = { id: corp };
        asyncService({
          ...submitAPICallData,
          data,
          token,
          onSuccess: (payload) => {
            setCorporationList((latestCorp) => [
              ...latestCorp,
              get(['data', 'documents'], payload),
            ]);
          },
          onError: () => {
            setCorporationList([]);
          },
          dispatch,
        });
      });
  }, [corpIDs, dispatch, token]);

  const handleOnSubmit = useCallback(
    (values) => {
      onUpdate({
        ...values,
        recruiterCorporations: get('corporations', values).map((corp) => corp._id),
      });
    },
    [onUpdate]
  );

  const readOnlyFieldValueStyling = {
    fontSize: get([...componentsDetailViewSemiBoldText, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSemiBoldText, 'fontFamily'], theme),
    color: get([...componentsDetailViewSemiBoldText, 'fontColor'], theme),
    margin: theme.spacing(0, 0, 0, 1),
  };

  return (
    <RFForm
      onSubmit={handleOnSubmit}
      initialValues={{
        ...initialValues,
        corporations: corporationList,
      }}
      render={({ handleSubmit, pristine }) => (
        <Box
          sx={{
            width: '100%',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
            height: '80vh',
          }}
        >
          <form
            onSubmit={handleSubmit}
            id="loginformid"
            style={{
              width: '100%',
            }}
          >
            <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(0, 0, 1, 0) }}>
              Corporations
            </Typography>
            <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
              <Typography sx={readOnlyFieldValueStyling}>
                Search for and add additional corporation by using the field below:
              </Typography>
              <Grid item>{buildFormField(formFieldData.corporations)}</Grid>
              {pristine ? null : (
                <StyledFixedBottomBox>
                  <Button sx={{ width: '100%' }} type="submit">
                    Submit
                  </Button>
                </StyledFixedBottomBox>
              )}
            </Grid>
          </form>
        </Box>
      )}
    />
  );
};

RecruiterCorporationTabForAdmins.propTypes = {
  initialValues: PropTypes.shape({}),
  onUpdate: PropTypes.func,
};

export default RecruiterCorporationTabForAdmins;
