/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import asyncService from '../../../../datahub/asyncService';
import Purple70Dot from '../../../assets/icons/Purple70Dot.svg';
import Button from '../../../components/Button';
import Label from '../../../components/Label';
import WaeMap from '../../../components/Map/WaeMap';
import TableComponent from '../../../components/TableComponent/TableComponent';
import searchParamOptions from '../../../constants/searchParams';
import UserRole from '../../../constants/user-role';
import selectUser from '../../../store/selectors/appSelector';
import theme from '../../../theme';
import { BLACK, PRIMARY_PURPLE } from '../../../theme/colorConstants';
import { epochToTimeInReadableFormat } from '../../../utils';
import launchDarklyToggles from '../../../utils/launchDarklyToggles';
import { ACT_BTN_TEXT, FIFTEEN_MINS_IN_EPOCH, TIMEKEEPING_STATUSES } from '../constants';
import { generateStatusData, handleLabelProps } from '../helpers';
import { postTimeCardApproval, postTimeCardFinalize, postTimeCardResolve } from '../reducer';
import TimekeepingEditDrawer from '../TimeKeepingEditDrawer/TimeKeepingEditDrawer';

import TimeKeepingDateSelector from './TImekeepingDateSelector';

const Overview = ({ daysOfWeek, initialData, flags, selectedDate, setSelectedDate }) => {
  const recruiterDarklyFlagTimeCardEditBlocked = launchDarklyToggles(
    flags,
    'isRecruiterTimeCardEditBlocked'
  );

  const weekValues = daysOfWeek.map((day) => ({
    date: get('dayObject', day).format('MM/DD'),
    day: get('weekday', day),
    totalHours:
      (get(get('field', day), initialData.days).length > 0 &&
        `${get(get('field', day), initialData.days)
          .reduce((acc, currVal) => acc + get(['timecard', 'totalHours'], currVal), 0)
          .toFixed(2)}`) ||
      '-',
    value: get('dayObject', day).format('MM/DD/YYYY').replace(/\b0/g, ''),
  }));

  const currentDate = selectedDate || weekValues[2].value;

  const [searchParams] = useSearchParams();
  const placement = searchParams.get(searchParamOptions.PLACEMENT);
  const selectedPlacementRef = useRef();

  useEffect(() => {
    if (selectedPlacementRef && selectedPlacementRef.current) {
      selectedPlacementRef.current.scrollIntoView();
    }
  }, [selectedPlacementRef]);

  const componentsTimecardDetailPunchCardPalette = [
    'components',
    'timecardDetailPunchCard',
    'palette',
  ];
  const componentsTimecardDetailPunchCardTypography = [
    'components',
    'timecardDetailPunchCard',
    'typography',
  ];
  const [workFlowData, setWorkFlowData] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editModeDescription, setEditModeDescription] = useState({});
  const [jobOrder, setJobOrder] = useState([]);
  const user = useSelector(selectUser);
  const token = get('token', user);
  const dispatch = useDispatch();
  useEffect(() => {
    const placements = get(['days', `${currentDate}`], initialData);
    const submitAPICallData = {
      httpMethod: 'GET',
      route: 'placements',
    };

    placements &&
      placements.forEach((place) => {
        const data = { id: `${place.placement}` };
        asyncService({
          ...submitAPICallData,
          data,
          token,
          onSuccess: (payload) => {
            setJobOrder([...jobOrder, ...get(['data', 'documents'], payload)]);
          },
          onError: () => {
            setJobOrder([]);
          },
          dispatch,
        });
      });
    return () => {
      setJobOrder([]);
    };
  }, []);

  const checkIfPunchesContainBothInAndOut = (punches) => {
    let check = true;

    punches.forEach((punch) => {
      if (!get(['out', 'stamp'], punch)) {
        check = false;
      }
    });
    return check;
  };
  const isShiftCompleted = (endTime) => {
    const momentInEpoch = new Date().getTime();
    return endTime + FIFTEEN_MINS_IN_EPOCH < momentInEpoch;
  };

  const getPlacementListToApprove = () => {
    let placementIDList = [];
    Object.keys(get('days', initialData)).forEach((key) => {
      placementIDList = [
        ...placementIDList,
        ...get('days', initialData)
          [key].filter(
            (day) =>
              get(['timecard', 'status'], day) === 'pending' &&
              get(['timecard', 'punches'], day).length > 0 &&
              checkIfPunchesContainBothInAndOut(get(['timecard', 'punches'], day)) &&
              isShiftCompleted(get(['end'], day))
          )
          .map((day) => get('placement', day)),
      ];
    });
    return placementIDList;
  };
  const nameTitleSx = {
    fontSize: '30px',
    fontFamily: 'Barlow-800',
    color: `${BLACK[0]}`,
  };
  const weekSx = {
    fontSize: '18px',
    fontFamily: 'Barlow-600',
    color: `${BLACK[50]}`,
  };
  const cardTitleSx = {
    paddingLeft: '24px',
    fontSize: '14px',
    color: `${BLACK[50]}`,
    fontFamily: 'Barlow-500',
  };
  const shiftTimeSx = {
    paddingLeft: '24px',
    fontSize: '20px',
    color: `${PRIMARY_PURPLE[40]}`,
    fontFamily: 'Barlow-700',
  };
  const cardShiftTitleSx = {
    paddingLeft: '24px',
    fontSize: '16px',
    color: `${BLACK[0]}`,
    fontFamily: 'Barlow-600',
  };

  const totalHoursSx = {
    fontSize: '18px',
    color: `${PRIMARY_PURPLE[40]}`,
    fontFamily: 'Barlow-600',
    paddingRight: '24px',
  };
  const HeaderGrid = styled(Grid)(() => ({
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  }));

  const SubHeaderGrid = styled(Grid)(() => ({
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  }));

  const dayandDateTypographySx = {
    fontSize: get([...componentsTimecardDetailPunchCardTypography, 'dayAndDateFontSize'], theme),
    fontFamily: get(
      [...componentsTimecardDetailPunchCardTypography, 'dayAndDateFontFamily'],
      theme
    ),
    color: get([...componentsTimecardDetailPunchCardPalette, 'dayandDateFontColor'], theme),
    padding: theme.spacing(2, 0, 0),
    lineHeight: '32px',
  };
  const buildingLocation = {
    lat: 43.1497,
    lng: -77.58832,
  };

  const generateGPSMarkers = (punches) => {
    const punchesGPS = [];
    punches.forEach((punch) => {
      punchesGPS.push({
        position: {
          lat: parseFloat(get(['in', 'gps', 'coordinates', 'latitude'], punch)),
          lng: parseFloat(get(['in', 'gps', 'coordinates', 'longitude'], punch)),
        },
      });
      if (get(['out'], punch)) {
        punchesGPS.push({
          position: {
            lat: parseFloat(get(['out', 'gps', 'coordinates', 'latitude'], punch)),
            lng: parseFloat(get(['out', 'gps', 'coordinates', 'longitude'], punch)),
          },
        });
      }
    });
    return punchesGPS;
  };

  const checkAnyPunchAreOoB = (punches) => {
    const checkObj = [];
    punches.forEach((punch) => {
      checkObj.push(get(['in', 'gps', 'oob'], punch));
      if ((get.out, punch)) {
        checkObj.push(get[('out', 'gps', 'oob')], punch);
      }
    });

    return checkObj.includes(true);
  };

  // Uncomment when map is ready
  const markers = (punches) => {
    const jobOrderFromPlacement = jobOrder.filter(
      (job) => get('_id', job) === get('placement', punches)
    );

    return [
      {
        position:
          (get(['jobOrder', 'location', 'target'], jobOrderFromPlacement[0]) && {
            long: get(['jobOrder', 'location', 'target', 'longitude'], jobOrderFromPlacement[0]),
            lat: get(['jobOrder', 'location', 'target', 'latitude'], jobOrderFromPlacement[0]),
          }) ||
          buildingLocation,
        icon: Purple70Dot,
        circle: {
          radius: get(['jobOrder', 'gps', 'maxDistance'], jobOrderFromPlacement[0]),
          fillColor: '#7A23FF',
        },
      },
      ...generateGPSMarkers(get(['timecard', 'punches'], punches)),
    ];
  };

  const [mapOpen, setMapOpen] = useState(false);

  const toggleMap = () => {
    setMapOpen(!mapOpen);
  };
  const RootBox = styled(Box)(() => ({
    width: '100%',
  }));

  const TableBodyPaper = styled(Paper)(() => ({
    backgroundColor: `${BLACK[100]}`,
    minHeight: '418px',
    width: '100%',
    display: 'flex',
    marginBottom: '32px',
    borderRadius: '16px',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    alignContent: 'center',
  }));
  const StyledFixedBottomBox = styled(Box)(() => ({
    position: 'absolute',
    backgroundImage: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
    fontFamily: `${theme.button.fontFamily}`,
    color: '#FFF',
    width: '100%',
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '4.25vh',
    cursor: 'pointer',
  }));

  const getEditModeFullDayName = (date) => {
    const filteredDayByDate = daysOfWeek.filter((day) => day.field === date);
    return get('fullWeekDayName', filteredDayByDate[0]);
  };
  const handleEditOnClick = (editData, corporation, shiftName, order, start, end) => {
    setIsDrawerOpen(true);
    setWorkFlowData(editData);
    setEditModeDescription({
      date: `${getEditModeFullDayName(currentDate)}, ${currentDate}`,
      corporation,
      order,
      shiftName,
      start,
      end,
    });
  };

  const additionalDateSelectorOnClick = async (date) => {
    const placements = get(['days', `${date}`], initialData);
    const submitAPICallData = {
      httpMethod: 'GET',
      route: 'placements',
    };
    placements.forEach((place) => {
      const data = { id: `${place.placement}` };
      asyncService({
        ...submitAPICallData,
        data,
        onSuccess: (payload) => {
          if (!jobOrder.some((job) => get('_id', job) === get('placement', place))) {
            setJobOrder([...jobOrder, ...get(['data', 'documents'], payload)]);
          }
        },
        onError: () => {
          setJobOrder([]);
        },
        dispatch,
        token,
      });
    });
  };

  const showEditAndActionButtons = (shift, role) => {
    if (recruiterDarklyFlagTimeCardEditBlocked && role === UserRole.RECRUITER) {
      return false;
    }

    const status = get(['timecard', 'status'], shift);
    return (
      isShiftCompleted(get(['end'], shift)) &&
      ((role === UserRole.EMPLOYER && status === TIMEKEEPING_STATUSES.PENDING) ||
        ((role === UserRole.ADMIN || role === UserRole.RECRUITER) &&
          status !== TIMEKEEPING_STATUSES.FINALIZED) ||
        false)
    );
  };

  /**
   * Renders the action button for the user role and status.
   * Handles the onClick action for the button which is a dispatch to post the appropriate action.
   * Handles the button text.
   *
   * @param {*} placementId
   * @param {*} status
   * @param {*} role
   * @return {*}
   */
  const createUserRoleActionButton = (placementId, status, role) => {
    let btnTxt = null;
    let btnAction = null;
    switch (status) {
      case TIMEKEEPING_STATUSES.PENDING:
        if (role === UserRole.EMPLOYER) {
          btnTxt = ACT_BTN_TEXT.APPROVE;
          btnAction = () => {
            dispatch(postTimeCardApproval({ placements: [placementId] }));
          };
        }
        if (role === UserRole.ADMIN || role === UserRole.RECRUITER) {
          btnTxt = ACT_BTN_TEXT.RESOLVE;
          btnAction = () => {
            dispatch(postTimeCardResolve({ placements: [placementId] }));
          };
        }
        break;
      case TIMEKEEPING_STATUSES.APPROVED:
        if (role === UserRole.ADMIN || role === UserRole.RECRUITER) {
          btnTxt = ACT_BTN_TEXT.FINALIZE;
          btnAction = () => {
            dispatch(postTimeCardFinalize({ placements: [placementId] }));
          };
        }

        break;
      case TIMEKEEPING_STATUSES.RESOLVED:
        if (role === UserRole.ADMIN || role === UserRole.RECRUITER) {
          btnTxt = ACT_BTN_TEXT.FINALIZE;
          btnAction = () => {
            dispatch(postTimeCardFinalize({ placements: [placementId] }));
          };
        }
        break;
      case TIMEKEEPING_STATUSES.DISPUTED:
        if (role === UserRole.ADMIN || role === UserRole.RECRUITER) {
          btnTxt = ACT_BTN_TEXT.RESOLVE;
          btnAction = () => {
            dispatch(postTimeCardResolve({ placements: [placementId] }));
          };
        }
        break;
      case TIMEKEEPING_STATUSES.FINALIZED:
        break;
      default:
        break;
    }

    return <Button onClick={() => btnAction()} text={btnTxt} sx={{ marginLeft: '24px' }} />;
  };

  return (
    <RootBox>
      <HeaderGrid container item>
        <Box sx={nameTitleSx}>{get(['candidate', 'name'], initialData)}</Box>
        <Label {...handleLabelProps(get(['status', 'label'], generateStatusData(initialData)))} />
      </HeaderGrid>
      <SubHeaderGrid container item>
        <Box sx={weekSx}>{`This week: ${get('value', weekValues[0])} - ${get(
          'value',
          weekValues[6]
        )}`}</Box>
        <Box sx={{ ...weekSx, color: `${BLACK[0]}` }}>{`${get('totalHours', initialData).toFixed(
          2
        )} total hours`}</Box>
      </SubHeaderGrid>
      <TimeKeepingDateSelector
        dates={weekValues}
        data={initialData}
        initialSelectedDate={currentDate}
        setCurrentDate={setSelectedDate}
        onClick={additionalDateSelectorOnClick}
      />
      <Typography sx={dayandDateTypographySx}>{`${getEditModeFullDayName(
        currentDate
      )}, ${currentDate}`}</Typography>
      <Box
        container
        sx={{
          maxHeight: '60vh',
          width: '100%',
          transform: 'translateZ(0)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          margin: theme.spacing(2, 0),
        }}
      >
        {get(['days', `${currentDate}`], initialData) &&
          get(['days', `${currentDate}`], initialData).map(
            (shift) =>
              get(['timecard', 'punches'], shift) && (
                <TableBodyPaper
                  key={get('placement', shift)}
                  ref={get('placement', shift) === placement ? selectedPlacementRef : null}
                >
                  <Grid container item direction="row" justifyContent="space-between">
                    <Grid>
                      <Box sx={cardTitleSx}>{get(['order'], shift)}</Box>
                      <Box sx={{ ...cardTitleSx }}>{get(['corporation'], shift)}</Box>
                    </Grid>
                    <Grid>
                      <Label
                        {...handleLabelProps(get(['timecard', 'status'], shift))}
                        sx={{
                          ...handleLabelProps(get(['timecard', 'status'], shift)).sx,
                          marginRight: '24px',
                        }}
                      />
                      {get(['timecard', 'overtimeHours'], shift) > 0 && (
                        <Label
                          nameIsShortened
                          {...handleLabelProps(TIMEKEEPING_STATUSES.OVERTIME)}
                          sx={{
                            ...handleLabelProps(TIMEKEEPING_STATUSES.OVERTIME).sx,
                            margin: theme.spacing(1, 3, 0, 0),
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Box sx={cardShiftTitleSx}>{get(['shiftName'], shift)}</Box>
                    <Box sx={shiftTimeSx}>{`${epochToTimeInReadableFormat(
                      get('start', shift)
                    )} - ${epochToTimeInReadableFormat(get('end', shift))}`}</Box>
                  </Grid>
                  <TableComponent
                    columnData={['In', 'Out', 'Hours']}
                    rowsData={get(['timecard', 'punches'], shift)}
                    sx={{
                      paddingRight: '24px',
                      paddingLeft: '24px',
                    }}
                  />
                  <Grid container item sx={{ justifyContent: 'flex-end' }}>
                    <Box sx={totalHoursSx}>{`Total: ${get(
                      ['timecard', 'totalHours'],
                      shift
                    ).toFixed(2)}`}</Box>
                  </Grid>
                  {/* Uncomment when we have the data to show the map with pucnhes out of bounds */}
                  {checkAnyPunchAreOoB(get(['timecard', 'punches'], shift)) && (
                    <Grid
                      item
                      sx={{
                        height: mapOpen ? '35vh' : '20px',
                        padding: theme.spacing(2, 2, 6, 2),
                        margin: theme.spacing(0, 0, 2, 0),
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                        onClick={toggleMap}
                      >
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <Label
                            {...handleLabelProps(TIMEKEEPING_STATUSES.CONFLICT)}
                            sx={{
                              ...handleLabelProps(TIMEKEEPING_STATUSES.CONFLICT).sx,
                              marginRight: theme.spacing(2),
                            }}
                            collapsed
                          />
                          <Typography sx={{ color: '#FFF' }}>
                            Employee clocked in outside of geofence.
                          </Typography>
                        </Box>

                        {mapOpen ? (
                          <ExpandLessIcon sx={{ color: theme.button.palette.secondary }} />
                        ) : (
                          <ExpandMoreIcon sx={{ color: theme.button.palette.secondary }} />
                        )}
                      </Box>

                      {mapOpen && (
                        <WaeMap center={buildingLocation} markers={markers(shift)} zoomLevel={13} />
                      )}
                    </Grid>
                  )}

                  {showEditAndActionButtons(shift, get('role', user)) && (
                    <Grid item>
                      <Button
                        onClick={() =>
                          handleEditOnClick(
                            shift,
                            get(['corporation'], shift),
                            get(['shiftName'], shift),
                            get(['order'], shift),
                            get(['start'], shift),
                            get(['end'], shift)
                          )
                        }
                        type="outlined"
                        text="EDIT"
                        sx={{ marginLeft: '24px', width: '93px' }}
                      />
                      {createUserRoleActionButton(
                        get('placement', shift),
                        get(['timecard', 'status'], shift),
                        get('role', user)
                      )}
                    </Grid>
                  )}
                </TableBodyPaper>
              )
          )}
        <TimekeepingEditDrawer
          anchor="right"
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          data={workFlowData}
          description={editModeDescription}
        />
      </Box>
      {get('role', user) === UserRole.EMPLOYER &&
        get(['status', 'label'], initialData) === 'pending' &&
        getPlacementListToApprove().length > 0 && (
          <StyledFixedBottomBox
            onClick={() =>
              dispatch(postTimeCardApproval({ placements: getPlacementListToApprove() }))
            }
          >
            <Typography>Approve Week</Typography>
          </StyledFixedBottomBox>
        )}
    </RootBox>
  );
};

Overview.propTypes = {
  daysOfWeek: PropTypes.arrayOf(PropTypes.shape({})),
  initialData: PropTypes.shape({
    days: PropTypes.shape([]),
  }),
  flags: PropTypes.shape({}),
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
};

export default withLDConsumer()(Overview);
