import { get } from 'lodash/fp';
import { useIntl } from 'react-intl';

import FormCheckBox from '../../../../../components/Form/FormCheckBox';
import FormSelectDropdownField from '../../../../../components/Form/FormSelectDropdownField';
import FormTextField from '../../../../../components/Form/FormTextField';
import SearchBox, { SEARCHBAR_BACKGROUND } from '../../../../../components/SearchBar';
import theme from '../../../../../theme/theme';

import {
  benefitsPackOptions,
  branchOptions,
  legalBusEntryOptions,
  payGroupOptions,
} from './AdminOverviewDropDownSelectOptions';

const AdminFormFieldDataOverview = (availableLocations, corpId) => {
  const intl = useIntl();

  return {
    name: {
      ComponentType: FormTextField,
      key: 'ptname',
      name: 'name',
      mode: 'dark',
      label: 'Title',
      sx: {
        height: '40px',
        width: '236px',
      },
    },
    description: {
      ComponentType: FormTextField,
      key: 'ptdescription',
      name: 'description',
      mode: 'dark',
      multiline: true,
      label: 'Description',
      fullWidth: true,
    },
    approved: {
      ComponentType: FormCheckBox,
      label: 'Approved',
      name: 'approved',
      variant: 'standard',
      fullWidth: true,
    },

    locations: {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.DARK,
      preDefinedOptions: [],
      data: null,
      key: 'id',
      name: 'locations',
      multiSelect: true,
      required: true,
      variant: 'standard',
      description:
        'Search for additional locations or add additional locations by clicking on the location chips below.',
      optionLabel: 'name',
      placeholder: 'Enter location',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'locations/read',
      },
      optionDisplayField: 'description',
    },

    departments: {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.DARK,
      preDefinedOptions: [],
      data: { corporation: corpId },
      key: '_id',
      name: 'departments',
      multiSelect: true,
      required: true,
      variant: 'standard',
      description:
        'Search for additional departments or add additional departments by clicking on the location chips below.',
      optionLabel: 'name',
      placeholder: 'Enter department',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'corporations/departments/read',
      },
      optionDisplayField: 'name',
    },

    branch: {
      ComponentType: FormSelectDropdownField,
      sx: { height: '40px', width: '236px', margin: theme.spacing(0, 0, 1, 0) },
      fullWidth: true,
      mode: 'dark',
      name: 'branch',
      readOnlyFormat: (value) => get('name', value),
      options: branchOptions(intl),
    },
    legalBusEntry: {
      ComponentType: FormSelectDropdownField,
      sx: { height: '40px', width: '236px', margin: theme.spacing(0, 0, 1, 0) },
      fullWidth: true,
      mode: 'dark',
      name: 'legalBusEntry',
      readOnlyFormat: (value) => get('name', value),
      options: legalBusEntryOptions(intl),
    },
    benefitsPack: {
      ComponentType: FormSelectDropdownField,
      sx: { height: '40px', width: '236px', margin: theme.spacing(0, 0, 1, 0) },
      fullWidth: true,
      mode: 'dark',
      name: 'benefitsPack',
      readOnlyFormat: (value) => get('name', value),
      options: benefitsPackOptions(intl),
    },
    payGroup: {
      ComponentType: FormSelectDropdownField,
      sx: { height: '40px', width: '236px', margin: theme.spacing(0, 0, 1, 0) },
      fullWidth: true,
      mode: 'dark',
      name: 'payGroup',
      readOnlyFormat: (value) => get('name', value),
      options: payGroupOptions(intl),
    },
    saleRep: {
      ComponentType: FormTextField,
      sx: { height: '40px', width: '236px', margin: theme.spacing(0, 0, 1, 0) },
      mode: 'dark',
      name: 'saleRep',
      variant: 'outlined',
    },
  };
};
export default AdminFormFieldDataOverview;
