import React, { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Box, Skeleton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import CheckboxChecked from '../../../assets/icons/CheckboxChecked.svg';
import CheckboxUnchecked from '../../../assets/icons/CheckboxUnchecked.svg';
import CrossIcon from '../../../assets/icons/CrossIcon';
import TickIcon from '../../../assets/icons/TickIcon';
import BackdropCircularProgress from '../../../components/BackdropCircularProgress/BackdropCircularProgress';
import { WaeButton } from '../../../components/Button/Button';
import searchParamOptions from '../../../constants/searchParams';
import {
  selectInvitedJob,
  selectLoadingInvitedJob,
  selectLoadingSkills,
  selectPostingInviteRespond,
  selectPostInviteRespondSuccessful,
  selectSkills,
} from '../../../store/selectors/Candidate/invitedJobSelector';
import theme from '../../../theme/theme';
import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../utils';

import {
  getInvitedJobOrder,
  getSkills,
  postInviteRespond,
  setJobInviteToInitState,
} from './reducer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '549px',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: 4,
  pr: 2,
};

const JobInvitation = () => {
  const themePath = ['candidate', 'components', 'jobInvitation'];
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const loadingInvitedJob = useSelector(selectLoadingInvitedJob);
  const invitedJob = useSelector(selectInvitedJob);
  const loadingSkills = useSelector(selectLoadingSkills);
  const skills = useSelector(selectSkills);
  const postingInviteRespond = useSelector(selectPostingInviteRespond);
  const postInviteRespondSuccessful = useSelector(selectPostInviteRespondSuccessful);
  const placementId = searchParams.get(searchParamOptions.PLACEMENT);

  const skillNames = useCallback(
    () => [
      'I have reliable transportation to the job',
      'I can perform the outlined duties of the job',
      ...skills.map((item) => get(['name'], item)),
    ],
    [skills]
  );

  const handleClose = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  useEffect(() => {
    dispatch(
      getInvitedJobOrder({
        filters: [
          {
            operation: 'equalsID',
            field: '_id',
            value: searchParams.get(searchParamOptions.JOB_ORDER),
          },
        ],
      })
    );
    return () => dispatch(setJobInviteToInitState());
  }, [dispatch, searchParams, handleClose]);

  useEffect(() => {
    if (get(['skills'], invitedJob)) {
      dispatch(
        getSkills({
          filters: [
            {
              operation: 'idContainedIn',
              field: '_id',
              value: get(['skills'], invitedJob),
            },
          ],
        })
      );
    }
  }, [dispatch, invitedJob]);

  useEffect(() => {
    if (postInviteRespondSuccessful) {
      handleClose();
    }
  }, [postInviteRespondSuccessful, handleClose]);

  const initialCheckState = useCallback(() => {
    const skillData = {};
    skillNames().forEach((i) => {
      skillData[i] = false;
    });
    return skillData;
  }, [skillNames]);

  const [skillsChecked, setSkillsChecked] = useState({});

  const disableAcceptButton = useCallback(() => {
    let disabled = false;
    Object.keys(skillsChecked).forEach((key) => {
      if (!skillsChecked[key]) {
        disabled = true;
      }
    });
    if (Object.keys(skillsChecked).length < Object.keys(initialCheckState()).length) {
      disabled = true;
    }
    return disabled;
  }, [skillsChecked, initialCheckState]);

  const handleChange = (event) => {
    setSkillsChecked({
      ...skillsChecked,
      [event.target.name]: event.target.checked,
    });
  };

  const getShiftTotal = () =>
    ((get(['end'], invitedJob) - get(['start'], invitedJob)) / 3600000) *
    get(['payRateReg'], invitedJob);

  const handleOnClick = ({ accepted }) => {
    dispatch(
      postInviteRespond({
        placement: placementId,
        accepted,
      })
    );
  };

  const TitleContentTypography = ({ title, content }) => (
    <>
      <Typography sx={get([...themePath, 'typography', 'subTitle'], theme)}>{title}</Typography>
      <Typography sx={get([...themePath, 'typography', 'subContent'], theme)}>{content}</Typography>
    </>
  );

  TitleContentTypography.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
  };

  const scrollBarStyles = {
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.components.scrollBar.trackColor,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.components.scrollBar.thumbColor,
      borderRadius: '50px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.components.scrollBar.thumbHover,
    },
  };

  return (
    <Modal
      open={!!searchParams.get(searchParamOptions.INVITED)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid sx={style}>
        <Grid
          sx={{
            overflow: 'auto',
            maxHeight: '500px',
            paddingRight: '16px',
            ...scrollBarStyles,
          }}
        >
          <Typography sx={get([...themePath, 'typography', 'title'], theme)}>
            You have a Job Invitation
          </Typography>
          <Typography sx={{ mt: 2, ...get([...themePath, 'typography', 'description'], theme) }}>
            A pending invitation for the following job order has been sent to you. If accepted this
            job will be accessible within your “My Jobs” list. There is no penalty for rejected a
            job invitation.
          </Typography>

          <Grid
            container
            sx={{
              backgroundColor: get([...themePath, 'palette', 'bodyBgColor'], theme),
              borderRadius: '16px',
              height: '340px',
              p: 3,
              mt: 2,
              mb: 2,
            }}
          >
            {loadingInvitedJob ? (
              <Skeleton height="inherit" variant="rectangular" sx={{ borderRadius: '16px' }} />
            ) : (
              <>
                <Typography
                  sx={{ width: '100%', ...get([...themePath, 'typography', 'jobName'], theme) }}
                >
                  {get(['name'], invitedJob)}
                </Typography>
                <Typography sx={get([...themePath, 'typography', 'corpName'], theme)}>
                  {get(['corporation', 'name'], invitedJob)}
                </Typography>
                <Grid container item direction="column" sx={{ mt: 2 }} xs={12}>
                  <TitleContentTypography
                    title="Estimated shift total"
                    content={`$${getShiftTotal().toFixed(2)}`}
                  />
                </Grid>
                <Grid container item direction="column" sx={{ mt: 2 }} xs={12}>
                  <TitleContentTypography
                    title="Hourly rate"
                    content={`$${get(['payRateReg'], invitedJob)}/hr`}
                  />
                </Grid>
                <Grid container direction="column" sx={{ mt: 2 }} item xs={5}>
                  <TitleContentTypography
                    title="to work on"
                    content={`${epochToDateInReadableFormat(get(['start'], invitedJob))}`}
                  />
                </Grid>
                <Grid container direction="column" sx={{ mt: 2 }} item xs={7}>
                  <TitleContentTypography
                    title="from "
                    content={`${epochToTimeInReadableFormat(
                      get(['start'], invitedJob)
                    )} - ${epochToTimeInReadableFormat(get(['end'], invitedJob))}`}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Typography sx={get([...themePath, 'typography', 'shiftChecklist'], theme)}>
            Shift Checklist
          </Typography>
          <Typography sx={get([...themePath, 'typography', 'shiftChecklistDesc'], theme)}>
            Please confirm the items below before signing up for this job.
          </Typography>
          {!loadingSkills && (
            <FormGroup onChange={handleChange} sx={{ mt: 3 }}>
              {skillNames().map((skill) => (
                <FormControlLabel
                  key={skill}
                  control={
                    <Checkbox
                      checkedIcon={
                        <Box
                          component="img"
                          sx={{
                            height: 24,
                            width: 24,
                          }}
                          alt="Stars"
                          src={CheckboxChecked}
                        />
                      }
                      icon={
                        <Box
                          component="img"
                          sx={{
                            height: 24,
                            width: 24,
                          }}
                          alt="Stars"
                          src={CheckboxUnchecked}
                        />
                      }
                      sx={{ pt: '4px', ml: '4px' }}
                    />
                  }
                  name={skill}
                  label={skill}
                  sx={get([...themePath, 'typography', 'skills'], theme)}
                />
              ))}
            </FormGroup>
          )}
        </Grid>
        <Grid container justifyContent="right" alignItems="center" paddingTop={4}>
          <WaeButton
            disabled={loadingInvitedJob}
            text="Reject Invitation"
            type="outlined"
            endIcon={<CrossIcon />}
            onClick={() => handleOnClick({ accepted: false })}
          />
          <WaeButton
            disabled={disableAcceptButton() || loadingInvitedJob}
            text="Accept Invitation"
            endIcon={<TickIcon />}
            onClick={() => handleOnClick({ accepted: true })}
            sx={{ ml: '16px', mr: '20px' }}
          />
        </Grid>
        {(loadingInvitedJob || loadingSkills || postingInviteRespond) && (
          <BackdropCircularProgress />
        )}
      </Grid>
    </Modal>
  );
};

export default JobInvitation;
