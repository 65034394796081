import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';

import Button from '../../../../../components/Button';
import { buildFormField } from '../../../../../components/Form/formFieldFactory';
import theme from '../../../../../theme/theme';

import {
  backButtonSx,
  buttonGridSx,
  forwardButtonSx,
  subTextSx,
  titleSx,
} from './DepartmentFlyOutStyling';
import FormFieldData from './formFieldData';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const DepartmentFlyOut = ({ handleOnClose, handleOnSubmit, initialValues, title }) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isWindowWidthLessThanModalWidth, setIsWindowWidthLessThanModalWidth] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    if (get('width', windowDimensions) < 517) {
      setIsWindowWidthLessThanModalWidth(true);
    } else {
      setIsWindowWidthLessThanModalWidth(false);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return (
    <Modal open>
      <Box
        sx={{
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: (isWindowWidthLessThanModalWidth && '95%') || '517px',
          height: '500px',
          borderRadius: '16px',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <RFForm
          onSubmit={handleOnSubmit}
          initialValues={initialValues || {}}
          render={({ handleSubmit, pristine }) => (
            <Grid sx={{ height: '100%' }}>
              <Typography sx={titleSx}>{title}</Typography>
              <form
                onSubmit={handleSubmit}
                style={{
                  width: '100%',
                  paddingBottom: pristine ? null : theme.spacing(3),
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '350px',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': { display: 'none' },
                  }}
                >
                  <Grid item sx={{ margin: theme.spacing(0, 0, 2, 0) }}>
                    <Typography sx={subTextSx}>Department Name</Typography>
                    {buildFormField(FormFieldData.title)}
                  </Grid>

                  <Grid item>
                    <Typography sx={subTextSx}>Department Description</Typography>
                    {buildFormField(FormFieldData.description)}
                  </Grid>
                </Box>
                <Grid container item justifyContent="flex-end" sx={buttonGridSx}>
                  <Button
                    onClick={handleOnClose}
                    type="outlined"
                    sx={backButtonSx}
                    startIcon={
                      <ArrowBackIcon
                        sx={{ color: get(['button', 'palette', 'secondary'], theme) }}
                      />
                    }
                  >
                    Cancel
                  </Button>
                  <Button data-testid="modalSubmitButton" sx={forwardButtonSx} type="submit">
                    Submit
                  </Button>
                </Grid>
              </form>
            </Grid>
          )}
        />
      </Box>
    </Modal>
  );
};

DepartmentFlyOut.propTypes = {
  handleOnClose: PropTypes.func,
  handleOnSubmit: PropTypes.func,
  initialValues: PropTypes.shape({}),
  title: PropTypes.string,
};

export default DepartmentFlyOut;
