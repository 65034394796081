import React from 'react';
import PropTypes from 'prop-types';

import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';

import theme from '../../theme';
import Chip from '../Chip';

import { errorHandler } from './formUtils';

const activeChipColor = theme.chip.bgColor.defaultBgColor;
const inactiveChipColor = theme.chip.bgColor.darkBgColor;
const chipFont = theme.chip.default.fontFamily;

const FormChip = ({ disabled, fieldErrorData, input, meta, size, required, variant }) => {
  const error = errorHandler(input, meta, fieldErrorData);
  const { iconSize } = theme.chip.default;

  return (
    <Box>
      <Chip
        checked={input.value || false}
        name={input.name}
        onChange={input.onChange}
        size={size}
        required={required}
        error={error}
        disabled={disabled}
        variant={variant}
        sx={{
          backgroundColor: `${
            input.value === 'active' ? `${activeChipColor}` : `${inactiveChipColor}`
          }`,
          marginTop: -4,
          marginRight: 1,
          fontFamily: `${chipFont}`,
        }}
        label={
          <Box>
            active
            <span> </span>
            {input.value === 'active' ? <CheckIcon fontSize={iconSize} /> : ''}
          </Box>
        }
      />
      <Chip
        checked={input.value || false}
        name={input.name}
        onChange={input.onChange}
        size={size}
        required={required}
        error={error}
        disabled={disabled}
        variant={variant}
        sx={{
          backgroundColor: `${
            input.value === 'unverified' ? `${activeChipColor}` : `${inactiveChipColor}`
          }`,
          marginTop: -4,
          fontFamily: `${chipFont}`,
        }}
        label={
          <Box>
            inactive
            <span> </span>
            {input.value === 'unverified' ? <CheckIcon fontSize={iconSize} /> : ' '}
          </Box>
        }
      />
    </Box>
  );
};

FormChip.propTypes = {
  disabled: PropTypes.bool,
  fieldErrorData: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),

  meta: PropTypes.shape(),
  required: PropTypes.bool,
  size: PropTypes.string, // default size is medium
  sx: PropTypes.shape({}),
  variant: PropTypes.string,
};

export default FormChip;
