import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Container } from '@mui/material';

import theme from '../../../theme';
import NavigationBar from '../../NavigationBar/NavigationBar';

import BottomAppBar from './EmployeeBottomBar/BottomAppBar';

const PhoneNav = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <NavigationBar />
    <Container sx={{ overflow: 'auto', flex: 1, padding: theme.spacing(2, 3, 2) }}>
      <Outlet />
    </Container>
    <BottomAppBar />
  </Box>
);

export default PhoneNav;
