import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';

import theme from '../../../../theme';

import ShiftDetailsCard from './subComponent/ShiftDetailsCard';

const ShiftDetails = ({ shiftGroupList }) => (
  <Box sx={{ width: '100%' }}>
    <Box
      sx={{
        color: theme.components.detailView.typography.sectionHeader.fontColor,
        fontFamily: theme.components.detailView.typography.sectionHeader.fontFamily,
        fontSize: '18px',
      }}
    >
      {' '}
      Shift Details
    </Box>
    {shiftGroupList &&
      shiftGroupList.map((shift) => (
        <ListItem key={get('id', shift)}>
          <ShiftDetailsCard selectedShift={shift} />
        </ListItem>
      ))}
  </Box>
);

ShiftDetails.propTypes = {
  shiftGroupList: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ShiftDetails;
