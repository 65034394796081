import React from 'react';
import PropTypes from 'prop-types';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';

const buttonBaseProps = {
  color: 'primary',
  size: 'small',
  startIcon: <FileDownloadIcon />,
};

const Toolbar = ({ exportCsv, exportPaginatedCsv }) => {
  const areButtonsActive = exportCsv || exportPaginatedCsv;
  return (
    (areButtonsActive && (
      <GridToolbarContainer>
        {!!exportCsv && (
          <Button {...buttonBaseProps} onClick={exportCsv.toggleOpen}>
            Export to csv
          </Button>
        )}
      </GridToolbarContainer>
    )) ||
    null
  );
};

Toolbar.propTypes = {
  exportCsv: PropTypes.shape({
    toggleOpen: PropTypes.func,
  }),
  exportPaginatedCsv: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    itemCount: PropTypes.number,
    pageSize: PropTypes.number,
    getPageData: PropTypes.func,
    fileName: PropTypes.string,
  }),
  toggleOpen: PropTypes.func,
};

export default Toolbar;
