import React from 'react';
import get from 'lodash/fp/get';

import Box from '@mui/material/Box';

import AdjustTimeIcon from '../../../assets/icons/AdjustTime.svg';
import AlertIcon from '../../../assets/icons/Alert.svg';
import ApproveTimeIcon from '../../../assets/icons/ApproveTime.svg';
import DollarIcon from '../../../assets/icons/Dollar.svg';
import DoneIcon from '../../../assets/icons/Done.svg';
import EditTimeIcon from '../../../assets/icons/EditTime.svg';
import JobsIcon from '../../../assets/icons/jobs.svg';
import OpenEyeIcon from '../../../assets/icons/openEye.svg';
import PositionIcon from '../../../assets/icons/Positions.svg';
import TimeIcon from '../../../assets/icons/Time.svg';
import WarningIcon from '../../../assets/icons/warning.svg';
import { epochToDateInReadableFormat } from '../../../utils';

const contentStyle = {
  fontSize: '14px',
  fontWeight: '500',
  fontFamily: 'Barlow',
  color: '#000033',
};
const boldTextStyle = {
  fontWeight: '600',
};

const generateContent = (notification, language) => {
  const notificationType = get('notificationType', notification);
  const timeDelta = Date.now() - get(['metadata', 'created'], notification);

  const jobName = get(['info', 'jobName'], notification);
  const jobOrderName = get(['info', 'jobOrderName'], notification);
  const jobOrderStart = get(['info', 'jobOrderStart'], notification);
  const candidateName = get(['info', 'candidateName'], notification);
  // employer
  const templateName = get(['info', 'templateName'], notification);
  const rosterName = get(['info', 'rosterName'], notification);
  const shiftTime = get(['info', 'shiftTime'], notification);
  const companyName = get(['info', 'companyName'], notification);
  const jobTitle = get(['info', 'jobTitle'], notification);
  const startTime = get(['info', 'jobOrderStart'], notification);
  const numOpenings = get(['info', 'numOpenings'], notification);
  const employeeName = get(['info', 'employeeName'], notification);
  const genericContent = get(['info', 'content'], notification);

  const createTimeDisplay = (time) => {
    const seconds = Math.floor(time / 1000);
    const minutes = Math.floor(time / (1000 * 60));
    const hours = Math.floor(time / (1000 * 60 * 60));
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(time / (1000 * 60 * 60 * 24 * 7));
    if (seconds < 60) return `${seconds}s`;
    if (minutes < 60) return `${minutes}min`;
    if (hours === 1) return `${hours}hr`;
    if (hours < 24) return `${hours}hrs`;
    if (days < 7) return `${days}d`;
    return `${weeks}w`;
  };

  // TODO i80n
  if (language === 'en') {
    switch (notificationType) {
      case 'positionTemplateApproval':
        return {
          content: (
            <Box sx={contentStyle}>
              WAE Staff have verified and approved your{' '}
              <span style={boldTextStyle}>{templateName}</span> position template
            </Box>
          ),
          logo: DoneIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'View Template',
        };
      case 'jobPostSuccess':
        return {
          content: (
            <Box sx={contentStyle}>
              Successful Job Posting:{' '}
              <span style={boldTextStyle}>
                {jobTitle} - {numOpenings}
              </span>{' '}
              Job Openings
            </Box>
          ),
          logo: DoneIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'View Job',
        };
      case 'rosterFilled':
        return {
          content: (
            <Box sx={contentStyle}>
              The roster for <span style={boldTextStyle}>{rosterName}</span> has been filled.
            </Box>
          ),

          logo: PositionIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'View Job',
        };
      case 'jobStarted':
        return {
          content: (
            <Box sx={contentStyle}>
              The job <span style={boldTextStyle}>{jobName}</span> has now started.
            </Box>
          ),
          logo: TimeIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'View Job',
        };
      case 'timeSheetReady':
        return {
          content: (
            <Box sx={contentStyle}>
              The Job <span style={boldTextStyle}>{jobName}</span> is now complete. Timesheets are
              ready for review.
            </Box>
          ),
          logo: OpenEyeIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'View Job',
        };
      case 'employeeDispute':
        return {
          content: (
            <Box sx={contentStyle}>
              <span style={boldTextStyle}>{employeeName} </span> disputed timecard{' '}
              <span style={boldTextStyle}>{jobName}. </span> WAE Staff will be in touch.
            </Box>
          ),
          logo: AlertIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'View Job',
        };
      case 'billSubmitted':
        return {
          content: (
            <Box sx={contentStyle}>
              Bill <span style={boldTextStyle}>{jobName} </span> has been submitted.
            </Box>
          ),
          logo: DollarIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'Billing',
        };

      case 'generic':
        return {
          content: <Box sx={contentStyle}>{genericContent}</Box>,
          logo: JobsIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          button: null,
          url: 'null',
        };
      // admin
      case 'positionTemplateNeedsReview':
        return {
          content: (
            <Box sx={contentStyle}>
              Position template <span style={boldTextStyle}>{templateName} </span> needs review.
            </Box>
          ),
          logo: OpenEyeIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          button: 'View Template',
          multipleActions: false,
          url: '#',
        };
      case 'timecardDisputed':
        return {
          content: (
            <Box sx={contentStyle}>
              A new timecard <span style={boldTextStyle}>{jobName} </span> has been disputed.
            </Box>
          ),
          logo: AlertIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          button: 'TimeSheet',
        };
      case 'jobFilled':
        return {
          content: (
            <Box sx={contentStyle}>
              The job <span style={boldTextStyle}>{jobName} </span> has not been filled.
            </Box>
          ),
          logo: AlertIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'View Job',
        };
      case 'retroactivePlacementRequest':
        return {
          content: <Box sx={contentStyle}>Retroactive Placement Request</Box>,
          logo: AdjustTimeIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'View Job',
        };

      // employee
      case 'jobOrderInvite':
        return {
          content: (
            <Box sx={contentStyle}>
              You have a Job Invitation: <span style={boldTextStyle}>{jobOrderName}</span>{' '}
              <span style={boldTextStyle}>{epochToDateInReadableFormat(startTime)}</span>
            </Box>
          ),

          logo: JobsIcon,
          notificationRole: 'employer',
          button: 'View Job',
          multipleActions: false,
          time: `${createTimeDisplay(timeDelta)}`,
          url: '#',
        };
      case 'newJobAssigned':
        return {
          content: (
            <Box sx={contentStyle}>
              You&apos;ve been assigned to a new job <span style={boldTextStyle}>{jobName}</span>
            </Box>
          ),
          logo: PositionIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'View Job',
        };

      case 'preClockInRemind':
        return {
          content: (
            <Box sx={contentStyle}>
              Job about to start, time to clock in for <span style={boldTextStyle}>{jobName}.</span>
            </Box>
          ),
          logo: TimeIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'TimeSheet',
        };
      case 'clockInRemind':
        return {
          content: (
            <Box sx={contentStyle}>
              Time to clock in for <span style={boldTextStyle}>{jobName}.</span>
            </Box>
          ),
          logo: TimeIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'TimeSheet',
        };
      case 'clockOutRemind':
        return {
          content: (
            <Box sx={contentStyle}>
              Time to clock out for <span style={boldTextStyle}>{jobName}.</span> You have up to 15
              mins after your shift ends.
            </Box>
          ),
          logo: TimeIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'TimeSheet',
        };
      case 'timecardEdited':
        return {
          content: (
            <Box sx={contentStyle}>
              Your timecard has been edited <span style={boldTextStyle}>{jobName}.</span> Make sure
              everything looks right.
            </Box>
          ),
          logo: EditTimeIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: 'TimeSheet',
        };
      case 'timeCardApproved':
        return {
          content: (
            <Box sx={contentStyle}>
              Your timecard has been approved <span style={boldTextStyle}>{jobName}.</span>
            </Box>
          ),
          logo: ApproveTimeIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: null,
        };
      case 'timeCardDisputeSubmitted':
        return {
          content: (
            <Box sx={contentStyle}>
              Your timecard dispute has been submitted for{' '}
              <span style={boldTextStyle}>{jobName}.</span>WAE staff will be in touch shortly.
            </Box>
          ),
          logo: TimeIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: null,
        };
      case 'timeCardDisputeResolved':
        return {
          content: (
            <Box sx={contentStyle}>
              Your timecard dispute has been resolved for{' '}
              <span style={boldTextStyle}>{jobName}.</span>
            </Box>
          ),
          logo: ApproveTimeIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: null,
        };
      case 'shiftCancellation':
        return {
          content: (
            <Box sx={contentStyle}>
              <span style={boldTextStyle}>{jobName} </span> Shift
              <span style={boldTextStyle}> {shiftTime} </span>
              at
              <span style={boldTextStyle}> {companyName} </span>
              has been canceled.
            </Box>
          ),
          logo: WarningIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: null,
        };
      case 'placementCancelled':
        return {
          content: (
            <Box sx={contentStyle}>
              <span style={boldTextStyle}>{candidateName} </span>&apos;s shift on
              <span style={boldTextStyle}> {epochToDateInReadableFormat(jobOrderStart)} </span>
              at
              <span style={boldTextStyle}> {companyName} </span>
              has been canceled.
            </Box>
          ),
          logo: WarningIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: null,
        };
      case 'jobOrderCancelled':
        return {
          content: (
            <Box sx={contentStyle}>
              <span style={boldTextStyle}>{jobOrderName} </span> on
              <span style={boldTextStyle}> {epochToDateInReadableFormat(jobOrderStart)} </span>
              at
              <span style={boldTextStyle}> {companyName} </span>
              has been canceled.
            </Box>
          ),
          logo: WarningIcon,
          time: `${createTimeDisplay(timeDelta)}`,
          multipleActions: false,
          button: null,
        };

      default:
        return {
          logo: JobsIcon,
          content: 'invalid content message',
          multipleActions: false,
          button: null,
          url: 'null',
        };
    }
  }

  // Mutiple language support
  // if (language === 'es') {
  //   switch (notificationType) {
  //     case 'JobInvitation':
  //       return {
  //         content: '',
  //       };
  //   }
  // }
  return {
    logo: JobsIcon,
    content: 'content is coming',
    multipleActions: false,
    button: null,
    url: 'null',
  };
};
export default generateContent;
