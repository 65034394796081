import React from 'react';
import PropTypes from 'prop-types';

// import Switch from '@material-ui/core/Switch';
import { Box, Checkbox, Typography } from '@mui/material';

const SwitchFilter = ({
  field,
  operation,
  onValueChange,
  fixedValue,
  initialValue,
  allowEmptyArray,
}) => {
  const [toggled, setToggled] = React.useState(!initialValue?.value);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'max-content',
      }}
    >
      <Box>
        <Typography
          sx={{
            fontFamily: 'Barlow-600',
            fontSize: '14px',
            color: 'black',
          }}
        >
          Match My Certifications
        </Typography>
      </Box>
      <Box>
        {/* <Switch */}
        <Checkbox
          color="primary"
          checked={!toggled}
          onChange={(e) => {
            setToggled(!toggled);
            onValueChange([
              {
                value: e.target.checked ? fixedValue : null,
                field,
                operation,
                allowEmptyArray,
              },
            ]);
          }}
        />
      </Box>
    </Box>
  );
};

SwitchFilter.propTypes = {
  field: PropTypes.string,
  operation: PropTypes.string,
  onValueChange: PropTypes.func,
  fixedValue: PropTypes.arrayOf(PropTypes.string),
  allowEmptyArray: PropTypes.bool,
  initialValue: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.shape),
  }),
};

export default SwitchFilter;
