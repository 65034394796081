import React from 'react';
import PropTypes from 'prop-types';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/material';

import Delete from '../../../../../assets/icons/Delete.svg';
import WhiteTrashIcon from '../../../../../assets/icons/WhiteTrashIcon';
import Button from '../../../../../components/Button';
import theme from '../../../../../theme';

import BasicDrawer from './BasicDrawer';
import DeletedPlacementCard from './DeletedPlacementCard';

const headerText = 'Are you sure you want to remove this employee?'; // TODO support multiple language
const subHeaderText =
  "Removing this employee will affect 1 opening. This action can not be undone,if you would like to proceed please do so by clicking 'Remove Employee' below. ";

const DeletePlacementDrawer = ({
  selectedPlacementId,
  isOpen,
  onClose,
  candidateName,
  handleCancelPlacement,
}) => {
  const handleCancelPlacementButton = () => {
    handleCancelPlacement(selectedPlacementId);
  };

  const HeaderIcon = () => (
    <Box
      component="img"
      sx={{
        height: 51.52,
        width: 51.52,
      }}
      alt="Delete"
      src={Delete}
    />
  );

  const GoBackButton = () => (
    <Button
      type="outlined"
      onClick={onClose}
      text="Go back"
      startIcon={<ArrowBackIcon sx={{ color: theme.button.palette.secondary }} />}
    />
  );

  const ActionButton = () => (
    <Button
      type="delete"
      onClick={handleCancelPlacementButton}
      sx={{ marginLeft: '5px' }}
      text="Remove Employee"
      endIcon={<WhiteTrashIcon />}
    />
  );

  return (
    <BasicDrawer
      goBackButton={<GoBackButton />}
      actionButton={<ActionButton />}
      headerIcon={<HeaderIcon /> || null}
      header={headerText}
      subHeader={subHeaderText}
      content={<DeletedPlacementCard name={candidateName} />}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

DeletePlacementDrawer.propTypes = {
  selectedPlacementId: PropTypes.string,
  candidateName: PropTypes.string,
  handleCancelPlacement: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DeletePlacementDrawer;
