import { get } from 'lodash/fp';

import FormSelectPillPicker from '../../../../../components/Form/FormSelectPillPicker';
import FormShiftSelector from '../../../../../components/Form/FormShiftSelector/FormShiftSelector';
import FormSwitch from '../../../../../components/Form/FormSwitch';
import FormTextField from '../../../../../components/Form/FormTextField';
import FormTypographyBox from '../../../../../components/Form/FormTypographyBox';
import { isInteger, isShiftSelectorEmpty } from '../../../../../components/Form/validations';
import SearchBox, { SEARCHBAR_BACKGROUND } from '../../../../../components/SearchBar';
import SearchBoxV2 from '../../../../../components/SearchBarV2';
import theme from '../../../../../theme/theme';
import JobRangeForForm from '../CustomComponents/JobRangeForForm';

const componentWorkFlowFont = ['components', 'workflow', 'typography'];
const workFlowHeaderStyling = {
  fontSize: get([...componentWorkFlowFont, 'defaultTitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  color: get([...componentWorkFlowFont, 'defaultLastPageFontColor', 'default'], theme),
  lineHeight: get([...componentWorkFlowFont, 'titleLineHeight'], theme),
  width: '448px',
};
const workFlowSubHeaderStyling = {
  fontSize: '16px',
  fontFamily: 'Barlow',
  color: '#333357',
  margin: theme.spacing(-1, 0, 2, 0),
};

const FormHeaderStyle = {
  fontSize: '14px',
  fontFamily: 'Barlow',
  color: '#000033',
};

const FormValueStyle = {
  fontSize: '16px',
  fontFamily: 'Barlow',
  color: '#000033',
  marginTop: '-20px',
};

const HeaderStyling = {
  fontSize: '18px',
  fontFamily: 'Barlow-700',
  color: '#000033',
};

export const doesWorkDayHaveAnyTruthy = (value) =>
  Object.values(value).some((val) => val === true) ? undefined : 'One Workday must be selected.';

export const positionTemplateSection = (createJobValues, employerCorporationName) => [
  {
    ComponentType: FormTypographyBox,
    key: 'positionTemplateHeader',
    name: 'positionTemplateHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'positionTemplateSubHeader',
    name: 'positionTemplateSubHeader',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: SearchBox,
    background: SEARCHBAR_BACKGROUND.LIGHT,
    preDefinedOptions: [],
    data: {
      filters: employerCorporationName
        ? [
            {
              field: 'corporation.name',
              value: employerCorporationName,
              operation: 'contains',
            },
          ]
        : [
            {
              field: 'corporation.name',
              value: get(['corporation', 'name'], createJobValues),
              operation: 'contains',
            },
            {
              field: 'approved',
              value: true,
              operation: 'equals',
            },
          ],
    },
    name: 'positionTemplate',
    multiSelect: false,
    required: true,
    variant: 'standard',
    optionLabel: 'name',
    placeholder: 'Enter Position Template',
    fullWidth: true,
    onSubmitApiCallData: {
      httpMethod: 'POST',
      route: 'position-templates/read',
    },
    optionDisplayField: 'name',
  },
];

export const locationSection = (
  createJobValues,
  predefinedLocationOptions,
  employerCorporationId
) => [
  {
    ComponentType: FormTypographyBox,
    key: 'locationSubHeader',
    name: 'locationSubHeader',
    sx: workFlowSubHeaderStyling,
  },

  {
    ComponentType: SearchBoxV2,
    background: SEARCHBAR_BACKGROUND.LIGHT,
    dropDownOptions: predefinedLocationOptions,
    data: null,
    name: 'location',
    multiSelect: false,
    required: true,
    variant: 'standard',
    optionLabel: 'name',
    placeholder: 'Search Locations',
    selectedSectionTitle: 'Selected Location',
    fullWidth: true,
    optionDisplayField: 'description',
    label: 'Location',
  },
  {
    ComponentType: SearchBoxV2,
    background: SEARCHBAR_BACKGROUND.LIGHT,
    data: {
      corporation: get(['corporation', '_id'], createJobValues)
        ? `${get(['corporation', '_id'], createJobValues)}`
        : `${employerCorporationId}`,
      filters: [
        {
          field: '_id',
          operation: 'idContainedIn',
          value: (get(['positionTemplate', 'departments'], createJobValues) || []).map((dept) =>
            get(['_id'], dept)
          ),
        },
      ],
    },
    name: 'department',
    required: true,
    variant: 'standard',
    optionLabel: 'name',
    placeholder: 'Search Departments',
    fullWidth: true,
    onSubmitApiCallData: {
      httpMethod: 'POST',
      route: 'corporations/departments/read',
    },
    optionDisplayField: 'name',
    key: '_id',
    multiSelect: false,
    label: 'Department',
    selectedSectionTitle: 'Selected Depeartment',
  },
];

export const shiftSection = (createJobValues) => [
  {
    ComponentType: FormTypographyBox,
    key: 'shiftHeader',
    name: 'shiftHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'shiftSubHeader',
    name: 'shiftSubHeader',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: FormShiftSelector,
    label: 'Shift',
    name: 'shift',
    shiftTitle: 'Assigned Shifts',
    initialShifts: get(['positionTemplate', 'shifts'], createJobValues),
    validate: isShiftSelectorEmpty,
    required: true,
  },
];

export const dateSection = (createJobValues) => [
  {
    ComponentType: FormTypographyBox,
    key: 'dateHeader',
    name: 'dateHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'calendarDate',
    name: 'calendarDate',
    sx: HeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'calendarDateDescription',
    name: 'calendarDateDescription',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: JobRangeForForm,
    key: 'jobRangeForForm',
    name: 'jobRange',
    createJobValues,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'workDayHeader',
    name: 'workDayHeader',
    sx: HeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'workDaySubHeader',
    name: 'workDaySubHeader',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: FormSelectPillPicker,
    name: 'daysOfWeek',
    required: true,
    validate: doesWorkDayHaveAnyTruthy,
    data: [
      {
        label: 'Su',
        value: 'sunday',
      },
      {
        label: 'Mo',
        value: 'monday',
      },
      {
        label: 'Tu',
        value: 'tuesday',
      },
      {
        label: 'We',
        value: 'wednesday',
      },
      {
        label: 'Th',
        value: 'thursday',
      },
      {
        label: 'Fr',
        value: 'friday',
      },
      {
        label: 'Sa',
        value: 'saturday',
      },
    ],
    sx: { justifyContent: 'center' },
  },
];

export const singleEmployeeSection = (JobCreateShiftGroupToggle) => [
  {
    ComponentType: FormTypographyBox,
    key: 'singleEmployeeHeader',
    name: 'singleEmployeeHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'singleEmployeeSubHeader',
    name: 'singleEmployeeSubHeader',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'singleEmployeeOptionHeader',
    name: 'singleEmployeeOptionHeader',
    sx: HeaderStyling,
    xs: 10,
  },
  {
    ComponentType: FormSwitch,
    name: 'singleEmployeeShift',
    disabled: !JobCreateShiftGroupToggle,
    xs: 2,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'singleEmployeeOptionSubHeader',
    name: 'singleEmployeeOptionSubHeader',
    sx: workFlowSubHeaderStyling,
    xs: 10,
  },
];

export const numOpeningsSection = [
  {
    ComponentType: FormTypographyBox,
    key: 'numOpeningHeader',
    name: 'numOpeningHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'numOpeningSubHeader',
    name: 'numOpeningSubHeader',
    sx: workFlowSubHeaderStyling,
  },
  {
    ComponentType: FormTextField,
    label: 'Number of Opening',
    name: 'numOpenings',
    validate: isInteger,
    required: true,
    fullWidth: true,
  },
];

export const confirmationSection = [
  {
    ComponentType: FormTypographyBox,
    key: 'confirmationPageHeader',
    name: 'confirmationPageHeader',
    sx: workFlowHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'confirmationPageSubHeader',
    name: 'confirmationPageSubHeader',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'corporationTitle',
    name: 'corporationTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'corporationValue',
    name: 'corporationValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'positionTitle',
    name: 'positionTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'positionValue',
    name: 'positionValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'corporationLocationTitle',
    name: 'corporationLocationTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'corporationLocationValue',
    name: 'corporationLocationValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'corporationDepartmentTitle',
    name: 'corporationDepartmentTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'corporationDepartmentValue',
    name: 'corporationDepartmentValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'jobDateTitle',
    name: 'jobDateTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'jobDateValue',
    name: 'jobDateValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'workdayLabelValue',
    name: 'workdayLabelValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'shiftTitle',
    name: 'shiftTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'shiftValue',
    name: 'shiftValue',
    sx: FormValueStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'openingsTitle',
    name: 'openingsTitle',
    sx: FormHeaderStyle,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'openingsValue',
    name: 'openingsValue',
    sx: FormValueStyle,
  },
];
