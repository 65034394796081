const DNRReasons = {
  Attendance: 'Attendance',
  Breach_Of_Company_Policy: 'Breach of company policy',
  Failure_To_Follow_Supervisor_Instruction: "Failure to follow supervisor's instructions",
  Failure_To_Follow_Operating_Procedures: 'Failure to follow proper operating procedures',
  Insubordination: 'Insubordination',
  No_Call_No_Show: 'No call no Show',
  Walked_Off: 'Walked off',
  Misconduct: 'Misconduct',
  Unauthorized_Possession_Company_Property:
    'Unauthorized possession or removal of company property',
  None: 'None',
};
export default DNRReasons;
