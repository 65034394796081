import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';

const MuiIconButton = ({ color, disabled, iconElement, onClick, size, sx }) => (
  <IconButton disabled={disabled} color={color} onClick={onClick} size={size} sx={sx}>
    {iconElement}
  </IconButton>
);

MuiIconButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  iconElement: PropTypes.element,
  onClick: PropTypes.func,
  size: PropTypes.string,
  sx: PropTypes.shape({}),
};

export default MuiIconButton;
