import get from 'lodash/fp/get';
import { batch } from 'react-redux';

import {
  getNotifications,
  getNotificationsError,
  getNotificationsProcessed,
  setNotificationsToRead,
  setNotificationsToReadError,
  setNotificationsToReadProcessed,
} from '../main/components/NotificationCenter/reducer';
import { navigator } from '../main/utils/navigator';
import {
  creditCandidateSkills,
  creditCandidateSkillsProcessed,
  creditLoggedInCandidateSkills,
  creditLoggedInCandidateSkillsProcessed,
  emailVerificationOrPWResetUpdateUserData,
  loginProcessed as loginProcessedUpdateApp,
  showSnackbar,
  updateSkillsForSession,
} from '../main/views/app';
import { fileError, fileProcessed, getFile } from '../main/views/app/fileReducer';
import {
  getCandidatesJobs,
  getCandidatesJobsError,
  getCandidatesJobsProcessed,
  getFeaturedJobs,
  getFeaturedJobsError,
  getFeaturedJobsProcessed,
  getIndustryJobs,
  getIndustryJobsError,
  getIndustryJobsProcessed,
  getInterestJobs,
  getInterestJobsError,
  getInterestJobsProcessed,
  getMyTimeThisWeek,
  getMyTimeThisWeekError,
  getMyTimeThisWeekProcessed,
  postPunchForDashboard,
  postPunchForDashboardError,
  postPunchForDashboardProcessed,
} from '../main/views/Candidate/dashboard';
import {
  getInvitedJobOrder,
  getInvitedJobOrderError,
  getInvitedJobOrderProcessed,
  getSkills as getSkillsForJobInvite,
  getSkillsError as getSkillsForJobInviteError,
  getSkillsProcessed as getSkillsForJobInviteProcessed,
  postInviteRespond,
  postInviteRespondError,
  postInviteRespondProcessed,
} from '../main/views/Candidate/jobInvitation';
import {
  cancelPlacementForCandidate,
  cancelPlacementForCandidateError,
  cancelPlacementForCandidateProcessed,
  createPlacementsForCandidate,
  createPlacementsForCandidateError,
  createPlacementsForCandidateProcessed,
  getCandidateJobsList,
  getCandidateJobsListError,
  getCandidateJobsListProcessed,
  getCandidatePathSelectedJob,
  getCandidatePathSelectedJobError,
  getCandidatePathSelectedJobProcessed,
  getCertificationsForCandidate,
  getCertificationsForCandidateError,
  getCertificationsForCandidateProcessed,
  getCorporationsForCandidate,
  getCorporationsForCandidateError,
  getCorporationsForCandidateProcessed,
  getFilesForCandidate,
  getFilesForCandidateError,
  getFilesForCandidateProcessed,
  getFilteredJobsList,
  getFilteredJobsListError,
  getFilteredJobsListProcessed,
  getGroupsJobOrders,
  getGroupsJobOrdersError,
  getGroupsJobOrdersProcessed,
  getJobOrderForCandidate,
  getJobOrderForCandidateError,
  getJobOrderForCandidateProcessed,
  getPathSelectedPlacementForCandidate,
  getPathSelectedPlacementForCandidateError,
  getPathSelectedPlacementForCandidateProcessed,
  getPlacements,
  getPlacementsError,
  getPlacementsForCandidate,
  getPlacementsForCandidateError,
  getPlacementsForCandidateProcessed,
  getPlacementsProcessed,
  getSkills as getSkillsForJobSignUp,
  getSkillsError as getSkillsForJobSignUpError,
  getSkillsProcessed as getSkillsForJobSignUpProcessed,
} from '../main/views/Candidate/jobs';
import {
  getNextShift,
  getNextShiftError,
  getNextShiftProcessed,
  getTimeCards as getEmployeeTimeCards,
  getTimeCardsError as getEmployeeTimeCardsError,
  getTimeCardsProcessed as getEmployeeTimeCardsProcessed,
  postDisputeTimeCard,
  postDisputeTimeCardError,
  postDisputeTimeCardProcessed,
  postPunch as postPunchForTimeTracking,
  postPunchError as postPunchForTimeTrackingError,
  postPunchProcessed as postPunchForTimeTrackingProcessed,
} from '../main/views/Candidate/timeTracking';
import {
  createDepartment,
  createDepartmentError,
  createDepartmentProcessed,
  deleteDepartment,
  deleteDepartmentError,
  deleteDepartmentProcessed,
  editDepartment,
  editDepartmentError,
  editDepartmentProcessed,
  getCorporationsList,
  getCorporationsListError,
  getCorporationsListProcessed,
  getDepartments,
  getDepartmentsError,
  getDepartmentsProcessed,
} from '../main/views/corporations/corporationList';
import {
  postCorporation,
  postCorporationError,
  postCorporationProcessed,
} from '../main/views/corporations/createCorporation';
import {
  postForgotPassword,
  postForgotPasswordError,
  postForgotPasswordProcessed,
} from '../main/views/forgotMyPassword';
import {
  getCorporations as getCorporationsForJobsOrder,
  getCorporationsError as getCorporationsForJobsOrderError,
  getCorporationsProcessed as getCorporationsProcessedForJobsOrder,
  getPositionTemplate as getPositionTemplateForJobsOrder,
  getPositionTemplateErrorForJobs as getPositionTemplateErrorForJobsOrder,
  getPositionTemplateProcessedForJobs as getPositionTemplateProcessedForJobsOrder,
  postJobs,
  postJobsError,
  postJobsProcessed,
} from '../main/views/jobs/createJobs';
import {
  cancelJob,
  cancelJobError,
  cancelJobProcessed,
  cancelPlacement,
  cancelPlacementError,
  cancelPlacementProcessed,
  cancelShiftGroup,
  cancelShiftGroupError,
  cancelShiftGroupProcessed,
  getCancelledCandidates,
  getCancelledCandidatesError,
  getCancelledCandidatesProcessed,
  getCorporationsForJobsList,
  getCorporationsForJobsListError,
  getCorporationsForJobsListProcessed,
  getGroupOrders,
  getGroupOrdersError,
  getGroupOrdersProcessed,
  getJobApplications,
  getJobApplicationsError,
  getJobApplicationsProcessed,
  getJobInvitations,
  getJobInvitationsError,
  getJobInvitationsProcessed,
  getJobRoster,
  getJobRosterError,
  getJobRosterProcessed,
  getJobsList,
  getJobsListError,
  getJobsListProcessed,
  getPathSelectedJob,
  getPathSelectedJobError,
  getPathSelectedJobProcessed,
  getPositionTemplate as getPositionTemplateForJobsList,
  getPositionTemplateError as getPositionTemplateErrorForJobsList,
  getPositionTemplateProcessed as getPositionTemplateProcessedForJobsList,
  getShiftGroupList,
  getShiftGroupListError,
  getShiftGroupListProcessed,
  getUsers as getUsersForJobList,
  getUsersError as getUsersErrorForJobList,
  getUsersProcessed as getUsersProcessedForJobList,
  putGroupOrders,
  putGroupOrdersError,
  putGroupOrdersProcessed,
  revokeJobInvitations,
  revokeJobInvitationsError,
  revokeJobInvitationsProcessed,
  setIsCreateJobsDialogOpen,
  updateJob,
  updateJobError,
  updateJobProcessed,
  updateJobRoster,
  updateJobRosterError,
  updateJobRosterProcessed,
  updatePlacement,
  updatePlacementError,
  updatePlacementProcessed,
} from '../main/views/jobs/jobList';
import {
  inviteEmployee,
  inviteEmployeeError,
  inviteEmployeeProcessed,
} from '../main/views/jobs/jobList/detail/SubComponents/reducer';
import { login as loginUpdateLogin, loginError, loginProcessed } from '../main/views/login';
import {
  updatePassword,
  updatePasswordError,
  updatePasswordProcessed,
} from '../main/views/passwordUpdate';
import {
  disableSendVerificationEmailButton,
  passwordReset,
  passwordResetError,
  passwordResetProcessed,
  reqVerifyAccount,
  verifyAccount,
  verifyAccountError,
  verifyAccountProcessed,
} from '../main/views/PasswordVerificationOrReset';
import {
  postPositionTemplate,
  postPositionTemplateError,
  postPositionTemplateProcessed,
} from '../main/views/positionTemplate/createPositionTemplateDrawer';
import {
  deletePositionTemplate,
  deletePositionTemplateError,
  deletePositionTemplateProcessed,
  getCertificationsForPositionTemplateList,
  getCertificationsForPositionTemplateListError,
  getCertificationsForPositionTemplateListProcessed,
  getCorporationsForPositionTemplateList,
  getCorporationsForPositionTemplateListError,
  getCorporationsForPositionTemplateListProcessed,
  getLocationsForPositionTemplateList,
  getLocationsForPositionTemplateListError,
  getLocationsForPositionTemplateListProcessed,
  getPositionTemplateList,
  getPositionTemplateListError,
  getPositionTemplateListProcessed,
  updatePositionTemplate,
  updatePositionTemplateError,
  updatePositionTemplateProcessed,
} from '../main/views/positionTemplate/positionTemplateList';
import {
  registerCandidate,
  registerCandidateError,
  registerCandidateProcessed,
  registerEmployer,
  registerEmployerError,
  registerEmployerProcessed,
} from '../main/views/register';
import {
  getDnas,
  getDnasError,
  getDnasProcessed,
  getDnrs,
  getDnrsError,
  getDnrsProcessed,
  getDoNotReturnBreakdown,
  getDoNotReturnBreakdownError,
  getDoNotReturnBreakdownProcessed,
  getEmployeeRetention,
  getEmployeeRetentionError,
  getEmployeeRetentionProcessed,
  getEmployeesHired,
  getEmployeesHiredError,
  getEmployeesHiredProcessed,
  getFillRatio,
  getFillRatioError,
  getFillRatioProcessed,
  getJobOrderRevenue,
  getJobOrderRevenueError,
  getJobOrderRevenueProcessed,
  getJobsCompleted,
  getJobsCompletedError,
  getJobsCompletedProcessed,
  getJobsEntered,
  getJobsEnteredError,
  getJobsEnteredProcessed,
  getOverallAttendance,
  getOverallAttendanceError,
  getOverallAttendanceProcessed,
  getTimeBasedJobOrderNumbers,
  getTimeBasedJobOrderNumbersError,
  getTimeBasedJobOrderNumbersProcessed,
  getUserCountByRole,
  getUserCountByRoleError,
  getUserCountByRoleProcessed,
} from '../main/views/reporting';
import {
  getCertificationsForSettings,
  getCertificationsForSettingsError,
  getCertificationsForSettingsProcessed,
  getUserInfo,
  getUserInfoError,
  getUserInfoProcessed,
  updateSkillsForSettings,
  updateSkillsForSettingsError,
  updateSkillsForSettingsProcessed,
} from '../main/views/settings';
import {
  deleteCert,
  deleteCertError,
  deleteCertProcessed,
  deleteSkill,
  deleteSkillError,
  deleteSkillProcessed,
  getCerts,
  getCertsError,
  getCertsProcessed,
  getSkills,
  getSkillsError,
  getSkillsProcessed,
  sendNewSkillToBackend,
  sendNewSkillToBackendError,
  sendNewSkillToBackendProcessed,
} from '../main/views/skillsAndCerts';
import {
  getPathSelectedTimeCards,
  getPathSelectedTimeCardsError,
  getPathSelectedTimeCardsProcessed,
  getTimeCards,
  getTimeCardsError,
  getTimeCardsProcessed,
  postTimeCardApproval,
  postTimeCardApprovalError,
  postTimeCardApprovalProcessed,
  postTimeCardBatchApproval,
  postTimeCardBatchApprovalError,
  postTimeCardBatchApprovalProcessed,
  postTimeCardFinalize,
  postTimeCardFinalizeError,
  postTimeCardFinalizeProcessed,
  postTimeCardResolve,
  postTimeCardResolveError,
  postTimeCardResolveProcessed,
  updateTimeCard,
  updateTimeCardError,
  updateTimeCardProcessed,
} from '../main/views/timekeeping';
import {
  getCertificationsForUsers,
  getCertificationsForUsersError,
  getCertificationsForUsersProcessed,
  getCorporations as getCorporationsForUsers,
  getCorporationsError as getCorporationsErrorForUsers,
  getCorporationsProcessed as getCorporationsProcessedForUsers,
  getSelectedUserCompletedJobs,
  getSelectedUserCompletedJobsError,
  getSelectedUserCompletedJobsProcessed,
  getSelectedUserUpcomingJobs,
  getSelectedUserUpcomingJobsError,
  getSelectedUserUpcomingJobsProcessed,
  getUserHistory,
  getUserHistoryError,
  getUserHistoryProcessed,
  getUsers,
  getUsersError,
  getUsersProcessed,
  postBullhornEmployers,
  postBullhornEmployersError,
  postBullhornEmployersProcessed,
  postUser,
  postUserError,
  postUserProcessed,
  updateCandidateDetails,
  updateCandidateDetailsError,
  updateCandidateDetailsProcessed,
  updateCertifications,
  updateCertificationsError,
  updateCertificationsProcessed,
  updateDNA,
  updateDNAError,
  updateDNAProcessed,
  updateDNR,
  updateDNRError,
  updateDNRProcessed,
  updateSkills,
  updateSkillsError,
  updateSkillsProcessed,
  updateUser,
  updateUserError,
  updateUserProcessed,
} from '../main/views/users';

const API = [
  {
    http: 'post',
    path: 'users/login',
    type: loginUpdateLogin.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(loginProcessedUpdateApp(payload));
        dispatch(loginProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(loginError({ errors }));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'skills/credit-candidates',
    type: creditLoggedInCandidateSkills.type,
    successCallback: (payload, dispatch) => {
      dispatch(creditLoggedInCandidateSkillsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'skills/credit-candidates',
    type: creditCandidateSkills.type,
    successCallback: (payload, dispatch) => {
      dispatch(
        showSnackbar({ message: 'The skills for this job have been saved to the candidate' })
      );
      dispatch(creditCandidateSkillsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'users/register-candidate',
    type: registerCandidate.type,
    successCallback: (payload, dispatch) => {
      navigator('/');
      const registerAction = registerCandidateProcessed(payload);
      const message = 'Verify email address to continue; verification email has been sent.';
      const messageAction = showSnackbar({ message });
      batch(() => {
        dispatch(registerAction);
        dispatch(messageAction);
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(registerCandidateError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'users/register-employer',
    type: registerEmployer.type,
    successCallback: (payload, dispatch) => {
      navigator('/');
      const registerAction = registerEmployerProcessed(payload);
      const message = 'Verify email address to continue; verification email has been sent.';
      const messageAction = showSnackbar({ message });
      batch(() => {
        dispatch(registerAction);
        dispatch(messageAction);
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(registerEmployerError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'users/summary',
    type: getUserHistory.type,
    successCallback: (payload, dispatch) => {
      dispatch(getUserHistoryProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getUserHistoryError(errors));
      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'patch',
    path: 'password',
    type: updatePassword.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        navigator('dashboard');
        dispatch(updatePasswordProcessed(payload));
        dispatch(showSnackbar({ message: 'Password update successful!' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(updatePasswordError(errors));
      const message = get(['newPassword'], errors);

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: message || globalError.messageKey }));
    },
  },
  // TODO:Update Function on Corporation DNR/Favorite Tab
  // {
  //   http: 'patch',
  //   path: 'corporations',
  //   type: updateFavoriteDnr.type,
  //   successCallback: (payload, dispatch) => {
  //     batch(() => {
  //       // dispatch(updateFavoriteDnr(payload));
  //     });
  //   },
  //   errorCallback: (payload, dispatch) => {
  //     const errors = get(['data', 'errors'], payload);
  //     // dispatch(updateFavoriteDnr(errors));
  //
  //     const globalError = get(['data', 'errors', 'GLOBAL'], payload);
  //     globalError &&
  //       globalError.messageKey &&
  //       dispatch(showSnackbar({ message: message || globalError.messageKey }));
  //   },
  // },

  {
    http: 'post', // Create Job Order
    path: 'job-orders/batch',
    type: postJobs.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postJobsProcessed(payload));
        dispatch(showSnackbar({ message: 'Job Post Successful!' }));
        dispatch(setIsCreateJobsDialogOpen(false));
        navigator('jobs/jobslist');
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postJobsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      const localErrors = get(['data', 'errors'], payload);
      const startErrorKey = Object.keys(localErrors).find((k) => k.endsWith('start'));

      globalError &&
        globalError.messageKey &&
        dispatch(
          showSnackbar({
            message: !startErrorKey ? globalError.messageKey : localErrors[startErrorKey],
          })
        );
    },
  },

  {
    http: 'post', // Get List of Job Order
    path: 'job-orders/read',
    type: getJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getJobsListError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'job-orders/read',
    type: getPathSelectedJob.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPathSelectedJobProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getPathSelectedJobError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'job-orders/groups/shift/read',
    type: getGroupOrders.type,
    successCallback: (payload, dispatch) => {
      dispatch(getGroupOrdersProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getGroupOrdersError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'job-orders/read',
    type: getShiftGroupList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getShiftGroupListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getShiftGroupListError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'put', // Put Shift Group
    path: 'job-orders/groups/shift',
    type: putGroupOrders.type,
    successCallback: (payload, dispatch) => {
      dispatch(putGroupOrdersProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(putGroupOrdersError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post', // Get select user data upcoming jobs
    path: 'job-orders/read',
    type: getSelectedUserUpcomingJobs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getSelectedUserUpcomingJobsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getSelectedUserUpcomingJobsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post', // Get select user data completed jobs
    path: 'job-orders/read',
    type: getSelectedUserCompletedJobs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getSelectedUserCompletedJobsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getSelectedUserCompletedJobsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post', // Get invited job
    path: 'job-orders/read',
    type: getInvitedJobOrder.type,
    successCallback: (payload, dispatch) => {
      dispatch(getInvitedJobOrderProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getInvitedJobOrderError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post', // Get List of Position Templates
    path: 'position-templates/read',
    type: getPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getPositionTemplateListError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post', // Get List of Users
    path: 'users/read',
    type: getUsers.type,
    successCallback: (payload, dispatch) => {
      dispatch(getUsersProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getUsersError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post', // Get myJobs list for candidate
    path: 'placements/read',
    type: getPlacementsForCandidate.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPlacementsForCandidateProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getPlacementsForCandidateError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post', // Get myJobs list for candidate
    path: 'placements/read',
    type: getPathSelectedPlacementForCandidate.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPathSelectedPlacementForCandidateProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getPathSelectedPlacementForCandidateError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  /*
  The code below sends a verification email to a user and handles success and error callbacks.

  On success:
  - The email verification process is considered as processed.
  - A snackbar is shown with the message 'Verification email sent'.
  - The 'Send Verification Email' button is temporarily disabled for 5 minutes

  On error:
  - Verification email request error is dispatched.
  - A snackbar is shown with an error message based on the error type.
  - The 'Send Verification Email' button may be temporarily disabled based on the errors.

  The requestCodeBlock object is defined as follows:
*/
  {
    http: 'post', // Send verification email to user using HTTP POST method
    path: 'users/request-verification', // API endpoint
    type: reqVerifyAccount.type,
    successCallback: (payload, dispatch) => {
      // On a successful verification email request
      batch(() => {
        dispatch(verifyAccountProcessed(payload)); // Dispatch account verification processed
        dispatch(showSnackbar({ message: 'Verification email sent' })); // Show snackbar message
        dispatch(disableSendVerificationEmailButton(true));
      });
      setTimeout(() => {
        dispatch(disableSendVerificationEmailButton(false));
      }, 300000);
    },
    errorCallback: (payload, dispatch) => {
      // On a failed verification email request
      const errors = get(['data', 'errors'], payload); // Extract errors from the payload
      const globalError = get(['data', 'errors', 'GLOBAL'], payload); // Get global error
      const verificationResendPreventedUntil = get(
        ['data', 'errors', 'verificationResendPreventedUntil'],
        payload
      ); // Get data (epoch) for 'verificationResendPreventedUntil
      const currUnixEpoch = Date.now(); // Get current Unix Epoch
      const diffTime = verificationResendPreventedUntil - currUnixEpoch; // Calculate difference between current Unix Epoch and 'verificationResendPreventedUntil'

      dispatch(verifyAccountError(errors)); // Dispatch action for failed verification email request

      // Show snackbar with error message, if available
      globalError && dispatch(showSnackbar({ message: globalError.messageKey }));

      // Enable 'Send Verification Email' button after a certain time
      setTimeout(() => {
        dispatch(disableSendVerificationEmailButton(false));
      }, diffTime);
    },
  },

  {
    http: 'get', // Get singular Job Order for Employee
    path: 'job-orders',
    type: getJobOrderForCandidate.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobOrderForCandidateProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getJobOrderForCandidateError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'patch',
    path: 'job-orders/cancel',
    type: cancelJob.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(cancelJobProcessed(payload));
        dispatch(showSnackbar({ message: 'Cancel Job Successful!' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        // FIXME: HB-114 need better error messages
        const errors = get(['data', 'errors'], payload);
        dispatch(cancelJobError(errors));

        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },
  {
    http: 'patch',
    path: 'job-orders/groups/shift/cancel',
    type: cancelShiftGroup.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(cancelShiftGroupProcessed(payload));
        dispatch(showSnackbar({ message: 'Cancel Job Successful!' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        // FIXME: HB-114 need better error messages
        const errors = get(['data', 'errors'], payload);
        dispatch(cancelShiftGroupError(errors));

        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },

  {
    http: 'patch',
    path: 'job-orders',
    type: updateJob.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateJobProcessed(payload));
        dispatch(showSnackbar({ message: 'Job update successful!' })); // FIXME: HB-114 need better error messages
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const errors = get(['data', 'errors'], payload);
        dispatch(updateJobError(errors));

        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },
  {
    http: 'patch',
    path: 'users',
    type: updateUser.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateUserProcessed(payload));
        dispatch(getUsers());
        dispatch(showSnackbar({ message: 'User update successful!' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const errors = get(['data', 'errors'], payload);
        dispatch(updateUserError(errors));

        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },

  {
    http: 'get',
    path: 'corporations',
    type: getCorporationsForJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsForJobsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCorporationsForJobsListError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'position-templates',
    type: postPositionTemplate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postPositionTemplateProcessed(payload));
        dispatch(showSnackbar({ message: 'success.position.template.post' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postPositionTemplateError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'get',
    path: 'position-templates',
    type: getPositionTemplateForJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateProcessedForJobsList(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getPositionTemplateErrorForJobsList(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'get',
    path: 'corporations',
    type: getCorporationsForUsers.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsProcessedForUsers(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCorporationsErrorForUsers(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'get',
    path: 'corporations',
    type: getCorporationsForJobsOrder.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsProcessedForJobsOrder(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getCorporationsForJobsOrderError(payload));
    },
  },

  {
    http: 'post',
    path: 'corporations',
    type: getCorporationsForCandidate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getCorporationsForCandidateProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCorporationsForCandidateError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'notifications/read',
    type: getNotifications.type,
    successCallback: (payload, dispatch) => {
      dispatch(getNotificationsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getNotificationsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      (globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }))) ||
        dispatch(showSnackbar({ message: 'error.read.notifications' }));
    },
  },
  {
    http: 'patch',
    path: 'notifications/mark-read',
    type: setNotificationsToRead.type,
    successCallback: (payload, dispatch) => {
      dispatch(setNotificationsToReadProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(setNotificationsToReadError(errors));
      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      (globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }))) ||
        dispatch(showSnackbar({ message: 'error.markRead.notifications' }));
    },
  },
  {
    http: 'post',
    path: 'corporations/read',
    type: getCorporationsList.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getCorporationsListProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getCorporationsListError(payload));
    },
  },
  {
    axiosOptions: {
      responseType: 'blob',
    },
    http: 'get',
    path: 'files',
    type: getFilesForCandidate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getFilesForCandidateProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getFilesForCandidateError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    axiosOptions: {
      responseType: 'blob',
    },
    http: 'get',
    path: 'files',
    type: getFile.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(fileProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(fileError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'position-templates/read',
    type: getPositionTemplateForJobsOrder.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateProcessedForJobsOrder(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getPositionTemplateErrorForJobsOrder(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'delete',
    path: 'position-templates',
    type: deletePositionTemplate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(deletePositionTemplateProcessed(payload));
        dispatch(showSnackbar({ message: 'Position deleted successfully!' }));
        dispatch(getPositionTemplateList());
      });
    },
    errorCallback: (payload, dispatch) => {
      /* batch(() => {
        dispatch(
          showSnackbar({
            message:
              payload.error.data ||
              "Sorry, we've encountered a problem on our servers. Tech staff have been alerted.",
          })
        );
        dispatch(deletePositionTemplateError(payload));
      }); */
      const errors = get(['data', 'errors'], payload);
      dispatch(deletePositionTemplateError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'patch',
    path: 'position-templates',
    type: updatePositionTemplate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updatePositionTemplateProcessed(payload));
        dispatch(showSnackbar({ message: 'Position updated successfully!' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        /* dispatch(
          showSnackbar({
            message:
              payload.error.data ||
              "Sorry, we've encountered a problem on our servers. Tech staff have been alerted.",
          })
        );
        dispatch(updatePositionTemplateError(payload)); */
        const errors = get(['data', 'errors'], payload);
        dispatch(updatePositionTemplateError(errors));

        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },

  {
    http: 'get', // Get List of Positions
    path: 'position-templates',
    type: getPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getPositionTemplateListError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'locations/read',
    type: getLocationsForPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getLocationsForPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getLocationsForPositionTemplateListError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'get',
    path: 'certifications',
    type: getCertificationsForSettings.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertificationsForSettingsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCertificationsForSettingsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'skills/update-candidates',
    type: updateSkillsForSettings.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateSkillsForSettingsProcessed(payload));
        dispatch(updateSkillsForSession(payload));

        dispatch(showSnackbar({ message: 'candidate.skillUpdated.successfully' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(updateSkillsForSettingsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'users/read',
    type: getUserInfo.type,
    successCallback: (payload, dispatch) => {
      dispatch(getUserInfoProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getUserInfoError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'get',
    path: 'certifications',
    type: getCertificationsForPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertificationsForPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCertificationsForPositionTemplateListError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  // user list
  {
    http: 'get',
    path: 'certifications',
    type: getCertificationsForUsers.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertificationsForUsersProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCertificationsForUsersError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'get',
    path: 'corporations',
    type: getCorporationsForPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsForPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCorporationsForPositionTemplateListError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'bullhorn/corporations',
    type: postCorporation.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postCorporationProcessed(payload));

        dispatch(showSnackbar({ message: 'Corporation created successfully!' })); // TODO: HB-200 Should not be hardcoded for multiple languages
        navigator('/corporations/corporationList');
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postCorporationError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'users',
    type: postUser.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postUserProcessed(payload));
        dispatch(showSnackbar({ message: 'User created successfully!' })); // TODO: HB-200 Should not be hardcoded for multiple languages
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postUserError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'bullhorn/employers',
    type: postBullhornEmployers.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postBullhornEmployersProcessed(payload));
        dispatch(showSnackbar({ message: 'User created successfully!' })); // TODO: HB-200 Should not be hardcoded for multiple languages
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postBullhornEmployersError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'users',
    type: postUser.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postUserProcessed(payload));
        dispatch(showSnackbar({ message: 'User created successfully!' })); // TODO: HB-200 Should not be hardcoded for multiple languages
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postUserError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'users/verify',
    type: verifyAccount.type,
    successCallback: (payload, dispatch) => {
      const snackBarMessage = 'account.verified.successfully';
      batch(() => {
        navigator('/dashboard');
        dispatch(verifyAccountProcessed(payload));
        dispatch(emailVerificationOrPWResetUpdateUserData(payload));
        dispatch(showSnackbar({ message: snackBarMessage }));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const errors = get(['data', 'errors'], payload);
        dispatch(verifyAccountError(errors));

        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },
  {
    http: 'post',
    path: 'password/reset',
    type: passwordReset.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        navigator('/dashboard');
        dispatch(passwordResetProcessed(payload));
        dispatch(emailVerificationOrPWResetUpdateUserData(payload));
        dispatch(showSnackbar({ message: 'Account Password Reset' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(passwordResetError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'password/request-reset',
    type: postForgotPassword.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        navigator('/');
        dispatch(postForgotPasswordProcessed(payload));
        dispatch(
          showSnackbar({ message: 'Email sent. Please check your email to reset your password.' }) // FIXME: HB-943 Change email to either email or mobile once mobile option is available
        );
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postForgotPasswordError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post', // Get List of job group template for candidates
    path: 'workable-groups',
    type: getCandidateJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCandidateJobsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCandidateJobsListError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post', // Get List of job group template for candidates
    path: 'workable-groups',
    type: getCandidatePathSelectedJob.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCandidatePathSelectedJobProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCandidatePathSelectedJobError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post', // Get filtered List of job group template for candidates
    path: 'workable-groups',
    type: getFilteredJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getFilteredJobsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getFilteredJobsListError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'get',
    path: 'certifications',
    type: getCertificationsForCandidate.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertificationsForCandidateProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCertificationsForCandidateError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'placements',
    type: createPlacementsForCandidate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(showSnackbar({ message: 'Job Scheduled!' }));
        dispatch(createPlacementsForCandidateProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(createPlacementsForCandidateError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'placements',
    type: getPlacements.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getPlacementsProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getPlacementsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'job-orders/read',
    type: getGroupsJobOrders.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getGroupsJobOrdersProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getGroupsJobOrdersError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'patch',
    path: 'placements/cancel',
    type: cancelPlacementForCandidate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(cancelPlacementForCandidateProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(cancelPlacementForCandidateError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'timecard/punch',
    type: postPunchForDashboard.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        const message = 'success.punch';
        dispatch(showSnackbar({ message }));
        dispatch(postPunchForDashboardProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postPunchForDashboardError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'timecard/punch',
    type: postPunchForTimeTracking.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        const message = 'success.punch';
        dispatch(showSnackbar({ message }));
        dispatch(postPunchForTimeTrackingProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postPunchForTimeTrackingError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'get',
    path: 'users',
    type: getUsers.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getUsersProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getUsersError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'get',
    path: 'users',
    type: getUsersForJobList.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getUsersProcessedForJobList(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getUsersErrorForJobList(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'patch', // user detail update certifications
    path: 'users/update-certifications',
    type: updateCertifications.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateCertificationsProcessed(payload));
        dispatch(showSnackbar({ message: 'Certifications updated successful!' }));
      });
      location.reload(); // eslint-disable-line
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const errors = get(['data', 'errors'], payload);
        dispatch(updateCertificationsError(errors));

        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },
  {
    http: 'post',
    path: 'skills/update-candidates',
    type: updateSkills.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateSkillsProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const errors = get(['data', 'errors'], payload);
        dispatch(updateSkillsError(errors));

        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },
  {
    http: 'patch', // user detail update distance,industry and certs
    path: 'users',
    type: updateCandidateDetails.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateCandidateDetailsProcessed(payload));
        dispatch(showSnackbar({ message: 'Candidate updated successful!' }));
        location.reload(); // eslint-disable-line
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const errors = get(['data', 'errors'], payload);
        dispatch(updateCandidateDetailsError(errors));

        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },

  {
    http: 'post',
    path: 'placements',
    type: updatePlacement.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        const message = 'candidate.assigned.successfully';
        dispatch(showSnackbar({ message }));
        dispatch(updatePlacementProcessed(payload));
        const docs = get(['data', 'documents'], payload);
        const candidate = docs[0]?.candidate;
        const candidateSkills = (candidate?.candidate?.skills || []).map((skill) => skill.skill);
        const skillsToCredit = [];
        (docs[0]?.jobOrder?.skills || []).forEach((skill) => {
          if (!candidateSkills.includes(skill)) {
            skillsToCredit.push(skill);
          }
        });
        if (skillsToCredit.length !== 0) {
          dispatch(
            creditCandidateSkills({
              candidates: [candidate._id],
              skills: skillsToCredit,
            })
          );
        }
      });
    },
    errorCallback: (payload, dispatch) => {
      const data = get(['data'], payload);
      const errors = get(['errors'], data);

      dispatch(updatePlacementError(errors));
      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      if (globalError && globalError.messageKey) {
        dispatch(showSnackbar({ message: globalError.messageKey }));
      } else {
        dispatch(showSnackbar({ message: data }));
      }
    },
  },

  {
    http: 'patch',
    path: 'placements/cancel',
    type: cancelPlacement.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        const message = 'page.cancelled.placement.successfully';
        dispatch(showSnackbar({ message }));
        dispatch(cancelPlacementProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const data = get(['data'], payload);
      const errors = get(['errors'], data);
      dispatch(cancelPlacementError(errors));
      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      if (globalError && globalError.messageKey) {
        dispatch(showSnackbar({ message: globalError.messageKey }));
      } else {
        dispatch(showSnackbar({ message: data }));
      }
    },
  },

  {
    http: 'post', // Get  Job Placements for Job Order
    path: 'placements/read-for-job-order',
    type: getJobRoster.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobRosterProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getJobRosterError(payload));
    },
  },
  {
    http: 'post',
    path: 'placements/read',
    type: getJobInvitations.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobInvitationsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getJobInvitationsError(payload));
    },
  },
  {
    http: 'post',
    path: 'job-order/invite/respond',
    type: revokeJobInvitations.type,
    successCallback: (payload, dispatch) => {
      dispatch(revokeJobInvitationsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(revokeJobInvitationsError(payload));
    },
  },

  {
    http: 'post',
    path: 'placements/read',
    type: getJobApplications.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobApplicationsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getJobApplicationsError(payload));
    },
  },
  {
    http: 'post',
    path: 'placements/read',
    type: getCancelledCandidates.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCancelledCandidatesProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatch(getCancelledCandidatesError(payload));
    },
  },

  {
    http: 'patch', // Update Job Placements for Job Order
    path: 'placements',
    type: updateJobRoster.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateJobRosterProcessed(payload));
        dispatch(showSnackbar({ message: 'success.jobRoster.update' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const errors = get(['data', 'errors'], payload);
        dispatch(updateJobRosterError(errors));
        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },
  {
    http: 'patch', // Update DNA
    path: 'users/dna',
    type: updateDNA.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateDNAProcessed(payload));
        dispatch(showSnackbar({ message: 'success.dna.update' }));
        // dispatch(getUserHistory(query id));// if we have query id, we can use it to update the user new DNA/NDR data to void refresh
      });
      location.reload(); // eslint-disable-line
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const errors = get(['data', 'errors'], payload);
        dispatch(updateDNAError(errors));

        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },

  {
    http: 'patch', // Update DNR
    path: 'corporations/dnr',
    type: updateDNR.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateDNRProcessed(payload));
        dispatch(showSnackbar({ message: 'success.dnr.update' }));
        // dispatch(getUserHistory(query id));// if we have query id, we can use it to update the user new DNA/NDR data to void refresh
      });
      location.reload(); // eslint-disable-line
    },
    errorCallback: (payload, dispatch) => {
      batch(() => {
        const errors = get(['data', 'errors'], payload);
        dispatch(updateDNRError(errors));

        const globalError = get(['data', 'errors', 'GLOBAL'], payload);
        globalError &&
          globalError.messageKey &&
          dispatch(showSnackbar({ message: globalError.messageKey }));
      });
    },
  },
  {
    http: 'post',
    path: 'timecards/read',
    type: getTimeCards.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getTimeCardsProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getTimeCardsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'timecards/read',
    type: getPathSelectedTimeCards.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getPathSelectedTimeCardsProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getPathSelectedTimeCardsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'patch',
    path: 'timecard/update',
    type: updateTimeCard.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateTimeCardProcessed(payload));
        dispatch(showSnackbar({ message: 'success.timecard.update' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(updateTimeCardError(errors));
    },
  },
  {
    http: 'post',
    path: 'timecards/read',
    type: getEmployeeTimeCards.type,
    successCallback: (payload, dispatch) => {
      dispatch(getEmployeeTimeCardsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getEmployeeTimeCardsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'timecard/dispute',
    type: postDisputeTimeCard.type,
    successCallback: (payload, dispatch) => {
      dispatch(postDisputeTimeCardProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postDisputeTimeCardError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'get-next-engagements',
    type: getNextShift.type,
    successCallback: (payload, dispatch) => {
      dispatch(getNextShiftProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getNextShiftError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'timecard/approve',
    type: postTimeCardApproval.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postTimeCardApprovalProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postTimeCardApprovalError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'timecard/approve',
    type: postTimeCardBatchApproval.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postTimeCardBatchApprovalProcessed(payload));
        dispatch(showSnackbar({ message: 'success.timecard.batch.approval' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postTimeCardBatchApprovalError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'timecard/resolve',
    type: postTimeCardResolve.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postTimeCardResolveProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postTimeCardResolveError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'timecard/finalize',
    type: postTimeCardFinalize.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postTimeCardFinalizeProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postTimeCardFinalizeError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'get-next-engagements',
    type: getCandidatesJobs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCandidatesJobsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCandidatesJobsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post', // Candidate Dashboard
    path: 'workable-groups',
    type: getFeaturedJobs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getFeaturedJobsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getFeaturedJobsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'workable-groups',
    type: getIndustryJobs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getIndustryJobsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getIndustryJobsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post', // Candidate Dashboard
    path: 'workable-groups',
    type: getInterestJobs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getInterestJobsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getInterestJobsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'timecards/read',
    type: getMyTimeThisWeek.type,
    successCallback: (payload, dispatch) => {
      dispatch(getMyTimeThisWeekProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getMyTimeThisWeekError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'skills/read',
    type: getSkills.type,
    successCallback: (payload, dispatch) => {
      dispatch(getSkillsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getSkillsError(errors));
    },
  },

  {
    http: 'post',
    path: 'skills/read',
    type: getSkillsForJobInvite.type,
    successCallback: (payload, dispatch) => {
      dispatch(getSkillsForJobInviteProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getSkillsForJobInviteError(errors));
      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'skills/read',
    type: getSkillsForJobSignUp.type,
    successCallback: (payload, dispatch) => {
      dispatch(getSkillsForJobSignUpProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getSkillsForJobSignUpError(errors));
      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'job-orders/invite/respond',
    type: postInviteRespond.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(showSnackbar({ message: 'success.joborder.invite.respond' }));
        dispatch(postInviteRespondProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(postInviteRespondError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'certifications/read',
    type: getCerts.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getCertsError(errors));
    },
  },
  {
    http: 'post',
    path: 'skills',
    type: sendNewSkillToBackend.type,
    successCallback: (payload, dispatch) => {
      dispatch(sendNewSkillToBackendProcessed(payload));
      dispatch(showSnackbar({ message: 'success.skill.add' }));

      dispatch(getSkills());
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(sendNewSkillToBackendError(errors));

      const errorName = get(['data', 'errors', 'name'], payload);
      errorName && dispatch(showSnackbar({ message: errorName }));
    },
  },
  {
    http: 'delete',
    path: 'skills',
    type: deleteSkill.type,
    successCallback: (payload, dispatch) => {
      dispatch(getSkills());
      dispatch(deleteSkillProcessed(payload));
      dispatch(showSnackbar({ message: 'delete.skill' }));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(deleteSkillError(errors));
    },
  },
  {
    http: 'delete',
    path: 'certifications',
    type: deleteCert.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCerts());
      dispatch(deleteCertProcessed(payload));
      dispatch(showSnackbar({ message: 'delete.cert' }));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(deleteCertError(errors));
    },
  },

  {
    http: 'post',
    path: 'metrics/fill-ratio',
    type: getFillRatio.type,
    successCallback: (payload, dispatch) => {
      dispatch(getFillRatioProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getFillRatioError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'metrics/employee-retention',
    type: getEmployeeRetention.type,
    successCallback: (payload, dispatch) => {
      dispatch(getEmployeeRetentionProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getEmployeeRetentionError(errors));
      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'metrics/user-count-by-role',
    type: getUserCountByRole.type,
    successCallback: (payload, dispatch) => {
      dispatch(getUserCountByRoleProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getUserCountByRoleError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'job-orders/read',
    type: getJobsEntered.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobsEnteredProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getJobsEnteredError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'job-orders/read',
    type: getJobsCompleted.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobsCompletedProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getJobsCompletedError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'metrics/jobs-entered-by-role-by-date',
    type: getTimeBasedJobOrderNumbers.type,
    successCallback: (payload, dispatch) => {
      dispatch(getTimeBasedJobOrderNumbersProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getTimeBasedJobOrderNumbersError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'metrics/job-orders/revenue',
    type: getJobOrderRevenue.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobOrderRevenueProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getJobOrderRevenueError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'metrics/employees-hires', // FIXME: Derya check this once endpoint is ready
    type: getEmployeesHired.type,
    successCallback: (payload, dispatch) => {
      dispatch(getEmployeesHiredProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getEmployeesHiredError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'metrics/dnrs', // FIXME: Derya check this once endpoint is ready
    type: getDnrs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getDnrsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getDnrsError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'metrics/dnas', // FIXME: Derya check this once endpoint is ready
    type: getDnas.type,
    successCallback: (payload, dispatch) => {
      dispatch(getDnasProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getDnasError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'metrics/overall-attendance',
    type: getOverallAttendance.type,
    successCallback: (payload, dispatch) => {
      dispatch(getOverallAttendanceProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getOverallAttendanceError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },

  {
    http: 'post',
    path: 'metrics/do-not-return-breakdown',
    type: getDoNotReturnBreakdown.type,
    successCallback: (payload, dispatch) => {
      dispatch(getDoNotReturnBreakdownProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getDoNotReturnBreakdownError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'job-orders/invite',
    type: inviteEmployee.type,
    successCallback: (payload, dispatch) => {
      dispatch(showSnackbar({ message: 'success.jobOrder.invite' }));
      dispatch(inviteEmployeeProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(inviteEmployeeError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'corporations/departments/delete',
    type: deleteDepartment.type,
    successCallback: (payload, dispatch) => {
      dispatch(showSnackbar({ message: 'success.department.delete' }));
      dispatch(deleteDepartmentProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(deleteDepartmentError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'corporations/departments/read',
    type: getDepartments.type,
    successCallback: (payload, dispatch) => {
      dispatch(getDepartmentsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(getDepartmentsError(errors));
      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'post',
    path: 'corporations/departments',
    type: createDepartment.type,
    successCallback: (payload, dispatch) => {
      dispatch(showSnackbar({ message: 'success.department.created' }));
      dispatch(createDepartmentProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(createDepartmentError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
  {
    http: 'patch',
    path: 'corporations/departments',
    type: editDepartment.type,
    successCallback: (payload, dispatch) => {
      dispatch(showSnackbar({ message: 'success.department.update' }));
      dispatch(editDepartmentProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      const errors = get(['data', 'errors'], payload);
      dispatch(editDepartmentError(errors));

      const globalError = get(['data', 'errors', 'GLOBAL'], payload);
      globalError &&
        globalError.messageKey &&
        dispatch(showSnackbar({ message: globalError.messageKey }));
    },
  },
];

export default API;
