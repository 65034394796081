import * as React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import StarOne from '../../../../../assets/Success.svg';
import Button from '../../../../../components/Button';
import theme from '../../../../../theme/theme';

const SuccessDrawer = ({ onClose, header, message }) => {
  const componentWorkFlowFont = ['components', 'workflow', 'typography'];

  const ConfirmationBodyBox = styled(Box)(() => ({
    minHeight: '50vh',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  }));

  const BodyBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(16, 8, 5),
    height: '100%',
    minWidth: '100%',
    backgroundColor: get(
      [
        'components',
        'workflow',
        'palette',
        'background',
        'default',
        'confirmationPageBackgroundColor',
      ],
      theme
    ),
  }));

  const headerSx = {
    fontSize: get(['components', 'workflow', 'typography', 'defaultTitleFontSize'], theme),
    color: get(
      ['components', 'workflow', 'palette', 'font', 'default', 'defaultLastPageFontColor'],
      theme
    ),

    fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  };

  const messageSx = {
    fontSize: get(['components', 'workflow', 'typography', 'defaultFontSize'], theme),
    color: get(
      ['components', 'workflow', 'palette', 'font', 'default', 'defaultLastPageFontColor'],
      theme
    ),

    fontFamily: get([...componentWorkFlowFont, 'defaultFont'], theme),
  };

  const ButtonSx = {
    background: 'white',
    color: `${theme.button.palette.primary}`,
    width: '50%',
    '&:hover, &.Mui-hovered': {
      background: `${theme.button.palette.primary}`,
      boxShadow: 'none',
      color: 'white',
      WebkitBackgroundClip: 'border-box',
      WebkitTextFillColor: 'white',
    },
  };

  return (
    <BodyBox>
      <Box
        component="img"
        sx={{
          height: 51.52,
          width: 51.52,
          marginBottom: 2,
        }}
        alt="Stars"
        src={StarOne}
      />
      <ConfirmationBodyBox>
        <Typography sx={headerSx}>{header}</Typography>
        {message && <Typography sx={messageSx}>{message}</Typography>}

        <Button sx={ButtonSx} startIcon={<ArrowBackIcon />} onClick={() => onClose()}>
          Back to work
        </Button>
      </ConfirmationBodyBox>
    </BodyBox>
  );
};

export default SuccessDrawer;

SuccessDrawer.propTypes = {
  onClose: PropTypes.func,
  header: PropTypes.string,
  message: PropTypes.string,
};
