import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import asyncService from '../../../../../datahub/asyncService';
import Chip from '../../../../components/Chip';
import selectUser from '../../../../store/selectors/appSelector';
import theme from '../../../../theme';

const componentsDetailViewSectionHeaders = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];

const viewHeaderStyling = {
  fontSize: get([...componentsDetailViewSectionHeaders, 'fontSize'], theme),
  fontFamily: get([...componentsDetailViewSectionHeaders, 'fontFamily'], theme),
  color: get([...componentsDetailViewSectionHeaders, 'fontColor'], theme),
};

const RecruiterCorporationTabForRecruiters = ({ initialValues }) => {
  const corpIDs = get(['recruiter', 'corporations'], initialValues);
  const user = useSelector(selectUser);
  const token = get('token', user);
  const dispatch = useDispatch();
  const [corporationList, setCorporationList] = useState([]);
  useEffect(() => {
    const submitAPICallData = {
      httpMethod: 'POST',
      route: 'corporations/read',
    };

    corpIDs &&
      corpIDs.forEach((corp) => {
        const data = { id: corp };
        asyncService({
          ...submitAPICallData,
          data,
          token,
          onSuccess: (payload) => {
            setCorporationList((latestCorp) => [
              ...latestCorp,
              get(['data', 'documents'], payload),
            ]);
          },
          onError: () => {
            setCorporationList([]);
          },
          dispatch,
        });
      });
  }, [corpIDs, dispatch, token]);

  return (
    <Box
      sx={{
        width: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        height: '80vh',
      }}
    >
      <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(0, 0, 1, 0) }}>
        Corporations
      </Typography>
      <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
        <Grid container item>
          {corporationList.map((corp) => (
            <Grid item sx={{ margin: theme.spacing(0, 1, 0, 0) }}>
              <Chip background="dark" label={get('name', corp)} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

RecruiterCorporationTabForRecruiters.propTypes = {
  initialValues: PropTypes.shape({}),
};

export default RecruiterCorporationTabForRecruiters;
