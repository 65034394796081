import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import theme from '../../theme';
import { BLACK } from '../../theme/colorConstants';

const CHIP_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};
const CHIP_SIZE = {
  SMALL: 'Small',
  LARGE: 'Large',
};

const WaeChip = (props) => {
  const { sx, background, size, label } = props;
  let chipBgColor;
  let chipHoverColor;
  let fontSize;

  switch (background) {
    case CHIP_BACKGROUND.DARK:
      chipBgColor = theme.chip.bgColor.darkBgColor;
      chipHoverColor = theme.chip.hover.darkHoverColor;
      break;
    case CHIP_BACKGROUND.LIGHT:
      chipBgColor = theme.chip.bgColor.defaultBgColor;
      chipHoverColor = theme.chip.hover.lightHoverColor;
      break;
    default:
      chipBgColor = theme.chip.bgColor.defaultBgColor;
      chipHoverColor = theme.chip.hover.defaultHoverColor;
  }
  switch (size) {
    case CHIP_SIZE.LARGE:
      fontSize = theme.chip.default.largeFontSize;

      break;

    default:
      fontSize = theme.chip.default.fontSize;
  }

  return (
    <Chip
      {...props}
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize,
            fontFamily: 'Barlow-600',
          }}
        >
          {label}

          <span> </span>
        </Box>
      }
      sx={{
        color: BLACK[0],
        backgroundColor: chipBgColor,

        '&.MuiChip-root': {
          ':hover': {
            backgroundColor: chipHoverColor,
          },
        },
        ...sx,
      }}
    />
  );
};
WaeChip.propTypes = {
  sx: PropTypes.shape({}),
  brightness: PropTypes.shape({}),
  background: PropTypes.string,
  size: PropTypes.string,
  selected: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  icon: PropTypes.element,
};

export { CHIP_BACKGROUND, CHIP_SIZE, WaeChip };
