import FormFillRate from '../../../../../components/Form/FormFillRate';
import FormTextField from '../../../../../components/Form/FormTextField';
import {
  composeValidators,
  isGreaterThan,
  isInteger,
} from '../../../../../components/Form/validations';

const formatNumber = (value) => (!Number.isNaN(value) ? value.toString() : '0');

const FormFieldDataRoster = () => ({
  fillRatio: {
    ComponentType: FormFillRate,
    key: 'fillRate',
    name: 'fillRate',
  },

  numOpenings: {
    ComponentType: FormTextField,
    mode: 'dark',
    name: 'numOpenings',
    validate: composeValidators(isInteger, isGreaterThan(0)),
    variant: 'outlined',
    fullWidth: true,
    xs: 6,
  },
  placementsCount: {
    ComponentType: FormTextField,
    mode: 'dark',
    name: 'placementsCount',
    format: (value) => formatNumber(value),
    readOnly: true,
    variant: 'outlined',
    fullWidth: true,
    xs: 6,
  },
});
export default FormFieldDataRoster;
