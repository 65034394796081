/* eslint-disable react/prop-types */
import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';

import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { SUBMIT_BUTTON_TYPE } from '../../constants/constants';

import ButtonActions from './ButtonActions';
import FormConfirmationModal from './FormConfirmationModal';
import { formFieldFactory } from './formFieldFactory';
import { mapFormValuesToStringRepresentation } from './formFrameworkUtilities';
import { hasAnyFieldErrors } from './formUtils';

const Form = ({
  buttonData,
  children,
  clearServerError,
  debugMode = false,
  errorData,
  formFieldData,
  formRules,
  formId,
  initialValues,
  keepDirtyOnReinitialize,
  onSubmit,
  confirmationModal,
  setButtonOnBottomAndFixed,
  validateForm,
}) => {
  const calculatedInitialValues = formFieldData.reduce((previousValue, currentValue) => {
    const fieldName = get('name', currentValue);

    const targetInitialValue = get('initialValue', currentValue) || get(fieldName, initialValues);
    return (
      // DevNote: This is intentionally checking against null and not for truthyness!
      (targetInitialValue !== null && {
        ...previousValue,
        ...{
          [fieldName]:
            (currentValue.format && currentValue.format(targetInitialValue)) || targetInitialValue,
        },
      }) ||
      previousValue
    );
  }, {});

  const [confirmationModalIsOpen, setConfirmationModalOpen] = React.useState(false);
  const createOpenConfirmationModalButtonData = (props) => ({
    variant: 'contained',
    onClick: () => setConfirmationModalOpen(!confirmationModalIsOpen),
    text: confirmationModal.buttonText,
    ...props,
  });

  const ButtonGrid = styled(Grid)(() => ({
    right: setButtonOnBottomAndFixed && '40px',
    bottom: setButtonOnBottomAndFixed && '5%',
    position: setButtonOnBottomAndFixed && 'fixed',
    width: 'inherit',
    zIndex: setButtonOnBottomAndFixed && 10,
  }));

  const formButtonOnBottomAndFixed = {
    paddingBottom: '60px',
  };
  return (
    <RFForm
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      initialValues={calculatedInitialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, valid, values }) => (
        <form
          onSubmit={handleSubmit}
          id={formId}
          style={setButtonOnBottomAndFixed && formButtonOnBottomAndFixed}
        >
          <Grid container item xs={12}>
            {formRules}
            {formFieldFactory(errorData, clearServerError, formFieldData)}
            {children}
          </Grid>
          {buttonData && (
            <ButtonGrid container item>
              <ButtonActions
                buttonData={[
                  ...buttonData.map(
                    (btn) =>
                      (btn.type === SUBMIT_BUTTON_TYPE && !valid && { ...btn, disabled: true }) ||
                      btn
                  ),
                  confirmationModal &&
                    createOpenConfirmationModalButtonData({
                      disabled: !valid || hasAnyFieldErrors(errorData),
                    }),
                ].filter(Boolean)}
                formValues={values}
                setButtonOnBottomAndFixed={setButtonOnBottomAndFixed}
              />
            </ButtonGrid>
          )}

          {confirmationModal && (
            <FormConfirmationModal
              data={mapFormValuesToStringRepresentation(form, formFieldData)}
              errorData={errorData}
              isOpen={confirmationModalIsOpen}
              onConfirm={() => onSubmit(values)}
              setIsOpen={setConfirmationModalOpen}
              submitting={confirmationModal.submitting}
            />
          )}
          {debugMode && <pre>{JSON.stringify(values, 0, 2)}</pre>}
        </form>
      )}
      validate={(values) => {
        const baseErrors = formFieldData.reduce(
          (accumulator, formFieldDef) =>
            // Verify required fields have values.
            (formFieldDef.required &&
              !values[formFieldDef.name] && {
                ...accumulator,
                [formFieldDef.name]: 'This value is required',
              }) ||
            // Else, this field has no errors.
            accumulator,
          {}
        );
        const customErrors = (validateForm && validateForm(values)) || {};
        return { ...baseErrors, ...customErrors };
      }}
    />
  );
};
Form.propTypes = {
  buttonData: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.element,
  clearServerError: PropTypes.func,
  confirmationModal: PropTypes.shape({ buttonText: PropTypes.string, submitting: PropTypes.bool }),
  debugMode: PropTypes.bool,
  errorData: PropTypes.shape({}),
  formData: PropTypes.shape({}),
  formFieldData: PropTypes.arrayOf(PropTypes.shape({})),
  formRules: PropTypes.arrayOf(PropTypes.shape({})),
  formId: PropTypes.string,
  keepDirtyOnReinitialize: PropTypes.bool,
  onSubmit: PropTypes.func,
  setButtonOnBottomAndFixed: PropTypes.bool,
  validateForm: PropTypes.func,
};
export default Form;
