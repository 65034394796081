import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ClickAwayListener, Paper, Popper } from '@mui/material';

import theme from '../../theme/theme';
import Button from '../Button';

import Filters from './Filters';

const PopOverFilter = ({ leftFilter, items, onFilterChange, updateQueryOptions }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    if (open) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.target);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const paperSx = {
    padding: theme.spacing(3),
    display: 'flex',
    backgroundColor: get(['popoverFilter', 'bgColor'], theme),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const fullWidthSx = {
    width: '100%',
  };

  return (
    <>
      <Button
        onClick={handleClick}
        type="outlined"
        startIcon={<FilterAltIcon sx={{ color: theme.button.palette.secondary }} />}
      >
        Filters
      </Button>
      <Popper
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ClickAwayListener onClickAway={handleClick}>
          <Paper sx={paperSx}>
            {leftFilter}
            <Filters
              filterDefinitions={items}
              onFilterChange={onFilterChange}
              updateQueryOptions={updateQueryOptions}
              wrapperSx={fullWidthSx}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

PopOverFilter.propTypes = {
  leftFilter: PropTypes.element,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onFilterChange: PropTypes.func,
  updateQueryOptions: PropTypes.func,
};

export default PopOverFilter;
