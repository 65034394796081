import FormTextField from '../../../../../components/Form/FormTextField';
import {
  composeValidators,
  isCurrencyGreaterThan,
} from '../../../../../components/Form/validations';
import SearchBox, { SEARCHBAR_BACKGROUND } from '../../../../../components/SearchBar';
import { formatCurrency } from '../../../../../utils';

const AdminFormFieldDataShiftDetails = (certifications) => {
  const predefinedCertsOptions = certifications.map((cert) => ({
    name: cert.name,
    _id: cert.id,
  }));

  return {
    billRateReg: {
      ComponentType: FormTextField,
      label: 'Bill Rate',
      mode: 'dark',
      name: 'billRateReg',
      format: (value) => formatCurrency(value),
      validate: composeValidators(isCurrencyGreaterThan(0)),
      placeholder: '$0.00',
      variant: 'outlined',
      fullWidth: true,
      xs: 6,
    },
    billRateDT: {
      ComponentType: FormTextField,
      label: 'Double Time Bill Rate',
      mode: 'dark',
      name: 'billRateDT',
      format: (value) => formatCurrency(value),
      validate: composeValidators(isCurrencyGreaterThan(0)),
      placeholder: '$0.00',
      variant: 'outlined',
      fullWidth: true,
      xs: 6,
    },
    billRateOT: {
      ComponentType: FormTextField,
      label: 'Over Time Bill Rate',
      mode: 'dark',
      name: 'billRateOT',
      format: (value) => formatCurrency(value),
      validate: composeValidators(isCurrencyGreaterThan(0)),
      placeholder: '$0.00',
      variant: 'outlined',
      fullWidth: true,
      xs: 6,
    },

    payRateReg: {
      ComponentType: FormTextField,
      label: 'Pay Rate',
      mode: 'dark',
      name: 'payRateReg',
      format: (value) => formatCurrency(value),
      validate: composeValidators(isCurrencyGreaterThan(0)),
      placeholder: '$0.00',
      variant: 'outlined',
      fullWidth: true,
      xs: 6,
    },
    payRateDT: {
      ComponentType: FormTextField,
      label: 'Double Time Pay Rate',
      mode: 'dark',
      name: 'payRateDT',
      format: (value) => formatCurrency(value),
      validate: composeValidators(isCurrencyGreaterThan(0)),
      placeholder: '$0.00',
      variant: 'outlined',
      fullWidth: true,
      xs: 6,
    },
    payRateOT: {
      ComponentType: FormTextField,
      label: 'Over Time Pay Rate',
      mode: 'dark',
      name: 'payRateOT',
      format: (value) => formatCurrency(value),
      validate: composeValidators(isCurrencyGreaterThan(0)),
      placeholder: '$0.00',
      variant: 'outlined',
      fullWidth: true,
      xs: 6,
    },

    workersCompCode: {
      ComponentType: FormTextField,
      label: 'Workers Comp Code',
      mode: 'dark',
      name: 'workersCompCode',
      variant: 'outlined',
      fullWidth: true,
      xs: 6,
    },

    certs: {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.DARK,
      preDefinedOptionText: 'Enter Certifications',
      dropDownOptions: predefinedCertsOptions,
      data: null,
      name: 'certifications',
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Enter Certifications',
      fullWidth: true,
      optionDisplayField: 'name',
      description:
        'Search for additional certifications or add additional certifications by clicking on the certification chips below.',
      key: '_id',
      multiSelect: true,
    },
    skills: {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.DARK,
      preDefinedOptionText: 'Enter Certifications',
      data: null,
      name: 'skills',
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Skills',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'skills/read',
      },
      optionDisplayField: 'name',
      description:
        'Search for additional skills or add additional skills by clicking on the skill chips below.',
      key: '_id',
      multiSelect: true,
    },
  };
};
export default AdminFormFieldDataShiftDetails;
