import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

export const selectCandidateJobsList = (state) => get('candidateJobsList', state);

export const selectCertifications = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.certifications
);
export const selectIsLoadingCertifications = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingCertifications
);

export const selectIsLoadingFilteredJobsList = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingFilteredJobsList
);

export const selectCorporations = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.corporations
);
export const selectCorporationById = createSelector(
  selectCorporations,
  (state, corporationId) => corporationId,
  (corporations, corporationId) => corporations.find(({ _id }) => _id === corporationId)
);

export const selectFiles = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.files
);

export const selectJobGroupsList = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.jobGroupsList
);

export const selectJobGroupsCount = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.jobGroupsCount
);

export const selectMyJobCount = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.myJobCount
);

export const selectIsLoadingAllJobsList = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingAllJobsList
);
export const selectIsLoadingPathSelectedJob = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingPathSelectedJob
);

export const selectIsLoadingPlacementList = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingPlacementList
);
export const selectIsLoadingPathSelectedPlacement = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingPlacementList
);

export const selectWorkGroupCount = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.workGroupCount
);

export const selectMyJobOrders = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.jobOrders
);

export const selectLocations = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.locations
);
export const selectLocationById = createSelector(
  selectLocations,
  (state, locationId) => locationId,
  (locations, locationId) => locations.find(({ _id }) => _id === locationId)
);

export const selectPlacements = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.placements
);
export const selectPlacementById = createSelector(
  selectPlacements,
  (state, placementId) => placementId,
  (placements, placementId) => placements.find(({ _id }) => _id === placementId)
);
export const selectIsLoadingPlacements = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingPlacements
);
export const selectSelectedGroup = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.selectedGroup
);
export const selectSelectedPlacement = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.selectedPlacement
);
export const selectIsLoadingGroupsJobs = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingSelectedGroupsJobs
);
export const selectIsProcessingCreatePlacement = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isProcessingCreatePlacement
);
export const selectGroupsJobs = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.selectedGroupsJobs
);
export const selectCreatedPlacements = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.createdPlacements
);
export const selectCreatePlacementErrorMessage = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.createPlacementErrorMessage
);
export const selectGroupsJobsCount = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.selectedGroupsJobsCount
);
export const selectGroupsJobsPage = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.selectedGroupsJobsPage
);

export const selectAllJobsPage = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.allJobsPage
);
export const selectMyJobsPage = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.myJobsPage
);
export const selectIsLoadingCancel = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingCancel
);
export const selectCancelledPlacement = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.cancelledPlacement
);
export const selectSkills = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.skills
);
export const selectIsLoadingSkills = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingSkills
);
