import React from 'react';

import Box from '@mui/material/Box';

import Label from '../../../components/Label';
import {
  WAE_SUPPORTED_DATE_FILTER_OPERATORS,
  WAE_SUPPORTED_FILTER_FIELD_TYPES,
  WAE_SUPPORTED_STRING_FILTER_OPERATORS,
} from '../../../constants/filterQueries';
import UserRole from '../../../constants/user-role';
import theme from '../../../theme';

import FillRatioCell from './FillRatioCell';
import { handleLabelProps } from './helpers';

const status = {
  field: 'status',
  headerClassName: 'theme-header',
  headerName: 'Status',
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.STRING,
  minWidth: 120,
  filterOperators: WAE_SUPPORTED_STRING_FILTER_OPERATORS,
  renderCell: (params) => <Label {...handleLabelProps(params.value)} />,
};

const endDate = {
  field: 'endDate',
  headerClassName: 'theme-header',
  headerName: 'End Date',
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.DATE,
  flex: 1,
};

const endTime = {
  field: 'endTime',
  headerClassName: 'theme-header',
  headerName: 'End Time',
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.DATE,
  flex: 1,
};

const externalId = {
  field: 'externalId',
  headerClassName: 'theme-header',
  headerName: 'External ID',
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.NUMBER,
  flex: 1,
  valueGetter: (params) => {
    // in some cases, bullhorn is not included in the metadata
    const { bullhorn = {} } = params.row.metadata;
    return Number(bullhorn.id);
  },
};

const longTermJobInfo = {
  field: 'longTermJobInfo',
  cellClassName: 'info-cell',
  customSort: {
    field: 'jobName',
  },
  headerClassName: 'theme-header',
  headerName: 'Job',
  renderCell: (params) => (
    <Box
      sx={{
        flexGrow: 1,
        padding: theme.spacing(1.5, 0, 1.5, 0),
        color: `${theme.dataGrid.jobInfo.corpColor}`,
      }}
    >
      <Box className="info-cell-light">{params.value.corporation}</Box>
      <Box
        sx={{
          display: 'flex',
          fontSize: `${theme.dataGrid.jobInfo.fontSize}`,
          fontFamily: `${theme.dataGrid.jobInfo.longTermFontFamily}`,
          color: `${theme.dataGrid.jobInfo.jobColor}`,
        }}
      >
        {params.value.name}
      </Box>
      <Box
        className="info-cell-light"
        sx={{
          fontSize: `${theme.dataGrid.jobInfo.smallFontSize}`,
          fontFamily: `${theme.dataGrid.jobInfo.fontFamily}`,
        }}
      >
        {params.value.description} <br />
        {params.value.address}
      </Box>
    </Box>
  ),
  minWidth: 350,
  flex: 1,
  filterOperators: WAE_SUPPORTED_STRING_FILTER_OPERATORS,
  isExportable: true,
  isSortable: true,
};

const dayJobInfo = {
  customSort: {
    field: 'name',
  },
  field: 'dayJobInfo',
  cellClassName: 'info-cell',
  headerClassName: 'theme-header',
  headerName: 'Job',
  renderCell: (params) => (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        className="info-cell-light"
        sx={{
          color: `${theme.dataGrid.jobInfo.corpColor}`,
          fontSize: `${theme.dataGrid.jobInfo.smallFontSize}`,
        }}
      >
        {params.value.corporation}
      </Box>
      <Box
        sx={{
          color: `${theme.dataGrid.jobInfo.jobColor}`,
          fontFamily: `${theme.dataGrid.jobInfo.fontFamily}`,
        }}
      >
        {params.value.name}
      </Box>
    </Box>
  ),
  flex: 1,
  filterOperators: WAE_SUPPORTED_STRING_FILTER_OPERATORS,
  isSortable: true,
};

const numOpenings = {
  field: 'numOpenings',
  headerClassName: 'theme-header',
  headerName: 'Openings',
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.NUMBER,
  width: 80,
};

const placements = {
  field: 'jobPlacements',
  headerClassName: 'theme-header',
  headerName: 'Placements',
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.NUMBER,
  flex: 1,
};

const fillRatio = {
  field: 'fillRate',
  headerClassName: 'theme-header',
  headerName: 'Fill Rate',
  renderCell: (params) =>
    !Number.isNaN(params.value) && Number.isFinite(params.value) ? (
      <Box sx={{ flexGrow: 1 }}>
        <FillRatioCell variant="determinate" id={params.id} value={params.value} />
      </Box>
    ) : (
      <Box />
    ),
  flex: 1,
};

const startDate = {
  field: 'startDate',
  headerClassName: 'theme-header',
  headerName: 'Start Date',
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.DATE,
  flex: 1,
  customSort: {
    field: 'start',
  },
  filterOperators: WAE_SUPPORTED_DATE_FILTER_OPERATORS,
  isSortable: true,
};

const startTime = {
  field: 'startTime',
  headerClassName: 'theme-header',
  headerName: 'Start Time',
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.DATE,
  flex: 1,
};

// TODO:add total orders here
const totalOrders = {
  field: 'totalOrders',
  headerClassName: 'theme-header',
  headerName: 'Total Orders',
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.NUMBER,
};

const adminColumnData = [
  status,
  dayJobInfo,
  startDate,
  endDate,
  startTime,
  endTime,
  numOpenings,
  placements,
  fillRatio,
  externalId,
];
const longTermAdminColumnData = [
  longTermJobInfo,
  totalOrders,
  startDate,
  endDate,
  startTime,
  endTime,
];

const employerColumnData = [
  status,
  dayJobInfo,
  startDate,
  endDate,
  startTime,
  endTime,
  numOpenings,
  placements,
  fillRatio,
];
const longTermEmployerColumnData = [
  longTermJobInfo,
  totalOrders,
  startDate,
  endDate,
  startTime,
  endTime,
];

const recruiterColumnData = [
  status,
  dayJobInfo,
  startDate,
  startTime,
  endTime,
  endDate,
  numOpenings,
  placements,
  fillRatio,
  externalId,
];

const longTermRecruiterColumnData = [
  longTermJobInfo,
  totalOrders,
  startDate,
  endDate,
  startTime,
  endTime,
];

const getJobListColumnData = (userRole, jobType) => {
  let jobListColumn;
  switch (userRole) {
    case UserRole.ADMIN:
      jobListColumn = jobType === 'LONG_TERM_JOBS' ? longTermAdminColumnData : adminColumnData;
      break;
    case UserRole.EMPLOYER:
      jobListColumn =
        jobType === 'LONG_TERM_JOBS' ? longTermEmployerColumnData : employerColumnData;
      break;
    case UserRole.RECRUITER:
      jobListColumn =
        jobType === 'LONG_TERM_JOBS' ? longTermRecruiterColumnData : recruiterColumnData;
      break;
    default:
      throw Error(`Invalid user role '${userRole}'`);
  }

  return jobListColumn;
};

export default getJobListColumnData;
