import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import FavoriteIcon from '@mui/icons-material/Favorite';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import theme from '../../../../../theme';

const styleForCandidateName = {
  color: theme.components.detailView.typography.locationText.fontColor,
  fontFamily: theme.components.detailView.typography.locationText.fontFamily,
};
const CancelledCandidates = ({ cancelledCandidates, corporation }) => (
  <Box>
    <List>
      {cancelledCandidates &&
        cancelledCandidates.length > 0 &&
        cancelledCandidates.map((candidate) => {
          const favorite =
            corporation.favorite.find((f) => f.waeID === candidate.candidateId) || false;
          return (
            <ListItem key={get('candidateId', candidate)}>
              {!!favorite && <FavoriteIcon />}
              <Typography sx={styleForCandidateName}>{get('candidateName', candidate)} </Typography>
            </ListItem>
          );
        })}
    </List>
  </Box>
);

CancelledCandidates.propTypes = {
  cancelledCandidates: PropTypes.arrayOf(PropTypes.shape({})),
  corporation: PropTypes.shape({ favorite: PropTypes.shape({ find: PropTypes.func }) }),
};

export default CancelledCandidates;
