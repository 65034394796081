import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Popover } from '@mui/material';

import Button from '../../../components/Button';
import theme from '../../../theme/theme';

import Filter from './Filter';

const ModelFilter = ({ items, handleValueChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    if (open) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.target);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const paperSx = {
    padding: theme.spacing(3),
  };

  return (
    <>
      <Button
        onClick={handleClick}
        type="outlined"
        startIcon={<FilterAltIcon sx={{ color: theme.button.palette.secondary }} />}
      >
        Filters
      </Button>
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ sx: paperSx }}
      >
        <Filter items={items} handleValueChange={handleValueChange} column />
      </Popover>
    </>
  );
};

ModelFilter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  handleValueChange: PropTypes.func,
};

export default ModelFilter;
