import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CrossIcon = () => (
  <SvgIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9255_3591)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2728 6.60619C18.6633 6.21567 18.6633 5.5825 18.2728 5.19198C17.8823 4.80145 17.2491 4.80145 16.8586 5.19198L11.5658 10.4848L6.27277 5.19174C5.88225 4.80121 5.24908 4.80121 4.85856 5.19173C4.46803 5.58226 4.46803 6.21542 4.85856 6.60595L10.1516 11.899L4.85873 17.1918C4.46821 17.5823 4.4682 18.2155 4.85873 18.606C5.24925 18.9966 5.88242 18.9966 6.27294 18.606L11.5658 13.3132L16.8584 18.6058C17.2489 18.9963 17.8821 18.9963 18.2726 18.6058C18.6631 18.2153 18.6631 17.5821 18.2726 17.1916L12.98 11.899L18.2728 6.60619Z"
          fill="url(#paint0_linear_9255_3591)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9255_3591"
          x1="3.55481"
          y1="17.3021"
          x2="19.5763"
          y2="6.49549"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0073E5" />
          <stop offset="1" stopColor="#44A1FF" />
        </linearGradient>
        <clipPath id="clip0_9255_3591">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default CrossIcon;
