import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { WaeButton as Button } from '../../components/Button/Button';
import { buildFormField } from '../../components/Form/formFieldFactory';
import theme from '../../theme/theme';

import { NotificationSettingsData } from './FormData/NotificationSettingsData';
import { sectionHeaderStyling } from './styling';

const NotificationSettingForm = ({ handleOnSubmitNotificationSetting, user }) => (
  <RFForm
    onSubmit={handleOnSubmitNotificationSetting}
    initialValues={{
      notificationSettingsPhoneValue: get(['candidate', 'phoneNumber'], user),
    }}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} id="notificationFormID">
        <Typography
          sx={{
            ...sectionHeaderStyling,
            margin: theme.spacing(4, 0, 4, 0),
          }}
        >
          Notification Settings
        </Typography>
        <Grid container spacing={2.5} direction="column">
          <Grid item>{buildFormField(NotificationSettingsData.phone)}</Grid>
        </Grid>

        <Button
          sx={{ margin: theme.spacing(4, 0, 0, 0), padding: theme.spacing(1, 3, 1, 3) }}
          type="submit"
        >
          Update Notification Settings
        </Button>
      </form>
    )}
  />
);

NotificationSettingForm.propTypes = {
  handleOnSubmitNotificationSetting: PropTypes.func,
  user: PropTypes.shape({}),
};

export default NotificationSettingForm;
