import { batch } from 'react-redux';

import { showSnackbar } from '../main/views/app';
import { logout } from '../main/views/app/reducer';

// eslint-disable-next-line import/prefer-default-export
export const logOutOn401 = (dispatch) => {
  batch(() => {
    dispatch(logout());
    dispatch(
      showSnackbar({
        message: 'Session expired, please login again!',
      })
    );
  });
};
