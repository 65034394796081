import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import WhiteTrashIcon from '../../../../assets/icons/WhiteTrashIcon';
import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import { JOB_CATEGORY, JOB_STATUS } from '../../../../constants/jobInformation';
import theme from '../../../../theme';
import CancelJobDrawer from '../detail/SubComponents/CancelJobDrawer';
import { handleLabelProps } from '../helpers';
import { cancelJob, cancelShiftGroup } from '../reducer';

const detailViewTypographyHeader = ['components', 'detailView', 'typography', 'header'];
const detailViewTypographySemiBoldHeader = [
  'components',
  'detailView',
  'typography',
  'semiBoldHeader',
];
const detailViewTypographySemiBoldText = ['components', 'detailView', 'typography', 'semiBoldText'];
const detailViewTypographyGeneral = ['components', 'detailView', 'typography', 'general'];
const detailViewTypographySubSectionHeader = [
  'components',
  'detailView',
  'typography',
  'subSectionHeader',
];
const detailViewTypographySectionHeader = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];

const styleForJobName = {
  fontSize: get([...detailViewTypographyHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographyHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographyHeader, 'fontColor'], theme),
};

const styleForCorporationName = {
  fontSize: get([...detailViewTypographySemiBoldHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySemiBoldHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographySemiBoldHeader, 'fontColor'], theme),
};

const styleForDescription = {
  margin: '16px 0px',
  fontSize: get([...detailViewTypographyGeneral, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographyGeneral, 'fontFamily'], theme),
  color: get([...detailViewTypographyGeneral, 'fontColor'], theme),
};

const styleForLocationTitle = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
};

const styleForLocationDetails = {
  margin: '16px 0px 32px 0px',
  fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
  color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
};

const styleForDurationTitle = {
  fontSize: get([...detailViewTypographySectionHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySectionHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographySectionHeader, 'fontColor'], theme),
};

const styleForDateTitle = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
};

const Overview = ({ initialValues, user, jobType }) => {
  const dispatch = useDispatch();
  const [isCancelJobDrawerOpen, setIsCancelJobDrawerOpen] = useState(false);
  const { jobName, description, corporationName, startDate, endDate, location, joborders } =
    initialValues;
  const upcomingCount = get('upcoming', joborders);
  const groupOrderStatus = (currentJobOrder) => {
    const cancelledJobs = get('cancelled', currentJobOrder);
    const totalJobs = get('total', currentJobOrder);
    const ongoingCount = get('ongoing', currentJobOrder);
    if (cancelledJobs === totalJobs || cancelledJobs === upcomingCount) {
      return JOB_STATUS.cancelled;
    }
    if (upcomingCount === 0 && ongoingCount === 0) {
      return JOB_STATUS.completed;
    }
    return JOB_STATUS.active;
  };
  const handelCancelJob = (jobInfo) => {
    if (jobType === JOB_CATEGORY.longTermJob) {
      dispatch(cancelShiftGroup(jobInfo));
    }
    dispatch(cancelJob(jobInfo));
  };

  return (
    <Box>
      <Box sx={{ color: 'white', display: 'flex' }}>
        <Label {...handleLabelProps(groupOrderStatus(joborders))} />
      </Box>
      <Box sx={styleForJobName}>{jobName}</Box>
      <Box sx={styleForCorporationName}>{corporationName}</Box>
      <Box sx={styleForDescription}>{description}</Box>
      <Box sx={styleForLocationTitle}>Job Location</Box>
      <Box sx={styleForLocationDetails}>
        {location
          ? `${location.address},${location.city},${location.state},${location.zipcode}`
          : ''}
      </Box>
      <Box
        color="white"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '276px',
          width: '100%',
        }}
      >
        <Box sx={styleForDurationTitle}>Order Duration</Box>
        <Box
          sx={{
            marginTop: '12px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography sx={styleForDateTitle}>Start Date</Typography>
            <Typography>{startDate} </Typography>
          </Box>
          <Box>
            <Typography sx={styleForDateTitle}>End Date</Typography>
            {endDate}
          </Box>
        </Box>
      </Box>

      {groupOrderStatus(joborders) === JOB_STATUS.active && (
        <Box
          onClick={() => {
            setIsCancelJobDrawerOpen(true);
          }}
        >
          <Button text="Cancel Job Order" type="delete" endIcon={<WhiteTrashIcon />} />
        </Box>
      )}
      <CancelJobDrawer
        jobType={JOB_CATEGORY.longTermJob}
        type="outlined"
        isOpen={isCancelJobDrawerOpen}
        onClose={() => setIsCancelJobDrawerOpen(false)}
        selectedJob={initialValues}
        user={user}
        handleCancelJob={handelCancelJob}
        upcomingCount={upcomingCount}
      />
    </Box>
  );
};

Overview.propTypes = {
  user: PropTypes.shape({}),
  jobType: PropTypes.string,
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    jobName: PropTypes.string,
    corporationName: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    joborders: PropTypes.shape({}),
    location: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipcode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),

    corporation: PropTypes.shape({}),
    numOpenings: PropTypes.number,
  }),
};

export default Overview;
