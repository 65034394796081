import React, { useCallback, useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash/fp';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import asyncServiceWithFetch from '../../../datahub/asyncServiceWithFetch';
import AlertOffIcon from '../../assets/icons/AlertOff.svg';
import WAELogo from '../../assets/icons/LargeLogo.svg';
import NotificationOnIcon from '../../assets/icons/NotificationOn.svg';
import selectUser from '../../store/selectors/appSelector';
import {
  selectHasUnreadNotifications,
  selectNotifications,
} from '../../store/selectors/notificationSelectors';
import theme, { navigationBarHeight } from '../../theme';
import launchDarklyToggles from '../../utils/launchDarklyToggles';
import NotificationCenter from '../NotificationCenter/NotificationCenter';
import { getNotifications } from '../NotificationCenter/reducer';

import ProfileMenu from './SubComponents/ProfileMenu';
import stringAvatar from './SubComponents/stringAvatar';

const NavigationBar = ({ flags }) => {
  const notificationToggle = launchDarklyToggles(flags, 'isNotificationCenterEnabled');
  const dispatch = useDispatch();
  const hasUnreadNotifications = useSelector(selectHasUnreadNotifications);
  const notifications = useSelector(selectNotifications);
  const user = useSelector(selectUser);
  const token = get('token', user);

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(
      getNotifications({
        page: 0,
        limit: 20,
        filters: [
          {
            operation: 'equals',
            field: 'flags.deleted',
            value: false,
          },
          { operation: 'equalsID', field: 'receiver', value: user.id },
        ],
      })
    );
  }, [dispatch, user]);

  const closeNotification = () => {
    setNotificationOpen(false);
  };
  const openNotification = () => {
    setNotificationOpen(true);
  };
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  const handleProfilePictureClick = (e) => {
    setAnchorEl(e.currentTarget);
    setProfileMenuOpen(!profileMenuOpen);
  };
  const handleProfileMenuClose = () => {
    setProfileMenuOpen(false);
  };

  const backendDeleteNotifications = useCallback(() => {
    const allFrontendSoftDeletedNotificationIds = flow(
      filter((n) => n.frontendSoftDeleted),
      map((n) => n._id)
    )(notifications);

    if (allFrontendSoftDeletedNotificationIds && allFrontendSoftDeletedNotificationIds.length > 0) {
      asyncServiceWithFetch({
        token,
        route: 'notifications/mark-deleted',
        httpMethod: 'PATCH',
        data: {
          fields: {
            notifications: allFrontendSoftDeletedNotificationIds,
            deleted: true,
          },
        },
        onSuccess: () => {},
        onError: () => {},
        dispatch,
      });
    }
  }, [dispatch, token, notifications]);

  useEffect(() => {
    window.addEventListener('beforeunload', backendDeleteNotifications);
    return () => {
      window.removeEventListener('beforeunload', backendDeleteNotifications);
    };
  }, [backendDeleteNotifications]);

  return (
    <Box
      sx={{
        height: navigationBarHeight,
        borderBottom: `1px solid ${theme.components.nav.borderBottom.color}`,
        backgroundColor: theme.palette.background.paper,
        position: 'fixed',
        width: '100%',
        display: 'flex',
        zIndex: '1500',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 4, 1, 4),
      }}
    >
      <NotificationCenter openNotifications={notificationOpen} onClose={closeNotification} />
      <ProfileMenu
        anchorEl={anchorEl}
        open={profileMenuOpen}
        handleProfileMenuClose={handleProfileMenuClose}
        user={user}
        backendDeleteNotifications={backendDeleteNotifications}
      />
      <Box component="img" alt="WAE logo" sx={{ width: '88px', height: '26px' }} src={WAELogo} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {notificationToggle && (
          <Box
            component="img"
            sx={{ height: '80%', marginRight: theme.spacing(2) }}
            alt="alertOn"
            src={hasUnreadNotifications ? NotificationOnIcon : AlertOffIcon}
            onClick={openNotification}
          />
        )}

        <Avatar {...stringAvatar(user)} onClick={handleProfilePictureClick} />
      </Box>
    </Box>
  );
};

NavigationBar.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(NavigationBar);
