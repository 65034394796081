import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import omit from 'lodash/fp/omit';

import { createSlice } from '@reduxjs/toolkit';

import { epochToShortDateString, epochToTimeInReadableFormat } from '../../../utils';

export const reformatJobEntry = (jobEntry) => {
  const rest = omit(['_id'], jobEntry);
  return {
    id: get('_id', jobEntry),
    title: get('name', jobEntry),
    dayJobInfo: {
      name: get('name', jobEntry),
      corporation: get(['corporation', 'name'], jobEntry),
    },
    longTermJobInfo: {
      address: get(['location', 'address'], jobEntry),
      description: get(['location', 'description'], jobEntry),
      name: get('jobName', jobEntry),
      corporation: get('corporationName', jobEntry),
    },
    jobPlacements: get('placementsCount', jobEntry),
    address: get(['location', 'address'], jobEntry),
    startDate: epochToShortDateString(get('start', jobEntry)),
    startTime: epochToTimeInReadableFormat(get('start', jobEntry)),
    endDate: epochToShortDateString(get('end', jobEntry)),
    endTime: epochToTimeInReadableFormat(get('end', jobEntry)),
    fillRate: Math.floor((100 * get('placementsCount', jobEntry)) / get('numOpenings', jobEntry)),
    ...rest,
  };
};

const reformatJobPlacements = (jobPlacementsResp) => ({
  corporationId: jobPlacementsResp.jobOrder.corporation,
  employees: map(
    (employee) => ({
      id: employee.candidate,
      ...employee,
    }),
    jobPlacementsResp.placements
  ),
});

const initialState = {
  corporations: [],
  jobListRowData: [],
  jobRoster: {
    employees: [],
  },

  totalRowCount: 0,
  isLoading: false,
  isCreateJobsDialogOpen: false,
  isCancelProcessing: false,
  isUpdateProcessing: false,
  isLoadingCorporations: false,
  isLoadingJobRoster: false,
  isPostingJobRoster: false,
  isLoadingPathSelectedJob: false,
  users: [],
  positionTemplateData: [],
  isLoadingPt: false,
  groupOrders: [],
  isLoadingGroupOrders: false,
  isProcessingPutGroupOrders: false,
  isCancelPlacementProcessing: false,
  ShiftGroupList: [],
  isLoadingShiftGroupList: false,
  errorPutGroupOrders: null,
  selectedJob: null,
};
export const jobListSlice = createSlice({
  name: 'jobListSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    getJobsList: (state) => {
      state.isLoading = true;
    },
    getJobsListProcessed: (state, action) => {
      state.isLoading = false;
      const { count, documents } = action.payload.data;
      const updatedData = documents.map((jobEntry) => reformatJobEntry(jobEntry));
      state.jobListRowData = updatedData;
      state.totalRowCount = count;
    },

    getJobsListError: (state) => {
      state.isLoading = false;
      state.jobListRowData = [];
    },
    setSelectedJob: (state, action) => {
      state.selectedJob = action.payload;
    },
    getPathSelectedJob: (state) => {
      state.isLoadingPathSelectedJob = true;
    },
    getPathSelectedJobProcessed: (state, action) => {
      state.isLoadingPathSelectedJob = false;
      const { documents } = action.payload.data;
      const updatedData = reformatJobEntry(documents[0]);
      state.jobListRowData.unshift(updatedData);
      state.selectedJob = updatedData;
    },

    getPathSelectedJobError: (state) => {
      state.isLoadingPathSelectedJob = false;
    },
    cancelJob: (state) => {
      state.isCancelProcessing = true;
    },
    cancelJobProcessed: (state, action) => {
      const { document } = action.payload.data;
      const updatedJob = reformatJobEntry(document);
      state.jobListRowData = state.jobListRowData.map((job) =>
        job.id === updatedJob.id ? { ...updatedJob } : job
      );
      state.isCancelProcessing = false;
    },
    cancelJobError: (state) => {
      state.isCancelProcessing = false;
    },
    cancelShiftGroup: (state) => {
      state.isCancelProcessing = true;
    },
    cancelShiftGroupProcessed: (state, action) => {
      const { document } = action.payload.data;
      const updatedJob = reformatJobEntry(document);
      state.jobListRowData = state.jobListRowData.map((job) =>
        job.id === updatedJob.id ? { ...updatedJob } : job
      );
      state.isCancelProcessing = false;
    },
    cancelShiftGroupError: (state) => {
      state.isCancelProcessing = false;
    },
    updateJob: (state) => {
      state.isUpdateProcessing = true;
    },
    updateJobProcessed: (state, action) => {
      const { document } = action.payload.data;
      const updatedJob = reformatJobEntry(document);
      state.jobListRowData = state.jobListRowData.map((job) =>
        job.id === updatedJob.id ? { ...updatedJob } : job
      );
      state.isUpdateProcessing = false;
    },

    updateJobError: (state) => {
      state.isUpdateProcessing = false;
    },
    getCorporationsForJobsList: (state) => {
      state.isLoadingCorporations = true;
    },
    getCorporationsForJobsListProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);
      const corporations = map((corporation) => {
        const { _id, ...rest } = corporation;
        return {
          id: _id,
          ...rest,
        };
      }, documents);
      state.corporations = corporations;
      state.isLoadingCorporations = false;
    },
    getCorporationsForJobsListError: (state) => {
      state.isLoadingCorporations = false;
    },
    getJobRoster: (state) => {
      state.isLoadingJobRoster = true;
    },
    getJobRosterProcessed: (state, action) => {
      state.isLoadingJobRoster = false;
      const jobRosterResp = get(['payload', 'data', 'document'], action);
      if (jobRosterResp && jobRosterResp.placements && jobRosterResp.placements.length > 0) {
        const jobPlacements = reformatJobPlacements(jobRosterResp);
        state.jobRoster = jobPlacements;
      } else {
        state.jobRoster = {};
      }
    },
    getJobRosterError: (state) => {
      state.isLoadingJobRoster = false;
    },

    getJobInvitations: (state) => {
      state.isLoadingJobInvitations = true;
    },
    getJobInvitationsProcessed: (state, action) => {
      state.isLoadingJobInvitations = false;
      const jobInvitationsResp = get(['payload', 'data', 'documents'], action);
      if (jobInvitationsResp && jobInvitationsResp.length > 0) {
        const jobInvitations = map(
          (invite) => ({
            candidateName: get(['candidate', 'name'], invite),
            candidateId: get(['candidate', '_id'], invite),
            invitationDate: get(['metadata', 'created'], invite),
            placementId: get('_id', invite),
          }),
          jobInvitationsResp
        );

        state.jobInvitations = jobInvitations;
      } else {
        state.jobInvitations = {};
      }
    },
    getJobInvitationsError: (state) => {
      state.isLoadingJobInvitations = false;
    },

    revokeJobInvitations: (state) => {
      state.isLoadingRevokeJobInvitations = true;
    },
    revokeJJobInvitationsProcessed: (state) => {
      state.isLoadingRevokeJobInvitations = false;
      // const jobRevokeInvitationsResp = get(['payload', 'data', 'documents'], action);
    },
    revokeJJobInvitationsError: (state) => {
      state.isLoadingRevokeJobInvitations = false;
    },

    getJobApplications: (state) => {
      state.isLoadingJobApplications = true;
    },
    getJobApplicationsProcessed: (state, action) => {
      state.isLoadingJobApplications = false;
      const jobApplicationsResp = get(['payload', 'data', 'documents'], action);

      // TODO:process JobApplications Data from here
      state.jobApplications = jobApplicationsResp;
    },
    getJobApplicationsError: (state) => {
      state.isLoadingJobApplications = false;
    },
    getCancelledCandidates: (state) => {
      state.isLoadingCancelledCandidates = true;
    },
    getCancelledCandidatesProcessed: (state, action) => {
      state.isLoadingCancelledCandidates = false;
      const cancelldCandidatesResp = get(['payload', 'data', 'documents'], action);

      const cancelledCandidates = map(
        (candidate) => ({
          candidateName: get(['candidate', 'name'], candidate),
          candidateId: get(['candidate', '_id'], candidate),
        }),
        cancelldCandidatesResp
      );

      state.cancelledCandidates = cancelledCandidates;
    },
    getCancelledCandidatesError: (state) => {
      state.isLoadingCancelledCandidates = false;
    },

    updateJobRoster: (state) => {
      state.isPostingJobRoster = true;
    },
    updateJobRosterProcessed: (state, action) => {
      state.isPostingJobRoster = false;
      const jobRosterResp = get(['payload', 'data', 'document'], action);
      if (jobRosterResp) {
        const updatedData = {
          attendance: get('attendance', jobRosterResp),
          state: get('state', jobRosterResp),
          candidate: get('candidate', jobRosterResp),
          employerNote: get('employerNote', jobRosterResp),
          rating: get('rating', jobRosterResp),
        };
        state.jobRoster.employees = state.jobRoster.employees.map(
          (emp) => (emp.id === updatedData.candidate.id && { ...emp, ...updatedData }) || emp
        );
      }
    },
    updateJobRosterError: (state) => {
      state.isPostingJobRoster = false;
    },

    getUsers: (state) => {
      state.isLoading = true;
    },

    getUsersProcessed: (state, action) => {
      state.isLoading = false;
      const documents = get(['payload', 'data', 'documents'], action);
      const mappedUsers = map((user) => {
        const { id, name, role, metadata, email } = user;
        return {
          id,
          name,
          role,
          externalId: get(['bullhorn', 'id'], metadata),
          email,
        };
      }, documents);

      const filteredUsers = mappedUsers.filter(
        (user) => user.email && user.name && user.role === 'candidate'
      );
      state.users = filteredUsers;
    },
    getUsersError: (state) => {
      state.isLoading = false;
    },

    updatePlacement: (state) => {
      state.isUpdateProcessing = true;
    },
    updatePlacementProcessed: (state) => {
      state.isUpdateProcessing = false;
    },

    updatePlacementError: (state) => {
      state.isUpdateProcessing = false;
    },

    cancelPlacement: (state) => {
      state.isCancelPlacementProcessing = true;
    },
    cancelPlacementProcessed: (state, action) => {
      state.isCancelPlacementProcessing = false;
      const cancelPlacementResp = get(['payload', 'data', 'document'], action);
      const updatedResp = {
        id: get('candidate', cancelPlacementResp),
        _id: get('_id', cancelPlacementResp),
        candidate: get('candidate', cancelPlacementResp),
        state: get('state', cancelPlacementResp),
      };
      if (cancelPlacementResp) {
        state.jobRoster.employees = state.jobRoster.employees.map(
          (emp) => (emp.id === cancelPlacementResp.candidate && { ...emp, ...updatedResp }) || emp
        );
        const newJobList = state.jobListRowData.map((jobOrder) => {
          if (jobOrder.id === cancelPlacementResp.jobOrder.id) {
            jobOrder.placementsCount -= 1;
          }
          return jobOrder;
        });
        state.jobListRowData = newJobList;
      }
    },

    cancelPlacementError: (state) => {
      state.isCancelPlacementProcessing = false;
    },

    getPositionTemplate: (state) => {
      state.isLoadingPt = true;
    },
    getPositionTemplateProcessed: (state, action) => {
      state.isLoadingPt = false;
      state.positionTemplateData = get(['payload', 'data', 'documents'], action);
    },
    getPositionTemplateError: (state) => {
      state.isLoadingPt = false;
    },
    /* eslint-disable no-param-reassign */
    getGroupOrders: (state) => {
      state.isLoadingGroupOrders = true;
    },
    getGroupOrdersProcessed: (state, action) => {
      state.isLoadingGroupOrders = false;
      const { count, documents } = action.payload.data;
      const updatedData = documents.map((jobEntry) => reformatJobEntry(jobEntry));
      state.totalRowCount = count;
      state.groupOrders = updatedData;
    },
    getGroupOrdersError: (state) => {
      state.isLoadingGroupOrders = false;
    },

    getShiftGroupList: (state) => {
      state.isLoadingShiftGroupList = true;
    },
    getShiftGroupListProcessed: (state, action) => {
      state.isLoadingShiftGroupList = false;
      const { count, documents } = action.payload.data;
      const updatedData = documents.map((jobEntry) => reformatJobEntry(jobEntry));
      state.shiftGroupList = updatedData;
      state.shiftGroupListCount = count;
    },
    getShiftGroupListError: (state) => {
      state.iisLoadingShiftGroupList = false;
    },
    putGroupOrders: (state) => {
      state.isProcessingPutGroupOrders = true;
    },
    putGroupOrdersProcessed: (state) => {
      state.isProcessingPutGroupOrders = false;
    },
    putGroupOrdersError: (state, action) => {
      state.isProcessingPutGroupOrders = false;
      const messageData =
        get(['payload', 'GLOBAL', 'messageKey'], action) || 'error.generic.something.went.wrong';
      state.errorPutGroupOrders = messageData;
    },
    clearPutGroupOrdersError: (state) => {
      state.errorPutGroupOrders = null;
    },
    setIsCreateJobsDialogOpen: (state, action) => {
      state.isCreateJobsDialogOpen = action.payload;
    },
  },
});

export const jobListReducer = jobListSlice.reducer;

export const {
  setSelectedJob,
  getJobsList,
  getJobsListError,
  getJobsListProcessed,
  getPathSelectedJob,
  getPathSelectedJobError,
  getPathSelectedJobProcessed,
  cancelJob,
  cancelJobError,
  cancelJobProcessed,
  cancelShiftGroup,
  cancelShiftGroupError,
  cancelShiftGroupProcessed,
  updateJob,
  updateJobProcessed,
  updateJobError,
  getCorporationsForJobsList,
  getCorporationsForJobsListProcessed,
  getCorporationsForJobsListError,
  getJobRoster,
  getJobRosterProcessed,
  getJobRosterError,
  getJobInvitations,
  getJobInvitationsError,
  getJobInvitationsProcessed,
  revokeJobInvitations,
  revokeJobInvitationsProcessed,
  revokeJobInvitationsError,
  getJobApplications,
  getJobApplicationsProcessed,
  getJobApplicationsError,
  getCancelledCandidates,
  getCancelledCandidatesProcessed,
  getCancelledCandidatesError,
  updateJobRoster,
  updateJobRosterProcessed,
  updateJobRosterError,
  getUsers,
  getUsersProcessed,
  getUsersError,
  getGroupOrders,
  getGroupOrdersProcessed,
  getGroupOrdersError,
  updatePlacement,
  updatePlacementProcessed,
  updatePlacementError,
  cancelPlacement,
  cancelPlacementProcessed,
  cancelPlacementError,
  getPositionTemplate,
  getPositionTemplateProcessed,
  getPositionTemplateError,
  getShiftGroupList,
  getShiftGroupListProcessed,
  getShiftGroupListError,
  putGroupOrders,
  putGroupOrdersProcessed,
  putGroupOrdersError,
  clearPutGroupOrdersError,
  setIsCreateJobsDialogOpen,
} = jobListSlice.actions;
