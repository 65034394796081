import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

export const selectJobList = (state) => get('jobList', state);

export const selectIsLoading = createSelector(selectJobList, (jobList) => jobList.isLoading);

export const selectJobListRowData = createSelector(
  selectJobList,
  (jobList) => jobList.jobListRowData
);
export const selectTotalRowCount = createSelector(
  selectJobList,

  (jobList) => jobList.totalRowCount
);

export const selectCorporations = createSelector(selectJobList, (jobList) => jobList.corporations);

export const selectIsLoadingCorporations = createSelector(
  selectJobList,
  (jobList) => jobList.isLoadingCorporations
);

export const selectIsCancelProcessing = createSelector(
  selectJobList,
  (jobList) => jobList.isCancelProcessing
);
export const selectIsLoadingPathSelectedJob = createSelector(
  selectJobList,
  (jobList) => jobList.isLoadingPathSelectedJob
);
export const selectSelectedJob = createSelector(selectJobList, (jobList) => jobList.selectedJob);

export const selectInvitations = createSelector(selectJobList, (jobList) => jobList.jobInvitations);

export const selectApplications = createSelector(
  selectJobList,
  (jobList) => jobList.jobApplications
);

export const selectCancelledCandidates = createSelector(
  selectJobList,
  (jobList) => jobList.cancelledCandidates
);

export const selectIsUpdateProcessing = createSelector(
  selectJobList,
  (jobList) => jobList.isUpdateProcessing
);
export const selectIsLoadingJobRoster = createSelector(
  selectJobList,
  (jobList) => jobList.isLoadingJobRoster
);

export const selectJobRoster = createSelector(selectJobList, (jobList) => jobList.jobRoster);

export const selectUsers = createSelector(selectJobList, (jobList) => jobList.users);

export const selectPositionTemplateData = createSelector(
  selectJobList,
  (jobList) => jobList.positionTemplateData
);
export const selectIsLoadingPt = createSelector(selectJobList, (jobList) => jobList.isLoadingPt);

export const selectGroupOrders = createSelector(selectJobList, (jobList) => jobList.groupOrders);

export const selectIsLoadingGroupOrders = createSelector(
  selectJobList,
  (jobList) => jobList.isLoadingGroupOrders
);

export const selectShiftGroupList = createSelector(
  selectJobList,
  (jobList) => jobList.shiftGroupList
);
export const selectIsLoadingShiftGroupList = createSelector(
  selectJobList,
  (jobList) => jobList.isLoadingShiftGroupList
);

export const selectIsProcessingPutGroupOrders = createSelector(
  selectJobList,
  (jobList) => jobList.isProcessingPutGroupOrders
);
export const selectErrorPutGroupOrders = createSelector(
  selectJobList,
  (jobList) => jobList.errorPutGroupOrders
);
export const selectIsCreateJobsDialogOpen = createSelector(
  selectJobList,
  (jobList) => jobList.isCreateJobsDialogOpen
);
