import { get } from 'lodash/fp';
import numeral from 'numeral';

import { minutesFromMidnightToTime } from './timeConverter';

export const dateToYearMonthDayMinutes = (date, minutes) => {
  const newObject = {
    year: date.getYear() + 1900,
    day: date.getDate(),
    month: date.getMonth() + 1,
    minutes,
  };
  return newObject;
};

export const formatCurrency = (value) =>
  value === undefined ? '' : numeral(value).format('$0,0.00');

export const nameAndTimeToReadable = (name, start, end) => {
  const readable = `${name} (${minutesFromMidnightToTime(start).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })} - ${minutesFromMidnightToTime(end).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })})`;

  return readable;
};

// capitalize the first letter of a string
export const setFirstLetterOfStringToCapital = (word) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();

export const searchParamSelectIdHandler = ({
  isLoading,
  isLoadingSelectedItem,
  pathId,
  listData,
  pageSize,
  selectedItem,
  onSelection,
  getData,
}) => {
  // Don't do anything in between page loads or there is not a path job
  if (
    isLoading ||
    isLoadingSelectedItem ||
    !pathId ||
    pathId === 'undefined' ||
    listData.length + 1 !== pageSize + 1 ||
    get('id', selectedItem) === pathId
  ) {
    return;
  }

  const match = listData.filter((row) => row.id === pathId);
  if (match.length > 0) {
    onSelection(match[0]);
    return;
  }

  // Get the data
  getData({ filters: [{ field: '_id', value: pathId, operation: 'equalsID' }] });
};

export const isSearchParamValid = (value) =>
  value && value !== 'null' && value !== 'undefined' && value !== 'false' && value !== 'NaN';
