import { get } from 'lodash/fp';

import { createSlice } from '@reduxjs/toolkit';

import { TIMEKEEPING_STATUSES } from './constants';
import { generateStatusData } from './helpers';

const updateTotals = (totals, newVal) => ({
  totalHours: totals.totalHours + get(['timecard', 'totalHours'], newVal),
  totalEarnings: totals.totalEarnings + get(['timecard', 'totalEarnings'], newVal),
  overtimeHours: totals.overtimeHours + get(['timecard', 'overtimeHours'], newVal),
  regularHours: totals.regularHours + get(['timecard', 'regularHours'], newVal),
});

const updateTimeCardStatus = (timecards, placements, status) =>
  timecards.map((card) => {
    let updated = {};
    Object.keys(get('days', card)).forEach((key) => {
      updated = {
        ...updated,
        ...{
          [key]: [
            ...get('days', card)[key].map((item) =>
              placements.find((placement) => placement === get(['placement'], item))
                ? { ...item, timecard: { ...item.timecard, status } }
                : item
            ),
          ],
        },
      };
    });

    return { ...card, days: updated };
  });

const setTimeCard = (timecards, newTimeCard) =>
  timecards.map((card) => {
    let updatedDays = {};
    let totals = {
      totalHours: 0,
      totalEarnings: 0,
      overtimeHours: 0,
      regularHours: 0,
    };
    Object.keys(get('days', card)).forEach((key) => {
      updatedDays = {
        ...updatedDays,
        ...{
          [key]: [
            ...get('days', card)[key].map((item) => {
              if (get(['placement'], newTimeCard) === get(['placement'], item)) {
                totals = updateTotals(totals, newTimeCard);
                return newTimeCard;
              }
              totals = updateTotals(totals, item);
              return item;
            }),
          ],
        },
      };
    });

    return {
      ...card,
      ...totals,
      days: updatedDays,
    };
  });

const setSelectedTimeCardOnUpdate = (timecards, selectedTimeCard) => {
  if (timecards && selectedTimeCard) {
    const filterTimecard = timecards.filter(
      (card) => get(['candidate', 'id'], card) === get(['candidate', 'id'], selectedTimeCard)
    );
    const newCard = filterTimecard[0];
    const updatedData = {
      ...newCard,
      ...generateStatusData(newCard, selectedTimeCard),
      id: get(['candidate', 'id'], newCard),
      overtimeHours: get('overtimeHours', newCard),
      regularHours: get('regularHours', newCard),
      totalHours: get('totalHours', newCard),
      employeeName: get(['candidate', 'name'], newCard),
    };

    return {
      ...filterTimecard[0],
      ...updatedData,
    };
  }
  return null;
};

const initialState = {
  timeCardJobOrder: {},
  timecards: [],
  timecardUpdateError: null,
  selectedTimeCard: null,
  updatedPlacement: [],
  timeCardIsUpdating: false,
  timecardFilterProps: {},
  timecardsIsProcessing: false,
  timecardActionIsProcessing: false,
  isProcessingBatchApproval: false,
  batchApprovalError: null,
};
export const timekeepingSlice = createSlice({
  name: 'timekeepingSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    getTimeCards: (state, action) => {
      state.timecards = [];
      state.timecardsIsProcessing = true;
      state.timecardFilterProps = action.payload;
    },
    getTimeCardsProcessed: (state, action) => {
      state.timecardsIsProcessing = false;
      const { count, documents } = action.payload.data;
      state.timeSheetGridTotalRowCount = count;
      state.timecards = documents;
    },
    getTimeCardsError: (state) => {
      state.timecardsIsProcessing = false;
    },

    getPathSelectedTimeCards: (state) => {
      state.timecardsIsProcessing = true;
    },
    getPathSelectedTimeCardsProcessed: (state, action) => {
      state.timecardsIsProcessing = false;
      const { documents } = action.payload.data;
      const doc = documents[0];

      if (
        doc &&
        state.timecards.length > 0 &&
        doc.candidate.id !== state.timecards[0].candidate.id
      ) {
        state.timecards.unshift(doc);
        state.selectedTimeCard = doc;
      }
    },
    getPathSelectedTimeCardsError: (state) => {
      state.timecardsIsProcessing = false;
    },

    updateTimeCard: (state) => {
      state.timeCardIsUpdating = true;
    },
    updateTimeCardProcessed: (state, action) => {
      const { placement } = get(['payload', 'data'], action);
      state.timecards = setTimeCard(state.timecards, placement);

      state.selectedTimeCard = setSelectedTimeCardOnUpdate(state.timecards, state.selectedTimeCard);
      state.timeCardIsUpdating = false;
    },
    updateTimeCardError: (state, action) => {
      state.timeCardIsUpdating = false;
      const messageData =
        get(['payload', 'GLOBAL', 'messageKey'], action) || 'error.generic.something.went.wrong';
      state.timecardUpdateError = messageData;
    },
    clearTimeCardError: (state) => {
      state.timecardUpdateError = null;
    },
    setSelectedTimeCard: (state, action) => {
      state.selectedTimeCard = action.payload;
    },
    postTimeCardApproval: (state) => {
      state.timecardActionIsProcessing = true;
    },
    postTimeCardApprovalProcessed: (state, action) => {
      state.timecardActionIsProcessing = false;
      const { placements } = get(['payload', 'data'], action);

      state.timecards = updateTimeCardStatus(
        state.timecards,
        placements,
        TIMEKEEPING_STATUSES.APPROVED
      );
      state.selectedTimeCard = setSelectedTimeCardOnUpdate(state.timecards, state.selectedTimeCard);
    },
    postTimeCardApprovalError: (state) => {
      state.timecardActionIsProcessing = false;
    },
    postTimeCardResolve: (state) => {
      state.timecardActionIsProcessing = true;
    },
    postTimeCardResolveProcessed: (state, action) => {
      state.timecardActionIsProcessing = false;
      const { placements } = get(['payload', 'data'], action);

      state.timecards = updateTimeCardStatus(
        state.timecards,
        placements,
        TIMEKEEPING_STATUSES.RESOLVED
      );
      state.selectedTimeCard = setSelectedTimeCardOnUpdate(state.timecards, state.selectedTimeCard);
    },
    postTimeCardResolveError: (state) => {
      state.timecardActionIsProcessing = false;
    },
    postTimeCardFinalize: (state) => {
      state.timecardActionIsProcessing = false;
    },
    postTimeCardFinalizeProcessed: (state, action) => {
      state.timecardActionIsProcessing = false;
      const { placements } = get(['payload', 'data'], action);
      state.timecards = updateTimeCardStatus(
        state.timecards,
        placements,
        TIMEKEEPING_STATUSES.FINALIZED
      );
      state.selectedTimeCard = setSelectedTimeCardOnUpdate(state.timecards, state.selectedTimeCard);
    },
    postTimeCardFinalizeError: (state) => {
      state.timecardActionIsProcessing = false;
    },
    postTimeCardBatchApproval: (state) => {
      state.isProcessingBatchApproval = true;
    },
    postTimeCardBatchApprovalProcessed: (state) => {
      state.isProcessingBatchApproval = false;
    },
    postTimeCardBatchApprovalError: (state, action) => {
      state.isProcessingBatchApproval = false;
      const messageData =
        get(['payload', 'GLOBAL', 'messageKey'], action) || 'error.generic.something.went.wrong';
      state.batchApprovalError = messageData;
    },
    clearBatchApprovalError: (state) => {
      state.batchApprovalError = null;
    },
  },
});

export const timekeepingReducer = timekeepingSlice.reducer;

export const {
  getCorporationsForTimekeepingProcessed,
  getCorporationsForTimekeepingError,
  getTimeCards,
  getTimeCardsProcessed,
  getTimeCardsError,
  getPathSelectedTimeCards,
  getPathSelectedTimeCardsProcessed,
  getPathSelectedTimeCardsError,
  updateTimeCard,
  updateTimeCardProcessed,
  updateTimeCardError,
  clearTimeCardError,
  setSelectedTimeCard,
  postTimeCardApproval,
  postTimeCardApprovalError,
  postTimeCardApprovalProcessed,
  postTimeCardResolve,
  postTimeCardResolveProcessed,
  postTimeCardResolveError,
  postTimeCardFinalize,
  postTimeCardFinalizeProcessed,
  postTimeCardFinalizeError,
  postTimeCardBatchApproval,
  postTimeCardBatchApprovalProcessed,
  postTimeCardBatchApprovalError,
  clearBatchApprovalError,
} = timekeepingSlice.actions;
