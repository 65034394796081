import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash/fp';
import findKey from 'lodash/fp/findKey';
import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box, ListItem } from '@mui/material';

import asyncService from '../../../../../../datahub/asyncService';
import WaeButton from '../../../../../components/Button';
import Attendance from '../../../../../constants/attendance';
import { JOB_CATEGORY } from '../../../../../constants/jobInformation';
import selectUser from '../../../../../store/selectors/appSelector';
import theme from '../../../../../theme';
import launchDarklyToggles from '../../../../../utils/launchDarklyToggles';
import NoRosterCard from '../../detail/NoRosterCard';
import RosterCard from '../../detail/RosterCard';
import AddEmployeeDrawer from '../../detail/SubComponents/AddEmployeeDrawer';
import ExportRosterToCSV from '../../detail/SubComponents/ExportRosterToCsv';
import { cancelPlacement, updateJobRoster, updatePlacement } from '../../reducer';

const detailViewTypographySectionHeader = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];

const detailHeaderStyle = {
  color: get([...detailViewTypographySectionHeader, 'fontColor'], theme),
  fontSize: get([...detailViewTypographySectionHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySectionHeader, 'fontFamily'], theme),
  margin: '10px 0',
};

const reformatJobPlacements = (jobPlacementsResp) => ({
  corporationId: jobPlacementsResp.jobOrder.corporation,
  employees: map(
    (p) => ({
      placementId: p._id,
      empId: p.candidate,
      ...p,
    }),
    jobPlacementsResp.placements
  ),
});

const Roster = ({ initialValues, candidates, flags }) => {
  const user = useSelector(selectUser);
  const token = get('token', user);
  const dispatch = useDispatch();
  const [isAddEmployerDrawerOpen, setIsAddEmployerDrawerOpen] = useState(false);
  const [disableAddEmployeeButton, setDisableAddEmployeeButton] = useState(false);
  const { corporation, cancelled, id } = initialValues;
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const isRecruiterForbiddenToCreateCancelPlacements = launchDarklyToggles(
    flags,
    'isRecruiterForbiddenToCreateCancelPlacements'
  );

  useEffect(() => {
    let isUnmount = false;
    if (cancelled || (user.role === 'recruiter' && isRecruiterForbiddenToCreateCancelPlacements)) {
      setDisableAddEmployeeButton(true);
    }
    asyncService({
      httpMethod: 'POST',
      route: 'placements/read-for-job-order',
      data: { jobOrder: id },
      onSuccess: (data) => {
        if (!isUnmount) {
          const jobRosterResp = get(['data', 'document'], data);
          if (jobRosterResp && jobRosterResp.placements && jobRosterResp.placements.length > 0) {
            const employees = get('employees', reformatJobPlacements(jobRosterResp)) || [];
            setFilteredEmployees(
              employees.filter((employee) => get(['state', 'status'], employee) === 'active')
            );
          } else {
            setFilteredEmployees([]);
          }
        }
      },
      onError: () => {},
      token,
    });
    return () => {
      isUnmount = true;
    };
  }, [token, id, cancelled, user.role, isRecruiterForbiddenToCreateCancelPlacements]);

  const onAddEmployeesButtonClick = () => {
    setIsAddEmployerDrawerOpen(true);
  };

  const handleOnSave = (employeeData) => {
    dispatch(updateJobRoster(employeeData));
  };

  const handleCancelPlacement = (placementId) => {
    dispatch(cancelPlacement({ id: placementId }));
  };

  const handleAddEmployees = (employee) => {
    dispatch(updatePlacement(employee));
    setIsAddEmployerDrawerOpen(true);
  };

  return (
    (id && (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ ...detailHeaderStyle, display: 'flex', justifyContent: 'space-between' }}>
          {filteredEmployees.length > 0 && (
            <Box>
              <ExportRosterToCSV corporation={corporation} roster={filteredEmployees} />
              <WaeButton
                disabled={disableAddEmployeeButton}
                sx={{ marginLeft: '6px' }}
                onClick={onAddEmployeesButtonClick}
                text="Add Employees +"
              />
            </Box>
          )}
          <AddEmployeeDrawer
            isOpen={isAddEmployerDrawerOpen}
            onClose={() => setIsAddEmployerDrawerOpen(false)}
            handleAddEmployees={handleAddEmployees}
            candidates={candidates}
            jobOrderId={id}
            corporation={corporation}
          />
        </Box>
        {(filteredEmployees.length > 0 &&
          filteredEmployees.map((emp) => {
            const favorite = corporation.favorite.find((f) => f.waeID === emp.empId) || false;
            const attendanceObj = {
              id: findKey((val) => val === emp.attendance, Attendance),
              label: emp.attendance || '',
            };
            const fav = !!favorite;
            const rating = get('rating', emp);
            const candidateName = get('name', emp);
            const employerNote = get('employerNote', emp);
            const empId = get('empId', emp);
            const placementId = get('placementId', emp);

            return (
              <ListItem key={placementId} sx={{ padding: '0' }}>
                <RosterCard
                  accordionType={JOB_CATEGORY.shift}
                  fav={fav}
                  originalRating={rating}
                  name={candidateName}
                  originalEmployerNote={employerNote}
                  attendanceObj={attendanceObj}
                  empId={empId}
                  handleOnSave={handleOnSave}
                  placementId={placementId}
                  handleCancelPlacement={handleCancelPlacement}
                  isRecruiterPlacementBlocked={isRecruiterForbiddenToCreateCancelPlacements}
                  currUserRole={user.role}
                />
              </ListItem>
            );
          })) || (
          <NoRosterCard
            isRecruiterPlacementBlocked={isRecruiterForbiddenToCreateCancelPlacements}
            currUserRole={user.role}
            onAddEmployeesClick={onAddEmployeesButtonClick}
            isCancelled={cancelled}
          />
        )}
      </Box>
    )) || <Box>Lack of JobOrderId, no roster data </Box>
  );
};

Roster.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  candidates: PropTypes.arrayOf(PropTypes.shape({})),
  initialValues: PropTypes.shape({
    cancelled: PropTypes.bool,
    id: PropTypes.string,
    fillRate: PropTypes.number,
    jobPlacements: PropTypes.number,
    corporation: PropTypes.oneOfType([
      PropTypes.shape({
        favorite: PropTypes.arrayOf(PropTypes.shape({})),
        DNR: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      PropTypes.string,
    ]),
    jobRosters: PropTypes.arrayOf(PropTypes.shape({})),
    numOpenings: PropTypes.number,
  }),
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(Roster);
