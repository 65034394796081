import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Autocomplete, InputAdornment, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

import asyncService from '../../../../../../datahub/asyncService';
import KeyboardArrowDownIcon from '../../../../../assets/icons/KeyboardArrowDownIcon.svg';
import Search from '../../../../../assets/icons/Search.svg';
import selectUser from '../../../../../store/selectors/appSelector';
import theme from '../../../../../theme';

const SEARCHBAR_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const SearchEmployeeBar = ({ value, setValue }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const token = get('token', user);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(open && searchTerm === '' && options.length === 0);
  const getOptionLabel = (opt) => `${opt.name}(${opt.email})`;
  switch (SEARCHBAR_BACKGROUND.LIGHT) {
    case SEARCHBAR_BACKGROUND.DARK:
      // Dark mode style here
      break;
    case SEARCHBAR_BACKGROUND.LIGHT:
      // Light mode style here
      break;
    default:
      // Default mode style here
      break;
  }

  const styleForFormControl = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '&:hover': {
      backgroundColor: !value && get(['searchEmployeeBar', 'bgColor', 'hover'], theme),
    },
    '&:focus-within': {
      backgroundColor: !value && get(['searchEmployeeBar', 'bgColor', 'focus'], theme),
      border: `1px solid ${get(['searchEmployeeBar', 'borderColor', 'focus'], theme)}`,
    },
    backgroundColor: value && get(['searchEmployeeBar', 'bgColor', 'complete'], theme),

    borderRadius: '40px',
    justifyContent: 'center',
    height: '40px',
    width: '100%',
    marginLeft: '8px',
  };
  const radioSx = {
    height: 22,
    width: 22,
    color: get(['button', 'palette', 'primary'], theme),
  };

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      const requestBody = {
        filters: [
          { operation: 'icontains', field: 'name', value: searchTerm },
          { operation: 'equals', field: 'role', value: 'candidate' },
        ].filter((i) => i),
      };

      asyncService({
        httpMethod: 'POST',
        route: 'users/read',
        data: requestBody,
        onSuccess: (d) => {
          const apiResultDocuments = get(['data', 'documents'], d);
          setOptions(apiResultDocuments);
          setLoading(false);
        },
        onError: () => {
          setOptions([]);
          setLoading(false);
        },
        dispatch,
        token,
      });
    }, 750);
    return () => clearTimeout(delayedSearch);
  }, [searchTerm, dispatch, token, loading]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <FormControl sx={styleForFormControl}>
      <Autocomplete
        onChange={handleChange}
        value={value}
        popupIcon={
          <Box
            component="img"
            sx={{
              height: 22,
              width: 22,
            }}
            alt="Stars"
            src={KeyboardArrowDownIcon}
          />
        }
        noOptionsText="No results found. Try searching for something else."
        loadingText={
          <Grid container justifyContent="space-between">
            <div> Loading results...</div>
            <CircularProgress size={22} />
          </Grid>
        }
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              borderRadius: '16px',
            }}
            placement="auto-start"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {getOptionLabel(option)}
            {selected ? (
              <RadioButtonCheckedIcon sx={radioSx} />
            ) : (
              <RadioButtonUncheckedIcon sx={radioSx} />
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search employees"
            InputProps={{
              ...params.InputProps,
              startAdornment: open && (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 24,
                      width: 24,
                    }}
                    alt="Stars"
                    src={Search}
                  />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setOptions([]);
              setLoading(true);
              setSearchTerm(e.target.value);
            }}
            onBlur={() => {
              setSearchTerm('');
              setOptions([]);
            }}
          />
        )}
        isOptionEqualToValue={(option, val) => option._id === val._id}
        open={open}
        onOpen={() => {
          setLoading(options.length <= 0);
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setLoading(false);
        }}
        loading={loading}
        options={options || []}
        getOptionLabel={(option) => getOptionLabel(option) || ''}
      />
    </FormControl>
  );
};

SearchEmployeeBar.propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.shape({}),
};

export default SearchEmployeeBar;
