import * as React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import Sign from '../../../assets/icons/Maintenance.svg';
import theme from '../../../theme';

const PaymentConstructionCard = ({ pageName }) => (
  <Box
    sx={{
      width: '100%',
      margin: '15% auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <Box
      component="img"
      sx={{
        height: 150,
        width: 250,
        marginBottom: 2,
      }}
      alt="Construction"
      src={Sign}
    />
    <Typography
      variant="h4"
      sx={{
        color: `${theme.emptyCard.headerColor}`,
        fontFamily: 'Barlow-800',
        fontSize: '30',
      }}
      align="center"
    >
      Under construction!
    </Typography>
    <Typography
      variant="body2"
      align="center"
      sx={{ color: `${theme.emptyCard.textColor}`, fontFamily: 'Barlow-500' }}
    >
      {pageName} functionality in WAE is under construction and this feature will come into the app
      soon!
    </Typography>
  </Box>
);

PaymentConstructionCard.propTypes = {
  pageName: PropTypes.string,
};

export default PaymentConstructionCard;
