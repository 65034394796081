import React from 'react';
import PropTypes from 'prop-types';

import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import List from '@mui/material/List';

import DefaultNavMenu from './DefaultNavMenu';
import JobsMenu from './JobsMenu';

const DrawerMenus = ({ closeDrawer, isOpen, toggleDrawer }) => (
  <List component="nav">
    <DefaultNavMenu
      closeDrawer={closeDrawer}
      isOpen={isOpen}
      url="dashboard"
      Icon={AutoAwesomeMosaicOutlinedIcon}
      label="Dashboard"
      testId="dashboard"
    />
    <JobsMenu
      closeDrawer={closeDrawer}
      isOpen={isOpen}
      toggleDrawer={toggleDrawer}
      Icon={BusinessCenterOutlinedIcon}
      testId="jobs"
    />
    <DefaultNavMenu
      closeDrawer={closeDrawer}
      isOpen={isOpen}
      url="timekeeping"
      Icon={QueryBuilderOutlinedIcon}
      label="Time Tracking"
      testId="time-tracking"
    />
    <DefaultNavMenu
      closeDrawer={closeDrawer}
      isOpen={isOpen}
      url="payment"
      Icon={CreditCardOutlinedIcon}
      label="Payment"
      testId="payment"
    />
  </List>
);

DrawerMenus.propTypes = {
  closeDrawer: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

export default DrawerMenus;
