export const JOB_STATUS = {
  active: 'active',
  cancelled: 'cancelled',
  completed: 'completed',
  open: 'open',
};

export const JOB_CATEGORY = {
  dayJob: 'dayJob',
  shift: 'shift',
  longTermJob: 'longTermJob',
};
