import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import theme from '../../theme';

const BUTTON_TYPE = {
  DEFAULT: 'default',
  DELETE: 'delete',
  OUTLINED: 'outlined',
  TEXT: 'text',
};

const WaeButton = ({
  children,
  color,
  disabled,
  form,
  fullWidth,
  onClick,
  showIndeterminateProgress,
  startIcon,
  endIcon,
  sx,
  text,
  type,
  variant,
}) => {
  let border;
  let buttonBgColor;
  let hoverBgColor;
  let outLine;
  let outlinedButtonTextStyle;
  let outlinedButtonHoverTextStyle;
  let outlinedOnFocusStyle;

  switch (type) {
    case BUTTON_TYPE.OUTLINED:
      buttonBgColor = `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`;
      border = {
        border: `2px solid ${theme.button.palette.primary}`,
      };
      hoverBgColor = `${theme.button.palette.primary}`;
      outlinedButtonTextStyle = {
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      };
      outlinedButtonHoverTextStyle = {
        ...border,
        color: 'white',
        WebkitBackgroundClip: 'border-box',
        WebkitTextFillColor: 'white',
      };
      outlinedOnFocusStyle = {
        border: `2px solid ${theme.button.palette.focus}`,
      };
      break;
    case BUTTON_TYPE.TEXT:
      buttonBgColor = `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`;
      border = {
        border: `2px solid ${theme.button.palette.primary}`,
      };
      hoverBgColor = `${theme.button.palette.primary}`;
      outlinedButtonTextStyle = {
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      };
      outlinedButtonHoverTextStyle = {
        ...border,
        color: 'white',
        WebkitBackgroundClip: 'border-box',
        WebkitTextFillColor: 'white',
      };
      outlinedOnFocusStyle = {
        border: `2px solid ${theme.button.palette.focus}`,
      };
      break;

    case BUTTON_TYPE.DELETE:
      buttonBgColor = `${theme.button.palette.delete}`;
      hoverBgColor = `${theme.button.palette.delete}`;
      outLine = {
        outline: `2px solid ${theme.button.palette.focus}`,
      };
      break;
    default:
      buttonBgColor = `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`;
      outLine = {
        outline: `2px solid ${theme.button.palette.focus}`,
      };
      hoverBgColor = `${theme.button.palette.primary}`;
  }

  const waeStyle =
    type !== BUTTON_TYPE.TEXT
      ? {
          background: `${buttonBgColor}`,
          ...border,
          boxShadow: 'none',
          color: `${theme.button.palette.text}`,
          borderRadius: `${theme.button.defaultBorderRadius}`,
          ...outlinedButtonTextStyle,
          transition: 'none',
          '&:hover, &.Mui-hovered': {
            background: `${hoverBgColor}`,
            boxShadow: 'none',
            ...outlinedButtonHoverTextStyle,
            '& .MuiSvgIcon-root': {
              color: 'white',
            },
          },
          '&:focus': {
            boxShadow: 'none',
            ...outLine,
            ...outlinedOnFocusStyle,
          },
          '&.Mui-disabled': {
            color: `${theme.button.palette.text}`,
            opacity: '0.45',
          },
        }
      : {};

  return (
    <Button
      disabled={disabled}
      color={color}
      form={form}
      fullWidth={fullWidth}
      onClick={onClick}
      size="small"
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        fontFamily: `${theme.button.fontFamily}`,
        fontSize: `${theme.button.fontSize}`,
        padding: theme.spacing(0.67, 2),
        height: '37.7px',
        minWidth: 'max-content',
        cursor: 'pointer',
        ...waeStyle,
        ...sx,
      }}
      style={{ textTransform: 'none' }}
      type={type}
      disableFocusRipple
      variant={variant}
    >
      {text} {children}
      {showIndeterminateProgress && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
          }}
        />
      )}
    </Button>
  );
};

WaeButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  endIcon: PropTypes.element,
  form: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  showIndeterminateProgress: PropTypes.bool,
  startIcon: PropTypes.element,
  sx: PropTypes.shape({}),
  text: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};

export { BUTTON_TYPE, WaeButton };
