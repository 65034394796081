import React from 'react';

import Box from '@mui/material/Box';

import PaymentConstructionCard from '../../../../Candidate/payment/PaymentConstructionCard';

const Applicants = () => (
  <Box>
    <PaymentConstructionCard pageName="Applicants" />
    {/* TODO Uncomment when BE is ready */}
    {/* {jobApplicants && */}
    {/*  jobApplicants.length > 0 && */}
    {/*  jobApplicants.map((jobApplicant) => { */}
    {/*    return ( */}
    {/*      <ListItem> */}
    {/*        <InvitationCard inviteData={jobApplicant} /> */}
    {/*      </ListItem> */}
    {/*       */}
    {/*    ); */}
    {/*  })} */}
  </Box>
);

export default Applicants;
