import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { WaeButton as Button } from '../../components/Button/Button';
import { buildFormField } from '../../components/Form/formFieldFactory';
import theme from '../../theme/theme';

import JobPreferenceSettingsData from './FormData/JobPreferenceSettingsData';
import {
  sectionHeaderStyling,
  sectionSubHeaderStyling,
  sectionSubSubTextStyling,
  sectionSubTextStyling,
} from './styling';

const JobPreferenceSettingForm = ({ handleJobPreferenceOnSubmit, user }) => {
  const generateJobPreferenceIndustryInitialValue = () =>
    get(['candidate', 'industry'], user).reduce(
      (previous, current) => ({ ...previous, [current]: true }),
      {}
    );

  return (
    <RFForm
      onSubmit={handleJobPreferenceOnSubmit}
      initialValues={{
        industry: generateJobPreferenceIndustryInitialValue(),
        distance: {
          id: `${get(['candidate', 'distance'], user)} Miles`,
          name: `${get(['candidate', 'distance'], user)} Miles`,
          value: get(['candidate', 'distance'], user),
        },
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id="notificationFormID">
          <Typography
            sx={{
              ...sectionHeaderStyling,
              margin: theme.spacing(4, 0, 0, 0),
            }}
          >
            Job Preference Settings
          </Typography>
          <Typography sx={{ ...sectionSubTextStyling, margin: theme.spacing(2, 0, 2, 0) }}>
            Manage your preferences when it comes to preferred industry and or distance to a job.
          </Typography>
          <Typography sx={sectionSubHeaderStyling}>Industry Preferences</Typography>
          <Typography sx={sectionSubSubTextStyling}>
            Select the industries that appeal to you.
          </Typography>
          <Grid>{buildFormField(JobPreferenceSettingsData.industryPreferences)}</Grid>
          <Typography sx={{ ...sectionSubHeaderStyling }}>Distance to Job Preference</Typography>
          <Typography sx={sectionSubSubTextStyling}>
            By default your preferred job distance radius is set to 25 miles, this can be adjusted
            before proceeding.
          </Typography>
          <Grid item>{buildFormField(JobPreferenceSettingsData.distance)}</Grid>

          <Button sx={{ margin: theme.spacing(4, 0, 0, 0) }} type="submit">
            Update Job Preference Settings
          </Button>
        </form>
      )}
    />
  );
};

JobPreferenceSettingForm.propTypes = {
  handleJobPreferenceOnSubmit: PropTypes.func,
  user: PropTypes.shape({}),
};

export default JobPreferenceSettingForm;
