import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';

import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from './axios';
import API from './datahubApi';
import { logOutOn401 } from './helpers';

const parseTokenFromStore = (store) => get(['app', 'session', 'user', 'token'], store.getState());

const parseWebResponse = (webResponse) => ({
  meta: { statusText: get('statusText', webResponse) },

  data: get(['data'], webResponse),

  headers: get(['headers'], webResponse),
});

export default function datahubMiddleware(storeAPI) {
  const onSuccess = (webResponse, successCallback) => {
    successCallback(parseWebResponse(webResponse), storeAPI.dispatch);
  };

  const fakeOnSuccess = (webResponse, successCallback) => {
    successCallback(webResponse, storeAPI.dispatch);
  };

  const onError = (err, errorCallback) => {
    const code = get(['response', 'status'], err);
    if (code === 401) {
      logOutOn401(storeAPI.dispatch);
      return;
    }
    errorCallback(
      parseWebResponse({ err, data: getOr('Unexpected error format', ['response', 'data'], err) }),
      storeAPI.dispatch
    );
  };

  const dispatchAsyncGet = ({ path, payload, successCallback, errorCallback, axiosOptions }) => {
    apiGet(path, payload, parseTokenFromStore(storeAPI), axiosOptions)
      .then((webResponse) => onSuccess(webResponse, successCallback))
      .catch((err) => onError(err, errorCallback));
  };

  const dispatchFakeAsyncGet = ({ payload, successCallback, errorCallback }) => {
    const { jobOrder } = payload || { jobOrder: '0 ' };
    const fakeResponse = {
      data: {
        documents: {
          jobOrder,
        },
      },
    };
    Promise.resolve()
      .then(() => fakeOnSuccess(fakeResponse, successCallback))
      .catch((err) => onError(err, errorCallback));
  };

  const dispatchAsyncPost = ({ path, payload, successCallback, errorCallback }) => {
    apiPost(path, payload, parseTokenFromStore(storeAPI))
      .then((webResponse) => onSuccess(webResponse, successCallback))
      .catch((err) => onError(err, errorCallback));
  };

  const dispatchAsyncPut = ({ path, payload, successCallback, errorCallback }) => {
    apiPut(path, payload, parseTokenFromStore(storeAPI))
      .then((webResponse) => onSuccess(webResponse, successCallback))
      .catch((err) => onError(err, errorCallback));
  };

  const dispatchAsyncPatch = ({ path, payload, successCallback, errorCallback }) => {
    apiPatch(path, payload, parseTokenFromStore(storeAPI))
      .then((webResponse) => onSuccess(webResponse, successCallback))
      .catch((err) => onError(err, errorCallback));
  };

  const dispatchAsyncDelete = ({ path, payload, successCallback, errorCallback }) => {
    apiDelete(path, payload, parseTokenFromStore(storeAPI))
      .then((webResponse) => onSuccess(webResponse, successCallback))
      .catch((err) => onError(err, errorCallback));
  };

  return function wrapDispatch(next) {
    return function handleAction(action) {
      const apiEndpoint = find((endpoint) => endpoint.type === action.type, API);
      if (apiEndpoint) {
        const {
          axiosOptions,
          errorCallback,
          http: httpMethod,
          path,
          successCallback,
          type: baseAction,
        } = apiEndpoint;

        const serverMessage = {
          axiosOptions,
          baseAction,
          errorCallback,
          path,
          payload: action.payload,
          successCallback,
        };

        switch (httpMethod.toUpperCase()) {
          case 'GET':
            dispatchAsyncGet(serverMessage);
            break;

          case 'FAKE GET':
            dispatchFakeAsyncGet(serverMessage);
            break;

          case 'POST':
            dispatchAsyncPost(serverMessage);
            break;

          case 'PATCH':
            dispatchAsyncPatch(serverMessage);
            break;

          case 'DELETE':
            dispatchAsyncDelete(serverMessage);
            break;

          // not expected to be used, but is here for completeness
          case 'PUT':
            dispatchAsyncPut(serverMessage);
            break;

          default:
            throw Error(`API Request Type '${httpMethod}' is invalid or not implemented yet.`);
        }
      }
      next(action);
    };
  };
}
