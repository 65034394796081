import * as React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import theme from '../../../../../theme';
import {
  epochToDayOfWeek,
  epochToShortDateString,
  epochToTimeInReadableFormat,
} from '../../../../../utils';
import { calculateTotalJobPay } from '../../../../../utils/Candidate/payHelpers';

const AvailableShiftCard = ({ id, start, end, payRate, isSelectable, selected, onClick }) => {
  const cardTheme = get(['candidate', 'components', 'groupDetails', 'availableShiftCard'], theme);
  const sxForCard = {
    backgroundColor: get('backgroundColor', cardTheme),
    display: 'flex',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(3),
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  };
  const sxForPrimaryText = {
    color: get(['primaryText', 'color'], cardTheme),
    fontFamily: get(['primaryText', 'font'], cardTheme),
    fontSize: get(['primaryText', 'fontSize'], cardTheme),
  };
  const sxForSecondaryText = {
    color: get(['secondaryText', 'color'], cardTheme),
    fontFamily: get(['secondaryText', 'font'], cardTheme),
    fontSize: get(['secondaryText', 'fontSize'], cardTheme),
  };
  const sxForRow = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.25),
  };

  const totalPay = calculateTotalJobPay({
    start,
    end,
    payRateReg: payRate,
  });

  const startDate = epochToShortDateString(start);
  const endDate = epochToShortDateString(start);

  const weekDay = epochToDayOfWeek(start);
  const startTime = epochToTimeInReadableFormat(start);
  const endTime = epochToTimeInReadableFormat(end);

  return (
    <Box sx={sxForCard} key={id}>
      <Box sx={{ width: isSelectable ? '90%' : '100%' }}>
        <Box sx={sxForRow}>
          <Typography sx={sxForPrimaryText}>
            {startDate === endDate ? startDate : `${startDate}-${endDate}`}
          </Typography>
          <Typography sx={sxForPrimaryText}>{totalPay}</Typography>
        </Box>
        <Box sx={sxForRow}>
          <Typography sx={sxForSecondaryText}>
            {weekDay} • {startTime} - {endTime}
          </Typography>
          <Typography sx={sxForSecondaryText}>${payRate}/hr</Typography>
        </Box>
      </Box>
      {isSelectable && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '10%',
            cursor: 'pointer',
          }}
          data-testid="addShift"
          onClick={() => onClick(id)}
        >
          {selected ? (
            <CloseIcon
              sx={{
                color: get('closeIconColor', cardTheme),
                backgroundColor: get('iconColor', cardTheme),
                fontSize: get('iconSize', cardTheme),
                borderRadius: '51%',
                padding: theme.spacing(0.5),
              }}
            />
          ) : (
            <AddCircleOutlineOutlinedIcon
              sx={{
                color: get('iconColor', cardTheme),
                fontSize: get('iconSize', cardTheme),
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default AvailableShiftCard;

AvailableShiftCard.propTypes = {
  id: PropTypes.string,
  start: PropTypes.number,
  end: PropTypes.number,
  payRate: PropTypes.number,
  isSelectable: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};
