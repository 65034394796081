import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import Geofence from '../../../assets/icons/Geofence.svg';
import GPS_STRATEGY_TYPES from '../../../constants/gps';
import theme from '../../../theme';
import { showSnackbar } from '../../../views/app';
import Button from '../../Button';
import IconButton from '../../IconButton';

const RootBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(2, 8),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '20px',
}));

const HeaderBox = styled(Box)(() => ({
  width: '100%',
}));

const HeaderBoxTypography = styled(Box)(() =>
  get(['candidate', 'components', 'timeCards', 'typography', 'punchAlertDrawerHeader'], theme)
);

const DescriptionBoxTypography = styled(Box)(() =>
  get(['candidate', 'components', 'timeCards', 'typography', 'punchAlertDrawerDescription'], theme)
);

const setErrorMessageBody = (errMessage) =>
  (errMessage === 'error.generic.invalid' && 'error.generic.invalid.description') ||
  (errMessage === 'error.timecard.outsidePunchRange' &&
    'error.timecard.outsidePunchRange.description') ||
  (errMessage === 'error.timecard.invalidTimestamp' &&
    'error.timecard.invalidTimestamp.description') ||
  'error.candidate.punch.unknownError.description';

const PunchAlertDrawer = ({
  content,
  punchError,
  onClose,
  placementId,
  postPunch,
  punchButtonText,
  gpsStrategy,
  gettingCurrentPosition,
  setGettingCurrentPosition,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const punchClickHandler = () => {
    const nav = navigator;

    if (!nav.geolocation) {
      dispatch(
        showSnackbar({
          message: intl.formatMessage({ id: 'error.candidate.punch.enableBrowserGeolocation' }),
        })
      );
    } else {
      setGettingCurrentPosition(true);
      nav.geolocation.getCurrentPosition(
        (position) => {
          const coordinates = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
          };
          postPunch({
            coordinates,
            placement: placementId,
            overrideGPSWarning: true,
          });
          setGettingCurrentPosition(false);
        },
        (error) => {
          dispatch(showSnackbar({ message: error.message }));
          setGettingCurrentPosition(false);
        }
      );
    }
  };

  return (
    <Drawer
      anchor="right"
      open={!!punchError}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: '576px' } }}
    >
      <HeaderBox>
        <IconButton
          iconElement={<CloseIcon />}
          onClick={() => onClose()}
          size="small"
          sx={{ marginLeft: 0.5, marginTop: 0.5 }}
        />
      </HeaderBox>
      <RootBox>
        <Box
          component="img"
          sx={{
            height: 51.52,
            width: 51.52,
          }}
          alt="Delete"
          src={Geofence}
        />
        <HeaderBoxTypography>
          {punchError && intl.formatMessage({ id: punchError })}
        </HeaderBoxTypography>
        <DescriptionBoxTypography>
          {/* {punchError &&
            intl.formatMessage({
              id: `${punchError}.description`,
            })} */}
          {punchError &&
            intl.formatMessage({
              id: setErrorMessageBody(punchError),
            })}
        </DescriptionBoxTypography>
        {content}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignSelf: 'flex-end',
          }}
        >
          {gettingCurrentPosition ? (
            <CircularProgress
              sx={{ color: theme.candidate.components.dashboard.clockInOut.details.fontColor }}
            />
          ) : (
            <Box>
              <Button
                onClick={onClose}
                type="outlined"
                sx={{ padding: theme.spacing(1, 2) }}
                startIcon={<ArrowBackIcon sx={{ color: theme.button.palette.secondary }} />}
              >
                Go Back
              </Button>
              {gpsStrategy === GPS_STRATEGY_TYPES.WARNING &&
                punchError === 'error.timecard.outsidePunchRange' && (
                  <Button
                    type="delete"
                    onClick={punchClickHandler}
                    sx={{ marginLeft: theme.spacing(1), padding: theme.spacing(1, 2) }}
                    text={punchButtonText}
                    endIcon={<CheckIcon />}
                  />
                )}
            </Box>
          )}
        </Box>
      </RootBox>
    </Drawer>
  );
};

PunchAlertDrawer.propTypes = {
  punchError: PropTypes.string,
  onClose: PropTypes.func,
  content: PropTypes.shape({}),
  placementId: PropTypes.string,
  postPunch: PropTypes.func,
  punchButtonText: PropTypes.string,
  gpsStrategy: PropTypes.string,
  gettingCurrentPosition: PropTypes.bool,
  setGettingCurrentPosition: PropTypes.func,
};

export default PunchAlertDrawer;
