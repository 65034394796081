import get from 'lodash/fp/get';

import theme from '../../../theme/theme';

export const JOB_ORDER_STATUSES = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  COMPLETE: 'completed',
  OPEN: 'open',
};

export const handleLabelProps = (status) => {
  const MESSAGES = {
    ACTIVE: 'jobOrder.status.active',
    CANCELLED: 'jobOrder.status.cancelled',
    COMPLETE: 'jobOrder.status.completed',
    OPEN: 'jobOrder.status.open',
  };

  let backgroundColor;
  let color;
  let text;

  switch (status) {
    case JOB_ORDER_STATUSES.ACTIVE:
      backgroundColor = get(['label', 'job_orders', 'bgColor', 'active'], theme);
      color = get(['label', 'job_orders', 'color', 'active'], theme);
      text = MESSAGES.ACTIVE;
      break;
    case JOB_ORDER_STATUSES.CANCELLED:
      backgroundColor = get(['label', 'job_orders', 'bgColor', 'cancelled'], theme);
      color = get(['label', 'job_orders', 'color', 'cancelled'], theme);
      text = MESSAGES.CANCELLED;
      break;
    case JOB_ORDER_STATUSES.COMPLETE:
      backgroundColor = get(['label', 'job_orders', 'bgColor', 'complete'], theme);
      color = get(['label', 'job_orders', 'color', 'complete'], theme);
      text = MESSAGES.COMPLETE;
      break;
    case JOB_ORDER_STATUSES.OPEN:
      backgroundColor = get(['label', 'job_orders', 'bgColor', 'open'], theme);
      color = get(['label', 'job_orders', 'color', 'open'], theme);
      text = MESSAGES.OPEN;
      break;
    default:
      throw new Error('Valid status option required');
  }

  return {
    text,
    sx: {
      backgroundColor,
      color,
    },
    sxForText: {
      fontFamily: get(['label', 'job_orders', 'font'], theme),
    },
  };
};
