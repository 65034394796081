import React, { useCallback } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';

import { updatePlacement } from './reducer';

const AssignEmployeeModal = ({
  isAssignDialogOpen,
  setIsAssignDialogOpen,
  selectedJob,
  candidates,
}) => {
  const dispatch = useDispatch();

  const jobId = get(['id'], selectedJob);
  const [assignedCandidate, setAssignedCandidate] = React.useState(null);

  const handleSubmitAssignCandidate = () => {
    dispatch(updatePlacement({ fields: { jobOrders: [jobId], candidate: assignedCandidate.id } }));
    setAssignedCandidate(null);
    setIsAssignDialogOpen(false);
  };

  const handleCloseOnClick = () => {
    setAssignedCandidate(null);
    setIsAssignDialogOpen(false);
  };

  const cancelDialogButtons = [
    <Button
      color="primary"
      text="Cancel"
      variant="text"
      key="handle-cancel-assign-employee-button"
      onClick={handleCloseOnClick}
    />,

    <Button
      color="primary"
      text="Confirm"
      disabled={assignedCandidate === null}
      variant="text"
      key="handle-confirm-assign-employee-button"
      onClick={handleSubmitAssignCandidate}
    />,
  ];

  const dialogContent = useCallback(
    () => (
      <Box sx={{ minWidth: 500 }}>
        {/* <FormControl fullWidth required variant="standard" onClose={() => console.log('123')}> */}
        <Autocomplete
          id="assign-candidate"
          options={candidates}
          getOptionLabel={(option) => `${option.name}(${option.email})`}
          value={assignedCandidate}
          onChange={(event, newValue) => {
            setAssignedCandidate(newValue);
          }}
          sx={{ minWidth: 300 }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Assigned Candidate" />
          )}
        />
        {/* </FormControl> */}
      </Box>
    ),
    [candidates, assignedCandidate, setAssignedCandidate]
  );

  return (
    <Dialog
      open={isAssignDialogOpen}
      dialogTitle="Add candidate to all job orders"
      dialogContentText={dialogContent()}
      dialogActions={cancelDialogButtons}
      component="span"
      onBackdropClick={() => setIsAssignDialogOpen(false)}
    />
  );
};
AssignEmployeeModal.propTypes = {
  selectedJob: PropTypes.shape({}),
  isAssignDialogOpen: PropTypes.bool,
  setIsAssignDialogOpen: PropTypes.func,
  candidates: PropTypes.arrayOf(PropTypes.shape({})),
};
export default AssignEmployeeModal;
