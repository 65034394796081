import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import UserRole from '../../constants/user-role';
import selectUser from '../../store/selectors/appSelector';
import { navigationBarHeight } from '../../theme';

import Header from './header/Header';
import Metrics from './Metrics';
import MetricsFilters from './metricsFilters';

const Reporting = memo(() => {
  const HeaderGrid = styled(Grid)(() => ({
    height: '58px',
    lineHeight: '42px',
    justifyContent: 'space-between',
  }));
  const user = useSelector(selectUser);

  return (
    <Grid container spacing={2} marginTop={navigationBarHeight}>
      <HeaderGrid container item>
        <Header />
        {user.role !== UserRole.EMPLOYER && <MetricsFilters />}
      </HeaderGrid>
      <Metrics />
    </Grid>
  );
});

export default Reporting;
