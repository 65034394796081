import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import searchParamOptions from '../../../constants/searchParams';
import WaeButton from '../../Button';

const NotificationButton = ({ notification, closeNotificationCenter }) => {
  const notificationType = get('notificationType', notification);
  const navigate = useNavigate();

  const onClick = ({ navProps }) => {
    navigate(navProps);
    closeNotificationCenter();
  };

  switch (notificationType) {
    case 'positionTemplateApproval':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'jobs/positionTemplateList',
                search: `?${searchParamOptions.POSITION}=${get(
                  ['info', 'positionTemplate'],
                  notification
                )}`,
              },
            })
          }
        >
          View Position Template
        </WaeButton>
      );

    case 'jobPostSuccess':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'jobs/jobsList',
                search: `?${searchParamOptions.JOB_ORDER}=${get(
                  ['info', 'jobOrder'],
                  notification
                )}`,
              },
            })
          }
        >
          View Job
        </WaeButton>
      );
    case 'rosterFilled':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'jobs/jobsList',
                search: `?${searchParamOptions.JOB_ORDER}=${get(
                  ['info', 'jobOrder'],
                  notification
                )}`,
              },
            })
          }
        >
          View Job
        </WaeButton>
      );
    case 'jobStarted':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'jobs/jobsList',
                search: `?${searchParamOptions.JOB_ORDER}=${get(
                  ['info', 'jobOrder'],
                  notification
                )}`,
              },
            })
          }
        >
          View Job
        </WaeButton>
      );
    case 'timeSheetReady':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'timekeeping',
                search: `?${searchParamOptions.START}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.CANDIDATE}=${get(
                  ['info', 'candidateName'],
                  notification
                )}&?${searchParamOptions.DATE}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.PLACEMENT}=${get(['info', 'placement'], notification)}`,
              },
            })
          }
        >
          View Timesheet
        </WaeButton>
      );
    case 'employeeDispute':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'timekeeping',
                search: `?${searchParamOptions.START}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.CANDIDATE}=${get(
                  ['info', 'candidateName'],
                  notification
                )}&?${searchParamOptions.DATE}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.PLACEMENT}=${get(['info', 'placement'], notification)}`,
              },
            })
          }
        >
          View Timesheet
        </WaeButton>
      );
    case 'billSubmitted':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'timekeeping',
                search: `?${searchParamOptions.START}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.CANDIDATE}=${get(
                  ['info', 'candidateName'],
                  notification
                )}&?${searchParamOptions.DATE}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.PLACEMENT}=${get(['info', 'placement'], notification)}`,
              },
            })
          }
        >
          View Timesheet
        </WaeButton>
      );

    case 'generic':
      return null;

    // admin
    case 'positionTemplateNeedsReview':
      return (
        <WaeButton
          onClick={() =>
            navigate({
              pathname: 'jobs/positionTemplateList',
              search: `?${searchParamOptions.POSITION}=${get(
                ['info', 'positionTemplate'],
                notification
              )}`,
            })
          }
        >
          View Position Template
        </WaeButton>
      );

    case 'timecardDisputed':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'timekeeping',
                search: `?${searchParamOptions.START}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.CANDIDATE}=${get(
                  ['info', 'candidateName'],
                  notification
                )}&?${searchParamOptions.DATE}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.PLACEMENT}=${get(['info', 'placement'], notification)}`,
              },
            })
          }
        >
          View Timesheet
        </WaeButton>
      );
    case 'jobFilled':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'jobs/jobsList',
                search: `?${searchParamOptions.JOB_ORDER}=${get(
                  ['info', 'jobOrder'],
                  notification
                )}`,
              },
            })
          }
        >
          View Timesheet
        </WaeButton>
      );
    case 'retroactivePlacementRequest':
      return (
        <WaeButton
          onClick={() =>
            navigate({
              pathname: 'jobs/positionTemplateList',
              search: `?${searchParamOptions.POSITION}=${get(
                ['info', 'positionTemplate'],
                notification
              )}`,
            })
          }
        >
          View Request
        </WaeButton>
      );

    // employee
    case 'jobOrderInvite':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'jobs/jobsList',
                search: `?${searchParamOptions.JOB_ORDER}=${get(
                  ['info', 'jobOrder'],
                  notification
                )}&${searchParamOptions.PLACEMENT}=${get(['info', 'placement'], notification)}&${
                  searchParamOptions.INVITED
                }=true`,
              },
            })
          }
        >
          View Job
        </WaeButton>
      );

    case 'newJobAssigned':
      return (
        <WaeButton
          onClick={() =>
            navigate({
              pathname: 'jobs/myJobs',
              search: `?${searchParamOptions.PLACEMENT}=${get(
                ['info', 'placement'],
                notification
              )}`,
            })
          }
        >
          View Job
        </WaeButton>
      );

    case 'preClockInRemind':
      return (
        <WaeButton
          onClick={() =>
            navigate({
              pathname: 'dashboard',
            })
          }
        >
          Go to Dashboard
        </WaeButton>
      );
    case 'clockInRemind':
      return (
        <WaeButton
          onClick={() =>
            navigate({
              pathname: 'jobs/jobsList',
            })
          }
        >
          Go to Dashboard
        </WaeButton>
      );
    case 'clockOutRemind':
      return (
        <WaeButton
          onClick={() =>
            navigate({
              pathname: 'dashboard',
            })
          }
        >
          Go to Dashboard
        </WaeButton>
      );
    case 'timecardEdited':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'timekeeping',
                search: `?${searchParamOptions.START}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.PLACEMENT}=${get(['info', 'placement'], notification)}`,
              },
            })
          }
        >
          View Timecard
        </WaeButton>
      );
    case 'timeCardApproved':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'timekeeping',
                search: `?${searchParamOptions.START}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.PLACEMENT}=${get(['info', 'placement'], notification)}`,
              },
            })
          }
        >
          View Timecard
        </WaeButton>
      );
    case 'timeCardDisputeSubmitted':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'timekeeping',
                search: `?${searchParamOptions.START}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.PLACEMENT}=${get(['info', 'placement'], notification)}`,
              },
            })
          }
        >
          View Timecard
        </WaeButton>
      );
    case 'timeCardDisputeResolved':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'timekeeping',
                search: `?${searchParamOptions.START}=${
                  get(['info', 'jobOrderStart'], notification) / 1000
                }&?${searchParamOptions.PLACEMENT}=${get(['info', 'placement'], notification)}`,
              },
            })
          }
        >
          View Timecard
        </WaeButton>
      );
    case 'shiftCancellation':
      return null;
    case 'placementCancelled':
      return null;
    case 'jobOrderCancelled':
      return (
        <WaeButton
          onClick={() =>
            onClick({
              navProps: {
                pathname: 'jobs/jobsList',
                search: `?${searchParamOptions.JOB_ORDER}=${get(
                  ['info', 'jobOrder'],
                  notification
                )}`,
              },
            })
          }
        >
          View Job
        </WaeButton>
      );

    default:
      return null;
  }
};

NotificationButton.propTypes = {
  notification: PropTypes.shape({}),
  closeNotificationCenter: PropTypes.func,
};

export default NotificationButton;
