import React, { memo } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

import theme from '../../theme';
import AlertComponent from '../AlertComponent/AlertComponent';
import Button from '../Button';
import IconButton from '../IconButton';

import FormPunchSetField from './FormPunchSetField';
import { errorHandler } from './formUtils';

const StyledList = styled(List)(() => ({
  display: 'flex',
  borderRadius: '16px',
}));

const FormPunchPicker = memo(
  ({ additiveElements, fieldErrorData, initialValue, input, label, listViewGridSpacing, meta }) => {
    const { onChange } = input;
    const setRFFValue = onChange;
    const punchData = get('value', input);
    const ONE_MIN_IN_MS = 60000;
    const errorMessage = errorHandler(input, meta, fieldErrorData);

    const addPunchOnClick = () => {
      const oneMinFromPrevPunchOut =
        get(['out', 'stamp'], punchData[punchData.length - 1]) + ONE_MIN_IN_MS;

      const newArray = [
        ...punchData,
        {
          in: { stamp: oneMinFromPrevPunchOut },
          out: { stamp: oneMinFromPrevPunchOut + ONE_MIN_IN_MS },
        },
      ];

      setRFFValue(newArray);
    };

    const handlePunchChange = (newPunchData) => {
      setRFFValue(newPunchData);
    };

    const punchDeleteOnClick = (rowStart) => {
      setRFFValue(punchData.filter((row) => get(['in', 'stamp'], row) !== get('stamp', rowStart)));
    };
    return (
      <Grid container spacing={listViewGridSpacing}>
        <Grid item xs>
          <StyledList name={input.name} label={label} values={input.values || initialValue}>
            <Box sx={{ width: '100%' }}>
              {punchData.map((rowData, index) => (
                // used index as a key that is best solution so far. ATTENTION : if you use rowData.in.stamp will cause DateTimePicker auto close issue
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index}>
                  <Typography sx={{ margin: theme.spacing(1, 0, 2, 0) }}>
                    Punch {index + 1}
                  </Typography>
                  <ListItem
                    key={`${rowData.in}`}
                    sx={{ display: 'flex', padding: theme.spacing(1, 0) }}
                  >
                    <FormPunchSetField
                      data={rowData}
                      index={index}
                      listData={punchData}
                      handlePunchChange={handlePunchChange}
                    />
                    <IconButton
                      sx={{ alignSelf: 'flex-start' }}
                      iconElement={
                        <HighlightOffIcon sx={{ color: theme.button.palette.secondary }} />
                      }
                      onClick={() => punchDeleteOnClick(rowData.in, setRFFValue)}
                    />
                  </ListItem>
                </Box>
              ))}
              <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                <AlertComponent isOpen={!!errorMessage} severity="error" message={errorMessage} />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    addPunchOnClick();
                  }}
                >
                  Add Punch +
                </Button>
              </Grid>
            </Box>
          </StyledList>
        </Grid>
        {additiveElements}
      </Grid>
    );
  }
);
FormPunchPicker.propTypes = {
  additiveElements: PropTypes.element,
  fieldErrorData: PropTypes.string,
  initialValue: PropTypes.number,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    values: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  label: PropTypes.string,
  listViewGridSpacing: PropTypes.number,
  meta: PropTypes.shape(),
};

export default FormPunchPicker;
