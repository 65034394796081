import React, { useEffect } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import BackdropCircularProgress from '../../../../components/BackdropCircularProgress';
import Button from '../../../../components/Button';
import { buildFormField } from '../../../../components/Form/formFieldFactory';
import { JOB_CATEGORY } from '../../../../constants/jobInformation';
import {
  selectApplications,
  selectCancelledCandidates,
  selectInvitations,
} from '../../../../store/selectors/jobListSelectors';
import theme from '../../../../theme';
import { BLACK } from '../../../../theme/colorConstants';
import { getCancelledCandidates, getJobApplications, getJobInvitations } from '../reducer';

import Applicants from './SubComponents/Applicants';
import CancelledCandidates from './SubComponents/CancelledCandidates';
import FormFieldDataRoster from './SubComponents/FormFieldDataRoster';
import Invitation from './SubComponents/Invitation';
import Roster from './Roster';

const componentsDetailViewSectionHeaders = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];
const detailViewTypographyDetailText = ['components', 'detailView', 'typography', 'detailText'];

const viewHeaderStyling = {
  fontSize: get([...componentsDetailViewSectionHeaders, 'fontSize'], theme),
  fontFamily: get([...componentsDetailViewSectionHeaders, 'fontFamily'], theme),
  color: get([...componentsDetailViewSectionHeaders, 'fontColor'], theme),
};

const subHeaderStyling = {
  fontSize: get([...detailViewTypographyDetailText, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographyDetailText, 'fontFamily'], theme),
  color: get([...detailViewTypographyDetailText, 'fontColor'], theme),
};

const StyledFixedBottomBox = styled(Box)(() => ({
  alignItems: 'center',
  backgroundImage: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
  color: BLACK[0],
  bottom: 0,
  borderBottomLeftRadius: '13px',
  borderBottomRightRadius: '13px',
  cursor: 'pointer',
  display: 'flex',
  fontFamily: `${theme.button.fontFamily}`,
  height: '4.40vh',
  left: 0,
  justifyContent: 'center',
  position: 'absolute',
  width: '100%',
  zIndex: 5000,
}));

const componentsTabPagesPaletteTabDarkSelected = get(
  ['components', 'tabPages', 'palette', 'tab', 'dark', 'selected'],
  theme
);

const componentsTabPagesTypographyTabSelected = get(
  ['components', 'tabPages', 'typography', 'tab', 'selected'],
  theme
);

const tabStyle = {
  '&.Mui-selected': {
    color: get('fontColor', componentsTabPagesPaletteTabDarkSelected),
  },
  height: '35px',
  minHeight: '35px',
  color: '#D7BDFF', // purple 40
  fontFamily: get('fontFamily', componentsTabPagesTypographyTabSelected),
  fontSize: get('fontSize', componentsTabPagesTypographyTabSelected),
};
const RosterForm = ({ initialValues, candidates, onUpdate }) => {
  const jobCorporation = get('corporation', initialValues);

  const formFieldData = FormFieldDataRoster();

  const dispatch = useDispatch();

  const jobInvitations = useSelector(selectInvitations);
  const jobApplications = useSelector(selectApplications);
  const cancelledCandidates = useSelector(selectCancelledCandidates);

  const [value, setValue] = React.useState('1');

  useEffect(() => {
    dispatch(
      getJobInvitations({
        filters: [
          {
            operation: 'equals',
            field: 'state.status',
            value: 'pendingEmployeeAcceptance',
          },
          { field: 'jobOrder._id', value: get('id', initialValues), operation: 'equalsID' },
        ],
      })
    );
    dispatch(
      getJobApplications({
        filters: [
          {
            operation: 'equals',
            field: 'state.status',
            value: 'pendingEmployerApproval',
          },
        ],
      })
    );
    dispatch(
      getCancelledCandidates({
        filters: [
          {
            operation: 'equals',
            field: 'state.status',
            value: 'cancelled',
          },
          { field: 'jobOrder._id', value: get('id', initialValues), operation: 'equalsID' },
        ],
      })
    );
  }, [dispatch, initialValues]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    (
      <Box
        sx={{
          width: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          maxHeight: '80vh',
        }}
      >
        <RFForm
          onSubmit={(values) => {
            onUpdate(values, initialValues);
          }}
          initialValues={initialValues}
          render={({ handleSubmit, pristine }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                width: '100%',
              }}
            >
              <Typography sx={{ ...viewHeaderStyling, width: '50%' }}>Fill Rate</Typography>
              <Box sx={{ width: '60%' }}>
                <Box>{buildFormField(formFieldData.fillRatio)}</Box>
              </Box>

              <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(2, 0, 0, 0) }}>
                Shift Placements
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '45%' }}>
                  <Typography
                    sx={{
                      ...subHeaderStyling,
                      margin: theme.spacing(2, 0, 0, 0),
                    }}
                  >
                    Job Openings
                  </Typography>
                  <Box>{buildFormField(formFieldData.numOpenings)}</Box>
                </Box>
                <Box sx={{ width: '45%' }}>
                  <Typography sx={{ ...subHeaderStyling, margin: theme.spacing(2, 0, 0, 0) }}>
                    Job Placements
                  </Typography>
                  <Box>{buildFormField(formFieldData.placementsCount)}</Box>
                </Box>
              </Box>
              {pristine ? null : (
                <StyledFixedBottomBox>
                  <Button
                    sx={{ width: '100%' }}
                    type="submit"
                    startIcon={<CheckOutlinedIcon sx={{ color: 'white', fontSize: 30 }} />}
                  >
                    Update Openings
                  </Button>
                </StyledFixedBottomBox>
              )}
            </form>
          )}
        />
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab
                  label={`Roster (${get('placementsCount', initialValues)})`}
                  value="1"
                  sx={tabStyle}
                  style={{ textTransform: 'none' }}
                />
                <Tab
                  label={`Invitations (${(jobInvitations && jobInvitations.length) || 0})`}
                  value="2"
                  sx={tabStyle}
                  style={{ textTransform: 'none' }}
                />
                <Tab label="Applicants" value="3" sx={tabStyle} style={{ textTransform: 'none' }} />
                <Tab
                  label={`Canceled (${(cancelledCandidates && cancelledCandidates.length) || 0})`}
                  value="4"
                  sx={tabStyle}
                  style={{ textTransform: 'none' }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Roster
                initialValues={initialValues}
                candidates={candidates}
                accordionType={JOB_CATEGORY.dayJob}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ color: 'white' }}>
              <Invitation selectedJobInvitations={jobInvitations} corporation={jobCorporation} />
            </TabPanel>
            <TabPanel value="3" sx={{ color: 'white' }}>
              <Applicants jobApplicants={jobApplications} />
            </TabPanel>
            <TabPanel value="4" sx={{ color: 'white' }}>
              <CancelledCandidates
                cancelledCandidates={cancelledCandidates}
                corporation={jobCorporation}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    ) || <BackdropCircularProgress />
  );
};

RosterForm.propTypes = {
  container: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  onUpdate: PropTypes.func,
  DNR: PropTypes.shape({}),
  DNA: PropTypes.shape({}),
  candidates: PropTypes.arrayOf(PropTypes.shape({})),
};

export default RosterForm;
