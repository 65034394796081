import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { ReactComponent as DefaultCorporation } from '../../assets/DefaultCorporation.svg';

const CorporationLogo = ({ file, corporationId, style }) => (
  <Box item>
    {file ? (
      <img src={file} alt="logo" style={style} />
    ) : (
      <DefaultCorporation
        fill={`#${corporationId.slice(-6)}`}
        style={{
          display: 'block',
          ...style,
        }}
      />
    )}
  </Box>
);

CorporationLogo.propTypes = {
  file: PropTypes.shape({}),
  corporationId: PropTypes.string,
  style: PropTypes.shape({}),
};

export default CorporationLogo;
