const primary = 'Barlow';
// const secondary = 'Barlow';

const PRIMARY_FONT = {
  default: primary,
  400: `${primary}-400`,
  500: `${primary}-500`,
  600: `${primary}-600`,
  700: `${primary}-700`,
  800: `${primary}-800`,
};

// eslint-disable-next-line import/prefer-default-export
export { PRIMARY_FONT };
