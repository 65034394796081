import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Accordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import selectUser from '../../../../../store/selectors/appSelector';
import { selectUsers } from '../../../../../store/selectors/jobListSelectors';
import theme from '../../../../../theme';
import {
  epochToDayOfWeek,
  epochToShortDateString,
  epochToTimeInReadableFormat,
} from '../../../../../utils';
import LinearExpandMore from '../../../../corporations/corporationList/detail/icons/LinearExpandMore';
import FillRatioCell from '../../FillRatioCell';
import { updateJob } from '../../reducer';

import ShiftRosterForm from './ShiftRosterForm';

const styleForAccordion = {
  backgroundColor: theme.components.detailView.palette.sectionBackgroundColor,
  borderRadius: '25px',
  padding: theme.spacing(1, 0, 0, 0),
  justifyContent: 'space-between',
  margin: '5px 0',
  boxShadow: 0,
  width: '100%',
};
const styleForIconButtons = {
  color: 'blue',
  fontSize: 30,
  cursor: 'pointer',
};

const styleForShortDate = {
  fontSize: theme.components.detailView.typography.semiBoldText.fontSize,
  margin: '3px',
  color: theme.components.detailView.palette.sectionTextColor,
  fontFamily: theme.components.detailView.typography.semiBoldText.fontFamily,
};

const styleForShift = {
  fontFamily: theme.components.detailView.typography.detailText.fontFamily,
  fontSize: theme.components.detailView.typography.detailText.fontSize,
  color: 'white',
};

const ShiftRosterAccordion = ({ selectedShift }) => {
  const { start, end, id, fillRatio } = selectedShift;
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const candidates = useSelector(selectUsers);

  const handleJobUpdate = useCallback(
    (formData) => {
      dispatch(
        updateJob({
          id,
          fields: { numOpenings: formData.numOpenings },
        })
      );
    },
    [dispatch, id]
  );

  return (
    <Accordion sx={styleForAccordion} square>
      <AccordionSummary
        expandIcon={<LinearExpandMore sx={styleForIconButtons} />}
        aria-controls="panel1a-content"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingRight: '5px',
          }}
        >
          <Box>
            <Typography sx={styleForShortDate} gutterBottom>
              {epochToShortDateString(start)}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '3px' }}>
              <Typography sx={styleForShift}>
                {epochToDayOfWeek(start)}. {epochToTimeInReadableFormat(start)}-{' '}
                {epochToTimeInReadableFormat(end)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ color: 'white', width: '40%' }}>
            <FillRatioCell variant="determinate" id={id} value={fillRatio} />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ margin: 0, padding: 0 }}>
        <ShiftRosterForm
          onUpdate={handleJobUpdate}
          initialValues={selectedShift}
          user={user}
          candidates={candidates}
        />
      </AccordionDetails>
    </Accordion>
  );
};

ShiftRosterAccordion.propTypes = {
  selectedShift: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    id: PropTypes.string,
    status: PropTypes.string,
    fillRatio: PropTypes.number,
  }),
};

export default ShiftRosterAccordion;
