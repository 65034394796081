import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import WhiteTrashIcon from '../../../assets/icons/WhiteTrashIcon';
import WaeButton from '../../../components/Button';
import theme from '../../../theme';
import { deleteCert, deleteSkill } from '../reducer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.skillsAndCerts.palette.modalBgColor,
  border: `2px solid ${theme.skillsAndCerts.palette.modalBorder}`,
  borderRadius: '16px',
  boxShadow: 24,
  p: 4,
};

const DeleteSkillOrCertsModal = ({ isOpen, setIsOpen, type, skillOrCertId }) => {
  const dispatch = useDispatch();
  const deleteText = `Are you certain you want to delete this ${type}? This action will permanently remove it from your ${type} list.`;

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDeleteSkillOrCert = () => {
    if (type === 'skill') {
      dispatch(deleteSkill({ id: skillOrCertId }));
    } else dispatch(deleteCert({ id: skillOrCertId }));
    setIsOpen(false);
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{
              fontSize: theme.skillsAndCerts.typography.modalHeader.fontSize,
              fontFamily: theme.skillsAndCerts.typography.modalHeader.fontFamily,
              color: theme.skillsAndCerts.palette.modalHeader,
            }}
          >
            {`Delete ${type}?`}
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.skillsAndCerts.typography.modalSubText.fontFamily,
              fontSize: theme.skillsAndCerts.typography.modalSubText.fontSize,
              color: theme.skillsAndCerts.palette.modalSubText,
              margin: theme.spacing(2, 0, 5, 0),
            }}
          >
            {' '}
            {deleteText}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <WaeButton
              text="Go Back"
              type="outlined"
              startIcon={<ArrowBackIcon sx={{ color: theme.button.palette.secondary }} />}
              onClick={handleClose}
              sx={{ marginRight: theme.spacing(1) }}
            />

            <WaeButton
              text={`Delete ${type}`}
              type="delete"
              endIcon={<WhiteTrashIcon />}
              onClick={handleDeleteSkillOrCert}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

DeleteSkillOrCertsModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  skillOrCertId: PropTypes.string,
  type: PropTypes.string,
};

export default DeleteSkillOrCertsModal;
