import get from 'lodash/fp/get';

import theme from '../../../../theme';

const detailTheme = get(['candidate', 'components', 'groupDetails'], theme);

const sxForMainContainer = {
  padding: theme.spacing(2, 4, 0, 4),
  height: '100%',
};

const sxForMainHeader = {
  color: get(['header', 'fontColor'], detailTheme),
  fontFamily: get(['header', 'font'], detailTheme),
  fontSize: get(['header', 'fontSize'], detailTheme),
  marginBottom: theme.spacing(1),
};

const sxForAddress = {
  color: get(['address', 'fontColor'], detailTheme),
  fontFamily: get(['address', 'font'], detailTheme),
  fontSize: get(['address', 'fontSize'], detailTheme),
};

const sxForValue = {
  color: get(['values', 'fontColor'], detailTheme),
  fontFamily: get(['values', 'font'], detailTheme),
  fontSize: get(['values', 'fontSize'], detailTheme),
};

const sxForLabels = {
  color: get(['valueLabels', 'fontColor'], detailTheme),
  fontFamily: get(['valueLabels', 'font'], detailTheme),
  fontSize: get(['valueLabels', 'fontSize'], detailTheme),
};

const sxForDescription = {
  color: get(['description', 'fontColor'], detailTheme),
  fontFamily: get(['description', 'font'], detailTheme),
  fontSize: get(['description', 'fontSize'], detailTheme),
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
};

const sxForSection = {
  margin: theme.spacing(2, 0, 2, 0),
};

const sxForWrapper = {
  display: 'flex',
  width: '100%',
};

const sxForContentContainer = {
  width: '100%',
  height: 'inherit',
  overflowY: 'auto',
  '&::-webkit-scrollbar': { display: 'none' },
};

const sxForSignUpButton = {
  position: 'absolute',
  backgroundImage: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
  fontFamily: `${theme.button.fontFamily}`,
  color: '#FFF',
  width: '100%',
  left: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '4.25vh',
  cursor: 'pointer',
};

export {
  sxForAddress,
  sxForContentContainer,
  sxForDescription,
  sxForLabels,
  sxForMainContainer,
  sxForMainHeader,
  sxForSection,
  sxForSignUpButton,
  sxForValue,
  sxForWrapper,
};
