import { get } from 'lodash/fp';

import getDepartmentsRequestSchema from '@careerstart/wae-common/schema/corporation/department/read.post.req.json';

import SearchableSelectDropdownFilter, {
  SEARCHBAR_BACKGROUND,
} from '../../../components/Filters/SearchableSelectDropdownFilter';
import SelectDropdownFilter, {
  SELECTDROPDOWN_BACKGROUND,
} from '../../../components/Filters/SelectDropdownFilter';
import TimeRangePickerFilter from '../../../components/Filters/TimeRangePickerFilter';

export const additionalFilters = (corpId) => [
  {
    customFilter: {
      background: SELECTDROPDOWN_BACKGROUND.LIGHT,
      field: 'status',
      operation: 'equals',
      getOptionLabel: (option) => get(['name'], option),
      options: [
        { name: 'Approved', value: 'approved' },
        { name: 'Not Approved', value: 'pending' },
        { name: 'Disputed', value: 'disputed' },
        { name: 'Resolved', value: 'resolved' },
        { name: 'Finalized', value: 'finalized' },
      ],
      placeholder: 'Status',
      type: SelectDropdownFilter,
    },
  },
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      field: 'corporation',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'corporations/read',
        generateBody: (searchTerm) => ({
          filters: [{ operation: 'contains', field: 'name', value: searchTerm }],
        }),
      },
      getOptionLabel: (option) => get(['name'], option),
      placeholder: 'Corporation',
      type: SearchableSelectDropdownFilter,
    },
  },
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      field: 'department',
      disabled: !corpId,
      conditionalSearchTerm: corpId,
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'corporations/departments/read',
        generateBody: (searchTerm) => ({
          ...(corpId && { corporation: corpId }),
          filters: [
            searchTerm && { operation: 'contains', field: 'name', value: searchTerm },
          ].filter((i) => i),
        }),
        contract: getDepartmentsRequestSchema,
      },
      getOptionLabel: (option) => get(['name'], option),
      placeholder: 'Department',
      type: SearchableSelectDropdownFilter,
    },
  },
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      conditionalSearchTerm: corpId,
      disabled: !corpId,
      field: 'location',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'locations/read',
        generateBody: (searchTerm) => ({
          filters: [
            searchTerm && { operation: 'contains', field: 'name', value: searchTerm },
            corpId && {
              operation: 'equalsID',
              field: 'corporation._id',
              value: corpId,
            },
          ].filter((i) => i),
        }),
      },
      getOptionLabel: (option) => `${get(['address'], option)} - ${get(['description'], option)}`,
      placeholder: 'Location',
      type: SearchableSelectDropdownFilter,
    },
  },
  {
    customFilter: {
      field: { start: 'startTime', end: 'startTime' },
      operation: { start: 'after', end: 'before' },
      placeholder: 'Time Range',
      type: TimeRangePickerFilter,
    },
  },
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      field: 'candidate',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'users/read',
        generateBody: (searchTerm) => ({
          filters: [
            { operation: 'contains', field: 'name', value: searchTerm },
            { operation: 'equals', field: 'role', value: 'candidate' },
          ].filter((i) => i),
        }),
      },
      getOptionLabel: (option) => get(['name'], option),
      placeholder: 'Employee',
      type: SearchableSelectDropdownFilter,
    },
  },
];

export const employerAdditionalFilters = (employerCorpId) => [
  {
    customFilter: {
      background: SELECTDROPDOWN_BACKGROUND.LIGHT,
      field: 'status',
      operation: 'equals',
      getOptionLabel: (option) => get(['name'], option),
      options: [
        { name: 'Approved', value: 'approved' },
        { name: 'Not Approved', value: 'pending' },
        { name: 'Conflict', value: 'conflict' },
      ],
      placeholder: 'Status',
      type: SelectDropdownFilter,
    },
  },
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      field: 'department',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'corporations/departments/read',
        generateBody: (searchTerm) => ({
          corporation: employerCorpId,
          filters: [
            searchTerm && { operation: 'contains', field: 'name', value: searchTerm },
          ].filter((i) => i),
        }),
        contract: getDepartmentsRequestSchema,
      },
      getOptionLabel: (option) => get(['name'], option),
      placeholder: 'Department',
      type: SearchableSelectDropdownFilter,
    },
  },
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      field: 'location',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'locations/read',
        generateBody: (searchTerm) => ({
          filters: [
            searchTerm && { operation: 'contains', field: 'name', value: searchTerm },
            {
              operation: 'equalsID',
              field: 'corporation._id',
              value: employerCorpId,
            },
          ].filter((i) => i),
        }),
      },
      getOptionLabel: (option) => `${get(['address'], option)} - ${get(['description'], option)}`,
      placeholder: 'Location',
      type: SearchableSelectDropdownFilter,
    },
  },
  {
    customFilter: {
      field: { start: 'startTime', end: 'startTime' },
      operation: { start: 'after', end: 'before' },
      placeholder: 'Time Range',
      type: TimeRangePickerFilter,
    },
  },
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      field: 'candidate',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'users/read',
        generateBody: (searchTerm) => ({
          filters: [
            { operation: 'contains', field: 'name', value: searchTerm },
            { operation: 'equals', field: 'role', value: 'candidate' },
          ].filter((i) => i),
        }),
      },
      getOptionLabel: (option) => get(['name'], option),
      placeholder: 'Employee',
      type: SearchableSelectDropdownFilter,
    },
  },
];
