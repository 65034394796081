import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import {
  selectCorporationById,
  selectFiles,
} from '../../../store/selectors/candidateJobsListSelectors';
import theme, { groupListCompanyImageSize } from '../../../theme';
import { calculateTotalPay } from '../../../utils/Candidate/payHelpers';
import CorporationLogo from '../../CorporationLogo';

const ShiftGroupCard = ({ itemClickHandler, data, selected }) => {
  const {
    _id,
    title,
    corporationName,
    corporationId,
    totalPay,
    payRateReg,
    multipleShiftCommitment,
    location,
  } = data;
  const componentsTheme = get(['candidate', 'components'], theme);
  const { logo: logoID } =
    useSelector((state) => selectCorporationById(state, corporationId)) || {};

  const files = useSelector(selectFiles);
  const file = get([`${logoID}`], files);

  const sxForCard = {
    padding: theme.spacing(1, 3, 1, 3),
    marginBottom: theme.spacing(3),
    backgroundColor: selected
      ? theme.candidate.components.shiftGroupCard.backgroundColor.selected
      : theme.candidate.components.shiftGroupCard.backgroundColor.default,
    borderRadius: theme.spacing(2),
    display: 'flex',
  };
  const sxForTitle = {
    color: selected
      ? theme.candidate.components.shiftGroupCard.headers.selectedFontColor
      : theme.candidate.components.shiftGroupCard.headers.fontColor,
    fontFamily: theme.candidate.components.shiftGroupCard.headers.font,
    fontSize: '18px',
  };
  const sxForTotal = {
    color: selected
      ? theme.candidate.components.shiftGroupCard.headers.selectedContrastColor
      : theme.candidate.components.shiftGroupCard.headers.contrastColor,
    fontFamily: theme.candidate.components.shiftGroupCard.headers.font,
  };
  const sxForSubtext = {
    color: selected
      ? theme.candidate.components.shiftGroupCard.subtext.selectedFontColor
      : theme.candidate.components.shiftGroupCard.subtext.fontColor,
    fontFamily: theme.candidate.components.shiftGroupCard.subtext.font,
    fontSize: '14px',
  };

  const sxForMultipleShift = {
    backgroundColor: get(['jobCard', 'pill', 'backgroundColor', 'default'], componentsTheme),
    borderRadius: '20px',
    color: get(['jobCard', 'pill', 'typography', 'color'], componentsTheme),
    fontFamily: get(['jobCard', 'pill', 'typography', 'font'], componentsTheme),
    fontSize: '12px',
    padding: '2px 6px',
    display: 'inline-block',
  };

  return (
    <Box sx={sxForCard} key={_id} onClick={() => itemClickHandler(data)} alignItems="center">
      <CorporationLogo
        file={file}
        corporationId={corporationId}
        style={{
          height: groupListCompanyImageSize.height,
          width: groupListCompanyImageSize.width,
          marginRight: theme.spacing(1),
        }}
      />
      <Box item sx={{ justifyContent: 'space-between', width: '100%' }}>
        <Grid container key={_id} direction="column" justifyContent="space-between">
          <Box item display="flex" alignItems="center" justifyContent="space-between">
            <Typography sx={sxForTitle}>{title}</Typography>
            <Typography variant="h5" sx={sxForTotal}>
              {calculateTotalPay(totalPay)}
            </Typography>
          </Box>
          <Box
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ marginRight: '5px' }}>
                <Typography variant="subtitle2" sx={sxForSubtext}>
                  {corporationName}
                </Typography>
                <Typography variant="subtitle2" sx={sxForSubtext}>
                  {get('address', location)},{get('city', location)},{get('state', location)}
                  {multipleShiftCommitment && (
                    <span style={sxForMultipleShift}> Multiple Shift Commitment</span>
                  )}
                </Typography>
              </Box>
            </Box>

            <Typography variant="subtitle2" sx={sxForSubtext}>
              ${payRateReg}/hr
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

ShiftGroupCard.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    logoID: PropTypes.string,
    location: PropTypes.shape({}),
    title: PropTypes.string,
    corporationName: PropTypes.string,
    corporationId: PropTypes.string,
    totalPay: PropTypes.shape({
      max: PropTypes.number,
      min: PropTypes.number,
    }),
    payRateReg: PropTypes.number,
    imageURL: PropTypes.string,
    multipleShiftCommitment: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  itemClickHandler: PropTypes.func,
  selected: PropTypes.bool,
};

export default ShiftGroupCard;
