import React, { useEffect } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import Form from '../../../../../components/Form/Form';
import FormCheckBox from '../../../../../components/Form/FormCheckBox';
import selectUser from '../../../../../store/selectors/appSelector';
import {
  selectIsLoadingSkills,
  selectSkills,
} from '../../../../../store/selectors/candidateJobsListSelectors';
import theme from '../../../../../theme';
import { epochToShortDateString, epochToTimeInReadableFormat } from '../../../../../utils';
import { calculateTotalJobPay } from '../../../../../utils/Candidate/payHelpers';
import { creditLoggedInCandidateSkills } from '../../../../app';
import { createPlacementsForCandidate, getSkills, setSkills } from '../../reducer';

const SignUpForShifts = ({ multipleShiftCommitment, selectedJobs }) => {
  const dispatch = useDispatch();
  const jobOrderDetails = selectedJobs[0];

  const intl = useIntl();

  const user = useSelector(selectUser);
  const isLoadingSkills = useSelector(selectIsLoadingSkills);
  const skills = useSelector(selectSkills);
  const boxesToCertify = ['candidate.signUp.getToJob', 'candidate.signUp.can.perform.duties'];

  useEffect(() => {
    const selectedJobSkills = () => {
      let selectedJobsSkills = [];
      selectedJobs.forEach((i) => {
        selectedJobsSkills = [
          ...selectedJobsSkills,
          ...(get(['skills'], i) ? get(['skills'], i) : []),
        ];
      });
      return selectedJobsSkills;
    };

    if (selectedJobSkills().length) {
      dispatch(
        getSkills({
          filters: [
            {
              operation: 'idContainedIn',
              field: '_id',
              value: selectedJobSkills(),
            },
          ],
        })
      );
    }
    return () => dispatch(setSkills([]));
  }, [dispatch, selectedJobs]);

  const shifts = [...selectedJobs].sort(
    (firstShift, secondShift) => firstShift.start - secondShift.start
  );
  const drawerTheme = get(['candidate', 'components', 'groupDetails', 'signUpDrawer'], theme);
  const sxForContainer = {
    padding: theme.spacing(2, 6, 2, 6),
  };

  const sxForHeader = {
    color: get(['header', 'fontColor'], drawerTheme),
    fontFamily: get(['header', 'font'], drawerTheme),
    fontSize: get(['header', 'fontSize'], drawerTheme),
  };

  const sxForSubHeader = {
    color: get(['subHeader', 'fontColor'], drawerTheme),
    fontFamily: get(['subHeader', 'font'], drawerTheme),
    fontSize: get(['subHeader', 'fontSize'], drawerTheme),
  };

  const sxForShiftInfoBox = {
    padding: theme.spacing(2),
    backgroundColor: get(['shiftsCard', 'backgroundColor'], drawerTheme),
    borderRadius: theme.spacing(2),
  };

  const sxForShiftName = {
    color: get(['shiftsCard', 'header', 'fontColor'], drawerTheme),
    fontFamily: get(['shiftsCard', 'header', 'font'], drawerTheme),
    fontSize: get(['shiftsCard', 'header', 'fontSize'], drawerTheme),
  };

  const sxForLabels = {
    color: get(['shiftsCard', 'label', 'fontColor'], drawerTheme),
    fontFamily: get(['shiftsCard', 'label', 'font'], drawerTheme),
    fontSize: get(['shiftsCard', 'label', 'fontSize'], drawerTheme),
  };

  const sxForValues = {
    color: get(['shiftsCard', 'value', 'fontColor'], drawerTheme),
    fontFamily: get(['shiftsCard', 'value', 'font'], drawerTheme),
    fontSize: get(['shiftsCard', 'value', 'fontSize'], drawerTheme),
  };

  const sxForShiftSection = {
    margin: theme.spacing(2, 0, 2, 0),
  };

  const jobPay = calculateTotalJobPay({
    start: get('start', jobOrderDetails),
    end: get('end', jobOrderDetails),
    payRateReg: get('payRateReg', jobOrderDetails),
  });

  const onFormSubmit = () => {
    const orderIds = multipleShiftCommitment
      ? [selectedJobs[0]._id]
      : selectedJobs.map((j) => j._id);

    const submitFields = { fields: { jobOrders: orderIds, candidate: user._id } };
    dispatch(createPlacementsForCandidate(submitFields));
    const candidateSkills = (user?.candidate?.skills || []).map((skill) => skill.skill);
    const skillsToCredit = [];
    (selectedJobs[0].skills || []).forEach((skill) => {
      if (!candidateSkills.includes(skill)) {
        skillsToCredit.push(skill);
      }
    });
    if (skillsToCredit.length !== 0) {
      dispatch(
        creditLoggedInCandidateSkills({
          candidates: [user._id],
          skills: skillsToCredit,
        })
      );
    }
  };

  const checkBoxSx = {
    '& .MuiCheckbox-root': {
      color: get(['form', 'checkboxColor'], drawerTheme),
    },
    '& .MuiFormControlLabel-label': {
      color: get(['form', 'labels', 'fontColor'], drawerTheme),
      fontSize: get(['form', 'labels', 'fontSize'], drawerTheme),
      fontFamily: get(['form', 'labels', 'font'], drawerTheme),
    },
    height: '16px',
  };

  const formHeaderSx = {
    color: get(['form', 'header', 'fontColor'], drawerTheme),
    fontSize: get(['form', 'header', 'fontSize'], drawerTheme),
    fontFamily: get(['form', 'header', 'font'], drawerTheme),
  };

  const formSubHeaderSx = {
    color: get(['form', 'subHeader', 'fontColor'], drawerTheme),
    fontSize: get(['form', 'subHeader', 'fontSize'], drawerTheme),
    fontFamily: get(['form', 'subHeader', 'font'], drawerTheme),
  };

  const formFieldData = [...boxesToCertify, ...skills].map((field) => ({
    ComponentType: FormCheckBox,
    label: intl.formatMessage({ id: field }),
    name: field.replaceAll('.', ''),
    fullWidth: true,
    required: true,
    sx: checkBoxSx,
  }));

  // eslint-disable-next-line react/prop-types
  const ShiftCard = ({ shift }) => {
    const startDate = epochToShortDateString(get('start', shift));

    const startTime = epochToTimeInReadableFormat(get('start', shift));
    const endTime = epochToTimeInReadableFormat(get('end', shift));

    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          ...sxForShiftSection,
        }}
      >
        <Box>
          <Typography sx={sxForLabels}>to work on</Typography>
          <Typography sx={sxForValues}>{startDate}</Typography>
        </Box>
        <Box>
          <Typography sx={sxForLabels}>from</Typography>
          <Typography sx={sxForValues}>
            {startTime} - {endTime}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={sxForContainer}>
      <Box sx={{ marginBottom: theme.spacing(4) }}>
        <Box sx={{ marginBottom: theme.spacing(2) }}>
          <Typography sx={sxForHeader}>
            We&apos;re happy to hear you&apos;re interested in this job!
          </Typography>
        </Box>
        <Box>
          <Typography sx={sxForSubHeader}>
            Once you hit &apos;Sign Up&apos; below, you will be able to access your assigned job
            under the My Jobs tab within the Work page. You can also access shifts by viewing the
            detail view of a job you are currently signed up for within the listed listed shifts.
            below, you will be able to access your assigned job under the My Jobs tab within the
            Work page. You can also access your shifts by viewing the detail view of a job you are
            currently signed up for within the listed shifts.
          </Typography>
        </Box>
      </Box>

      <Box sx={sxForShiftInfoBox}>
        <Box sx={sxForShiftSection}>
          <Typography sx={sxForShiftName}>{get('name', jobOrderDetails)}</Typography>
          <Typography sx={sxForLabels}>{get(['corporation', 'name'], jobOrderDetails)}</Typography>
        </Box>

        <Box sx={sxForShiftSection}>
          <Typography sx={sxForLabels}>Estimated shift total</Typography>
          <Typography sx={sxForValues}>{jobPay}</Typography>
        </Box>

        <Box sx={sxForShiftSection}>
          <Typography sx={sxForLabels}>Hourly Rate</Typography>
          <Typography sx={sxForValues}>${get('payRateReg', jobOrderDetails).toFixed(2)}</Typography>
        </Box>

        <Box>
          {shifts.map((shift) => (
            <ShiftCard shift={shift} key={get(['_id'], shift)} />
          ))}
        </Box>
      </Box>

      <Box>
        <Box sx={{ margin: theme.spacing(2, 0, 1, 0) }}>
          <Typography sx={formHeaderSx}>Shift Checklist</Typography>
          <Typography sx={formSubHeaderSx}>
            Please confirm the item(s) below before signing up for this job.
          </Typography>
        </Box>
        {isLoadingSkills ? (
          '...Loading skills'
        ) : (
          <Form
            formFieldData={formFieldData}
            onSubmit={(values) => onFormSubmit(values)}
            buttonData={[
              {
                color: 'primary',
                text: 'Sign up!',
                type: 'submit',
                sx: {
                  width: '100%',
                },
              },
            ]}
          />
        )}
      </Box>
    </Box>
  );
};

export default SignUpForShifts;

SignUpForShifts.propTypes = {
  multipleShiftCommitment: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  selectedJobs: PropTypes.arrayOf(
    PropTypes.shape({ _id: PropTypes.string, skills: PropTypes.arrayOf(PropTypes.shape({})) })
  ),
};
