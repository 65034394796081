// TODO: Revisit this file when we complete HB-1346. Is this file necessary anymore? I believe not.
import { getGridDateOperators, getGridStringOperators } from '@mui/x-data-grid-pro';

export const WAE_SUPPORTED_DATE_FILTER_OPERATORS = getGridDateOperators().filter(
  (op) =>
    op.value === 'after' ||
    op.value === 'onOrAfter' ||
    op.value === 'before' ||
    op.value === 'onOrBefore'
);

export const WAE_SUPPORTED_STRING_FILTER_OPERATORS = getGridStringOperators().filter(
  (op) => op.value === 'contains' || op.value === 'equals'
);

export const WAE_SUPPORTED_FILTER_FIELD_TYPES = {
  DATE: 'date',
  NUMBER: 'number',
  STRING: 'string',
};
