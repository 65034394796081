import React from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import get from 'lodash/fp/get';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';

import store from './main/store/configureStore';
import theme from './main/theme';
import App from './main/views/app/App';

const muiLicenseKey = process.env.REACT_APP_MUI_LICENSE_KEY;
const launchDarklyClientID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID;
const bugsnagKey = process.env.REACT_APP_BUGSNAG_KEY;

Bugsnag.start({
  apiKey: bugsnagKey,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    const userData = get(['app', 'session', 'user'], store.getState());
    event.setUser(get('_id', userData), get('email', userData), get('name', userData));
  },
});

LicenseInfo.setLicenseKey(muiLicenseKey);
(async () => {
  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
  const LDProvider = await asyncWithLDProvider({
    clientSideID: `${launchDarklyClientID}`,
    user: {
      key: 'wae-highbar-client',
      name: 'WAE Client',
    },
    options: {
      /* ... */
    },
  });
  ReactDOM.render(
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <LDProvider>
            <App />
          </LDProvider>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
})();
