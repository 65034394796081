import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import WhiteTrashIcon from '../../../../assets/icons/WhiteTrashIcon';
import { JOB_CATEGORY } from '../../../../constants/jobInformation';
import UserRole from '../../../../constants/user-role';
import selectUser from '../../../../store/selectors/appSelector';
import theme from '../../../../theme';

import DeletePlacementDrawer from './SubComponents/DeletePlacementDrawer';
import ManageUserDrawer from './SubComponents/ManageUserDrawer';

const MODE_KEYS = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const styleForCandidateName = {
  color: theme.components.detailView.typography.locationText.fontColor,
  fontFamily: theme.components.detailView.typography.locationText.fontFamily,
};

const RosterCard = ({
  accordionType,
  handleOnSave,
  mode,
  fav,
  name,
  originalEmployerNote,
  attendanceObj,
  placementId,
  handleCancelPlacement,
  isRecruiterPlacementBlocked,
  currUserRole,
}) => {
  let filledIconsColor;

  switch (mode) {
    case MODE_KEYS.DARK:
      filledIconsColor = get(['button', 'palette', 'whiteIcon'], theme);

      break;
    default:
      // Light mode designs don't exist right now.
      filledIconsColor = get(['button', 'palette', 'whiteIcon'], theme);

      break;
  }

  const styleForAccordian = {
    backgroundColor:
      accordionType === JOB_CATEGORY.shift
        ? `${theme.palette.background.dark}`
        : `${theme.palette.background.black}`,
    borderRadius: '25px',
    padding: theme.spacing(1, 0, 0, 0),
    justifyContent: 'space-between',
    margin: 0,
    boxShadow: 0,
    width: '100%',
    marginTop: '15px',
  };

  const user = useSelector(selectUser);
  const [isDeletePlacementDrawerOpen, setIsDeletePlacementDrawerOpen] = useState(false);

  const [isUserManageDrawerOpen, setIsUserManageDrawerOpen] = useState(false);

  const isRecruiterForbiddenToCreateCancelPlacements =
    isRecruiterPlacementBlocked && currUserRole === UserRole.RECRUITER;

  const handleDeleteOpen = () => {
    setIsDeletePlacementDrawerOpen(true);
  };

  const handleEditClick = () => {
    setIsUserManageDrawerOpen(true);
  };

  return (
    <Box sx={styleForAccordian}>
      <Box sx={{ padding: theme.spacing(0, 2) }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            paddingRight: '5px',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {fav && <FavoriteIcon sx={{ color: filledIconsColor }} />}
            <Typography sx={styleForCandidateName}>{name}</Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box onClick={handleEditClick}>
              <EditIcon sx={{ color: filledIconsColor }} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!isRecruiterForbiddenToCreateCancelPlacements &&
                (get('role', user) === UserRole.ADMIN ||
                  get('role', user) === UserRole.RECRUITER) && (
                  <Button
                    sx={{
                      borderRadius: '50%',
                      minWidth: '15px',
                      height: '30px',
                    }}
                    onClick={handleDeleteOpen}
                  >
                    <WhiteTrashIcon />
                  </Button>
                )}
              <DeletePlacementDrawer
                type="outlined"
                isOpen={isDeletePlacementDrawerOpen}
                onClose={() => setIsDeletePlacementDrawerOpen(false)}
                selectedPlacementId={placementId}
                candidateName={name}
                handleCancelPlacement={handleCancelPlacement}
              />
              <ManageUserDrawer
                candidateName={name}
                originalEmployerNote={originalEmployerNote}
                handleOnSave={handleOnSave}
                fav={fav}
                placementId={placementId}
                isOpen={isUserManageDrawerOpen}
                onClose={() => setIsUserManageDrawerOpen(false)}
                attendanceObj={attendanceObj}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
RosterCard.propTypes = {
  accordionType: PropTypes.string,
  handleOnSave: PropTypes.func,
  handleCancelPlacement: PropTypes.func,
  placementId: PropTypes.string,
  mode: PropTypes.string,
  attendanceObj: PropTypes.shape({ id: PropTypes.string, label: PropTypes.string }),
  state: PropTypes.shape({ status: PropTypes.string }),
  fav: PropTypes.bool,
  name: PropTypes.string,
  originalEmployerNote: PropTypes.string,
  isRecruiterPlacementBlocked: PropTypes.bool,
  currUserRole: PropTypes.string,
};

export default RosterCard;
