import * as React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import theme from '../../theme';
import { epochToTimeInReadableFormat } from '../../utils';

const TableComponent = ({ columnData, mode, rowsData, sx }) => {
  const MODE_KEYS = {
    DARK: 'dark',
    LIGHT: 'light', // Same as default
  };

  let tableBodyBackgroundColor;
  let fontColor;
  let oddRowBackgroundColor;

  const tableBodyBackgroundColorDark = get(
    ['components', 'table', 'palette', 'body', 'dark', 'backgroundColor'],
    theme
  );
  const fontColorDark = get(['components', 'table', 'palette', 'body', 'dark', 'fontColor'], theme);
  const oddRowBackgroundColorDark = get(
    ['components', 'table', 'palette', 'body', 'dark', 'odd', 'backgroundColor'],
    theme
  );

  switch (mode) {
    case MODE_KEYS.DARK:
      tableBodyBackgroundColor = tableBodyBackgroundColorDark;
      fontColor = fontColorDark;
      oddRowBackgroundColor = oddRowBackgroundColorDark;
      break;
    case MODE_KEYS.LIGHT:
      tableBodyBackgroundColor = tableBodyBackgroundColorDark;
      fontColor = fontColorDark;
      oddRowBackgroundColor = oddRowBackgroundColorDark;
      break;

    default: // By default, use dark
      tableBodyBackgroundColor = tableBodyBackgroundColorDark;
      fontColor = fontColorDark;
      oddRowBackgroundColor = oddRowBackgroundColorDark;
      break;
  }

  const BodyTypography = styled(Box)(() => ({
    color: fontColor,
    fontFamily: get(['components', 'table', 'typography', 'body', 'fontFamily'], theme),
    fontSize: get(['components', 'table', 'typography', 'body', 'fontSize'], theme),
  }));
  const HeaderTypography = styled(Box)(() => ({
    color: fontColor,
    fontFamily: get(['components', 'table', 'typography', 'body', 'fontFamily'], theme),
    fontSize: get(['components', 'table', 'typography', 'header', 'fontSize'], theme),
  }));

  const tableBodyRowsSx = {
    '&:nth-of-type(odd)': {
      backgroundColor: oddRowBackgroundColor,
      color: fontColor,
    },
    '&:nth-of-type(event)': {
      color: fontColor,
    },

    '&:last-child td, &:last-child th': {
      border: 0,
    },
  };

  return (
    <TableContainer component={Box} sx={sx}>
      <Table
        sx={{
          backgroundColor: tableBodyBackgroundColor,
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        }}
      >
        <TableHead>
          <TableRow>
            {columnData &&
              columnData.map((col) => (
                <TableCell key={col}>
                  <HeaderTypography>{col}</HeaderTypography>
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData.length
            ? rowsData.map((row) => (
                <TableRow
                  sx={tableBodyRowsSx}
                  key={`${get(['in', 'stamp'], row)} ${get(['out', 'stamp'], row)}`}
                >
                  <TableCell>
                    <BodyTypography>
                      {epochToTimeInReadableFormat(get(['in', 'stamp'], row))}
                    </BodyTypography>
                  </TableCell>
                  <TableCell>
                    <BodyTypography>
                      {get(['out', 'stamp'], row)
                        ? epochToTimeInReadableFormat(get(['out', 'stamp'], row))
                        : '———'}
                    </BodyTypography>
                  </TableCell>
                  <TableCell>
                    <BodyTypography>{get(['hours'], row).toFixed(2)}</BodyTypography>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
TableComponent.propTypes = {
  rowsData: PropTypes.arrayOf(PropTypes.shape({})),
  mode: PropTypes.string,
  columnData: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.shape({}),
};
export default TableComponent;
