import { get } from 'lodash/fp';

import { FIFTEEN_MIN_IN_EPOCH, NEXT_SHIFT_INDEX } from './constants';

export const getMomentInEpoch = () => new Date().getTime();

export const isActive = (startTimeInEpoch, endTimeInEpoch) =>
  startTimeInEpoch - FIFTEEN_MIN_IN_EPOCH < getMomentInEpoch() &&
  endTimeInEpoch + FIFTEEN_MIN_IN_EPOCH > getMomentInEpoch();

export const disablePunch = (nextShifts, index) =>
  nextShifts.length === 2
    ? (index === NEXT_SHIFT_INDEX.firstShift &&
        get(['placementHasPunches'], nextShifts[NEXT_SHIFT_INDEX.secondShift])) ||
      (index === NEXT_SHIFT_INDEX.secondShift &&
        get(['candidateIsPunchedIn'], nextShifts[NEXT_SHIFT_INDEX.firstShift])) ||
      false
    : false;
