import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import DNRandDNAComponent from './DNRandDNAComponent';

const FormDNROrDNA = ({ input, sx }) => (
  <Box sx={{ ...sx }}>
    <DNRandDNAComponent DNRorDNA={input.value || {}} />
  </Box>
);

FormDNROrDNA.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.shape({}),
  }),
  sx: PropTypes.shape({}),
};

export default FormDNROrDNA;
