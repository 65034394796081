import React, { useEffect } from 'react';
import { get } from 'lodash/fp';
import find from 'lodash/fp/find';
import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Dialog } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import Button from '../../../components/Button';
import UserRole from '../../../constants/user-role';
import selectUser from '../../../store/selectors/appSelector';
import {
  allDataValid,
  corporationOptions,
  jobsList,
  selectedCorporation,
  selectIsLoadingCorporations,
  selectIsLoadingPt,
  selectIsPostingJobs,
  selectPositionTemplateData,
} from '../../../store/selectors/createJobsSelectors';
import theme from '../../../theme';

import CreateJobsDataGridLS from './CreateJobsDataGridLS';
import {
  clearValidateList,
  getCorporations,
  postJobs,
  setJobCreateInitState,
  updateCorporationSelection,
  updateValidateList,
} from './reducer';

const RootGrid = styled(Grid)(() => ({}));

const HeaderGrid = styled(Grid)(() => ({
  padding: theme.spacing(3, 0, 0),
}));

const FormGrid = styled(Grid)(() => ({
  direction: 'column',
  justifyContent: 'center',
  alignContent: 'space-between',
  padding: theme.spacing(0, 0, 3),
}));

const ButtonGrid = styled(Grid)(() => ({
  justifyContent: 'flex-end',
}));

const styleForFormControl = {
  '& .MuiOutlinedInput-notchedOutline': {
    border: '0px',
  },
  '&:hover': {
    backgroundColor: get(['searchBoxForFilter', 'bgColor', 'hover'], theme),
  },
  '&:focus-within': {
    backgroundColor: get(['searchBoxForFilter', 'bgColor', 'focus'], theme),
    border: `1px solid ${get(['searchBoxForFilter', 'borderColor', 'focus'], theme)}`,
  },
  backgroundColor: get(['searchBoxForFilter', 'bgColor', 'complete'], theme),

  borderRadius: '40px',
  justifyContent: 'center',
  height: '40px',
  width: '40vw',
  marginBottom: '8px',
};

const CorporationSelection = () => {
  const dispatch = useDispatch();
  const isLoadingCorporations = useSelector(selectIsLoadingCorporations);
  const corporations = useSelector(corporationOptions);

  useEffect(() => {
    dispatch(getCorporations());
  }, [dispatch]);

  return (
    !isLoadingCorporations && (
      <FormControl sx={styleForFormControl}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={corporations}
          onChange={(event, newValue) => {
            dispatch(updateCorporationSelection(newValue));
          }}
          size="small"
          sx={{ marginBottom: '5px', marginTop: '5px' }}
          renderInput={(params) => <TextField {...params} placeholder="Company" />}
        />
      </FormControl>
    )
  );
};

const CreateJobs = ({ open, handleModalClose }) => {
  const dispatch = useDispatch();
  const displayJobsList = useSelector(jobsList);
  const positionTemplateData = useSelector(selectPositionTemplateData);
  const isAllDataValid = useSelector(allDataValid);
  const selectedCorp = useSelector(selectedCorporation);
  const user = useSelector(selectUser);
  const isLoadingPt = useSelector(selectIsLoadingPt);
  const isPostingJobs = useSelector(selectIsPostingJobs);
  const isCorporationRequired = user.role === UserRole.ADMIN || user.role === UserRole.RECRUITER;

  const intl = useIntl();

  const [openPostDialog, setOpenPostDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [dialogDesc, setDialogDesc] = React.useState('');
  const [postData, setPostData] = React.useState([]);

  useEffect(() => () => dispatch(setJobCreateInitState()), [dispatch]);

  const handleDialogClose = () => {
    setOpenPostDialog(false);
  };

  const handleDialogPost = () => {
    handleDialogClose();
    if (postData.length > 0) {
      const jobsData = isCorporationRequired
        ? map((pd) => ({ ...pd, company: selectedCorp.id }), postData)
        : postData;
      dispatch(postJobs({ orders: jobsData }));
    }
  };

  const generateTimeInfo = (positionId, shiftId, startInfo) => {
    const selectedPositionTemplate = find((pt) => pt._id === positionId, positionTemplateData) || {
      shifts: [],
    };
    const selectShift = find((shift) => shift._id === shiftId, selectedPositionTemplate.shifts) || {
      start: 0,
      end: 0,
    };

    const startMinutes = selectShift.start;
    const endMinutes = selectShift.end;

    const startDate = new Date(startInfo.year, startInfo.month - 1, startInfo.day, 0, 0, 0);
    const endDate = new Date(startDate);

    if (startMinutes > endMinutes) {
      // startMinutes>endMinutes one more day overnight job
      endDate.setDate(endDate.getDate() + 1);
    }

    return {
      start: {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
        minutes: startMinutes,
      },

      end: {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate(),
        minutes: endMinutes,
      },
    };
  };

  const handlePostButton = () => {
    setOpenPostDialog(true);
    dispatch(clearValidateList());
    const res = [];
    if (displayJobsList) {
      displayJobsList.forEach((job) => {
        if (
          job.startInfo.year &&
          job.numOpenings &&
          job.location &&
          job.location.id &&
          job.shift &&
          job.shift.id
        ) {
          const timeInfo = generateTimeInfo(job.position.id, job.shift.id, job.startInfo);
          res.push({
            frontEndId: job.id,
            start: timeInfo.start,
            end: timeInfo.end,
            numOpenings: parseInt(job.numOpenings, 10),
            location: job.location.id,
            positionTemplate: job.position.id,
          });
        } else {
          // TODO: HB-964 add batch validate update for day labor scheduler
          if (!job.position.id) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'position',
                status: false,
              })
            );
          }
          if (!job.location.id) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'location',
                status: false,
              })
            );
          }

          if (!job.startInfo.year) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'startInfo',
                status: false,
              })
            );
          }

          if (!job.shift.id) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'shift',
                status: false,
              })
            );
          }

          if (Number.isNaN(job.numOpenings) || job.numOpenings <= 0) {
            dispatch(
              updateValidateList({
                id: job.id,
                field: 'numOpenings',
                status: false,
              })
            );
          }
        }
      });
    }
    const resLength = res.length;

    let totalOpenNumber = 0;
    res.forEach((job) => {
      totalOpenNumber += job.numOpenings;
    });

    if (resLength > 0) {
      setPostData(res);
      setDialogTitle(`Post all jobs?`);
      setDialogDesc(
        // eslint-disable-next-line react-hooks/rules-of-hooks
        intl.formatMessage(
          { id: 'job_order.batch.create.confirmation' },
          {
            totalOpenings: totalOpenNumber,
          }
        )
      );
    } else {
      setPostData([]);
    }
  };

  return (
    <RootGrid container>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ width: 'inherit', margin: '50px' }}
      >
        <Grid
          it="modal-modal-title"
          item
          backgroundColor="#FFFFFF"
          sx={{
            height: '80vh',
            padding: '32px',
            overflowY: 'auto',
            margin: theme.spacing(2, 0, 0, 0),
          }}
        >
          <IconButton color="inherit" aria-label="close" onClick={handleModalClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
          {isCorporationRequired && (
            <HeaderGrid item>
              <Typography variant="h5" align="left">
                Which corporation is this for?
              </Typography>
              <CorporationSelection />
            </HeaderGrid>
          )}
          {((isCorporationRequired && selectedCorp) || !isCorporationRequired) && (
            <>
              <FormGrid item container>
                <CreateJobsDataGridLS />
              </FormGrid>
              <ButtonGrid item container>
                <Button
                  sx={{ marginLeft: 1 }}
                  text="CANCEL"
                  key="create-jobs-cancel"
                  onClick={() => handleModalClose()}
                />
                <Button
                  sx={{ marginLeft: 1 }}
                  text="POST"
                  variant="contained"
                  key="create-jobs-post"
                  disabled={!isAllDataValid || (isCorporationRequired && !selectedCorp)}
                  onClick={handlePostButton}
                />
                <Dialog
                  sx={{ minWidth: 350 }} // TODO: HB-965 Refactor to use built in Dialog component and set minWidth for Dialog
                  open={openPostDialog}
                  onClose={handleDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description-none-send">
                      <span style={{ whiteSpace: 'pre-line' }}>{dialogDesc}</span>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose}>CANCEL</Button>
                    {postData.length >= 1 && (
                      <Button onClick={handleDialogPost} autoFocus>
                        CONFIRM
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </ButtonGrid>
            </>
          )}
        </Grid>
      </Modal>
      {(isPostingJobs || isLoadingPt) && <BackdropCircularProgress />}
    </RootGrid>
  );
};

CreateJobs.propTypes = {
  open: PropTypes.bool,
  handleModalClose: PropTypes.func,
};

export default CreateJobs;
