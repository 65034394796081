import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import OrangeTrashIcon from '../../../../../assets/icons/OrangeTrashIcon';
import { JOB_CATEGORY, JOB_STATUS } from '../../../../../constants/jobInformation';
import selectUser from '../../../../../store/selectors/appSelector';
import theme from '../../../../../theme';
import {
  epochToDayOfWeek,
  epochToShortDateString,
  epochToTimeInReadableFormat,
} from '../../../../../utils/timeConverter';
import CancelJobDrawer from '../../detail/SubComponents/CancelJobDrawer';
import { cancelJob } from '../../reducer';

const styleForCardBackground = {
  width: '100%',
  backgroundColor: theme.components.detailView.palette.sectionBackgroundColor,
  color: theme.components.detailView.typography.detailText.fontColor,
  marginBottom: '6px',
  padding: '10px',
  borderRadius: '30px',
};

const styleForDate = {
  fontSize: theme.components.detailView.typography.semiBoldText.fontSize,
  margin: '3px',
  color: theme.components.detailView.palette.sectionTextColor,
  fontFamily: theme.components.detailView.typography.semiBoldText.fontFamily,
};

const styleForTime = {
  fontFamily: theme.components.detailView.typography.shiftName.fontFamily,
  fontSize: theme.components.detailView.typography.detailText.fontSize,
  color: theme.components.detailView.typography.detailText.fontColor,
};

const styleForStatus = {
  fontFamily: theme.components.detailView.typography.shiftName.fontFamily,
  fontSize: theme.components.detailView.typography.detailText.fontSize,
  color: theme.components.detailView.typography.detailText.fontColor,
};

const ShiftDetailsCard = ({ selectedShift }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { start, end, status } = selectedShift;
  const [isDeleteShiftDrawerOpen, setIsDeleteShiftDrawerOpen] = useState(false);

  const handelCancelJob = (jobInfo) => {
    dispatch(cancelJob(jobInfo));
  };

  return (
    <Paper variant="outlined" sx={styleForCardBackground}>
      <Typography sx={styleForDate} gutterBottom>
        {epochToShortDateString(start)}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '3px' }}>
        <Typography sx={styleForTime}>
          {epochToDayOfWeek(start)}. {epochToTimeInReadableFormat(start)}-{' '}
          {epochToTimeInReadableFormat(end)}
        </Typography>
        {status === JOB_STATUS.open ? (
          <Button
            sx={{
              borderRadius: '50%',
              minWidth: '15px',
              height: '30px',
            }}
            onClick={() => setIsDeleteShiftDrawerOpen(true)}
          >
            <OrangeTrashIcon />
          </Button>
        ) : (
          <Typography sx={styleForStatus}>{status}</Typography>
        )}

        <CancelJobDrawer
          type="outlined"
          isOpen={isDeleteShiftDrawerOpen}
          onClose={() => setIsDeleteShiftDrawerOpen(false)}
          selectedJob={selectedShift}
          user={user}
          handleCancelJob={handelCancelJob}
          jobType={JOB_CATEGORY.shift}
        />
      </Box>
    </Paper>
  );
};

ShiftDetailsCard.propTypes = {
  selectedShift: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    status: PropTypes.string,
    name: PropTypes.string,
    corporation: PropTypes.shape({}),
    jobDate: PropTypes.string,
    jobShift: PropTypes.string,
  }),
};

export default ShiftDetailsCard;
