import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import theme from '../../../theme';

const AlertBanner = (props) => {
  const { LeftIcon, message, messageSx, backgroundColor, CloseIcon } = props;

  const [isOpen, setIsOpen] = useState(true);

  const sxForCard = {
    backgroundColor,
    display: 'flex',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    justifyContent: CloseIcon ? 'space-between' : 'left',
    alignItems: 'center',
    width: '100%',
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Collapse in={isOpen} sx={{ width: '100%' }}>
      <Box sx={sxForCard}>
        <Box>
          <LeftIcon />
        </Box>
        <Box>
          <Typography sx={{ ...messageSx, margin: theme.spacing(0, 1, 0, 1) }}>
            {message}
          </Typography>
        </Box>

        {CloseIcon && (
          <Box onClick={() => close()}>
            <CloseIcon />
          </Box>
        )}
      </Box>
    </Collapse>
  );
};
AlertBanner.propTypes = {
  LeftIcon: PropTypes.shape({}),
  message: PropTypes.string,
  messageSx: PropTypes.shape({}),
  backgroundColor: PropTypes.string,
  CloseIcon: PropTypes.shape({}),
};

export default AlertBanner;
