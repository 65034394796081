import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { validTimeFormat } from '../../views/timekeeping/TimeKeepingEditDrawer/FormFieldData/FormFieldValidations';

import { PunchInputGrid, styleForTextField } from './Styles/PunchPickerStyles';
import FormDateTimePickerField from './FormDateTimePickerField';

const FormPunchSetField = ({ listData, data, index, handlePunchChange }) => {
  const [punchInputData, setPunchInputData] = useState({
    in: {
      stamp: get(['in', 'stamp', 'stamp'], data)
        ? get(['in', 'stamp', 'stamp'], data)
        : get(['in', 'stamp'], data),
    },
    out: {
      stamp: get(['out', 'stamp', 'stamp'], data)
        ? get(['out', 'stamp', 'stamp'], data)
        : get(['out', 'stamp'], data) || get(['in', 'stamp'], data),
    },
  });

  const [punchError, setPunchError] = useState({ in: undefined, out: undefined });

  const punchInputOnChange = (value, punchInOut) => {
    if (value) {
      const dateObject = new Date(value.toString());
      const epochValue = dateObject.getTime();
      const newPunchData = { ...punchInputData, [punchInOut]: { stamp: epochValue } };

      setPunchInputData(newPunchData);
      setPunchError((prevVal) => ({ ...prevVal, [punchInOut]: validTimeFormat(value) }));
      if (!validTimeFormat(value)) {
        const transformedArray = listData.map((item, listIndex) =>
          listIndex !== index ? item : newPunchData
        );
        handlePunchChange(transformedArray);
      }
    }
  };

  return (
    <PunchInputGrid container spacing={1}>
      <Grid item xs={6}>
        <FormDateTimePickerField
          sx={styleForTextField}
          label="Clock In"
          onChange={(value) => punchInputOnChange(value, 'in')}
          size="small"
          value={new Date(get(['in', 'stamp'], punchInputData))}
          error={punchError.in}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={6}>
        <FormDateTimePickerField
          sx={styleForTextField}
          label="Clock Out"
          onChange={(value) => punchInputOnChange(value, 'out')}
          size="small"
          value={new Date(get(['out', 'stamp'], punchInputData))}
          error={punchError.out}
          variant="outlined"
        />
      </Grid>
    </PunchInputGrid>
  );
};

FormPunchSetField.propTypes = {
  data: PropTypes.shape({}),
  index: PropTypes.number,
  listData: PropTypes.arrayOf(PropTypes.shape({})),
  handlePunchChange: PropTypes.func,
};

export default FormPunchSetField;
