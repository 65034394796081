/* eslint-disable no-unused-vars */
import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import FavoriteIcon from '@mui/icons-material/Favorite';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import WaeButton from '../../../../../components/Button';
import theme from '../../../../../theme';
import { BLACK } from '../../../../../theme/colorConstants';
import { revokeJobInvitations } from '../../reducer';

import CancelledCandidates from './CancelledCandidates';

const invitationCardStyle = {
  backgroundColor: BLACK[100],
  borderRadius: '25px',
  padding: theme.spacing(1, 0, 0, 0),
  justifyContent: 'space-between',
  margin: 0,
  boxShadow: 0,
  width: '100%',
  marginTop: '15px',
};

const styleForCandidateName = {
  color: theme.components.detailView.typography.locationText.fontColor,
  fontFamily: theme.components.detailView.typography.locationText.fontFamily,
};

const styleForInvitation = {
  color: theme.components.detailView.typography.fieldHeaders.fontColor,
  fontFamily: theme.components.detailView.typography.fieldHeaders.fontFamily,
  fontSize: theme.components.detailView.typography.fieldHeaders.fontSize,
};

const InvitationCard = ({ inviteData, fav }) => {
  const dispatch = useDispatch();

  const handleRevokeClick = () => {
    const placementId = get('placementId', inviteData);
    dispatch(revokeJobInvitations({ placement: placementId }));
  };
  return (
    <Box sx={invitationCardStyle}>
      <Box sx={{ display: 'flex' }}>
        {fav && <FavoriteIcon />}
        <Typography sx={styleForCandidateName}>{inviteData.candidateName}</Typography>
      </Box>
      <Box>
        <Typography sx={styleForInvitation}>
          {' '}
          Invitation sent: {inviteData.invitationDate.slice(0, 10)}
        </Typography>
      </Box>
      {/* TODO Uncomment button when BE is ready,also update the time using epoch when BE has updated */}
      {/* <Box> */}
      {/*  {' '} */}
      {/*  <WaeButton text="Revoke Invite" type="outlined" onClick={handleRevokeClick} />{' '} */}
      {/* </Box> */}
    </Box>
  );
};

InvitationCard.propTypes = {
  inviteData: PropTypes.shape({
    candidateName: PropTypes.string,
    invitationDate: PropTypes.string,
  }),
  corporation: PropTypes.shape({}),
  fav: PropTypes.bool,
};

export default InvitationCard;
