import { get } from 'lodash/fp';
import { v4 } from 'uuid';

import { apiUrl } from './axios';
import { logOutOn401 } from './helpers';

const fetchQuery = async function fetchQuery({ route, data, token, httpMethod }) {
  const response = await fetch(`${apiUrl}${route}`, {
    method: httpMethod,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      correlationId: v4(),
    },
    keepalive: true,
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(data),
  });
  return response.json();
};

const asyncServiceWithFetch = ({ httpMethod, route, data, token, onSuccess, onError, dispatch }) =>
  fetchQuery({ route, data, token, httpMethod })
    .then((webResponse) => onSuccess(webResponse))
    .catch((err) => {
      const code = get(['response', 'status'], err);
      if (code === 401 && dispatch) {
        logOutOn401(dispatch);
        return;
      }
      onError(err);
    });
export default asyncServiceWithFetch;
