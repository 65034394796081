import React, { useState } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import theme from '../../../theme';
import { showSnackbar } from '../../../views/app';
import BackdropCircularProgress from '../../BackdropCircularProgress/BackdropCircularProgress';
import Button from '../../Button';

import PunchAlertDrawer from './PunchAlertDrawer';

const ClockInOut = ({
  disablePunch,
  corporationName,
  endTime,
  gpsStrategy,
  hasPunches,
  isPunchedIn,
  isProcessingPunch,
  placementId,
  postPunch,
  punchError,
  setPunchError,
  shiftName,
  startTime,
}) => {
  const dispatch = useDispatch();
  const [gettingCurrentPosition, setGettingCurrentPosition] = useState(false);

  const typography = get(['candidate', 'components', 'timeCards', 'typography'], theme);
  const palette = get(['candidate', 'components', 'timeCards', 'palette'], theme);
  const sxForCard = {
    padding: theme.spacing(2),
    backgroundColor: get(['clockInOutBackgroundClr', hasPunches ? 'clockOut' : 'clockIn'], palette),
    borderRadius: theme.spacing(2),
    justifyContent: 'space-between',
  };
  const punchButtonText = `Clock ${isPunchedIn ? 'out' : 'in'}`;

  const punchClickHandler = () => {
    const nav = navigator;

    if (!nav.geolocation) {
      dispatch(showSnackbar({ message: 'Please enable geolocation for your browser' }));
    } else {
      setGettingCurrentPosition(true);
      nav.geolocation.getCurrentPosition(
        (position) => {
          const coordinates = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
          };

          postPunch({ coordinates, placement: placementId });
          setGettingCurrentPosition(false);
        },
        (error) => {
          dispatch(showSnackbar({ message: error.message }));
          setGettingCurrentPosition(false);
        }
      );
    }
  };

  return (
    <Grid container sx={sxForCard} direction="row">
      <Grid container item xs="auto">
        {hasPunches ? (
          <Typography sx={get(['clockInOut', 'header', 'hasPunches'], typography)}>
            You are clocked {isPunchedIn ? 'in' : 'out'}.
          </Typography>
        ) : (
          <Grid container alignItems="center">
            <QueryBuilderOutlinedIcon />
            <Typography
              sx={{
                paddingLeft: '8px',
                ...get(['clockInOut', 'header', 'noPunches'], typography),
              }}
            >
              Time to clock in!
            </Typography>
          </Grid>
        )}
        <Grid container direction="column">
          <Typography
            sx={get(['clockInOut', 'shiftName'], typography)}
          >{`${shiftName} `}</Typography>
          <Typography
            sx={get(['clockInOut', 'details'], typography)}
          >{`${corporationName} `}</Typography>
          <Typography
            sx={get(['clockInOut', 'details'], typography)}
          >{`${startTime} - ${endTime} `}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs="auto" alignItems="center">
        <Button
          disabled={disablePunch}
          text={punchButtonText}
          onClick={() => punchClickHandler(true)}
          sx={{ height: '40px' }}
        />
      </Grid>
      <PunchAlertDrawer
        punchError={punchError}
        onClose={() => dispatch(setPunchError())}
        placementId={placementId}
        postPunch={postPunch}
        punchButtonText={punchButtonText}
        gpsStrategy={gpsStrategy}
        gettingCurrentPosition={gettingCurrentPosition}
        setGettingCurrentPosition={(val) => setGettingCurrentPosition(val)}
      />
      {(isProcessingPunch || gettingCurrentPosition) && <BackdropCircularProgress />}
    </Grid>
  );
};

ClockInOut.propTypes = {
  disablePunch: PropTypes.bool,
  corporationName: PropTypes.string,
  endTime: PropTypes.string,
  gpsStrategy: PropTypes.string,
  hasPunches: PropTypes.bool,
  isPunchedIn: PropTypes.bool,
  isProcessingPunch: PropTypes.bool,
  placementId: PropTypes.string,
  postPunch: PropTypes.func,
  punchError: PropTypes.string,
  setPunchError: PropTypes.func,
  shiftName: PropTypes.string,
  startTime: PropTypes.string,
};

export default ClockInOut;
