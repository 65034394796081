import React, { useCallback, useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { first, get, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import PostJobReqSch from '@careerstart/wae-common/schema/job-order/shift/create.put.req.json';
import Drawer from '@mui/material/Drawer';

import { isGreaterThanDate } from '../../../../components/Form/formLevelValidations';
import { validateSchema } from '../../../../components/Form/validations';
import WorkFlow from '../../../../components/WorkFlow';
import UserRole from '../../../../constants/user-role';
import selectUser from '../../../../store/selectors/appSelector';
import { selectCorporations } from '../../../../store/selectors/corporationListSelectors';
import {
  selectErrorPutGroupOrders,
  selectIsProcessingPutGroupOrders,
} from '../../../../store/selectors/jobListSelectors';
import { dateToYearMonthDayMinutes } from '../../../../utils';
import launchDarklyToggles from '../../../../utils/launchDarklyToggles';
import { getCorporationsList } from '../../../corporations/corporationList';
import { clearPutGroupOrdersError, putGroupOrders } from '../reducer';

import {
  LongTermJobFormFieldDataForAdminAndRecruiter,
  LongTermJobFormFieldDataForEmployer,
  readOnlyValues,
} from './FormFieldData/FormFieldData';

const LongTermJobListDrawer = ({ isOpen, flags, onClose }) => {
  const [createJobValues, setCreateJobValues] = useState({});
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const corp = useSelector(selectCorporations);
  const errorPutGroupOrders = useSelector(selectErrorPutGroupOrders);
  const isProcessingPutGroupOrders = useSelector(selectIsProcessingPutGroupOrders);
  const role = get('role', user);
  const employerCorporationName = get(['name'], first(corp));
  const employerCorporationId = get(['id'], first(corp));
  const JobCreateShiftGroupToggle = launchDarklyToggles(
    flags,
    'isShiftGroupToggleInJobCreateDrawerEnabled'
  );

  useEffect(() => {
    dispatch(getCorporationsList());
  }, [dispatch]);
  const formFieldDataByType = () => {
    switch (role) {
      case UserRole.ADMIN:
      case UserRole.RECRUITER:
        return LongTermJobFormFieldDataForAdminAndRecruiter(
          createJobValues,
          JobCreateShiftGroupToggle
        );
      case UserRole.EMPLOYER:
        return LongTermJobFormFieldDataForEmployer(
          createJobValues,
          employerCorporationName,
          employerCorporationId,
          JobCreateShiftGroupToggle
        );

      default:
        return [];
    }
  };
  const handleOnClose = useCallback(() => {
    onClose();
    dispatch(clearPutGroupOrdersError());
  }, [dispatch, onClose]);

  const workFlowValuesUpdated = (values) => {
    setCreateJobValues(values);
  };
  const handleAPICall = (values) => {
    const corporationID = get(['corporations', '_id'], values);
    const positionTemplateID = get(['positionTemplate', '_id'], values);
    const locationID = get(['location', '_id'], values);
    const departmentID = get(['department', '_id'], values);
    const startDate = omit('minutes', dateToYearMonthDayMinutes(get(['startDate'], values)));
    const endDate = omit('minutes', dateToYearMonthDayMinutes(get(['endDate'], values)));
    const numOpenings = parseInt(get(['numOpenings'], values), 10);
    const shift = omit(['_id', 'name'], get(['shift'], values));
    const daysOfWeek = get(['daysOfWeek'], values);
    const data = {
      corporation: corporationID,
      positionTemplate: positionTemplateID,
      location: locationID,
      department: departmentID,
      daysOfWeek,
      numOpenings,
      grouped: get('singleEmployeeShift', values),
      shift,
      start: startDate,
      end: endDate,
    };

    if (!get('FORM_ERROR', validateSchema(data, PostJobReqSch))) {
      dispatch(putGroupOrders(data));
      return true;
    }
    return false;
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleOnClose}
      PaperProps={{
        sx: {
          width: '576px',
          maxWidth: '100vw',
        },
      }}
    >
      <WorkFlow
        confirmationPageButtonText="Back to Jobs"
        formSpec={formFieldDataByType()}
        onClose={handleOnClose}
        readOnlyValues={readOnlyValues(createJobValues, employerCorporationName)}
        workFlowValuesUpdatedCallBack={(values) => workFlowValuesUpdated(values)}
        validateForm={isGreaterThanDate('startDate', 'endDate', {
          endDate: 'End date must be after the start date',
          startDate: 'End date must be after the start date',
        })}
        attemptToHandleAPICall={(values) => handleAPICall(values)}
        isApiCallProcessing={isProcessingPutGroupOrders}
        processingMessage="message.generic.processing"
        successMessage="success.job.post"
        errorMessage={errorPutGroupOrders}
      />
    </Drawer>
  );
};

LongTermJobListDrawer.propTypes = {
  isOpen: PropTypes.bool,
  flags: PropTypes.shape({}),
  onClose: PropTypes.func,
};

export default withLDConsumer()(LongTermJobListDrawer);
