import FormPhoneField from '../../../components/Form/FormPhoneField';
import { isValidPhoneNumber } from '../../../components/Form/validations';

export const NotificationSettingsData = {
  phone: {
    ComponentType: FormPhoneField,
    key: 'notificationSettingsPhoneValue',
    name: 'notificationSettingsPhoneValue',
    validate: isValidPhoneNumber,
  },
};

export const buildButtonData = [
  {
    color: 'primary',
    text: 'Update Notification Settings',
    type: 'submit',
  },
];
