import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Paper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { selectRecruiterCorporations } from '../../../../../store/selectors/userSelectors';
import theme from '../../../../../theme/theme';

const DNRorDNAAutocomplete = ({ onChange }) => {
  const corporations = useSelector(selectRecruiterCorporations);
  const [open, setOpen] = React.useState(false);

  return (
    <Autocomplete
      PaperComponent={({ children }) => (
        <Paper sx={{ backgroundColor: theme.palette.secondary.main, color: 'white' }}>
          {children}
        </Paper>
      )}
      fullWidth
      variant="standard"
      id="asynchronous-demo"
      sx={{ width: '100%', '& .MuiOutlinedInput-input': { color: 'white' } }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        onChange(value);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={corporations}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '40px',
              width: '100%',
              background: theme.palette.secondary.main,
              borderRadius: '40px',
              padding: theme.spacing(0, 1),
              fontFamily: 'Barlow',
              '&:hover': {
                background: theme.palette.secondary.light,
              },
            },
          }}
          placeholder="Corporation"
        />
      )}
    />
  );
};

DNRorDNAAutocomplete.propTypes = {
  onChange: PropTypes.func,
};

export default DNRorDNAAutocomplete;
