import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const TickIcon = () => (
  <SvgIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3739 6.29289C19.7644 6.68342 19.7644 7.31658 19.3739 7.70711L9.74885 17.3321C9.35833 17.7226 8.72517 17.7226 8.33464 17.3321L3.95964 12.9571C3.56912 12.5666 3.56912 11.9334 3.95964 11.5429C4.35017 11.1524 4.98333 11.1524 5.37385 11.5429L9.04175 15.2108L17.9596 6.29289C18.3502 5.90237 18.9833 5.90237 19.3739 6.29289Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);

export default TickIcon;
