import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import { get, getOr } from 'lodash/fp';
import findIndex from 'lodash/fp/findIndex';
import { batch, useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import AlertBanner from '../../../components/Candidate/AlertBanner';
import ClockInOut from '../../../components/Candidate/ClockInOut';
import ListJobCard from '../../../components/Candidate/JobCards/ListJobCard';
import SquareJobCardBrief from '../../../components/Candidate/JobCards/SquareJobCardBrief';
import SquareJobCardDetailed from '../../../components/Candidate/JobCards/SquareJobCardDetailed';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectActiveJobs,
  selectCandidatesJobs,
  selectFeaturedJobs,
  selectHasLoadedFeaturedJobs,
  selectHasLoadedIndustryJobs,
  selectHasLoadedInterestJobs,
  selectIndustryJobs,
  selectInterestJobs,
  selectIsLoadingCandidatesJobs,
  selectIsProcessingPunch,
  selectPunchError,
} from '../../../store/selectors/Candidate/dashboardSelector';
import theme, { navigationBarHeight } from '../../../theme';

import MyTime from './components/MyTime';
import TopBanner from './components/TopBanner';
import filters from './filters';
import {
  getCandidatesJobs,
  getFeaturedJobs,
  getIndustryJobs,
  getInterestJobs,
  postPunchForDashboard,
  setPunchError,
} from './reducer';

const deSelect = (induJobs, feaJobs, inteJobs, selectedJobs) => {
  selectedJobs.forEach((selectedJob) => {
    const induIndex = findIndex(
      (job) => (job._id && selectedJob._id ? job._id === selectedJob._id : false),
      induJobs
    );
    if (induIndex >= 0) {
      induJobs.splice(induIndex, 1);
    }
    const feaIndex = findIndex(
      (job) => (job._id && selectedJob._id ? job._id === selectedJob._id : false),
      feaJobs
    );
    if (feaIndex >= 0) {
      feaJobs.splice(feaIndex, 1);
    }

    const inteIndex = findIndex(
      (job) => (job._id && selectedJob._id ? job._id === selectedJob._id : false),
      inteJobs
    );
    if (inteIndex >= 0) {
      inteJobs.splice(inteIndex, 1);
    }
  });
};

const dashboardTheme = get(['candidate', 'components', 'dashboard'], theme);
const sxForHeaders = {
  color: get(['headers', 'fontColor'], dashboardTheme),
  fontFamily: get(['headers', 'font'], dashboardTheme),
  marginBottom: theme.spacing(1),
};
const sxForSubHeaders = {
  color: get(['subHeaders', 'fontColor'], dashboardTheme),
  fontFamily: get(['subHeaders', 'font'], dashboardTheme),
  marginBottom: theme.spacing(1),
};
const sxForEmptyJobs = {
  color: get(['emptyJob', 'fontColor'], dashboardTheme),
  fontFamily: get(['emptyJob', 'font'], dashboardTheme),
  marginBottom: theme.spacing(1),
};

const sxForGrid = {
  maxWidth: '100%',
  margin: 0,
};
const sxForFeaturedBoxDesktop = {
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'space-between',
};

const sxForFeaturedBoxMobile = {
  display: 'flex',
  overflow: 'scroll',
  justifyContent: 'space-between',
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const candidatesJobs = useSelector(selectCandidatesJobs);
  const user = useSelector(selectUser);
  const alertBannerTheme = get(['candidate', 'components', 'alertBanner'], theme);
  const featuredJobs = useSelector(selectFeaturedJobs);
  const industryJobs = useSelector(selectIndustryJobs);
  const interestJobs = useSelector(selectInterestJobs);
  const [isLoadingDisplayJobs, setIsLoadingDisplayJobs] = useState(false);
  const [displayFeaturedJobs, setDisplayFeaturedJobs] = useState([]);
  const [displayIndustryJobs, setDisplayIndustryJobs] = useState([]);
  const [displayInterestJobs, setDisplayInterestJobs] = useState([]);
  const isLoadingCandidatesJobs = useSelector(selectIsLoadingCandidatesJobs);
  const hasLoadedFeaturedJobs = useSelector(selectHasLoadedFeaturedJobs);
  const hasLoadedIndustryJobs = useSelector(selectHasLoadedIndustryJobs);
  const hasLoadedInterestJobs = useSelector(selectHasLoadedInterestJobs);
  const isProcessingPunch = useSelector(selectIsProcessingPunch);
  const punchError = useSelector(selectPunchError);

  const activeJobs = useSelector(selectActiveJobs);
  const mediumScreen = useMediaQuery('(min-width:1000px)');
  const phoneScreen = useMediaQuery('(min-width:600px)');
  const userName = getOr('', 'name', user);

  const sxForColumnWrapper = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: mediumScreen ? 'row' : 'column',
  };

  const candidatePreferredIndustry =
    get(['candidate', 'industry'], user).length > 0
      ? get(['candidate', 'industry'], user)[0]
      : 'manufacturing';

  const featuredFilterInfo = {
    limit: 12,
    filters: filters.FEATURE,
    sortBy: [{ field: 'fillRatio', descending: true }],
  };
  const industryFilterInfo = {
    limit: 12,
    filters: [{ field: 'industry', operation: 'equals', value: candidatePreferredIndustry }],
    sortBy: [{ field: 'start', descending: true }],
  };
  const interestFilterInfo = {
    limit: 12,
    sortBy: [{ field: 'start', descending: true }],
  };

  useEffect(() => {
    batch(() => {
      setIsLoadingDisplayJobs(true);
      dispatch(getCandidatesJobs(filters.THIS_WEEK_JOB));
      dispatch(getFeaturedJobs(featuredFilterInfo));
      dispatch(getIndustryJobs(industryFilterInfo));
      dispatch(getInterestJobs(interestFilterInfo));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (hasLoadedFeaturedJobs && hasLoadedIndustryJobs && hasLoadedInterestJobs) {
      setIsLoadingDisplayJobs(false);

      const featuredJobArray = [...featuredJobs];
      const industryJobsArray = [...industryJobs];
      const interestJobsArray = [...interestJobs];

      // STEP1 try to select 4 jobs for each joblist
      const displayIndustryJobsArray = industryJobsArray.splice(0, 4);
      // for the Jobs has been selected to display, remove them from joblist  to avoid duplicate jobs
      deSelect(industryJobsArray, featuredJobArray, interestJobsArray, displayIndustryJobsArray);
      const displayFeaturedJobArray = featuredJobArray.splice(0, 4);
      deSelect(industryJobsArray, featuredJobArray, interestJobsArray, displayFeaturedJobArray);
      const displayInterestJobsArray = interestJobsArray.splice(0, 4);
      // deSelect(industryJobsArray, featuredJobArray, interestJobsArray, displayInterestJobsArray);

      // STEP2 display these three joblist
      setDisplayFeaturedJobs(displayFeaturedJobArray);
      setDisplayIndustryJobs(displayIndustryJobsArray);
      setDisplayInterestJobs(displayInterestJobsArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoadedFeaturedJobs, hasLoadedIndustryJobs, hasLoadedInterestJobs]);
  return (
    <Grid
      sx={{
        position: 'relative',
        height: phoneScreen
          ? `calc(100vh - ${navigationBarHeight})`
          : `calc(90vh - ${navigationBarHeight})`,
        top: navigationBarHeight,
      }}
      container
    >
      <Box sx={sxForGrid}>
        <Box sx={{ width: '100%', margin: theme.spacing(0, 0, 2, 0) }}>
          {phoneScreen && <TopBanner userName={userName} />}
          <AlertBanner
            LeftIcon={WarningAmberIcon}
            CloseIcon={CloseIcon}
            message="Earnings displayed are pre-tax and do not include any withhold amounts. The final amount
          received may be different after taxes and other deductions have been taken out."
            backgroundColor={get(['backgroundColor'], alertBannerTheme)}
            messageSx={{
              color: get(['fontColor'], alertBannerTheme),
              fontFamily: get(['font'], alertBannerTheme),
            }}
          />
        </Box>
        <Box sx={sxForColumnWrapper}>
          <Box sx={{ width: mediumScreen ? '60%' : '100%' }}>
            {activeJobs.length && (
              <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                {activeJobs.map(
                  (shift) =>
                    get(['isActive'], shift) && (
                      <Grid
                        item
                        xs={12}
                        md={get(['isActive'], activeJobs[1]) ? 6 : 12}
                        key={get(['placementId'], shift)}
                      >
                        <ClockInOut
                          disablePunch={get(['disablePunch'], shift)}
                          corporationName={get(['corporationName'], shift)}
                          endTime={get(['endTime'], shift)}
                          gpsStrategy={get(['gpsStrategy'], shift)}
                          hasPunches={get(['placementHasPunches'], shift)}
                          isPunchedIn={get(['candidateIsPunchedIn'], shift)}
                          isProcessingPunch={isProcessingPunch}
                          placementId={get(['placementId'], shift)}
                          postPunch={(val) => dispatch(postPunchForDashboard(val))}
                          punchError={punchError}
                          setPunchError={setPunchError}
                          shiftName={get(['shiftName'], shift)}
                          startTime={get(['startTime'], shift)}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            )}
            <Box>
              <Typography sx={sxForHeaders}>My Time This Week</Typography>
              <MyTime />
            </Box>

            <Box>
              <Typography sx={sxForHeaders}>My Jobs This Week</Typography>

              {isLoadingCandidatesJobs && <CircularProgress />}
              {!isLoadingCandidatesJobs && candidatesJobs.length ? (
                <>
                  {candidatesJobs.map((item) => (
                    <ListJobCard
                      corporation={item.corporation}
                      jobOrder={item.placement.jobOrder}
                      key={item.placement._id}
                    />
                  ))}
                </>
              ) : (
                <Typography sx={sxForEmptyJobs}> You have no jobs this week </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{ width: mediumScreen ? '38%' : '100%' }}>
            <Box>
              <Typography sx={sxForSubHeaders}>Featured Jobs</Typography>
              <Box sx={mediumScreen ? sxForFeaturedBoxDesktop : sxForFeaturedBoxMobile}>
                {isLoadingDisplayJobs && <CircularProgress />}
                {!isLoadingDisplayJobs && displayFeaturedJobs.length ? (
                  <>
                    {displayFeaturedJobs.map((item) => (
                      <SquareJobCardDetailed
                        key={item._id}
                        data={item}
                        sx={
                          mediumScreen ? {} : { minWidth: '220px', marginRight: theme.spacing(2) }
                        }
                      />
                    ))}
                  </>
                ) : (
                  <Typography sx={sxForEmptyJobs}> There are no featured jobs </Typography>
                )}
              </Box>
            </Box>

            <Box>
              <Typography sx={sxForSubHeaders}>
                Discover Jobs in {startCase(candidatePreferredIndustry)}
              </Typography>
              <Box
                sx={{
                  display: 'flex ',
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                {isLoadingDisplayJobs && <CircularProgress />}
                {!isLoadingDisplayJobs && displayIndustryJobs.length ? (
                  <>
                    {displayIndustryJobs.map((item) => (
                      <SquareJobCardBrief data={item} key={item._id} />
                    ))}
                  </>
                ) : (
                  <Typography sx={sxForEmptyJobs}>
                    {' '}
                    There are no {startCase(candidatePreferredIndustry)} jobs{' '}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Typography sx={sxForSubHeaders}>Jobs That Might Interest You</Typography>
              <Box
                sx={{
                  display: 'flex ',
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                {isLoadingDisplayJobs && <CircularProgress />}
                {!isLoadingDisplayJobs && displayInterestJobs.length ? (
                  <>
                    {displayInterestJobs.map((item) => (
                      <SquareJobCardBrief data={item} key={item._id} />
                    ))}
                  </>
                ) : (
                  <Typography sx={sxForEmptyJobs}> There are no Interest Jobs </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default Dashboard;
