import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { CreditCardOutlined, QueryBuilderOutlined } from '@mui/icons-material';
import AutoAwesomeMosaicOutlined from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import theme from '../../../../theme';

const BottomAppBar = () => {
  const location = useLocation();
  let currentValue = location.pathname.split('/')[1];
  currentValue = currentValue === 'dashboard' ? 'jobs' : currentValue;
  const [value, setValue] = React.useState(currentValue);

  return (
    <>
      <CssBaseline />
      <BottomNavigation
        value={value}
        onChange={(_event, newValue) => setValue(newValue)}
        sx={{
          backgroundColor: theme.candidate.components.nav.footer.backgroundColor,
          borderRadius: theme.spacing(2, 2, 0, 0),
          '& .MuiSvgIcon-root': {
            color: theme.candidate.components.nav.footer.inactive,
          },
          '& .Mui-selected': {
            '& .MuiSvgIcon-root': {
              color: theme.candidate.components.nav.footer.active,
              backgroundColor: theme.candidate.components.nav.footer.constrastColor,
              borderRadius: '50%',
              width: '45px',
              height: '45px',
              padding: theme.spacing(1),
            },
          },
        }}
      >
        <BottomNavigationAction
          component={Link}
          to="dashboard"
          value="dashboard"
          icon={<AutoAwesomeMosaicOutlined />}
        />
        <BottomNavigationAction
          component={Link}
          to="jobs/jobsList"
          value="jobs"
          icon={<BusinessCenterOutlinedIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="timekeeping"
          value="timekeeping"
          icon={<QueryBuilderOutlined />}
        />
        <BottomNavigationAction
          component={Link}
          to="payment"
          value="payment"
          icon={<CreditCardOutlined />}
        />
      </BottomNavigation>
    </>
  );
};

export default BottomAppBar;
