import React, { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { first, get, getOr } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BackButtonIcon from '../../../assets/icons/BackButton.svg';
import FrontButtonIcon from '../../../assets/icons/ForwardButton.svg';
import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import Button from '../../../components/Button';
import WaePaginatedDataGrid from '../../../components/DataGrid';
import DetailView from '../../../components/DetailView/DetailView2';
import MuiIconButton from '../../../components/IconButton/IconButton';
import ListHeader from '../../../components/ListHeader';
import searchParamOptions from '../../../constants/searchParams';
import UserRole from '../../../constants/user-role';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectSelectedTimeCard,
  selectTimecardActionIsProcessing,
  selectTimeCards,
  selectTimeCardsIsProcessing,
  selectTimeSheetGridTotalRowCount,
} from '../../../store/selectors/timeSheetSelector';
import { dataGridFiltersHeight, listHeaderHeight } from '../../../theme';
import { BLACK } from '../../../theme/colorConstants';
import { epochToShortDateString, isSearchParamValid } from '../../../utils';
import { useDims } from '../../../utils/customHooks';
import { getSunday } from '../../../utils/timeConverter';
import { SEVEN_DAYS_IN_MS } from '../../Candidate/constants';
import { TIMEKEEPING_STATUSES } from '../constants';
import Overview from '../detailView/Overview';
import { getPathSelectedTimeCards, getTimeCards, setSelectedTimeCard } from '../reducer';
import TimeKeepingBatchApprovalDrawer from '../TimeKeepingBatchApprovalDrawer/TimeKeepingBatchApprovalDrawer';

import { additionalFilters, employerAdditionalFilters } from './additionalFilterData';
import { getDays, getTimekeepingGridColumnData } from './timekeepingGridColumnData';

const RootGrid = styled(Grid)(({ theme }) => ({
  height: '100vh',
  padding: theme.spacing(8, 0),
}));

const TimekeepingGridView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedStart = searchParams.get(searchParamOptions.START);
  const selectedCandidate = searchParams.get(searchParamOptions.CANDIDATE);
  const selectedDateEpoch = searchParams.get(searchParamOptions.DATE);

  const defaultStart =
    getSunday(new Date(parseInt(selectedStart, 10) * 1000)) || getSunday(new Date());

  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const user = useSelector(selectUser);
  const isTimeCardLoading = useSelector(selectTimeCardsIsProcessing);
  const timecardActionIsProcessing = useSelector(selectTimecardActionIsProcessing);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [startEpoch, setStartEpoch] = useState(defaultStart);
  const [columnData, setColumnData] = useState(getTimekeepingGridColumnData(startEpoch) || []);
  const [daysOfWeek, setDaysOfWeek] = useState(getDays(startEpoch));
  const [rowCount, setRowCount] = React.useState(10);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCorp, setSelectedCorp] = useState(null);
  const updateDate = useCallback(
    (shortDateString) => {
      const selectedEpoch = shortDateString ? dayjs(shortDateString).unix() : startEpoch;
      const newParams = {
        [searchParamOptions.START]: selectedStart,
        [searchParamOptions.CANDIDATE]: selectedCandidate,
        [searchParamOptions.DATE]: selectedEpoch,
      };
      setSearchParams(newParams);
    },
    [selectedCandidate, selectedStart, setSearchParams, startEpoch]
  );
  const gridContainerRef = useRef();
  const timecards = useSelector(selectTimeCards);
  const selectedTimeCard = useSelector(selectSelectedTimeCard);
  const totalRowCount = useSelector(selectTimeSheetGridTotalRowCount);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setSearchParams({ [searchParamOptions.CANDIDATE]: null });
    dispatch(setSelectedTimeCard(null));
  }, [dispatch, setSearchParams]);
  const gridContainerDims = useDims(gridContainerRef);

  useEffect(
    () => () => {
      dispatch(setSelectedTimeCard(null));
    },
    [dispatch]
  );

  const checkIfPunchesContainBothInAndOut = (punches) => {
    let check = true;

    punches.forEach((punch) => {
      if (!get(['out', 'stamp'], punch)) {
        check = false;
      }
    });
    return check;
  };
  const generateBatchApprovalIds = () => {
    let placementIDList = [];
    timecards.forEach((card) =>
      Object.keys(get('days', card)).forEach((key) => {
        placementIDList = [
          ...placementIDList,
          ...get('days', card)
            [key].filter(
              (day) =>
                get(['timecard', 'status'], day) === 'pending' &&
                get(['timecard', 'punches'], day).length > 0 &&
                checkIfPunchesContainBothInAndOut(get(['timecard', 'punches'], day))
            )
            .map((day) => get('placement', day)),
        ];
      })
    );
    return placementIDList;
  };
  const generateBatchApprovalNameAndHours = () => {
    let placementNameAndTime = [];
    timecards.forEach((card) =>
      Object.keys(get('days', card)).forEach((key) => {
        get('days', card)
          [key].filter(
            (day) =>
              get(['timecard', 'status'], day) === 'pending' &&
              get(['timecard', 'punches'], day).length > 0 &&
              checkIfPunchesContainBothInAndOut(get(['timecard', 'punches'], day))
          )
          .forEach((time) => {
            if (
              !placementNameAndTime.some(
                (place) => get('id', place) === get(['candidate', 'id'], card)
              )
            ) {
              placementNameAndTime.push({
                id: get(['candidate', 'id'], card),
                values: {
                  name: get(['candidate', 'name'], card),
                  hours: get(['timecard', 'punches'], time).reduce(
                    (prev, curr) => prev + get('hours', curr),
                    0
                  ),
                },
              });
            } else {
              placementNameAndTime = placementNameAndTime.map((place) => {
                if (get('id', place) === get(['candidate', 'id'], card)) {
                  return {
                    ...place,
                    values: {
                      name: get(['values', 'name'], place),
                      hours:
                        get(['values', 'hours'], place) + get(['timecard', 'totalHours'], time),
                    },
                  };
                }
                return place;
              });
            }
          });
      })
    );
    return placementNameAndTime;
  };

  const approveWeek = () => {
    setIsDrawerOpen(true);
    generateBatchApprovalNameAndHours();
    generateBatchApprovalIds();
  };

  const weekStartDate = (epoch) =>
    `${new Date(Math.round(Number(epoch * 1000))).getUTCMonth() + 1}/${new Date(
      Math.round(Number(epoch * 1000))
    ).getUTCDate()}/${new Date(Math.round(Number(epoch * 1000))).getUTCFullYear()}`;

  const weekEndDate = (epoch) =>
    `${new Date(Math.round(Number(epoch * 1000 + 518400000))).getUTCMonth() + 1}/${new Date(
      Math.round(Number(epoch * 1000 + 518400000))
    ).getUTCDate()}/${new Date(Math.round(Number(epoch * 1000 + 518400000))).getUTCFullYear()}`;

  const timecardPaginationQuery = React.useCallback(
    (params) => {
      const corpFilter = get('filters', params).filter((i) => i.field === 'corporation');
      setSelectedCorp(corpFilter.length ? first(corpFilter).value : null);

      const startEpochMili = startEpoch * 1000;
      const endEpochMili = startEpoch * 1000 + SEVEN_DAYS_IN_MS;
      const weekParams = {
        filters: [
          { operation: 'onOrAfter', field: 'start', value: startEpochMili },
          { operation: 'before', field: 'start', value: endEpochMili },
        ],
      };

      const customFilter = {
        filters: [...get('filters', params), ...get('filters', weekParams)],
      };

      dispatch(getTimeCards({ ...params, ...customFilter }));
    },
    [dispatch, startEpoch]
  );

  const oneWeekInMS = 604800;

  const apiPath = 'timecards';

  const tabData = () => [
    {
      tabContent: (
        <Grid container item>
          <Overview
            daysOfWeek={daysOfWeek}
            selectedDate={epochToShortDateString(selectedDateEpoch * 1000)}
            setSelectedDate={updateDate}
            initialData={selectedTimeCard}
          />
        </Grid>
      ),
      tabLabel: 'Overview',
    },
  ];

  const handleSubtractWeekOnClick = () => {
    const newStartEpoch = startEpoch - oneWeekInMS;
    setStartEpoch(newStartEpoch);
    setSearchParams({
      [searchParamOptions.START]: newStartEpoch,
      [searchParamOptions.DATE]: newStartEpoch,
    });
    setDaysOfWeek(getDays(newStartEpoch));
    setColumnData(getTimekeepingGridColumnData(newStartEpoch));
    dispatch(setSelectedTimeCard(null));
    handleClose();
  };

  const handleAddWeekOnClick = () => {
    const newStartEpoch = startEpoch + oneWeekInMS;
    setStartEpoch(newStartEpoch);
    setSearchParams({
      [searchParamOptions.START]: newStartEpoch,
      [searchParamOptions.DATE]: newStartEpoch,
    });
    setDaysOfWeek(getDays(newStartEpoch));
    setColumnData(getTimekeepingGridColumnData(newStartEpoch));
    dispatch(setSelectedTimeCard(null));
    handleClose();
  };

  const sumDayTotalTime = (day) => {
    const value = day.reduce(
      (accumulator, curr) =>
        accumulator + parseFloat(getOr(0, ['timecard', 'totalHours'], curr), 10),
      0
    );
    return value;
  };

  const generateCandidateHoursData = (data) => {
    const daysData = get(['days'], data);

    const days = Object.keys(daysData);
    const returnObj = {};
    const approveList = [];
    days.forEach((day) => {
      returnObj[day] = daysData[day].length > 0 ? sumDayTotalTime(daysData[day]) : '-';

      daysData[day].forEach((time) => {
        approveList.push(get(['timecard', 'status'], time));
      });
    });
    returnObj.status = {
      label:
        (approveList.includes(TIMEKEEPING_STATUSES.DISPUTED) && TIMEKEEPING_STATUSES.DISPUTED) ||
        (approveList.includes(TIMEKEEPING_STATUSES.PENDING) && TIMEKEEPING_STATUSES.PENDING) ||
        (approveList.includes(TIMEKEEPING_STATUSES.APPROVED) && TIMEKEEPING_STATUSES.APPROVED) ||
        (approveList.includes(TIMEKEEPING_STATUSES.RESOLVED) && TIMEKEEPING_STATUSES.RESOLVED) ||
        TIMEKEEPING_STATUSES.FINALIZED,
      detailViewStatus: !!selectedTimeCard,
    };

    return returnObj;
  };

  const onSelectionModelChange = (selectedTimeObject) => {
    if (timecards && selectedTimeObject) {
      const sundayOfSelectedDate = getSunday(new Date(parseInt(selectedDateEpoch, 10) * 1000));
      const shouldUseParamDate =
        isSearchParamValid(selectedDateEpoch) &&
        parseInt(selectedStart, 10) * 1000 === sundayOfSelectedDate;

      setSearchParams({
        [searchParamOptions.START]: startEpoch,
        [searchParamOptions.CANDIDATE]: get(['candidate', 'id'], selectedTimeObject),
        [searchParamOptions.DATE]: shouldUseParamDate ? selectedDateEpoch : '',
      });
    }
  };

  useEffect(() => {
    if (isSearchParamValid(selectedCandidate) && timecards.length > 0) {
      const filterTimecard = timecards.filter(
        (card) => get(['candidate', 'id'], card) === selectedCandidate
      )[0];

      if (filterTimecard) {
        dispatch(setSelectedTimeCard(filterTimecard));
        return;
      }

      const endEpochMili = startEpoch * 1000 + SEVEN_DAYS_IN_MS;

      dispatch(
        getPathSelectedTimeCards({
          filters: [
            { field: 'candidate', value: selectedCandidate, operation: 'equalsID' },
            { field: 'start', value: startEpoch, operation: 'onOrAfter' },
            { field: 'start', value: endEpochMili, operation: 'before' },
          ],
        })
      );
    } else if (selectedCandidate === null) {
      dispatch(setSelectedTimeCard(null));
    }
  }, [dispatch, selectedCandidate, startEpoch, timecards, user]);

  useEffect(() => {
    if (!selectedTimeCard) {
      return;
    }
    if (!selectedDateEpoch) {
      Object.entries(selectedTimeCard.days || {}).forEach((day) => {
        if (epochToShortDateString(selectedStart * 1000) === day[0]) {
          updateDate(day[0]);
        }
      });
    }
  }, [selectedDateEpoch, selectedStart, selectedTimeCard, updateDate]);

  const thisWeekSx = {
    color: `${BLACK[70]}`,
    fontFamily: 'Barlow-700',
    fontSize: '18px',
  };

  const dateRangeSx = {
    color: `${BLACK[70]}`,
    fontFamily: 'Barlow-500',
    fontSize: '18px',
    marginLeft: '8px',
  };

  const WeekFilter = () => (
    <Grid container item justifyContent="space-between" direction="row">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <MuiIconButton
          onClick={handleSubtractWeekOnClick}
          iconElement={
            <Box
              component="img"
              sx={{
                height: '32px',
                width: '32px',
              }}
              alt="Back"
              src={BackButtonIcon}
            />
          }
        />
        <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
          <Box sx={thisWeekSx}> This Week: </Box>
          <Box sx={dateRangeSx}>{`${weekStartDate(startEpoch)}-${weekEndDate(startEpoch)}`}</Box>
        </Box>
        {weekStartDate(startEpoch) !== weekStartDate(getSunday(new Date())) && (
          <MuiIconButton
            onClick={handleAddWeekOnClick}
            iconElement={
              <Box
                component="img"
                sx={{
                  height: '32px',
                  width: '32px',
                }}
                alt="Back"
                src={FrontButtonIcon}
              />
            }
          />
        )}
      </Box>
    </Grid>
  );

  return (
    <RootGrid container>
      <Slide
        direction="right"
        unmountOnExit
        in={!(!mediumScreen && selectedTimeCard)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
      >
        <Grid
          item
          container
          md
          xs
          sm
          sx={{
            height: `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight})`,
            marginRight: selectedTimeCard && 2,
          }}
          ref={gridContainerRef}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <ListHeader headerText="Time Tracking" />
            {get('role', user) === UserRole.ADMIN || get('role', user) === UserRole.RECRUITER ? (
              <> </>
            ) : (
              <Grid>
                <Button
                  sx={{ height: '40px', width: '173px' }}
                  type=""
                  text="✓ Batch Approve"
                  onClick={() => approveWeek()}
                />
              </Grid>
            )}
          </Box>

          <Grid
            container
            sx={{
              height: `calc(100% - ${listHeaderHeight})`,
              width: '100%',
            }}
          >
            {timecards && (
              <WaePaginatedDataGrid
                apiPath={apiPath}
                columnData={columnData}
                additiveFilters={
                  (get('role', user) === UserRole.EMPLOYER &&
                    employerAdditionalFilters(get(['employer', 'corporation'], user))) ||
                  additionalFilters(selectedCorp)
                }
                disableMultipleSelection
                disableVirtualization
                FilterLeftComponent={<WeekFilter />}
                hideFilterBreakpoint={selectedTimeCard ? '2700px' : '0'}
                onSelectionModelChange={onSelectionModelChange}
                page={page}
                pageSize={pageSize}
                pagination
                paginatedData={timecards.map((data) => ({
                  ...data,
                  ...generateCandidateHoursData(data),
                  id: get(['candidate', 'id'], data),
                  overtimeHours: get('overtimeHours', data),
                  regularHours: get('regularHours', data),
                  totalHours: get('totalHours', data),
                  employeeName: get(['candidate', 'name'], data),
                }))}
                paginationQuery={timecardPaginationQuery}
                setPageSize={setPageSize}
                setPage={setPage}
                setRowCount={setRowCount}
                rowCount={rowCount}
                totalRowCount={totalRowCount}
                sx={{
                  border: 'none',
                  height: '100%',
                  width: '100%',
                }}
                selectionModel={
                  get(['candidate', 'id'], selectedTimeCard) && [
                    get(['candidate', 'id'], selectedTimeCard),
                  ]
                }
              />
            )}
            {selectedTimeCard && (
              <DetailView
                close={handleClose}
                sx={{ marginLeft: theme.spacing(1.5), height: gridContainerDims.height }}
                tabData={tabData}
              />
            )}
          </Grid>
        </Grid>
      </Slide>
      {!mediumScreen && selectedTimeCard && (
        <DetailView
          close={handleClose}
          sx={{
            width: '520px',
            marginLeft: theme.spacing(1.5),
            height: '100%',
          }}
          tabData={tabData}
        />
      )}
      <TimeKeepingBatchApprovalDrawer
        anchor="right"
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        submitValue={generateBatchApprovalIds()}
        displayValue={generateBatchApprovalNameAndHours()}
      />
      {(timecardActionIsProcessing || isTimeCardLoading) && <BackdropCircularProgress />}
    </RootGrid>
  );
};
export default TimekeepingGridView;

TimekeepingGridView.propTypes = {};
