import React, { useCallback, useEffect } from 'react';
import { get } from 'lodash/fp';
import { useDispatch } from 'react-redux';

import { Grid } from '@mui/material';

import DateRangePickerFilter from '../../../components/Filters/DateRangePickerFilter';
import Filters from '../../../components/Filters/Filters';
import SearchableSelectDropdownFilter from '../../../components/Filters/SearchableSelectDropdownFilter';
import { setFilters } from '../reducer';

const MetricsFilters = () => {
  const dispatch = useDispatch();
  useEffect(() => () => dispatch(setFilters([])), [dispatch]);

  const updateQueryOptions = useCallback(
    (items = []) => {
      const filter = {};
      items.forEach((item) => {
        filter[item.field] = item.value;
      });
      dispatch(setFilters(filter));
    },
    [dispatch]
  );
  return (
    <Grid item alignSelf="center">
      <Filters
        onFilterChange={() => []}
        updateQueryOptions={updateQueryOptions}
        filterDefinitions={[
          {
            customFilter: {
              field: 'corporation',
              operation: 'equalsID',
              optionsAPICallback: {
                httpMethod: 'POST',
                route: 'corporations/read',
                generateBody: (searchTerm) => ({
                  filters: [{ operation: 'contains', field: 'name', value: searchTerm }],
                }),
              },
              getOptionLabel: (option) => get(['name'], option),
              placeholder: 'Corporation',
              type: SearchableSelectDropdownFilter,
            },
          },
          {
            customFilter: {
              field: { start: 'start', end: 'end' },
              operation: { start: 'onOrAfter', end: 'onOrBefore' },
              placeholder: 'Date Range',
              type: DateRangePickerFilter,
            },
          },
        ]}
      />
    </Grid>
  );
};

export default MetricsFilters;
