export const validTimeFormat = (value) =>
  Number.isNaN(Date.parse(value)) ? 'error.timecard.invalidTimestamp' : undefined;

export const outAfterIn = (value) => {
  if (value?.in?.stamp && !value?.out?.stamp) {
    return 'error.timecard.punchOutMustFollowPunchIn';
  }
  if (!value?.in?.stamp || !value?.out?.stamp) {
    return undefined;
  }
  return value.in.stamp > value.out.stamp ? 'error.timecard.punchOutMustFollowPunchIn' : undefined;
};

export const allOutsAfterIn = (value) => {
  if (!Array.isArray(value)) {
    return undefined;
  }
  const returnOb = value
    .map((set) => outAfterIn(set))
    .filter((message) => message !== undefined)[0];
  return returnOb;
};

export const hasNoOverlaps = (value) => {
  if (!Array.isArray(value) || value.length < 2) {
    return undefined;
  }
  const sorted = [...value].sort((a, b) => a.in.stamp - b.in.stamp);

  let error;
  let currentIndex = 0;

  while (currentIndex < value.length - 2 && !error) {
    const nextItem = sorted[currentIndex + 1];
    if (
      sorted[currentIndex].out.stamp > nextItem.in.stamp ||
      (sorted[currentIndex].out.stamp < nextItem.out.stamp &&
        sorted[currentIndex].out.stamp > nextItem.in.stamp)
    ) {
      error = 'error.timecard.punchesOverlap';
    }
    currentIndex += 1;
  }

  return error;
};
