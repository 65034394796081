import React from 'react';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { WaeButton as Button } from '../../components/Button/Button';
import { buildFormField } from '../../components/Form/formFieldFactory';

import CertificationsSkillsSettingsData from './FormData/CertificationsSkillsSettingsData';
import { sectionHeaderStyling, sectionSubHeaderStyling, sectionSubTextStyling } from './styling';

const SkillsAndCertsSettingForm = ({ handleSkillsAndCertsOnSubmit, skills }) => (
  <RFForm
    onSubmit={handleSkillsAndCertsOnSubmit}
    initialValues={{
      skillsHeader: 'Skills',
      skillsBoxText:
        ' Search for additional skills or add additional skills by clicking on the chips below.',
      skills,
    }}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} id="notificationFormID">
        <Typography sx={sectionHeaderStyling}>Skills</Typography>
        <Typography sx={sectionSubTextStyling}>
          Manage your skills, make sure to keep these up to date as it will impact your job
          availability.
        </Typography>
        <Typography sx={sectionSubHeaderStyling}>Skills</Typography>

        <Box>{buildFormField(CertificationsSkillsSettingsData.skillsSetting)}</Box>
        <Button type="submit">Update Skills</Button>
      </form>
    )}
  />
);

SkillsAndCertsSettingForm.propTypes = {
  handleSkillsAndCertsOnSubmit: PropTypes.func,
  user: PropTypes.shape({}),
  skills: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SkillsAndCertsSettingForm;
