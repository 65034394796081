import React from 'react';
import { get, getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Button from '../../../../components/Button';
import { buildFormField } from '../../../../components/Form/formFieldFactory';
import theme from '../../../../theme';
import { formatCurrency, minutesFromMidnightToTimeOnlyHrAndMins } from '../../../../utils';

import AdminFormFieldDataShiftDetails from './FormData/AdminFormFieldDataShiftDetails';

const AdminShiftDetail = ({ initialValues, onUpdate, certifications }) => {
  const formFieldData = AdminFormFieldDataShiftDetails(certifications, initialValues);

  const StyledFixedBottomBox = styled(Box)(() => ({
    position: 'absolute',
    backgroundImage: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
    fontFamily: `${theme.button.fontFamily}`,
    color: '#FFF',
    width: '100%',
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '4.40vh',
    cursor: 'pointer',
    borderBottomLeftRadius: '13px',
    borderBottomRightRadius: '13px',
  }));

  const componentsDetailViewSectionHeaders = [
    'components',
    'detailView',
    'typography',
    'sectionHeader',
  ];

  const componentsDetailViewFieldHeaders = [
    'components',
    'detailView',
    'typography',
    'fieldHeaders',
  ];

  const componentsDetailViewPtShiftName = ['components', 'detailView', 'typography', 'ptShiftName'];
  const componentsDetailViewSemiBoldText = [
    'components',
    'detailView',
    'typography',
    'semiBoldText',
  ];
  const viewHeaderStyling = {
    fontSize: get([...componentsDetailViewSectionHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSectionHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewSectionHeaders, 'fontColor'], theme),
    margin: theme.spacing(4, 0, 0, 1),
  };

  const fieldHeaderStyling = {
    fontSize: get([...componentsDetailViewFieldHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewFieldHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewFieldHeaders, 'fontColor'], theme),
    margin: theme.spacing(2, 0, 2, 1),
  };

  const readOnlyFieldBoldValueStyling = {
    fontSize: get([...componentsDetailViewSemiBoldText, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSemiBoldText, 'fontFamily'], theme),
    color: get([...componentsDetailViewSemiBoldText, 'fontColor'], theme),
  };

  const readOnlyFieldValueStyling = {
    fontSize: get([...componentsDetailViewPtShiftName, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewPtShiftName, 'fontFamily'], theme),
    color: get([...componentsDetailViewPtShiftName, 'fontColor'], theme),
    margin: theme.spacing(0, 0, 0, 1),
  };

  const shiftReadOnlyData = getOr([], 'shifts', initialValues).map((shift) => ({
    name: get('name', shift),
    value: `${minutesFromMidnightToTimeOnlyHrAndMins(
      get('start', shift)
    )} - ${minutesFromMidnightToTimeOnlyHrAndMins(get('end', shift))} `,
  }));

  const adjustedInitialValues = {
    ...initialValues,
    payRateDT: formatCurrency(get('payRateDT', initialValues)),
    payRateOT: formatCurrency(get('payRateOT', initialValues)),
    payRateReg: formatCurrency(get('payRateReg', initialValues)),
    billRateDT: formatCurrency(get('billRateDT', initialValues)),
    billRateOT: formatCurrency(get('billRateOT', initialValues)),
    billRateReg: formatCurrency(get('billRateReg', initialValues)),
  };

  return (
    <RFForm
      onSubmit={(values) => {
        onUpdate(values, initialValues);
      }}
      initialValues={adjustedInitialValues}
      render={({ handleSubmit, pristine }) => (
        <Box>
          <Box
            sx={{
              width: '100%',
              overflowY: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
              height: '80vh',
            }}
          >
            <form
              onSubmit={handleSubmit}
              id="loginformid"
              style={{
                width: '100%',
              }}
            >
              <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
                <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(1, 0, 0, 1) }}>
                  Shifts
                </Typography>
                <Grid container item>
                  {shiftReadOnlyData.map((shift) => (
                    <Grid container item justifyContent="space-between" key={get('name', shift)}>
                      <Typography sx={readOnlyFieldValueStyling}>{get('name', shift)}</Typography>
                      <Typography
                        sx={{
                          ...readOnlyFieldBoldValueStyling,
                          margin: theme.spacing(0, 0, 0, 1),
                        }}
                      >
                        {get('value', shift)}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Typography sx={viewHeaderStyling}>Bill Rate</Typography>
                <Grid container item spacing={1} sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}>
                  <Grid item xs={6}>
                    {buildFormField(formFieldData.billRateReg)}
                  </Grid>
                  <Grid item xs={6}>
                    {buildFormField(formFieldData.billRateDT)}
                  </Grid>
                  <Grid item xs={6} sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
                    {buildFormField(formFieldData.billRateOT)}
                  </Grid>
                </Grid>
                <Typography sx={viewHeaderStyling}>Pay Rate</Typography>
                <Grid container item spacing={1} sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}>
                  <Grid item xs={6}>
                    {buildFormField(formFieldData.payRateReg)}
                  </Grid>
                  <Grid item xs={6}>
                    {buildFormField(formFieldData.payRateDT)}
                  </Grid>
                  <Grid item xs={6} sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
                    {buildFormField(formFieldData.payRateOT)}
                  </Grid>
                </Grid>
                <Typography sx={viewHeaderStyling}>Workers Comp</Typography>
                <Grid container item spacing={1} sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}>
                  <Grid item xs={6}>
                    {buildFormField(formFieldData.workersCompCode)}
                  </Grid>
                </Grid>
                <Typography sx={viewHeaderStyling}>GPS</Typography>
                <Grid container item spacing={1} sx={{ margin: theme.spacing(0, 0, 0, 0) }}>
                  <Grid item xs={6}>
                    <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(0, 0, 1.5, 0) }}>
                      GPS Strategy
                    </Typography>
                    <Typography sx={readOnlyFieldBoldValueStyling}>
                      {get(['gps', 'strategy'], initialValues)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ ...fieldHeaderStyling, margin: theme.spacing(0, 0, 1.5, 0) }}>
                      Max Punch Distance
                    </Typography>
                    <Typography sx={readOnlyFieldBoldValueStyling}>
                      {get(['gps', 'maxDistance'], initialValues)}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(4, 0, 0, 1) }}>
                  Required Certifications
                </Typography>
                <Grid item xs={12} sx={{ margin: theme.spacing(0, 0, 3, 0) }}>
                  {buildFormField(formFieldData.certs)}
                </Grid>
                <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(4, 0, 0, 1) }}>
                  Required Skills
                </Typography>
                <Grid item xs={12} sx={{ margin: theme.spacing(0, 0, 3, 0) }}>
                  {buildFormField(formFieldData.skills)}
                </Grid>
              </Grid>
              {pristine ? null : (
                <StyledFixedBottomBox>
                  <Button sx={{ width: '100%' }} type="submit">
                    Submit
                  </Button>
                </StyledFixedBottomBox>
              )}
            </form>
          </Box>
        </Box>
      )}
    />
  );
};

AdminShiftDetail.propTypes = {
  certifications: PropTypes.arrayOf(PropTypes.shape({})),
  initialValues: PropTypes.shape({}),
  onUpdate: PropTypes.func,
};

export default AdminShiftDetail;
