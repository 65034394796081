import { first, get, map } from 'lodash/fp';

import FormMapDisplayWrapper from '../../../../components/Form/FormMapWrapper';
import FormSelectPillPicker from '../../../../components/Form/FormSelectPillPicker';
import FormShiftPicker from '../../../../components/Form/FormShiftPicker';
import FormTextField from '../../../../components/Form/FormTextField';
import FormTypographyBox from '../../../../components/Form/FormTypographyBox';
import FormTypographyDisplayList from '../../../../components/Form/FormTypographyDisplayList';
import { composeValidators, isPTShiftEmpty } from '../../../../components/Form/validations';
import SearchBox from '../../../../components/SearchBar';
import { SEARCHBAR_BACKGROUND, SearchBoxV2 } from '../../../../components/SearchBarV2/SearchBarV2';
import theme from '../../../../theme';
import { BLACK } from '../../../../theme/colorConstants';
import { minutesFromMidnightToTimeOnlyHrAndMins } from '../../../../utils';

const componentWorkFlowFont = ['components', 'workflow', 'typography'];
const workFlowHeaderStyling = {
  fontSize: get([...componentWorkFlowFont, 'defaultTitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  color: get([...componentWorkFlowFont, 'defaultLastPageFontColor', 'default'], theme),
};
const workFlowSubHeaderStyling = {
  fontSize: '16px',
  fontFamily: 'Barlow',
  color: '#333357',
  margin: theme.spacing(-1, 0, 0),
};

const FormHeaderStyle = {
  fontSize: '14px',
  fontFamily: 'Barlow-500',
  color: '#333357',
  margin: theme.spacing(-1, 0, 0),
};

const FormValueStyle = {
  fontSize: '16px',
  fontFamily: 'Barlow-500',
  color: '#000033',
  margin: theme.spacing(-1, 0, 0),
  wordBreak: 'break-all',
};

const HeaderStyling = {
  fontSize: '18px',
  fontFamily: 'Barlow-700',
  color: '#000033',
};

const predefinedCertsOptions = (certifications) =>
  certifications.map((cert) => ({
    name: cert.name,
    _id: cert.id,
  }));

const predefinedLocationOptions = (createPositionTemplateValues, corporationLocation) => {
  const corpID = get(['corporation', '_id'], createPositionTemplateValues);
  return corporationLocation
    .filter((loc) => get(['corporation', '_id'], loc) === corpID)
    .map((location) => ({
      address: location.address,
      _id: location.id,
      description: location.description,
    }));
};

const employerPredefinedLocationOptions = (corporationLocation) =>
  corporationLocation.map((location) => ({
    address: location.address,
    _id: location.id,
    description: location.description,
  }));

export const PositionTemplateFormFieldDataForAdminAndRecruiter = (
  createPositionTemplateValues,
  corporationLocation,
  certifications
) => [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'corporationChoice',
      name: 'corporationChoice',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationHeader',
      name: 'corporationHeader',
      sx: HeaderStyling,
    },
    {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      preDefinedOptions: [],
      data: null,
      key: 'id',
      name: 'corporation',
      multiSelect: false,
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Enter Corporation',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'corporations/read',
      },
      optionDisplayField: 'name',
    },
    {
      ComponentType: FormTypographyBox,
      key: 'industryChoice',
      name: 'industryChoice',
      sx: HeaderStyling,
    },
    {
      ComponentType: FormSelectPillPicker,
      name: 'industry',
      required: true,
      data: [
        {
          label: 'Healthcare',
          value: 'healthcare',
        },
        {
          label: 'Manufacturing',
          value: 'manufacturing',
        },
        {
          label: 'Hospitality',
          value: 'hospitality',
        },
      ],
      sx: { fontSize: '18px', fontFamily: 'Barlow-600', margin: theme.spacing(0, 0, 0, 1.5) },
    },
  ],

  [
    {
      ComponentType: FormTypographyBox,
      key: 'positionInfoHeader',
      name: 'positionInfoHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: FormTextField,
      label: 'Position Name',
      name: 'positionName',
      required: true,
      fullWidth: true,
      sx: {
        input: {
          color: 'black',
          fontFamily: 'Barlow-600',
        },
      },
    },

    {
      ComponentType: FormTypographyBox,
      key: 'positionMeaning',
      name: 'positionMeaning',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTextField,
      label: 'Position Description',
      name: 'description',
      required: true,
      fullWidth: true,
      multiline: true,
      sx: {
        input: {
          color: 'black',
          fontFamily: 'Barlow-600',
        },
      },
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionMeaning',
      name: 'positionDescriptionMeaning',
      sx: workFlowSubHeaderStyling,
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'positionLocationHeader',
      name: 'positionLocationHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: SearchBoxV2,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      dropDownOptions: predefinedLocationOptions(createPositionTemplateValues, corporationLocation),
      data: null,
      selectedSectionTitle: 'Selected Location(s)',
      name: 'location',
      label: 'Location',
      multiSelect: true,
      description:
        'Select an existing location for WAE or enter in a new location below and continue.',
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Location',
      fullWidth: true,
      optionDisplayField: 'description',
    },

    {
      ComponentType: SearchBoxV2,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      data: {
        corporation: get(['corporation', '_id'], createPositionTemplateValues),
      },
      selectedSectionTitle: 'Selected Department(s)',
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'corporations/departments/read',
      },
      autoCompleteTestId: 'departmentAutoComplete',
      name: 'department',
      label: 'Department',
      multiSelect: true,
      description:
        'Select an existing department for WAE or enter in a new department below and continue.',
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Department',
      fullWidth: true,
      optionDisplayField: 'name',
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'gpsHeader',
      name: 'gpsHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'gpsSubHeader',
      name: 'gpsSubHeader',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'gpsStrategyHeader',
      name: 'gpsStrategyHeader',
      sx: HeaderStyling,
    },
    {
      ComponentType: FormSelectPillPicker,
      name: 'gpsStrategy',
      required: true,
      data: [
        { id: 'enforced', label: 'Enforced', value: 'enforced' },
        { id: 'warning', label: 'Warning', value: 'warning' },
        { id: 'unenforced', label: 'Not Required', value: 'unenforced' },
      ],
      sx: { justifyContent: 'space-between' },
    },
    {
      ComponentType: FormTypographyBox,
      key: 'gpsDescription',
      name: 'gpsDescription',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'geoHeader',
      name: 'geoHeader',
      sx: HeaderStyling,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'corporationLocationValue',
      name: 'corporationLocationValue',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormMapDisplayWrapper,
      key: 'companyMap',
      name: 'geoLoc',
      sx: {
        fontSize: '16px',
        fontColor: `${BLACK[0]}`,
        fontFamily: 'Barlow-600',
        width: '100%',
        borderRadius: '15px',
        height: '200px',
      },
    },
    {
      ComponentType: FormTextField,
      label: 'Max Punch Distance(m)',
      name: 'punchDistance',
      fullWidth: true,
      required: true,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'distanceDescription',
      name: 'distanceDescription',
      sx: workFlowSubHeaderStyling,
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'certificationHeader',
      name: 'certificationHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'certificationDescription',
      name: 'certificationDescription',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'selectedCertificationHeader',
      name: 'selectedCertificationHeader',
      sx: HeaderStyling,
    },
    {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      preDefinedOptions: [],
      data: null,
      dropDownOptions: predefinedCertsOptions(certifications),
      key: 'id',
      name: 'certifications',
      multiSelect: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Enter Certifications',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'GET',
        route: 'certifications',
      },
      optionDisplayField: 'name',
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'skillsHeader',
      name: 'skillsHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'skillDescription',
      name: 'skillDescription',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'selectedSkillHeader',
      name: 'selectedSkillHeader',
      sx: HeaderStyling,
    },
    {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      preDefinedOptions: [],
      data: null,
      key: 'id',
      name: 'skills',
      multiSelect: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Skills',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'skills/read',
      },
      optionDisplayField: 'name',
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'shiftPageHeader',
      name: 'shiftPageHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'shiftPageDescription',
      name: 'shiftPageDescription',
      sx: workFlowSubHeaderStyling,
    },

    {
      ComponentType: FormShiftPicker,
      label: 'Shifts',
      name: 'shifts',
      shiftTitle: 'Assigned Shifts',
      readOnlyFormat: (value) => (Array.isArray(value) ? value.map((e) => get('name', e)) : value),
      validate: composeValidators(isPTShiftEmpty),
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'confirmationPageHeader',
      name: 'confirmationPageHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'confirmationPageSubHeader',
      name: 'confirmationPageSubHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionName',
      name: 'positionName',
      sx: FormValueStyle,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionTitle',
      name: 'positionDescriptionTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionValue',
      name: 'positionDescriptionValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationTitle',
      name: 'corporationTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationValue',
      name: 'corporationValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationLocationTitle',
      name: 'corporationLocationTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'corporationLocationValue',
      name: 'corporationLocationValue',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationDepartmentTitle',
      name: 'corporationDepartmentTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'corporationDepartmentValue',
      name: 'corporationDepartmentValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'gpsStrategyHeader',
      name: 'gpsStrategyHeader',
      sx: FormHeaderStyle,
      xs: 6,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'punchDistanceHeader',
      name: 'punchDistanceHeader',
      sx: FormHeaderStyle,
      xs: 6,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'gpsStrategyValue',
      name: 'gpsStrategyValue',
      sx: FormValueStyle,
      xs: 6,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'punchDistanceMeter',
      name: 'punchDistanceMeter',
      sx: FormValueStyle,
      xs: 6,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'certificationTitle',
      name: 'certificationTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'certificationsValue',
      name: 'certificationsValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'skillTitle',
      name: 'skillTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'skillsValue',
      name: 'skillsValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'shiftsHeader',
      name: 'shiftsHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'shiftsValue',
      name: 'shiftsValue',
      sx: FormValueStyle,
    },
  ],
];

export const PositionTemplateFormFieldDataForAdminAndRecruiterWithoutGPSEnforcement = (
  createPositionTemplateValues,
  corporationLocation,
  certifications
) => [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'corporationChoice',
      name: 'corporationChoice',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationHeader',
      name: 'corporationHeader',
      sx: HeaderStyling,
    },
    {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      preDefinedOptions: [],
      data: null,
      key: 'id',
      name: 'corporation',
      multiSelect: false,
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Enter Corporation',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'corporations/read',
      },
      optionDisplayField: 'name',
    },
    {
      ComponentType: FormTypographyBox,
      key: 'industryChoice',
      name: 'industryChoice',
      sx: HeaderStyling,
    },
    {
      ComponentType: FormSelectPillPicker,
      name: 'industry',
      required: true,
      data: [
        {
          label: 'Healthcare',
          value: 'healthcare',
        },
        {
          label: 'Manufacturing',
          value: 'manufacturing',
        },
        {
          label: 'Hospitality',
          value: 'hospitality',
        },
      ],
      sx: { fontSize: '18px', fontFamily: 'Barlow-600', margin: theme.spacing(0, 0, 0, 1.5) },
    },
  ],

  [
    {
      ComponentType: FormTypographyBox,
      key: 'positionInfoHeader',
      name: 'positionInfoHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: FormTextField,
      label: 'Position Name',
      name: 'positionName',
      required: true,
      fullWidth: true,
      sx: {
        input: {
          color: 'black',
          fontFamily: 'Barlow-600',
        },
      },
    },

    {
      ComponentType: FormTypographyBox,
      key: 'positionMeaning',
      name: 'positionMeaning',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTextField,
      label: 'Position Description',
      name: 'description',
      required: true,
      fullWidth: true,
      multiline: true,
      sx: {
        input: {
          color: 'black',
          fontFamily: 'Barlow-600',
        },
      },
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionMeaning',
      name: 'positionDescriptionMeaning',
      sx: workFlowSubHeaderStyling,
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'positionLocationHeader',
      name: 'positionLocationHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: SearchBoxV2,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      dropDownOptions: predefinedLocationOptions(createPositionTemplateValues, corporationLocation),
      data: null,
      selectedSectionTitle: 'Selected Location(s)',
      name: 'location',
      label: 'Location',
      multiSelect: true,
      description:
        'Select an existing location for WAE or enter in a new location below and continue.',
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Location',
      fullWidth: true,
      optionDisplayField: 'description',
    },

    {
      ComponentType: SearchBoxV2,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      data: {
        corporation: get(['corporation', '_id'], createPositionTemplateValues),
      },
      autoCompleteTestId: 'departmentAutoComplete',
      selectedSectionTitle: 'Selected Department(s)',
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'corporations/departments/read',
      },
      name: 'department',
      label: 'Department',
      multiSelect: true,
      description:
        'Select an existing department for WAE or enter in a new department below and continue.',
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Department',
      fullWidth: true,
      optionDisplayField: 'name',
    },
  ],

  [
    {
      ComponentType: FormTypographyBox,
      key: 'certificationHeader',
      name: 'certificationHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'certificationDescription',
      name: 'certificationDescription',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'selectedCertificationHeader',
      name: 'selectedCertificationHeader',
      sx: HeaderStyling,
    },
    {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      preDefinedOptions: [],
      data: null,
      dropDownOptions: predefinedCertsOptions(certifications),
      key: 'id',
      name: 'certifications',
      multiSelect: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Enter Certifications',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'GET',
        route: 'certifications',
      },
      optionDisplayField: 'name',
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'skillsHeader',
      name: 'skillsHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'skillDescription',
      name: 'skillDescription',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'selectedSkillHeader',
      name: 'selectedSkillHeader',
      sx: HeaderStyling,
    },
    {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      preDefinedOptions: [],
      data: null,
      key: 'id',
      name: 'skills',
      multiSelect: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Skills',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'skills/read',
      },
      optionDisplayField: 'name',
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'shiftPageHeader',
      name: 'shiftPageHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'shiftPageDescription',
      name: 'shiftPageDescription',
      sx: workFlowSubHeaderStyling,
    },

    {
      ComponentType: FormShiftPicker,
      label: 'Shifts',
      name: 'shifts',
      shiftTitle: 'Assigned Shifts',
      readOnlyFormat: (value) => (Array.isArray(value) ? value.map((e) => get('name', e)) : value),
      validate: composeValidators(isPTShiftEmpty),
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'confirmationPageHeader',
      name: 'confirmationPageHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'confirmationPageSubHeader',
      name: 'confirmationPageSubHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionName',
      name: 'positionName',
      sx: FormValueStyle,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionTitle',
      name: 'positionDescriptionTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionValue',
      name: 'positionDescriptionValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationTitle',
      name: 'corporationTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationValue',
      name: 'corporationValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationLocationTitle',
      name: 'corporationLocationTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'corporationLocationValue',
      name: 'corporationLocationValue',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationDepartmentTitle',
      name: 'corporationDepartmentTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'corporationDepartmentValue',
      name: 'corporationDepartmentValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'certificationTitle',
      name: 'certificationTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'certificationsValue',
      name: 'certificationsValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'skillTitle',
      name: 'skillTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'skillsValue',
      name: 'skillsValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'shiftsHeader',
      name: 'shiftsHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'shiftsValue',
      name: 'shiftsValue',
      sx: FormValueStyle,
    },
  ],
];

export const PositionTemplateFormFieldDataForEmployer = (corporationLocation, corporation) => [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'positionInfoHeader',
      name: 'positionInfoHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: FormTextField,
      label: 'Position Name',
      name: 'positionName',
      required: true,
      fullWidth: true,
      sx: {
        input: {
          color: 'black',
          fontFamily: 'Barlow-600',
        },
      },
    },

    {
      ComponentType: FormTypographyBox,
      key: 'positionMeaning',
      name: 'positionMeaning',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTextField,
      label: 'Position Description',
      name: 'description',
      required: true,
      fullWidth: true,
      sx: {
        input: {
          color: 'black',
          fontFamily: 'Barlow-600',
        },
      },
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionMeaning',
      name: 'positionDescriptionMeaning',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'industryChoice',
      name: 'industryChoice',
      sx: HeaderStyling,
    },
    {
      ComponentType: FormSelectPillPicker,
      name: 'industry',
      required: true,
      data: [
        {
          label: 'Healthcare',
          value: 'healthcare',
        },
        {
          label: 'Manufacturing',
          value: 'manufacturing',
        },
        {
          label: 'Hospitality',
          value: 'hospitality',
        },
      ],
      sx: { fontSize: '18px', fontFamily: 'Barlow-600' },
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'positionLocationHeader',
      name: 'positionLocationHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: SearchBoxV2,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      dropDownOptions: employerPredefinedLocationOptions(corporationLocation),
      data: null,
      selectedSectionTitle: 'Selected Location(s)',
      name: 'location',
      label: 'Location',
      multiSelect: true,
      description:
        'Select an existing location for WAE or enter in a new location below and continue.',
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Location',
      fullWidth: true,
      optionDisplayField: 'description',
    },

    {
      ComponentType: SearchBoxV2,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      data: {
        corporation: get(['id'], first(corporation)),
      },
      selectedSectionTitle: 'Selected Department(s)',
      autoCompleteTestId: 'departmentAutoComplete',
      name: 'department',
      label: 'Department',
      multiSelect: true,
      description:
        'Select an existing department for WAE or enter in a new department below and continue.',
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Department',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'corporations/departments/read',
      },
      optionDisplayField: 'name',
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'gpsHeader',
      name: 'gpsHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'gpsSubHeader',
      name: 'gpsSubHeader',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'gpsStrategyHeader',
      name: 'gpsStrategyHeader',
      sx: HeaderStyling,
    },
    {
      ComponentType: FormSelectPillPicker,
      name: 'gpsStrategy',
      required: true,
      data: [
        { id: 'enforced', label: 'Enforced', value: 'enforced' },
        { id: 'warning', label: 'Warning', value: 'warning' },
        { id: 'unenforced', label: 'Not Required', value: 'unenforced' },
      ],
      sx: { justifyContent: 'space-between' },
    },
    {
      ComponentType: FormTypographyBox,
      key: 'gpsDescription',
      name: 'gpsDescription',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'geoHeader',
      name: 'geoHeader',
      sx: HeaderStyling,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'corporationLocationValue',
      name: 'corporationLocationValue',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormMapDisplayWrapper,
      key: 'companyMap',
      name: 'geoLoc',
      sx: {
        fontSize: '16px',
        fontColor: `${BLACK[0]}`,
        fontFamily: 'Barlow-600',
        width: '100%',
        borderRadius: '15px',
        height: '200px',
      },
    },
    {
      ComponentType: FormTextField,
      label: 'Max Punch Distance(m)',
      name: 'punchDistance',
      fullWidth: true,
      required: true,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'distanceDescription',
      name: 'distanceDescription',
      sx: workFlowSubHeaderStyling,
    },
  ],

  [
    {
      ComponentType: FormTypographyBox,
      key: 'shiftPageHeader',
      name: 'shiftPageHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'shiftPageDescription',
      name: 'shiftPageDescription',
      sx: workFlowSubHeaderStyling,
    },

    {
      ComponentType: FormShiftPicker,
      label: 'Shifts',
      name: 'shifts',
      shiftTitle: 'Assigned Shifts',
      readOnlyFormat: (value) => (Array.isArray(value) ? value.map((e) => get('name', e)) : value),
      validate: composeValidators(isPTShiftEmpty),
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'confirmationPageHeader',
      name: 'confirmationPageHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'confirmationPageSubHeader',
      name: 'confirmationPageSubHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionName',
      name: 'positionName',
      sx: FormValueStyle,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionTitle',
      name: 'positionDescriptionTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionValue',
      name: 'positionDescriptionValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationTitle',
      name: 'corporationTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'employerCorporationName',
      name: 'employerCorporationName',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationLocationTitle',
      name: 'corporationLocationTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'corporationLocationValue',
      name: 'corporationLocationValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationDepartmentTitle',
      name: 'corporationDepartmentTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'corporationDepartmentValue',
      name: 'corporationDepartmentValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'gpsStrategyHeader',
      name: 'gpsStrategyHeader',
      sx: FormHeaderStyle,
      xs: 6,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'punchDistanceHeader',
      name: 'punchDistanceHeader',
      sx: FormHeaderStyle,
      xs: 6,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'gpsStrategyValue',
      name: 'gpsStrategyValue',
      sx: FormValueStyle,
      xs: 6,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'punchDistanceMeter',
      name: 'punchDistanceMeter',
      sx: FormValueStyle,
      xs: 6,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'shiftsHeader',
      name: 'shiftsHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'shiftsValue',
      name: 'shiftsValue',
      sx: FormValueStyle,
    },
  ],
];

export const PositionTemplateFormFieldDataForEmployerWithoutGpsEnforcement = (
  corporationLocation,
  corporation
) => [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'positionInfoHeader',
      name: 'positionInfoHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: FormTextField,
      label: 'Position Name',
      name: 'positionName',
      required: true,
      fullWidth: true,
      sx: {
        input: {
          color: 'black',
          fontFamily: 'Barlow-600',
        },
      },
    },

    {
      ComponentType: FormTypographyBox,
      key: 'positionMeaning',
      name: 'positionMeaning',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTextField,
      label: 'Position Description',
      name: 'description',
      required: true,
      fullWidth: true,
      sx: {
        input: {
          color: 'black',
          fontFamily: 'Barlow-600',
        },
      },
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionMeaning',
      name: 'positionDescriptionMeaning',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'industryChoice',
      name: 'industryChoice',
      sx: HeaderStyling,
    },
    {
      ComponentType: FormSelectPillPicker,
      name: 'industry',
      required: true,
      data: [
        {
          label: 'Healthcare',
          value: 'healthcare',
        },
        {
          label: 'Manufacturing',
          value: 'manufacturing',
        },
        {
          label: 'Hospitality',
          value: 'hospitality',
        },
      ],
      sx: { fontSize: '18px', fontFamily: 'Barlow-600', margin: theme.spacing(0, 0, 0, 1.5) },
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'positionLocationHeader',
      name: 'positionLocationHeader',
      sx: workFlowHeaderStyling,
    },

    {
      ComponentType: SearchBoxV2,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      dropDownOptions: employerPredefinedLocationOptions(corporationLocation),
      data: null,
      selectedSectionTitle: 'Selected Location(s)',
      name: 'location',
      label: 'Location',
      multiSelect: true,
      description:
        'Select an existing location for WAE or enter in a new location below and continue.',
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Location',
      fullWidth: true,
      optionDisplayField: 'description',
    },

    {
      ComponentType: SearchBoxV2,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      data: {
        corporation: get(['id'], first(corporation)),
      },
      selectedSectionTitle: 'Selected Department(s)',
      autoCompleteTestId: 'departmentAutoComplete',
      name: 'department',
      label: 'Department',
      multiSelect: true,
      description:
        'Select an existing department for WAE or enter in a new department below and continue.',
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Department',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'corporations/departments/read',
      },
      optionDisplayField: 'name',
    },
  ],

  [
    {
      ComponentType: FormTypographyBox,
      key: 'shiftPageHeader',
      name: 'shiftPageHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'shiftPageDescription',
      name: 'shiftPageDescription',
      sx: workFlowSubHeaderStyling,
    },

    {
      ComponentType: FormShiftPicker,
      label: 'Shifts',
      name: 'shifts',
      shiftTitle: 'Assigned Shifts',
      readOnlyFormat: (value) => (Array.isArray(value) ? value.map((e) => get('name', e)) : value),
      validate: composeValidators(isPTShiftEmpty),
    },
  ],
  [
    {
      ComponentType: FormTypographyBox,
      key: 'confirmationPageHeader',
      name: 'confirmationPageHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'confirmationPageSubHeader',
      name: 'confirmationPageSubHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionName',
      name: 'positionName',
      sx: FormValueStyle,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionTitle',
      name: 'positionDescriptionTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'positionDescriptionValue',
      name: 'positionDescriptionValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationTitle',
      name: 'corporationTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'employerCorporationName',
      name: 'employerCorporationName',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationLocationTitle',
      name: 'corporationLocationTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'corporationLocationValue',
      name: 'corporationLocationValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationDepartmentTitle',
      name: 'corporationDepartmentTitle',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'corporationDepartmentValue',
      name: 'corporationDepartmentValue',
      sx: FormValueStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'shiftsHeader',
      name: 'shiftsHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyDisplayList,
      key: 'shiftsValue',
      name: 'shiftsValue',
      sx: FormValueStyle,
    },
  ],
];

const getDisplayLocation = (location) => {
  let displayLoc;
  if (location) {
    displayLoc = location.map((loc) => ({ label: loc.description }));
  }
  return displayLoc;
};

const getDisplayDepartment = (department) => {
  let displayDep;
  if (department) {
    displayDep = department.map((dep) => ({ label: dep.name }));
  }
  return displayDep;
};

const getGPSValue = (values) => {
  let dispValue;
  if (values) {
    Object.keys(values).forEach((displayValue) => {
      if (values[displayValue]) dispValue = displayValue;
    });
  }
  return dispValue;
};

const certificationOrSkillLabel = (certificationsArrayValues) => {
  const res = [];
  map((cert) => {
    res.push({
      id: cert.id,
      label: cert.name,
    });
  }, certificationsArrayValues);
  return res;
};

const shiftsLabel = (shiftsArrayValues) => {
  const res = [];
  map((shift) => {
    res.push({
      id: `${shift.name}-${shift.start}-${shift.end}`,
      label: `${get('name', shift)} - ${minutesFromMidnightToTimeOnlyHrAndMins(
        get('start', shift)
      )} -${minutesFromMidnightToTimeOnlyHrAndMins(get('end', shift))}`,
    });
  }, shiftsArrayValues);
  return res;
};

export const readOnlyValues = (createPositionTemplateValues, employerCorporationName) => ({
  corporationChoice: 'What corporation and industry is this position for?',
  corporationHeader: 'Corporation',
  industryChoice: 'Industry',
  positionInfoHeader: 'Tell us more about the position you want to create.',
  positionLocationHeader:
    'Let us know which location(s) and department(s) this position applies to.',
  corporationValue: `${get(['corporation', 'name'], createPositionTemplateValues)}`,
  gpsStrategyHeader: 'GPS Strategy',
  gpsDescription: 'Employees will be prohibited from clocking in outside of the set Geofence.',
  geoHeader: 'Geofence Configuration',
  geoLoc: {
    longitude: Math.random() * 180,
    latitude: Math.random() * 85,
  },
  positionLocationSubHeader: `Select an existing location for ${get(
    ['corporation', 'name'],
    createPositionTemplateValues
  )} or enter in a new location below and continue.`,

  employerPositionLocationSubHeader: `Select an existing location for ${employerCorporationName}
 or enter in a new location below and continue.`,
  shiftPageHeader: 'How many shifts does this position include?',
  shiftPageDescription:
    'Create at least one shift to apply to this new position. Multiple shifts can be added to a single position.',
  shiftNameDescription:
    'A quick description of the shift to identify it while selecting throughout the system.',
  industry: {
    healthcare: false,
    manufacturing: false,
    hospitality: false,
  },
  gpsStrategy: {
    enforced: false,
    warning: false,
    unenforced: false,
  },
  positionMeaning: 'This will be used to reference this position in other parts of the system.',
  positionDescriptionMeaning:
    'A brief description detailing the specifics of the position you would like to create.',
  gpsHeader: 'Would you like to enable GPS enforcement for employees while punching in?',
  gpsSubHeader:
    'Create a digital geofence around your new positions location to ensure employees are on location when clocking in to their shift.',
  corporationDepartmentTitle: 'Department',
  corporationDepartmentValue: getDisplayDepartment(get('department', createPositionTemplateValues)),
  corporationLocationValue: getDisplayLocation(get('location', createPositionTemplateValues)),
  distanceDescription:
    'The maximum distance away from a location an employee is allowed to clock-in.',
  certificationHeader: 'Are any certifications required for this position?',
  certificationDescription: `Select an existing certification for  ${get(
    ['corporation', 'name'],
    createPositionTemplateValues
  )} or enter in a new certification below and continue.`,
  skillsHeader: 'Are any skills required for this position?',
  skillDescription: `Select an existing skill for  ${get(
    ['corporation', 'name'],
    createPositionTemplateValues
  )} or enter in a new skill below and continue.`,
  selectedSkillHeader: 'Selected Skills',
  shiftsHeader: 'Shifts',
  startTimeDescription: 'When the shift begins.',
  endTimeDescription: 'When the shift ends.',
  confirmationPageHeader:
    'Please verify that all details of your position are correct before posting.',
  confirmationPageSubHeader: 'Position Name',
  positionDescriptionTitle: 'Position Description',
  positionDescriptionValue: `${get('description', createPositionTemplateValues)}`,
  corporationTitle: 'Corporation',
  corporationLocationTitle: 'Corporation Location',
  punchDistanceHeader: 'Max Punch-in Distance',
  punchDistanceValue: `${get('punchDistance', createPositionTemplateValues)}`,
  punchDistanceMeter: `${get('punchDistance', createPositionTemplateValues)}m`,
  gpsStrategyValue: getGPSValue(createPositionTemplateValues.gpsStrategy),
  certificationTitle: 'Required Certification',
  skillTitle: 'Required Skill',
  certificationsValue: certificationOrSkillLabel(createPositionTemplateValues.certifications),
  skillsValue: certificationOrSkillLabel(createPositionTemplateValues.skills),
  shiftsValue: shiftsLabel(createPositionTemplateValues.shifts),
  shiftValue: `${get(
    ['shift', 'name'],
    createPositionTemplateValues
  )} - ${minutesFromMidnightToTimeOnlyHrAndMins(
    get(['shift', 'start'], createPositionTemplateValues)
  )} -${minutesFromMidnightToTimeOnlyHrAndMins(
    get(['shift', 'end'], createPositionTemplateValues)
  )}`,
  positionTitle: 'Position',
  positionValue: `${get(['positionTemplate', 'name'], createPositionTemplateValues)}`,
  selectedCertificationHeader: 'Selected Certifications',
  employerCorporationName,
});
