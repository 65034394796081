import FormChip from '../../../../../components/Form/FormChip';
import FormRating from '../../../../../components/Form/FormRating';
import FormTypographyBox from '../../../../../components/Form/FormTypographyBox';
import FormDNROrDNA from '../subComponent/FormDNROrDNA';

export const usersCommonFields = () =>
  new Map([
    [
      'name',
      {
        ComponentType: FormTypographyBox,
        key: 'userName',
        name: 'name',
      },
    ],
    [
      'location',
      {
        ComponentType: FormTypographyBox,
        key: 'location',
        name: 'location',
      },
    ],
    [
      'userStatus',
      {
        ComponentType: FormTypographyBox,
        key: 'userStatus',
        name: 'userStatus',
      },
    ],
    [
      'status',
      {
        ComponentType: FormChip,
        label: 'Active',
        name: 'status',
      },
    ],
    [
      'userRole',
      {
        ComponentType: FormTypographyBox,
        key: 'userRole',
        name: 'userRole',
      },
    ],
    [
      'role',
      {
        ComponentType: FormTypographyBox,
        key: 'role',
        name: 'role',
      },
    ],
    [
      'userEmail',
      {
        ComponentType: FormTypographyBox,
        key: 'userEmail',
        name: 'userEmail',
      },
    ],
    [
      'email',
      {
        ComponentType: FormTypographyBox,
        key: 'email',
        name: 'email',
      },
    ],
    [
      'phoneNumberHeader',
      {
        ComponentType: FormTypographyBox,
        key: 'phoneNumberHeader',
        name: 'phoneNumberHeader',
      },
    ],
    [
      'phoneNumber',
      {
        ComponentType: FormTypographyBox,
        key: 'phoneNumber',
        name: 'phoneNumber',
      },
    ],
  ]);

export const employerCandidateSharedFields = () =>
  new Map([
    [
      'userRating',
      {
        ComponentType: FormTypographyBox,
        key: 'userRating',
        name: 'userRating',
      },
    ],
    [
      'rating',
      {
        ComponentType: FormRating,
        key: 'rating',
        name: 'rating',
      },
    ],
    [
      'upcomingJobs',
      {
        ComponentType: FormTypographyBox,
        key: 'upcomingJobs',
        name: 'upcomingJobs',
      },
    ],
    [
      'jobNum',
      {
        ComponentType: FormTypographyBox,
        key: 'jobNum',
        name: 'jobNum',
      },
    ],
    [
      'userFlag',
      {
        ComponentType: FormTypographyBox,
        key: 'userFlag',
        name: 'userFlag',
      },
    ],
    [
      'DNR',
      {
        ComponentType: FormDNROrDNA,
        key: 'DNR',
        name: 'DNR',
      },
    ],
    [
      'DNA',
      {
        ComponentType: FormDNROrDNA,
        key: 'DNA',
        name: 'DNA',
      },
    ],
  ]);
