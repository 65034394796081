import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { batch, useSelector } from 'react-redux';

import Form from '../../../../components/Form';
import { SUBMIT_BUTTON_TYPE } from '../../../../constants/constants';
import UserRole from '../../../../constants/user-role';
import selectUser from '../../../../store/selectors/appSelector';
// import darly flags to handle ability to edit canditadates certs
import launchDarklyToggles from '../../../../utils/launchDarklyToggles';

import FormFieldDataCertSkills from './FormData/FormFieldDataCertSkills';
import FormFieldDataCertSkillsForEmployee from './FormData/FormFieldDataCertSkillsForEmployee';

const UserCertSkills = ({ initialValues, onUpdate, certifications, flags, skills }) => {
  // The role of the user being edited/viewed
  const role = get('role', initialValues);
  const user = useSelector(selectUser);
  // The role of the logged in user.
  const currUserRole = get('role', user);
  const originalCertifications = get(['candidate', 'certifications'], initialValues) || [];
  const originalSkills = get(['candidate', 'skills'], initialValues) || [];
  const candidateCertifications = originalCertifications
    .map((certificateId) => certifications.find((certificate) => certificate.id === certificateId))
    .filter((res) => res !== undefined);

  const distanceNum = get(['candidate', 'distance'], initialValues) || 25;
  const originalDistance = {
    id: `${distanceNum} Miles`,
    name: `${distanceNum} Miles`,
    value: distanceNum,
  };
  const isRecruiterEmployeeCertificationBlocked = launchDarklyToggles(
    flags,
    'isRecruiterEmployeeCertificationBlocked'
  );
  const blockUpdateBtn =
    isRecruiterEmployeeCertificationBlocked && currUserRole === UserRole.RECRUITER;
  let formFieldData;
  let buttonData;
  switch (role) {
    case UserRole.CANDIDATE:
      formFieldData = FormFieldDataCertSkillsForEmployee(certifications, flags, skills);
      buttonData = [
        {
          color: 'primary',
          text: 'Update',
          type: SUBMIT_BUTTON_TYPE,
          disabled: blockUpdateBtn,
          sx: {
            width: '100%',
          },
        },
      ];

      break;
    default:
      formFieldData = FormFieldDataCertSkills(certifications, flags);
      buttonData = [];
  }

  const certificationText =
    'Search for additional certifications or add additional certifications by clicking on the chips below.';
  const skillsText =
    'Search for additional skills or add additional skills by clicking on the chips below.';
  return (
    <Form
      formFieldData={formFieldData}
      initialValues={{
        ...initialValues,
        certifications: candidateCertifications,
        skills: originalSkills,
        certificationsHeader: 'Certifications',
        certificationsText: `${certificationText}`,
        distance: originalDistance,
        industry: get(['candidate', 'industry'], initialValues) || [],
        industryPreferenceHeader: 'Industry Preferences',
        JobDistanceHeader: 'Distance to Job Preference',
        skillsHeader: 'Skills',
        skillsText: `${skillsText}`,
      }}
      onSubmit={(values) => {
        batch(() => {
          onUpdate(values, initialValues);
        });
      }}
      buttonData={buttonData}
    />
  );
};

UserCertSkills.propTypes = {
  certifications: PropTypes.arrayOf(PropTypes.shape({})),
  skills: PropTypes.arrayOf(PropTypes.shape({})),
  flags: PropTypes.shape({}),
  initialValues: PropTypes.shape({
    certifications: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onUpdate: PropTypes.func,
};
export default withLDConsumer()(UserCertSkills);
