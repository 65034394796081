import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Dialog from '../Dialog';

const ConfirmationModal = ({
  deleteDialogBody,
  isDeleteDialogOpen,
  onDelete,
  setIsDeleteDialogOpen,
  titleOfDeleteDialog,
}) => {
  const handlePositionTemplateDelete = useCallback(() => {
    onDelete();
    setIsDeleteDialogOpen();
  }, [onDelete, setIsDeleteDialogOpen]);

  const deleteDialogButtons = [
    <Button
      text="Cancel"
      variant="text"
      key="handle-cancel-delete-button"
      onClick={setIsDeleteDialogOpen}
    />,

    <Button
      text="Confirm"
      variant="text"
      key="handle-confirm-delete-button"
      onClick={handlePositionTemplateDelete}
    />,
  ];

  return (
    <Dialog
      open={isDeleteDialogOpen}
      dialogTitle={titleOfDeleteDialog}
      dialogContentText={deleteDialogBody}
      dialogActions={deleteDialogButtons}
      component="span"
      onBackdropClick={setIsDeleteDialogOpen}
    />
  );
};

ConfirmationModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  deleteDialogBody: PropTypes.any,
  isDeleteDialogOpen: PropTypes.bool,
  onDelete: PropTypes.func,
  setIsDeleteDialogOpen: PropTypes.func,
  titleOfDeleteDialog: PropTypes.string,
};

export default ConfirmationModal;
