import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { findKey, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import ListItem from '@mui/material/ListItem';

import WaeButton from '../../../../components/Button';
import Attendance from '../../../../constants/attendance';
import UserRole from '../../../../constants/user-role';
import selectUser from '../../../../store/selectors/appSelector';
import { selectJobRoster } from '../../../../store/selectors/jobListSelectors';
import theme from '../../../../theme';
import launchDarklyToggles from '../../../../utils/launchDarklyToggles';
import { cancelPlacement, getJobRoster, updateJobRoster, updatePlacement } from '../reducer';

import AddEmployeeDrawer from './SubComponents/AddEmployeeDrawer';
import ExportRosterToCSV from './SubComponents/ExportRosterToCsv';
import InviteEmployeeDrawer from './SubComponents/InviteEmployeeDrawer';
import { inviteEmployee } from './SubComponents/reducer';
import NoRosterCard from './NoRosterCard';
import RosterCard from './RosterCard';

const detailViewTypographySectionHeader = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];

const detailHeaderStyle = {
  color: get([...detailViewTypographySectionHeader, 'fontColor'], theme),
  fontSize: get([...detailViewTypographySectionHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySectionHeader, 'fontFamily'], theme),
  margin: '10px 0',
};

const Roster = ({ initialValues, candidates, accordionType, flags }) => {
  const dispatch = useDispatch();
  const [isAddEmployerDrawerOpen, setIsAddEmployerDrawerOpen] = useState(false);
  const [isInviteEmployeeDrawerOpen, setIsInviteEmployeeDrawerOpen] = useState(false);
  const [disableAddEmployeeButton, setDisableAddEmployeeButton] = useState(false);
  const { corporation, cancelled, id } = initialValues;
  const jobRoster = useSelector(selectJobRoster);
  const employees = get('employees', jobRoster) || [];
  const filteredEmployees = employees.filter(
    (employee) => get(['state', 'status'], employee) === 'active'
  );

  const user = useSelector(selectUser);
  const isRecruiterForbiddenToCreateCancelPlacements = launchDarklyToggles(
    flags,
    'isRecruiterForbiddenToCreateCancelPlacements'
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(
      getJobRoster({
        jobOrder: id,
        // Todo Uncomment when BE is ready
        // filters: [
        //   { field: 'jobOrder._id', value: id, operation: 'equalsID' },
        //   {
        //     operation: 'equals',
        //
        //     value: 'active',
        //
        //     field: 'state.status',
        //   },
        // ],
      })
    );
    if (cancelled || (user.role === 'recruiter' && isRecruiterForbiddenToCreateCancelPlacements)) {
      setDisableAddEmployeeButton(true);
    }
  }, []);
  const onAddEmployeesButtonClick = () => {
    setIsAddEmployerDrawerOpen(true);
  };

  const onInviteEmployeesButtonClick = () => {
    setIsInviteEmployeeDrawerOpen(true);
  };

  const handleOnSave = (employeeData) => {
    dispatch(updateJobRoster(employeeData));
  };

  const handleCancelPlacement = (placementId) => {
    dispatch(cancelPlacement({ id: placementId }));
  };

  const handleAddEmployees = (employee) => {
    dispatch(updatePlacement(employee));

    setIsAddEmployerDrawerOpen(true);
  };
  const handleInviteEmployees = (employee) => {
    dispatch(inviteEmployee(employee));

    setIsInviteEmployeeDrawerOpen(true);
  };

  return (
    (id && (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ ...detailHeaderStyle, display: 'flex', justifyContent: 'space-between' }}>
          {filteredEmployees.length > 0 && (
            <Box>
              <ExportRosterToCSV corporation={corporation} roster={filteredEmployees} />
              {(get('role', user) === UserRole.ADMIN ||
                get('role', user) === UserRole.RECRUITER) && (
                <WaeButton
                  disabled={disableAddEmployeeButton}
                  sx={{ marginLeft: '6px' }}
                  onClick={onAddEmployeesButtonClick}
                  text="Add Employees"
                  startIcon={<AddIcon />}
                  variant="contained"
                />
              )}
              {get('role', user) === UserRole.EMPLOYER && (
                <WaeButton
                  disabled={disableAddEmployeeButton}
                  sx={{ marginLeft: '6px' }}
                  onClick={onInviteEmployeesButtonClick}
                  text="Invite Employees"
                  startIcon={<AddIcon />}
                  variant="contained"
                />
              )}
            </Box>
          )}
          <AddEmployeeDrawer
            isOpen={isAddEmployerDrawerOpen}
            onClose={() => setIsAddEmployerDrawerOpen(false)}
            handleAddEmployees={handleAddEmployees}
            candidates={candidates}
            jobOrderId={id}
            corporation={corporation}
          />
          <InviteEmployeeDrawer
            isOpen={isInviteEmployeeDrawerOpen}
            onClose={() => setIsInviteEmployeeDrawerOpen(false)}
            handleInviteEmployees={handleInviteEmployees}
            candidates={candidates}
            jobOrderId={id}
            corporation={corporation}
          />
        </Box>
        {(filteredEmployees.length > 0 &&
          filteredEmployees.map((emp) => {
            const favorite = corporation.favorite.find((f) => f.waeID === emp.id) || false;
            const attendanceObj = {
              id: findKey((val) => val === emp.attendance, Attendance),
              label: emp.attendance || '',
            };
            const fav = !!favorite;
            const rating = get('rating', emp);
            const candidateName = get('name', emp);
            const employerNote = get('employerNote', emp);
            const empId = emp.id;
            const placementId = get('_id', emp);

            return (
              <ListItem key={placementId} sx={{ padding: '0' }}>
                <RosterCard
                  accordionType={accordionType}
                  fav={fav}
                  originalRating={rating}
                  name={candidateName}
                  originalEmployerNote={employerNote}
                  attendanceObj={attendanceObj}
                  empId={empId}
                  handleOnSave={handleOnSave}
                  placementId={placementId}
                  handleCancelPlacement={handleCancelPlacement}
                  isRecruiterPlacementBlocked={isRecruiterForbiddenToCreateCancelPlacements}
                  currUserRole={user.role}
                />
              </ListItem>
            );
          })) || (
          <NoRosterCard
            isRecruiterPlacementBlocked={isRecruiterForbiddenToCreateCancelPlacements}
            currUserRole={user.role}
            onAddEmployeesClick={onAddEmployeesButtonClick}
            onInviteEmployeesClick={onInviteEmployeesButtonClick}
            isCancelled={cancelled}
          />
        )}
      </Box>
    )) || <Box>Lack of JobOrderId, no roster data </Box>
  );
};

Roster.propTypes = {
  accordionType: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  candidates: PropTypes.arrayOf(PropTypes.shape({})),
  initialValues: PropTypes.shape({
    cancelled: PropTypes.bool,
    id: PropTypes.string,
    fillRate: PropTypes.number,
    jobPlacements: PropTypes.number,
    corporation: PropTypes.oneOfType([
      PropTypes.shape({
        favorite: PropTypes.arrayOf(PropTypes.shape({})),
        DNR: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      PropTypes.string,
    ]),
    jobRosters: PropTypes.arrayOf(PropTypes.shape({})),
    numOpenings: PropTypes.number,
  }),
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(Roster);
