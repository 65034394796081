import axios from 'axios';
import { v4 } from 'uuid';

export const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS || 'https://dev.core.waeapp.com/';

export const apiPost = (route, data, token) =>
  axios.post(`${apiUrl}${route}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      correlationId: v4(),
    },
  });

export const apiPut = (route, data, token) =>
  axios.put(`${apiUrl}${route}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      correlationId: v4(),
    },
  });

export const apiPatch = (route, data, token) =>
  axios.patch(`${apiUrl}${route}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      correlationId: v4(),
    },
  });

export const apiGet = (route, data, token, axiosOptions = {}) =>
  axios.get(`${apiUrl}${route}`, {
    params: { ...data },
    headers: {
      Authorization: `Bearer ${token}`,
      correlationId: v4(),
    },
    ...axiosOptions,
  });

export const apiDelete = (route, data, token) =>
  axios.delete(`${apiUrl}${route}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      correlationId: v4(),
    },
    data,
  });
