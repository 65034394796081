import { get } from 'lodash/fp';

import FormPunchPicker from '../../../../components/Form/FormPunchPicker';
import FormTypographyBox from '../../../../components/Form/FormTypographyBox';
import { composeValidators } from '../../../../components/Form/validations';
import theme from '../../../../theme';

import { allOutsAfterIn, hasNoOverlaps } from './FormFieldValidations';

const componentWorkFlowFont = ['components', 'workflow', 'typography'];
const componentWorkFlowtimecardEditPalette = ['components', 'workflow', 'timecardEdit', 'palette'];
const componentWorkFlowtimecardEditTypography = [
  'components',
  'workflow',
  'timecardEdit',
  'typography',
];
const workFlowHeaderStyling = {
  fontSize: get([...componentWorkFlowFont, 'defaultTitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  color: get([...componentWorkFlowFont, 'defaultLastPageFontColor', 'default'], theme),
};
const workFlowSubHeaderStyling = {
  fontSize: get([...componentWorkFlowFont, 'typography', 'defaultSubtitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'typography', 'defaultFont'], theme),
  color: '#333357',
  margin: theme.spacing(-1, 0, 0, 0),
};

const dayAndDateHeaderStyling = {
  fontSize: get([...componentWorkFlowtimecardEditTypography, 'dayAndDateFontSize'], theme),
  fontFamily: get([...componentWorkFlowtimecardEditTypography, 'dayAndDatefontFamily'], theme),
  color: get([...componentWorkFlowtimecardEditPalette, 'dayAndDatefontColor'], theme),
};
const descriptionHeaderStyling = {
  fontSize: get([...componentWorkFlowtimecardEditTypography, 'descriptionFontSize'], theme),
  fontFamily: get([...componentWorkFlowtimecardEditTypography, 'descriptionFontFamily'], theme),
  color: get([...componentWorkFlowtimecardEditPalette, 'descriptionNameFontColor'], theme),
};

const shiftTextStyling = {
  fontSize: get([...componentWorkFlowtimecardEditTypography, 'shiftTImeFontSize'], theme),
  fontFamily: get([...componentWorkFlowtimecardEditTypography, 'shiftTimeFontFamily'], theme),
  color: get([...componentWorkFlowtimecardEditPalette, 'shiftTimeFontColor'], theme),
  margin: theme.spacing(-2.5, 0, 0),
};

const FormHeaderStyle = {
  fontSize: '18px',
  fontFamily: 'Barlow-700',
  color: '#000033',
};
const formSubHeaderStyle = {
  fontSize: '16px',
  fontFamily: 'Barlow',
  color: '#333357',
  margin: theme.spacing(-1, 0, 0, 0),
};

export const formFieldData = [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'editTimeWorkFlowHeader',
      name: 'editTimeWorkFlowHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'editTimeWorkFlowSubHeader',
      name: 'editTimeWorkFlowSubHeader',
      sx: workFlowSubHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'date',
      name: 'date',
      sx: dayAndDateHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'order',
      name: 'order',
      sx: { ...descriptionHeaderStyling, margin: theme.spacing(-2.5, 0, 0, 0) },
    },

    {
      ComponentType: FormTypographyBox,
      key: 'corporation',
      name: 'corporation',
      sx: { ...descriptionHeaderStyling, margin: theme.spacing(-3, 0, 0, 0) },
    },

    {
      ComponentType: FormTypographyBox,
      key: 'shiftName',
      name: 'shiftName',
      sx: descriptionHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'shiftTime',
      name: 'shiftTime',
      sx: shiftTextStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'fullNameHeader',
      name: 'fullNameHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'fullNameSubHeader',
      name: 'fullNameSubHeader',
      sx: formSubHeaderStyle,
    },

    {
      ComponentType: FormPunchPicker,
      label: 'Punches',
      name: 'punches',
      validate: composeValidators(allOutsAfterIn, hasNoOverlaps),
    },
  ],
];

export const readOnlyValues = {
  editTimeWorkFlowHeader: 'Edit Time',
  editTimeWorkFlowSubHeader:
    'Edit existing punches or add additional time to an employee’s punch card.',
};
