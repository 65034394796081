import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const WhiteTrashIcon = () => (
  <SvgIcon>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4471 10.1008C14.9994 10.1008 15.4471 10.5485 15.4471 11.1008V16.5014C15.4471 17.0536 14.9994 17.5014 14.4471 17.5014C13.8948 17.5014 13.4471 17.0536 13.4471 16.5014V11.1008C13.4471 10.5485 13.8948 10.1008 14.4471 10.1008Z"
        fill="white"
      />
      <path
        d="M11.8906 11.1008C11.8906 10.5485 11.4428 10.1008 10.8906 10.1008C10.3383 10.1008 9.89056 10.5485 9.89056 11.1008V16.5014C9.89056 17.0536 10.3383 17.5014 10.8906 17.5014C11.4428 17.5014 11.8906 17.0536 11.8906 16.5014V11.1008Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2253 4.80018V5.60036H18.8927L18.8954 5.60036H20.671C21.2233 5.60036 21.671 6.04808 21.671 6.60036C21.671 7.15265 21.2233 7.60036 20.671 7.60036H19.8927V19.2016C19.8927 19.9403 19.603 20.6513 19.0833 21.1773C18.5633 21.7038 17.8553 22.0018 17.1145 22.0018H8.22315C7.48233 22.0018 6.77436 21.7038 6.2543 21.1773C5.73466 20.6513 5.44489 19.9403 5.44489 19.2016V7.60036H4.66663C4.11434 7.60036 3.66663 7.15265 3.66663 6.60036C3.66663 6.04808 4.11434 5.60036 4.66663 5.60036H6.44227L6.44489 5.60036H8.11228V4.80018C8.11228 4.06145 8.40206 3.35055 8.9217 2.8245C9.44176 2.29803 10.1497 2 10.8905 2H14.4471C15.1879 2 15.8959 2.29803 16.4159 2.8245C16.9356 3.35055 17.2253 4.06145 17.2253 4.80018ZM10.3446 4.23002C10.4915 4.08129 10.6881 4 10.8905 4H14.4471C14.6495 4 14.8462 4.08129 14.9931 4.23002C15.1404 4.37917 15.2253 4.58403 15.2253 4.80018V5.60036H10.1123V4.80018C10.1123 4.58403 10.1972 4.37917 10.3446 4.23002ZM7.44489 7.60036V19.2016C7.44489 19.4178 7.52982 19.6226 7.67715 19.7718C7.82407 19.9205 8.02072 20.0018 8.22315 20.0018H17.1145C17.3169 20.0018 17.5136 19.9205 17.6605 19.7718C17.8078 19.6226 17.8927 19.4178 17.8927 19.2016V7.60036L7.44489 7.60036Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);

export default WhiteTrashIcon;
