import { getSunday } from '../../../utils/timeConverter';
import {
  FIFTEEN_MIN_IN_EPOCH,
  FOURTEEN_DAYS_IN_MS,
  ONE_SEC_IN_MS,
  SEVEN_DAYS_IN_MS,
} from '../constants';

const FILTERS = {
  THIS_WEEK_JOB: {
    end: getSunday(new Date()) * SEVEN_DAYS_IN_MS + FIFTEEN_MIN_IN_EPOCH,
  },
  FEATURE: [{ field: 'start', operation: 'before', value: +new Date() + FOURTEEN_DAYS_IN_MS }],
  MY_TIME_START: {
    operation: 'after',
    field: 'start',
    value: getSunday(new Date()) * ONE_SEC_IN_MS,
  },
  MY_TIME_END: {
    operation: 'before',
    field: 'end',
    value: getSunday(new Date()) * ONE_SEC_IN_MS + SEVEN_DAYS_IN_MS,
  },
};
export default FILTERS;
