import React from 'react';
import { get } from 'lodash/fp';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const headerFont = ['views', 'reporting', 'typography', 'header'];

const RootGrid = styled(Grid)(({ theme }) => ({
  fontWeight: get([...headerFont, 'fontWeight'], theme),
  fontSize: get([...headerFont, 'fontSize'], theme),
  fontFamily: get([...headerFont, 'fontFamily'], theme),
  fontColor: get([...headerFont, 'fontColor'], theme),
}));

const Header = () => <RootGrid item>Reporting</RootGrid>;
export default Header;
