import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import WaeButton from '../../../../components/Button';
import UserRole from '../../../../constants/user-role';
import selectUser from '../../../../store/selectors/appSelector';
import { BLACK } from '../../../../theme/colorConstants';

const NoRosterCard = ({
  onAddEmployeesClick,
  onInviteEmployeesClick,
  isCancelled,
  isRecruiterPlacementBlocked,
  currUserRole,
}) => {
  const user = useSelector(selectUser);

  return (
    <Box
      sx={{
        backgroundColor: BLACK[100],
        borderRadius: '10px',
        padding: '20px 0',
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Typography sx={{ color: BLACK[0], textAlign: 'center', margin: '0px 0 15px 0' }}>
          There are no employees added to this job order
        </Typography>
      </Box>

      {(get('role', user) !== UserRole.EMPLOYER || get('role', user) === UserRole.RECRUITER) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <WaeButton
            disabled={isCancelled || (isRecruiterPlacementBlocked && currUserRole === 'recruiter')}
            sx={{
              color: BLACK[0],
              variant: 'contained',
              margin: '0 auto',
            }}
            onClick={onAddEmployeesClick}
            text="Add Employees"
            startIcon={<AddIcon />}
          />
        </Box>
      )}

      {get('role', user) === UserRole.EMPLOYER && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <WaeButton
            disabled={isCancelled || (isRecruiterPlacementBlocked && currUserRole === 'recruiter')}
            sx={{
              color: BLACK[0],
              variant: 'contained',
              margin: '0 auto',
            }}
            onClick={onInviteEmployeesClick}
            text="Invite Employees"
            startIcon={<AddIcon />}
          />
        </Box>
      )}
    </Box>
  );
};
NoRosterCard.propTypes = {
  onAddEmployeesClick: PropTypes.func,
  onInviteEmployeesClick: PropTypes.func,
  isCancelled: PropTypes.bool,
  isRecruiterPlacementBlocked: PropTypes.bool,
  currUserRole: PropTypes.string,
};

export default NoRosterCard;
