import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import theme from '../../../../theme';

import ShiftRosterAccordion from './subComponent/ShiftRosterAccordion';

const ShiftRoster = ({ shiftGroupList }) => (
  <Box sx={{ width: '100%' }}>
    <Box
      sx={{
        color: theme.components.detailView.typography.sectionHeader.fontColor,
        fontFamily: theme.components.detailView.typography.sectionHeader.fontFamily,
        fontSize: '18px',
      }}
    >
      Shift Rosters
    </Box>
    {shiftGroupList &&
      shiftGroupList.map((shift) => (
        <Box
          key={get('id', shift)}
          sx={{
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
        >
          <ShiftRosterAccordion selectedShift={shift} />
        </Box>
      ))}
  </Box>
);

ShiftRoster.propTypes = {
  shiftGroupList: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ShiftRoster;
