import React, { useEffect, useRef, useState } from 'react';
import { get, uniqBy } from 'lodash/fp';
import PropTypes from 'prop-types';

import { useDims } from '../../../utils/customHooks';

import Filter from './Filter';
import ModelFilter from './ModelFilter';

const ResponsiveFilter = ({ items, onFilterChange, containerDims, forceCollapse }) => {
  const filterContainerRef = useRef();

  const filterDims = useDims(filterContainerRef || <div />);

  const [filtersWidth, setFiltersWidth] = useState(0);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (filterDims.width > filtersWidth) {
      setFiltersWidth(filterDims.width);
    }
  }, [filterDims, filtersWidth]);

  useEffect(() => {
    setCollapsed(filtersWidth > containerDims.width);
  }, [containerDims.width, filtersWidth]);

  const [filterState, setFilterState] = useState([]);
  const [itemState, setItemState] = useState(items);
  const handleValueChange = (filters) => {
    let newFilterState = filterState;
    filters.forEach((filter) => {
      const { value, field } = filter;
      newFilterState =
        value !== null &&
        (!Array.isArray(value) ||
          (Array.isArray(value) && value.length > 0) ||
          filter.allowEmptyArray)
          ? uniqBy('field', [filter, ...filterState])
          : filterState.filter((item) => item.field !== field);
    });
    setFilterState(newFilterState);
    onFilterChange({ items: newFilterState });

    setItemState(
      itemState.map((item) => {
        const itemValue = newFilterState.filter(
          (selected) => get('field', selected) === get('field', item)
        )[0];

        const initialValueObject = {
          name: get('value', itemValue),
          value: get('value', itemValue),
        };

        const updatedFilterObject =
          itemValue || item.allowEmptyArray
            ? { ...item, initialValue: initialValueObject || {} }
            : item;
        return updatedFilterObject;
      })
    );
  };

  return collapsed || forceCollapse ? (
    <ModelFilter items={itemState} handleValueChange={handleValueChange} />
  ) : (
    <Filter items={itemState} handleValueChange={handleValueChange} ref={filterContainerRef} />
  );
};

ResponsiveFilter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onFilterChange: PropTypes.func,
  containerDims: PropTypes.shape({
    width: PropTypes.number,
  }),
  forceCollapse: PropTypes.bool,
};

export default ResponsiveFilter;
