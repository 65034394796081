import React, { useEffect } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import { selectFiles } from '../../../store/selectors/fileSelectors';
import theme, { groupListCompanyImageSize } from '../../../theme';
import { calculateTotalJobPay } from '../../../utils/Candidate/payHelpers';
import { getFile } from '../../../views/app/fileReducer';
import CorporationLogo from '../../CorporationLogo';

const shiftGroupCardTheme = get(['candidate', 'components', 'shiftGroupCard'], theme);

const sxForCard = {
  padding: theme.spacing(1, 3, 1, 3),
  marginBottom: theme.spacing(1),
  backgroundColor: get(['backgroundColor', 'default'], shiftGroupCardTheme),
  borderRadius: theme.spacing(2),
  display: 'flex',
};
const sxForTitle = {
  color: get(['headers', 'fontColor'], shiftGroupCardTheme),
  fontFamily: get(['headers', 'font'], shiftGroupCardTheme),
  fontSize: '18px',
};
const sxForTotal = {
  color: get(['headers', 'contrastColor'], shiftGroupCardTheme),
  fontFamily: get(['headers', 'font'], shiftGroupCardTheme),
};
const sxForSubtext = {
  color: get(['subtext', 'fontColor'], shiftGroupCardTheme),
  fontFamily: get(['subtext', 'font'], shiftGroupCardTheme),
  fontSize: '14px',
};
const sxForDate = {
  color: get(['subtext', 'fontColor'], shiftGroupCardTheme),
  fontFamily: get(['headers', 'font'], shiftGroupCardTheme),
  fontSize: '14px',
  marginRight: theme.spacing(1),
};

const ListJobCard = ({ corporation, jobOrder }) => {
  const { title, payRateReg, start, end, startDate, startTime, endTime, id } = jobOrder;
  const corporationId = get('_id', corporation);
  const corporationName = get('name', corporation);
  const logo = get('logo', corporation);
  const dispatch = useDispatch();

  const files = useSelector(selectFiles);
  const file = get([`${logo}`], files);

  useEffect(() => {
    if (!file && !file?.isLoading && logo && logo !== null) {
      dispatch(getFile({ id: logo }));
    }
  }, [dispatch, logo, files, file]);

  return (
    <Box sx={sxForCard} key={id}>
      <CorporationLogo
        file={file}
        corporationId={corporationId}
        style={{
          height: groupListCompanyImageSize.height,
          width: groupListCompanyImageSize.width,
          marginRight: theme.spacing(1),
        }}
      />
      <Box item sx={{ justifyContent: 'space-between', width: '100%' }}>
        <Grid container direction="column" justifyContent="space-between">
          <Box sx={{ display: 'flex' }}>
            <Typography sx={sxForDate}>{startDate}</Typography>
            <Typography sx={sxForSubtext}>{`${startTime} - ${endTime}`}</Typography>
          </Box>
          <Box item display="flex" alignItems="center" justifyContent="space-between">
            <Typography sx={sxForTitle}>{title}</Typography>
            <Typography variant="h5" sx={sxForTotal}>
              {calculateTotalJobPay({
                payRateReg,
                start,
                end,
              })}
            </Typography>
          </Box>
          <Box item display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle2" sx={sxForSubtext}>
              {corporationName}
            </Typography>

            <Typography variant="subtitle2" sx={sxForSubtext}>
              ${payRateReg}/hr
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

ListJobCard.propTypes = {
  corporation: PropTypes.shape({}),
  jobOrder: PropTypes.shape({
    payRateReg: PropTypes.number,
    title: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    startDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default ListJobCard;
