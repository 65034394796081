import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Autocomplete, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';

import CheckboxChecked from '../../../../assets/icons/CheckboxChecked.svg';
import CheckboxUnchecked from '../../../../assets/icons/CheckboxUnchecked.svg';
import KeyboardArrowDownIcon from '../../../../assets/icons/KeyboardArrowDownIcon.svg';
import theme from '../../../../theme';

const SELECTDROPDOWN_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const SelectMultipleDropdownForFilter = ({
  field,
  operation,
  options,
  placeholder,
  onValueChange,
  reformatValue,
  getOptionLabel,
  background,
  initialValue,
  sx,
}) => {
  const [value, setValue] = useState(initialValue?.value || []);

  const [open, setOpen] = React.useState(false);
  switch (background) {
    case SELECTDROPDOWN_BACKGROUND.DARK:
      // Dark mode style here
      break;
    case SELECTDROPDOWN_BACKGROUND.LIGHT:
      // Light mode style here
      break;
    default:
      // Default mode style here
      break;
  }

  const styleForFormControl = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '&:hover': {
      backgroundColor: value.length < 1 && get(['searchBoxForFilter', 'bgColor', 'hover'], theme),
    },
    '&:focus-within': {
      backgroundColor: value.length < 1 && get(['searchBoxForFilter', 'bgColor', 'focus'], theme),
      border: `1px solid ${get(['searchBoxForFilter', 'borderColor', 'focus'], theme)}`,
    },
    backgroundColor: value.length > 0 && get(['searchBoxForFilter', 'bgColor', 'complete'], theme),

    borderRadius: '40px',
    justifyContent: 'center',
    height: '40px',
    width: '200px',
    marginLeft: '8px',
  };

  const handleChange = (event, newValue) => {
    if (newValue.length > 0) {
      if (reformatValue) {
        onValueChange([
          {
            value: newValue ? reformatValue(newValue) : null,
            field,
            operation,
          },
        ]);
      } else {
        onValueChange([{ value: newValue ? newValue.value || newValue : null, field, operation }]);
      }
      setValue(newValue);
    } else {
      onValueChange([{ value: [], field }]);
      setValue([]);
    }
  };

  return (
    <FormControl sx={styleForFormControl}>
      <Autocomplete
        multiple
        renderTags={() => null}
        onChange={handleChange}
        value={value}
        popupIcon={
          <Box
            component="img"
            sx={{
              height: 22,
              width: 22,
            }}
            alt="Stars"
            src={KeyboardArrowDownIcon}
          />
        }
        noOptionsText="No results found. Try searching for something else."
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              borderRadius: '16px',
            }}
            placement="auto-start"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {getOptionLabel(option)}
            <Box
              component="img"
              sx={{
                height: 22,
                width: 22,
              }}
              alt="Stars"
              src={selected ? CheckboxChecked : CheckboxUnchecked}
            />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            {...params}
            sx={sx}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Box sx={{ display: 'flex', alignItems: 'center', direction: 'row' }}>
                  {value.length > 0 && (
                    <Chip
                      label={value.length}
                      sx={{
                        height: '22px',
                        width: '35px',
                        backgroundColor: get(['searchBoxForFilter', 'chipBgColor'], theme),
                      }}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </Box>
              ),
            }}
            defaultValue={initialValue || undefined}
          />
        )}
        isOptionEqualToValue={(option, val) => option.name === val.name || option.name === val}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options || []}
        getOptionLabel={(option) => getOptionLabel(option) || ''}
      />
    </FormControl>
  );
};

SelectMultipleDropdownForFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  field: PropTypes.string,
  initialValue: PropTypes.string,
  operation: PropTypes.string,
  onValueChange: PropTypes.func,
  reformatValue: PropTypes.func,
  onSubmitApiCallData: PropTypes.shape({}),
  getOptionLabel: PropTypes.func,
  sx: PropTypes.shape({}),
  background: PropTypes.string,
  multiple: PropTypes.bool,
};

export { SELECTDROPDOWN_BACKGROUND, SelectMultipleDropdownForFilter };
