import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Form as RForm } from 'react-final-form';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import { buildFormField } from '../../../../components/Form/formFieldFactory';
import UserRole from '../../../../constants/user-role';
import theme from '../../../../theme';

import { employerCandidateSharedFields, usersCommonFields } from './FormData/FormFieldDataOverview';
import SendAccountVerificationEmailBtn from './subComponent/SendAccountVerificationEmailBtn';

const detailViewTypographyHeader = ['components', 'detailView', 'typography', 'header'];
const detailViewTypographySubSectionHeader = [
  'components',
  'detailView',
  'typography',
  'subSectionHeader',
];
const detailViewTypographySemiBoldHeader = [
  'components',
  'detailView',
  'typography',
  'semiBoldHeader',
];
const detailViewTypographySemiBoldText = ['components', 'detailView', 'typography', 'semiBoldText'];

const nameSx = {
  fontSize: get([...detailViewTypographyHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographyHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographyHeader, 'fontColor'], theme),
  height: '30px',
  width: '100%',
  marginTop: '-5px',
};

const locationSx = {
  fontSize: get([...detailViewTypographySemiBoldHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySemiBoldHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographySemiBoldHeader, 'fontColor'], theme),
  marginTop: '-10px',
};

const userStatusSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  marginTop: '-5px',
};

const statusSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  marginTop: '10px',
};

const userRoleSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-10px',
};

const roleSx = {
  fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
  color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
  marginTop: '-5px',
};

const userEmailSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-10px',
};

const emailSx = {
  fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
  color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
  marginTop: '-10px',
};

const phoneNumberHeaderSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-10px',
};
const phoneNumberSx = {
  fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
  color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
  marginTop: '-10px',
};

const userRatingSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-5px',
};

const ratingSx = {
  marginTop: '-5px',
};

const upcomingJobsSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-10px',
};

const jobNumSx = {
  fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
  color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
  marginTop: '-10px',
};

const userFlagSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-5px',
};

const DNRSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-10px',
};

const DNASx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-10px',
};

/**
 * A React component that displays common fields for a user profile.
 *
 * @param {Object} initialValues - The initial field values to populate the component.
 * @param {string} selectedUserID - The ID of the selected user.
 * @param {string} userStatus - The current status of the user.
 * @param {string} userEmail - The email of the user.
 *
 * @returns {JSX.Element} A JSX element containing common user fields
 * in a Grid layout.
 */
const UsersCommonFieldLayout = ({ initialValues, selectedUserID, userStatus, userEmail }) => {
  const usersCommonFieldData = usersCommonFields();
  const UNVERIFIED = 'unverified';
  return (
    <>
      <Grid item>
        <Typography sx={nameSx}>{get('name', initialValues)}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={locationSx}>{get('location', initialValues)}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={userStatusSx}>{get('userStatuss', initialValues)}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={statusSx}>{buildFormField(usersCommonFieldData.get('status'))}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={userRoleSx}>{get('userRole', initialValues)}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={roleSx}>{get('role', initialValues)}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={userEmailSx}>
          {buildFormField(usersCommonFieldData.get('userEmail'))}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={emailSx}>{get('email', initialValues)}</Typography>
      </Grid>
      {userStatus === UNVERIFIED && (
        <Grid item sx={{ marginTop: '-5px' }}>
          <SendAccountVerificationEmailBtn userID={selectedUserID} userEmail={userEmail} />
        </Grid>
      )}
      <Grid item>
        <Typography sx={phoneNumberHeaderSx}>
          {buildFormField(usersCommonFieldData.get('phoneNumberHeader'))}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={phoneNumberSx}>
          {buildFormField(usersCommonFieldData.get('phoneNumber'))}
        </Typography>
      </Grid>
    </>
  );
};

/**
 * EmployerCandidateSharedFieldLayout is a functional component that renders a collection of fields
 * shared between employer and candidate in a Grid layout.
 *
 * The component retrieves field data from the employerCandidateSharedFields function and
 * constructs form fields using the buildFormField function for each item in the data.
 *
 * The key and sx properties for each Grid item are set based on the field data,
 * using default values if not provided in the data.
 */
const EmployerCandidateSharedFieldLayout = () => {
  const employerCandidateSharedFieldData = employerCandidateSharedFields();
  return (
    <>
      <Grid item>
        <Typography sx={userRatingSx}>
          {buildFormField(employerCandidateSharedFieldData.get('userRating'))}
        </Typography>
      </Grid>

      <Grid item>
        <Typography sx={ratingSx}>
          {buildFormField(employerCandidateSharedFieldData.get('rating'))}
        </Typography>
      </Grid>

      <Grid item>
        <Typography sx={upcomingJobsSx}>
          {buildFormField(employerCandidateSharedFieldData.get('upcomingJobs'))}
        </Typography>
      </Grid>

      <Grid item>
        <Typography sx={jobNumSx}>
          {buildFormField(employerCandidateSharedFieldData.get('jobNum'))}
        </Typography>
      </Grid>

      <Grid item>
        <Typography sx={userFlagSx}>
          {buildFormField(employerCandidateSharedFieldData.get('userFlag'))}
        </Typography>
      </Grid>

      <Grid item>
        <Typography sx={DNRSx}>
          {buildFormField(employerCandidateSharedFieldData.get('DNR'))}
        </Typography>
      </Grid>

      <Grid item>
        <Typography sx={DNASx}>
          {buildFormField(employerCandidateSharedFieldData.get('DNA'))}
        </Typography>
      </Grid>
    </>
  );
};

/**
 * Overview component that displays a user's information based on their role.
 *
 * @param {Object} props - The component properties.
 * @param {Function} onUpdate - Callback to update the data.
 * @param {Object} initialValues - The initial values for the form.
 * @param {number} count - The number of jobs for the user.
 * @param {Object} DNR - Data for Do Not Rehire.
 * @param {Object} DNA - Data for Do Not Assign.
 * @param {boolean} isLoading - Flag indicating if data is still being loaded.
 */
const Overview = ({ onUpdate, initialValues, count, DNR, DNA, isLoading }) => {
  const userStatus = get('status', initialValues);
  const userRole = get('role', initialValues);
  const selectedUserID = get('id', initialValues);
  const userEmail = get('email', initialValues);
  const [isUserCandidateOrEmployer, setIsUserCandidateOrEmployer] = useState(false);
  const [allFormFieldData, setAllFormFieldData] = useState(new Map([]));

  /*
   * Formats a phone number.
   *
   * @param {string} phoneNumberString - The phone number string.
   * @returns {string|null} - The formatted phone number or null if invalid.
   */
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  };
  const phoneNumber = formatPhoneNumber(`${get(['candidate', 'phoneNumber'], initialValues)}`);

  const initialValuesData = useMemo(
    () => ({
      ...initialValues,
      userStatus: 'User Status',
      userRole: 'User Role',
      userEmail: 'User Email',
      userRating: 'Rating', // TODO:replace with general rating
      upcomingJobs: 'Upcoming Jobs',
      userFlag: 'User Flag',
      DNR: { ...DNR, userID: selectedUserID, isLoading },
      DNA: { ...DNA, userID: selectedUserID, isLoading },
      jobNum: `${count}`,
      phoneNumber,
      phoneNumberHeader: 'Phone',
    }),
    [initialValues, DNR, DNA, selectedUserID, isLoading, count, phoneNumber]
  );

  useEffect(() => {
    setIsUserCandidateOrEmployer(userRole === UserRole.CANDIDATE || userRole === UserRole.EMPLOYER);
    if (isUserCandidateOrEmployer) {
      setAllFormFieldData(new Map([...usersCommonFields(), ...employerCandidateSharedFields()]));
    } else {
      setAllFormFieldData(usersCommonFields());
    }
  }, [isUserCandidateOrEmployer, userRole]);

  const calcInitVals = useMemo(() => {
    const calculatedInitialValues = {};
    allFormFieldData.forEach((value) => {
      const fieldName = get('name', value);
      const targetInitialValue = get('initialValue', value) || get(fieldName, initialValuesData);

      // DevNote: This is intentionally checking against null and not for truthyness!
      if (targetInitialValue !== null) {
        calculatedInitialValues[fieldName] =
          (value.format && value.format(targetInitialValue)) || targetInitialValue;
      }
    });
    return calculatedInitialValues;
  }, [allFormFieldData, initialValuesData]);

  return (
    <RForm
      onSubmit={(values) => {
        // Do nothing
        onUpdate(values);
      }}
      initialValues={calcInitVals}
      render={() => (
        <Grid container spacing={0} direction="row" sx={{ width: '100%' }}>
          <Grid container direction="column" item spacing={3}>
            <UsersCommonFieldLayout
              initialValues={initialValuesData}
              selectedUserID={selectedUserID}
              userStatus={userStatus}
              userEmail={userEmail}
            />
            {userRole === UserRole.CANDIDATE && (
              <EmployerCandidateSharedFieldLayout selectedUserID={selectedUserID} />
            )}
          </Grid>
        </Grid>
      )}
    />
  );
};

UsersCommonFieldLayout.propTypes = {
  initialValues: PropTypes.shape({}),
  recruiterAdminFieldData: PropTypes.shape({}),
  selectedUserID: PropTypes.string,
  userStatus: PropTypes.string,
  userEmail: PropTypes.string,
};

Overview.propTypes = {
  container: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  onUpdate: PropTypes.func,
  count: PropTypes.number,
  DNR: PropTypes.shape({}),
  DNA: PropTypes.shape({}),
  isLoading: PropTypes.bool,
};

export default Overview;
