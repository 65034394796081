/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getOr } from 'lodash/fp';
import get from 'lodash/fp/get';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Drawer, Grid, Typography } from '@mui/material';

import BackdropCircularProgress from '../../../../components/BackdropCircularProgress/BackdropCircularProgress';
import selectUser from '../../../../store/selectors/appSelector';
import {
  selectCancelledPlacement,
  selectIsLoadingCancel,
  selectSelectedPlacement,
} from '../../../../store/selectors/candidateJobsListSelectors';
import theme from '../../../../theme';
import { epochToDateInReadableFormat } from '../../../../utils';
import { cancelPlacementForCandidate, clearCancelledPlacement } from '../reducer';

import RequiredSection from './SubComponents/RequiredSection';
import SuccessDrawer from './SubComponents/SuccessDrawer';
import YourShiftCard from './SubComponents/YourShiftCard';
import {
  sxForAddress,
  sxForDescription,
  sxForLabels,
  sxForMainContainer,
  sxForMainHeader,
  sxForSection,
  sxForValue,
  sxForWrapper,
} from './DetailSx';

const MyJobsDetail = () => {
  const dispatch = useDispatch();
  const selectedPlacement = useSelector(selectSelectedPlacement);
  const isLoadingCancel = useSelector(selectIsLoadingCancel);
  const cancelledPlacement = useSelector(selectCancelledPlacement);
  const user = useSelector(selectUser);

  const [certsHeld, setCertsHeld] = useState([]);
  const [certsNotHeld, setCertsNotHeld] = useState([]);
  const [skillsHeld, setSkillsHeld] = useState([]);
  const [skillsNotHeld, setSkillsNotHeld] = useState([]);

  useEffect(() => {
    const certsUserHas = [];
    const certsUserDoesNotHave = [];
    const skillsUserHas = [];
    const skillsUserDoesNotHave = [];

    const usersSkills = getOr([], ['candidate', 'skills'], user);
    const usersSkillsId = usersSkills.map((sk) => sk.skill);
    const usersCerts = getOr([], ['candidate', 'certifications'], user);

    selectedPlacement.certifications &&
      selectedPlacement.certifications.forEach((cert) => {
        if (usersCerts.includes(cert._id)) {
          certsUserHas.push(cert);
        } else {
          certsUserDoesNotHave.push(cert);
        }
      });

    selectedPlacement.skills &&
      selectedPlacement.skills.forEach((sk) => {
        if (usersSkillsId.includes(sk._id)) {
          skillsUserHas.push(sk);
        } else {
          skillsUserDoesNotHave.push(sk);
        }
      });

    setCertsHeld(certsUserHas);
    setCertsNotHeld(certsUserDoesNotHave);
    setSkillsHeld(skillsUserHas);
    setSkillsNotHeld(skillsUserDoesNotHave);
  }, [selectedPlacement]);

  const closeSuccessDrawer = () => {
    dispatch(clearCancelledPlacement());
  };

  return (
    <Grid item container sx={sxForMainContainer}>
      <Grid
        item
        sx={{
          width: '100%',
          height: 'inherit',
          overflowY: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <Box>
          <Typography sx={sxForLabels}>
            {get(['corporation', 'name'], selectedPlacement)}
          </Typography>
          <Typography sx={sxForMainHeader}>
            {get(['jobOrder', 'name'], selectedPlacement)}
          </Typography>
          <Box sx={sxForAddress}>
            <Typography>{get(['location', 'address'], selectedPlacement)}</Typography>
            <Typography>
              {get(['location', 'city'], selectedPlacement)}
              {get(['location', 'state'], selectedPlacement)}
              {get(['location', 'zipcode'], selectedPlacement)}
            </Typography>
          </Box>
        </Box>

        <Box sx={sxForSection}>
          <Box>
            <Typography sx={sxForValue}>
              {epochToDateInReadableFormat(get(['jobOrder', 'start'], selectedPlacement))}
            </Typography>
            <Typography sx={sxForLabels}>Date</Typography>
          </Box>
          <Box sx={{ ...sxForWrapper, margin: theme.spacing(1, 0, 1, 0) }}>
            <Box sx={{ marginRight: theme.spacing(4) }}>
              <Typography sx={sxForValue}>
                {get(['jobOrder', 'shiftPay'], selectedPlacement)}
              </Typography>
              <Typography sx={sxForLabels}>Est. shift earnings</Typography>
            </Box>
            <Box>
              <Typography sx={sxForValue}>
                ${get(['jobOrder', 'payRateReg'], selectedPlacement)}/hr
              </Typography>
              <Typography sx={sxForLabels}>Hourly Rate</Typography>
            </Box>
          </Box>
          <Box>
            <Typography sx={sxForValue}>
              {get(['location', 'description'], selectedPlacement)}
            </Typography>
            <Typography sx={sxForLabels}>Location name</Typography>
          </Box>
        </Box>

        <Box sx={sxForSection}>
          <Typography sx={sxForDescription}>
            {get(['jobOrder', 'description'], selectedPlacement)}
          </Typography>
        </Box>

        <Box sx={sxForSection}>
          <Typography sx={{ ...sxForValue, marginBottom: theme.spacing(1) }}>
            Required Certifications
          </Typography>
          {selectedPlacement.jobOrder.certifications &&
          selectedPlacement.jobOrder.certifications.length > 0 ? (
            <RequiredSection certsOrSkillsHeld={certsHeld} certsOrSkillsNotHeld={certsNotHeld} />
          ) : (
            <Typography sx={{ ...sxForLabels, marginBottom: theme.spacing(1) }}>
              There are no required certifications for this job
            </Typography>
          )}
        </Box>
        <Box sx={sxForSection}>
          <Typography sx={{ ...sxForValue, marginBottom: theme.spacing(1) }}>
            Required Skills
          </Typography>
          {selectedPlacement.skills && selectedPlacement.skills.length > 0 ? (
            <RequiredSection certsOrSkillsHeld={skillsHeld} certsOrSkillsNotHeld={skillsNotHeld} />
          ) : (
            <Typography sx={{ ...sxForLabels, marginBottom: theme.spacing(1) }}>
              There are no required skills for this job
            </Typography>
          )}
        </Box>

        <Box sx={sxForSection}>
          <Typography sx={{ ...sxForValue, marginBottom: theme.spacing(1) }}>Your shift</Typography>

          <YourShiftCard
            start={selectedPlacement.jobOrder.start}
            end={selectedPlacement.jobOrder.end}
            shiftPay={selectedPlacement.jobOrder.shiftPay}
            id={selectedPlacement._id}
            timecard={selectedPlacement.timecard}
            cancelPlacement={(props) => dispatch(cancelPlacementForCandidate(props))}
          />
        </Box>
      </Grid>
      {isLoadingCancel && <BackdropCircularProgress />}

      <Drawer
        anchor="right"
        open={cancelledPlacement}
        PaperProps={{ sx: { width: '500px', maxWidth: '100vw' } }}
      >
        <SuccessDrawer
          onClose={closeSuccessDrawer}
          header={`You have successfully cancelled your shift as a ${get(
            ['jobOrder', 'name'],
            cancelledPlacement
          )} on ${epochToDateInReadableFormat(get(['jobOrder', 'start'], cancelledPlacement))}`}
        />
      </Drawer>
    </Grid>
  );
};

export default MyJobsDetail;
