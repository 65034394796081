import React from 'react';
import dayjs from 'dayjs';
import get from 'lodash/fp/get';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import Label from '../../../components/Label';
import {
  WAE_SUPPORTED_FILTER_FIELD_TYPES,
  WAE_SUPPORTED_STRING_FILTER_OPERATORS,
} from '../../../constants/filterQueries';
import { BLACK } from '../../../theme/colorConstants';
import { handleLabelProps } from '../helpers';

const advancedFormat = require('dayjs/plugin/advancedFormat');

const formatToFixed = (params) => {
  if (typeof get('value', params) !== 'number' && get('value', params) !== undefined) {
    return params.value;
  }
  return params.value !== undefined ? params.value.toFixed(2) : 'loading';
};

dayjs.extend(advancedFormat);

const status = {
  field: 'status',
  filterOperators: WAE_SUPPORTED_STRING_FILTER_OPERATORS,
  headerName: '',
  headerClassName: 'theme-header',
  width: 120,
  renderCell: (params) => {
    const isDetailOpen = get(['value', 'detailViewStatus'], params);
    return (
      <Box>
        <Label {...handleLabelProps(get(['value', 'label'], params))} collapsed={isDetailOpen} />
      </Box>
    );
  },
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.STRING,
};

const candidateName = {
  field: 'employeeName',
  headerName: 'Employee Name',
  headerClassName: 'theme-header',
  flex: 1.5,
};

const regular = {
  field: 'regularHours',
  valueFormatter: formatToFixed,
  headerName: 'Reg',
  align: 'center',
  headerAlign: 'center',
  headerClassName: 'theme-header',
  flex: 1,
};
const overtime = {
  field: 'overtimeHours',
  valueGetter: formatToFixed,
  headerName: 'OT',
  align: 'center',
  headerAlign: 'center',
  headerClassName: 'theme-header',
  flex: 1,
};
const total = {
  field: 'totalHours',
  valueGetter: formatToFixed,
  headerName: 'Total',
  align: 'center',
  headerAlign: 'center',
  headerClassName: 'theme-header',
  flex: 1,
};

export const getDays = (startEpoch) => {
  const days = [];
  const startObject = dayjs.unix(startEpoch);

  for (let day = 0; day <= 6; day += 1) {
    const dayObject = startObject.add(day, 'day');

    const weekday = dayObject.format('ddd');
    const fullWeekDayName = dayObject.format('dddd');
    const date = dayObject.format('MM/DD');
    const dateHeader = dayObject.format('MM/DD/YYYY').replace(/\b0/g, '');
    const dayEpoch = dayObject.format('X');
    const dayObjectHeader = {
      id: dayEpoch,
      field: dateHeader,
      valueGetter: formatToFixed,
      weekday,
      dayObject,
      fullWeekDayName,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Barlow-500',
              fontSize: '12px',
              color: `${BLACK[100]}`,
            }}
          >
            {weekday}
          </Typography>

          <Typography
            sx={{
              fontFamily: 'Barlow-600',
              fontSize: '14px',
              fontColor: `${BLACK[100]}`,
            }}
          >
            {date}
          </Typography>
        </div>
      ),
    };
    days.push(dayObjectHeader);
  }
  return days;
};

export const getTimekeepingGridColumnData = (startEpoch) => {
  const days = getDays(startEpoch);
  const timekeepingGridColumnData = [status, candidateName, ...days, regular, overtime, total];
  return timekeepingGridColumnData;
};
