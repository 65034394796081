import React from 'react';
import PropTypes from 'prop-types';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { navigationBarHeight, paperElevation } from '../../../theme';

const DetailView = ({ close, Content }) => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const phoneScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    (
      <Slide direction="left" in unmountOnExit timeout={200}>
        {/* FIXME: height needs to be changed once filter component is created and exist above the detail view */}
        <Grid item md={6} xs sx={{ height: '100%' }}>
          <Paper
            elevation={mediumScreen ? paperElevation : 0}
            sx={{
              backgroundColor: theme.components.detailView.palette.backgroundColor,
              borderRadius: '16px',
              height: phoneScreen
                ? `calc(95vh - ${navigationBarHeight})` // TODO: 95vh need to be fixed, not good for responsive design
                : `calc(86vh - ${navigationBarHeight})`,
              position: 'relative',
              top: 0,
              left: 0,
            }}
          >
            <Grid sx={{ height: `100%`, width: `100%`, position: 'relative' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: `100%`,
                }}
              >
                <IconButton aria-label="close">
                  <CloseRoundedIcon
                    style={{ color: theme.components.detailView.palette.closeIconWhiteColor }}
                    onClick={close}
                  />
                </IconButton>
              </Box>
              <Grid sx={{ height: '90%' }}>
                <Content />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Slide>
    ) || null
  );
};
DetailView.propTypes = {
  close: PropTypes.func,
  Content: PropTypes.func,
};
export default DetailView;
