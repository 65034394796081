import React from 'react';
import PropTypes from 'prop-types';

import { Circle, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import pin from '../../assets/icons/MapPin.svg';

const SERVER_GOOGLE_MAPS_KEY = 'AIzaSyCW3Djd63eQhL4pU5ExhLoUnqVB2ljhL6c'; // This key is restricted to career start IP address. VPN necessary.

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '20px',
};

const WaeMap = ({ center, markers, zoomLevel }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_LOCAL_GOOGLE_MAPS_API_KEY || SERVER_GOOGLE_MAPS_KEY,
  });
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(
    (currentMap) => {
      const bounds = new window.google.maps.LatLngBounds(center);

      if (currentMap.current) {
        currentMap.current.fitBounds(bounds);
        setMap(currentMap);
      }
    },
    [center]
  );

  // eslint-disable-next-line no-unused-vars
  const onUnmount = React.useCallback((thisMap) => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoomLevel}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {markers.map((marker) => (
        <>
          <Marker icon={marker.icon || pin} onLoad={onLoad} position={marker.position} />
          {marker.circle && (
            <Circle
              onLoad={onLoad}
              onUnmount={onUnmount}
              center={marker.position}
              radius={marker.circle.radius} // Meters
              options={{
                fillColor: marker.circle.fillColor,
                strokeColor: marker.circle.strokeColor || 'transparent',
              }}
            />
          )}
        </>
      ))}
    </GoogleMap>
  ) : (
    <>CONTENT</>
  );
};

export default WaeMap;
WaeMap.propTypes = {
  center: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  markers: PropTypes.arrayOf(PropTypes.shape({})),
  zoomLevel: PropTypes.number,
};
