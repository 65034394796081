import React from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import List from '@mui/material/List';

import UserRole from '../../../constants/user-role';
import launchDarklyToggles from '../../../utils/launchDarklyToggles';

import DefaultNavMenu from './DefaultNavMenu';
import JobsMenu from './JobsMenu';
import PunchInOutMenu from './PunchInOutMenu';

const DrawerMenus = ({ closeDrawer, role, flags, isOpen }) => {
  const { ADMIN, CANDIDATE, RECRUITER } = UserRole;
  const isAdmin = role === ADMIN;
  const isEmployee = role === CANDIDATE;
  const isRecruiter = role === RECRUITER;
  const isAdminMetricsPageEnabled = launchDarklyToggles(flags, 'isAdminMetricsPageEnabled');
  const timesheetToggle = launchDarklyToggles(flags, 'isTimesheetEnabled');

  return (
    <List component="nav">
      <DefaultNavMenu
        closeDrawer={closeDrawer}
        isOpen={isOpen}
        url="dashboard"
        Icon={PieChartOutlineOutlinedIcon}
        label="Dashboard"
        testId="dashboard"
      />
      {isEmployee && <PunchInOutMenu closeDrawer={closeDrawer} isOpen={isOpen} />}
      <DefaultNavMenu
        closeDrawer={closeDrawer}
        isOpen={isOpen}
        url="jobs/positionTemplateList"
        Icon={PersonAddOutlinedIcon}
        label="Positions"
        testId="positions"
      />
      <JobsMenu
        closeDrawer={closeDrawer}
        isOpen={isOpen}
        Icon={BusinessCenterOutlinedIcon}
        testId="jobs"
      />
      {timesheetToggle && (
        <DefaultNavMenu
          closeDrawer={closeDrawer}
          isOpen={isOpen}
          url="timekeeping"
          Icon={QueryBuilderOutlinedIcon}
          label="Timekeeping"
          testId="timekeeping"
        />
      )}
      {isAdmin && isAdminMetricsPageEnabled && (
        <DefaultNavMenu
          closeDrawer={closeDrawer}
          isOpen={isOpen}
          url="reporting"
          Icon={EqualizerIcon}
          label="Reporting"
          testId="reporting"
        />
      )}
      {(isAdmin || isRecruiter) && (
        <DefaultNavMenu
          closeDrawer={closeDrawer}
          isOpen={isOpen}
          url="users/usersList"
          Icon={PeopleOutlinedIcon}
          label="Users"
          testId="users"
        />
      )}
      {(isAdmin || isRecruiter) && (
        <DefaultNavMenu
          closeDrawer={closeDrawer}
          isOpen={isOpen}
          url="corporations/corporationList"
          Icon={BusinessIcon}
          label="Corporations"
          testId="corporations"
        />
      )}
      {isAdmin && (
        <DefaultNavMenu
          closeDrawer={closeDrawer}
          isOpen={isOpen}
          url="skillsAndCerts"
          Icon={CheckCircleOutlineIcon}
          label="Skills & Certs"
          testId="skillsAndCerts"
        />
      )}
    </List>
  );
};

DrawerMenus.propTypes = {
  closeDrawer: PropTypes.func,
  role: PropTypes.string,
  flags: PropTypes.shape({}),
  isOpen: PropTypes.bool,
};

export default withLDConsumer()(DrawerMenus);
