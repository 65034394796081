import * as React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import BackArrow from '../../../assets/icons/BackArrow.svg';
import ForwardArrow from '../../../assets/icons/ForwardArrow.svg';
import MuiIconButton from '../../../components/IconButton/IconButton';
import theme from '../../../theme';

const weeksFilter = get(
  ['candidate', 'components', 'timeCards', 'typography', 'weeksFilter'],
  theme
);

const WeekFilter = ({ handleAddWeekOnClick, handleSubtractWeekOnClick, startEpoch }) => {
  const weekStartDate = (epoch) =>
    `${new Date(Math.round(Number(epoch * 1000))).getUTCMonth() + 1}/${new Date(
      Math.round(Number(epoch * 1000))
    ).getUTCDate()}/${new Date(Math.round(Number(epoch * 1000))).getUTCFullYear()}`;

  const weekEndDate = (epoch) =>
    `${new Date(Math.round(Number(epoch * 1000 + 518400000))).getUTCMonth() + 1}/${new Date(
      Math.round(Number(epoch * 1000 + 518400000))
    ).getUTCDate()}/${new Date(Math.round(Number(epoch * 1000 + 518400000))).getUTCFullYear()}`;
  return (
    <Grid container sx={{ paddingTop: '24px', paddingBottom: '8px' }}>
      <MuiIconButton
        onClick={() => handleSubtractWeekOnClick()}
        sx={{ height: '32px', width: '32px' }}
        iconElement={
          <Box
            component="img"
            sx={{
              height: '24px',
              width: '24px',
            }}
            alt="Back"
            src={BackArrow}
          />
        }
      />
      <Box sx={{ display: 'flex', marginTop: '3px' }}>
        <Box sx={{ ...weeksFilter, marginLeft: '8px', marginRight: '8px' }}>{`${weekStartDate(
          startEpoch
        )} - ${weekEndDate(startEpoch)}`}</Box>
      </Box>

      <MuiIconButton
        onClick={() => handleAddWeekOnClick()}
        sx={{ height: '32px', width: '32px' }}
        iconElement={
          <Box
            component="img"
            sx={{
              height: '24px',
              width: '24px',
            }}
            alt="Forward"
            src={ForwardArrow}
          />
        }
      />
    </Grid>
  );
};

WeekFilter.propTypes = {
  handleAddWeekOnClick: PropTypes.func,
  handleSubtractWeekOnClick: PropTypes.func,
  startEpoch: PropTypes.number,
};

export default WeekFilter;
